import api from "../../services/api";

export default {
  namespaced: true,
  state: {
    business_address: {}
  },
  getters: {
    business_coordinates: state => state.business_address.geo
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async fetchMyAddress({ commit }) {
      const response = await api.getMyBusinessAddress();
      commit("updateValue", {
        business_address: response.data.results
      });
    }
  }
};
