<template>
  <v-fade-transition name="loading-fade" @after-leave="handleAfterLeave">
    <v-overlay
      v-model="visible"
      absolute
      :opacity="0.45"
      :z-index="99"
      :class="[customClass, { 'is-fullscreen': fullscreen }]"
      :style="{ backgroundColor: background || '' }"
    >
      <v-progress-circular v-if="!spinner" indeterminate color="primary"></v-progress-circular>
      <v-progress-linear v-else indeterminate></v-progress-linear>
      <p v-if="text" class="loading-text">{{ text }}</p>
    </v-overlay>
  </v-fade-transition>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      text: null,
      spinner: null,
      background: null,
      fullscreen: true,
      visible: false,
      customClass: ""
    };
  },
  methods: {
    handleAfterLeave() {
      this.$emit("after-leave");
    },
    setText(text) {
      this.text = text;
    }
  }
};
</script>
<style lang="scss">
.loading-parent--relative {
  position: relative;
}
.loading-parent--hidden {
  display: none;
}
</style>
