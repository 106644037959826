import api from "../../services/api";

export default {
  namespaced: true,
  state: {
    results: {}
  },
  getters: {},
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async search({ commit }, { q, limit, skip }) {
      const res = await api.search(q, limit, skip);
      if (res.data) {
        commit("updateValue", {
          results: res.data.results
        });
      }
    }
  }
};
