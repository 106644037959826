import axios from "axios";
import Cookie from "js-cookie";
import qs from "qs";
import { getToken } from "./authority";
import { map, isObject, has } from "lodash";
import eventBus from "../plugin/eventBus";
window.Cookie = Cookie;

/**
 * Config axios request and response
 */
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.request.use(
  config => {
    config.headers.common["Authorization"] = getToken();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const errorHandler = error => {
  if (error.response) {
    let errorMsg = undefined;
    const errorDetail = error.response.data;
    if (isObject(errorDetail)) {
      if (has(errorDetail, "error.details")) {
        errorMsg = map(errorDetail.error.details, "message");
      } else {
        errorMsg = errorDetail.detail || errorDetail.error || errorDetail.message;
      }
    }
    swal(errorMsg || JSON.stringify(errorDetail), "", "error");
  } else if (error?.request?.status != 0) {
    swal(error.message || JSON.stringify(error), "", "error");
  }
  return Promise.reject(error);
};

const successHandler = response => {
  return response;
};

axios.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
const api_prefix = "/api";

const get = (url, params) => {
  eventBus.emit("request_start", url);
  return axios
    .get(`${api_prefix || "/"}${url}?${qs.stringify(params)}`.replace("//", "/"))
    .then(res => {
      eventBus.emit("request_end", url);
      return res;
    })
    .catch(err => {
      eventBus.emit("request_end", url);
      throw err;
    });
};

const post = (url, data, headers = {}) => {
  eventBus.emit("request_start", url);
  const _url = `${api_prefix || "/"}${url}`.replace("//", "/");
  return axios({
    url: _url,
    data: data,
    method: "post",
    headers: headers
  })
    .then(res => {
      eventBus.emit("request_end", url);
      return res;
    })
    .catch(err => {
      eventBus.emit("request_end", url);
      return err;
    });
};

const put = (url, data, headers = {}) => {
  eventBus.emit("request_start", url);
  const _url = `${api_prefix || "/"}${url}`.replace("//", "/");
  return axios({
    url: _url,
    data: data,
    method: "put",
    headers: headers
  })
    .then(res => {
      eventBus.emit("request_end", url);
      return res;
    })
    .catch(err => {
      eventBus.emit("request_end", url);
      return err;
    });
};

const _delete = (url, params, headers = {}) => {
  eventBus.emit("request_start", url);
  const _url = `${api_prefix || "/"}${url}?${qs.stringify(params)}`.replace("//", "/");
  return axios({
    url: _url,
    method: "delete",
    headers: headers
  })
    .then(res => {
      eventBus.emit("request_end", url);
      return res;
    })
    .catch(err => {
      eventBus.emit("request_end", url);
      return err;
    });
};

window.axios = axios;

export default {
  get,
  post,
  put,
  _delete
};
