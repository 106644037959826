import api from "../../services/api";

export default {
  namespaced: true,
  state: {
    list: []
  },
  getters: {},
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async fetchList({ commit }) {
      const response = await api.getList("company-type", "", "", "");
      commit("updateValue", {
        list: response.data.results
      });
    }
  }
};
