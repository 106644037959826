import _ from "lodash";
import api from "../../services/api";
import memberApi from "../../services/memberApi";

export default {
  namespaced: true,
  state: {
    notificationList: [
      {
        id: "",
        status: "",
        title: "",
        type: "",
        url: "",
        content: ""
      }
    ],
    memberInfo: {},
    memberProduct: []
  },
  getters: {},
  actions: {
    async fetchNotificationList(store, payload) {
      const response = await api.getNotificationList();
      if (response.data) {
        store.commit("save", {
          payload: response.data.data,
          keyPath: "notificationList"
        });
      }
    },
    async getMemberInfo(store, payload) {
      const res = await api.getMemberInfo();
      if (res.data) {
        store.commit("save", {
          payload: res.data,
          keyPath: "memberInfo"
        });
      }
    },
    async addClub(store, { payload }) {
      const { id } = payload;
      return await api.addClub(id);
    },
    async removeClub(store, { payload }) {
      const { id } = payload;
      return await api.removeClub(id);
    },
    async deleteItem(store, { payload }) {
      const { id, endpoint } = payload;
      return await api.deleteItems(endpoint, { id });
    },
    async getMemberProduct(store, payload) {
      const res = await memberApi.getMemberHomeProduct();
      if (res.data.results) {
        store.commit("save", {
          payload: res.data.results,
          keyPath: "memberProduct"
        });
      }
    },
    clear(store) {
      store.commit("clear");
    }
  },
  mutations: {
    save(state, action) {
      _.set(state, action.keyPath, action.payload);
    },
    clear(state) {
      state.notificationList = [
        {
          id: "",
          status: "",
          title: "",
          type: "",
          url: "",
          content: ""
        }
      ];
      state.memberInfo = {};
    }
  }
};
