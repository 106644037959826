export default {
  namespaced: true,
  state: {
    global: false,
    actions: {},
    requests: {}
  },
  mutations: {
    SHOW(state, { payload }) {
      state.global = true;
      state.actions = {
        ...state.actions,
        [payload]: true
      };
    },
    HIDE(state, { payload }) {
      state.global = false;
      state.actions = {
        ...state.actions,
        [payload]: false
      };
    },
    START_Request(state, { payload }) {
      state.global = true;
      state.requests = {
        ...state.requests,
        [payload]: true
      };
    },
    END_Request(state, { payload }) {
      state.global = false;
      state.requests = {
        ...state.requests,
        [payload]: false
      };
    }
  }
};
