import _ from "lodash";
import api from "../../services/api";
export default {
  namespaced: true,
  state: {
    myCompanies: [],
    current: undefined
  },
  getters: {
    primaryCompany(state) {
      return _.find(state.myCompanies, x => !x.parentCompany);
    },
    childCompanies(state) {
      return _.filter(state.myCompanies, x => !!x.parentCompany);
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },
    fetchMyCompanies({ commit }) {
      api.getList("company", undefined, undefined, undefined).then(res => {
        commit("updateValue", {
          myCompanies: res.data.results
        });
      });
    },
    async fetchCurrentCompany({ commit }) {
      const res = await api.getBusinessCompany();
      if (res.data) {
        commit("updateValue", {
          current: res.data.results
        });
      }
    },
    switchBusinessCompany(x, { id }) {
      api.setBusinessCompany(id).then(res => {
        if (res.data.status === "success") {
          window.location.reload();
        }
      });
    }
  }
};
