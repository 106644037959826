/*
 * @Author: Peter
 * @Date:   2019-01-03 17:44:25
 * @Last Modified by: Darcy
 * @Last Modified time: 2020-03-08 20:24:03
 */

import Vue from "vue";
import Vuex from "vuex";
import { loadingPlugin } from "./loadingPlugin";
import * as modules from "./modules";
import * as memberModules from "./modules/member-portal";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ...modules,
    ...memberModules
  },
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    member: "",
    google_recaptcha: false
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    authMember(state, member) {
      state.member = Object.assign({}, member);
    },
    save(state, { key, value }) {
      state[key] = value;
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  },
  actions: {
    turnGoogleRecaptcha(store) {
      store.commit("save", { key: "google_recaptcha", value: true });
    }
  },
  plugins: [loadingPlugin]
});

export default store;
