/*
 * @Author: django-wong
 * @Date:   2018-09-30 01:41:49
 * @Last Modified by: Darcy
 * @Last Modified time: 2020-07-07 08:01:20
 */
import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";
import VueRouter from "vue-router";
import vuetify from "./plugin/vuetify";
import _ from "lodash";
import Confirmation from "./components/common/confirmation.vue";
import Loading from "./packages/loading";
import SwitchLanguage from "./components/common/SwitchLanguage.vue";

// i18n parameter
import VueI18n from "vue-i18n";
// const config = dotenv.config();
Vue.use(VueI18n);

let language;
if (localStorage.getItem("locale") != null) {
  language = localStorage.getItem("locale");
} else {
  language = "zhtw";
}

const locales = {
  locale: language, // change the default language here
  messages: {
    en: require("../locales/en.json"),
    zhtw: require("../locales/zhtw.json"),
    zh: require("../locales/zh.json"),
    ja: require("../locales/ja.json"),
    ko: require("../locales/ko.json"),
    es: require("../locales/es.json"),
    mm: require("../locales/mm.json")
  }
};
const i18n = new VueI18n(locales);

try {
  window.$ = window.jQuery = require("jquery");
  require("bootstrap");
} catch (e) {
  console.error(e.message);
}

import VueSession from "vue-session";

// require("plugins/datatables.js");
// require("plugins/common.js");

import store from "./store";

Vue.use(VueRouter);
Vue.use(VueSession);
Vue.use(Loading);

// stripe setting

// import { StripeCheckout } from 'vue-stripe';
// Vue.component('stripe-checkout', StripeCheckout);
// //end of stripe setting

let routes = require("./config/routes/public").default;
Object.defineProperty(Vue.prototype, "$_", {
  value: _
});

const router = new VueRouter({
  routes: routes,
  mode: "history"
});

window.app = new Vue({
  i18n,
  router,
  store,
  vuetify,
  components: {
    Confirmation,
    SwitchLanguage
  }
}).$mount("#app");
