<template>
  <v-btn text class="mr-3" v-if="languages.length && toolbar.translate">
    <a
      :class="getClass(languages[0].value)"
      @click="chLanguage(languages[0].value)"
    >{{ display(languages[0].value) }}</a>
    <span v-for="(language, i) in languages" :key="language.value" v-show="i">
      |
      <a
        :class="getClass(language.value)"
        @click="chLanguage(language.value)"
      >{{ display(language.value) }}</a>
    </span>
  </v-btn>
</template>

<script>
import { getLanguages } from "../../utils/language";
import Cookies from "js-cookie";

export default {
  name: "SwitchLanguage",
  data() {
    return {
      toolbar: {},
      languages: [],
      lan: ''
    };
  },
  mounted() {
    axios.get("/api/setting/" + "language").then(res => {
      this.languages = getLanguages().filter(x => res.data.language.includes(x.value));
      let lang = localStorage.getItem("locale") || res.data.default;
      this.chLanguage(lang);
    });
    axios.get("/api/setting/" + "default").then(res => {
      this.toolbar = res.data;
    });
  },
  methods: {
    chLanguage(lang) {
      Cookies.set("lang", lang);
      this.$vuetify.lang.current = lang;
      localStorage.setItem("locale", lang);
      this.$i18n.locale = lang;
      this.lan = lang;
    },
    display(lang) {
      if (lang == "en") return "EN";
      else if (lang == "zhtw") return "中文";
      else return lang;
    },
    getClass(lang) {
      let style = "text-caption";
      if (this.$i18n.locale != lang) style += " grey--text";
      if (lang == "zhtw") style += " font-weight-bold";
      return style;
    }
  }
};
</script>
