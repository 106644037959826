module.exports = {
	"Account or password was incorrect!": "Account or password was incorrect!",
	"New order submitted": "New order submitted.",
	"Order Updated": "Order Updated.",
	"Order Number": "Order Number :",
	"Table Number": "Table No. :",
	"Clear Search": "Clear Search",
	"Inmydevice": "In my device",
	"InOthersdevice": "In Others device",
	"Campaign Approval": "Campaign Approval",
	"Please approve this campaign": "Please approve this campaign",
	"Can't get your device info": "Can't get your device info",
	"Device does not exist or has been deleted, please reselect": "Device does not exist or has been deleted, please reselect",
	"Duplicate current plan": "Duplicate current plan",
	"Email and passcode is required!": "Email and passcode is required!",
	"Email or password is incorrect": "Email or password is incorrect",
	"Find device error": "Find device error",
	"Find user error": "Find user error",
	"Get current customer plan is required": "Get current customer plan is required",
	"Hello": "",
	"Id is required!": "Id is required!",
	"Inconsistent password": "Inconsistent password",
	"Member does not exist, Please try again later!": "Member does not exist, Please try again later!",
	"No id": "No id",
	"Please enter the device name": "Please enter the device name",
	"Please enter your email": "Please enter your email",
	"Please enter your mobile": "Please enter your mobile",
	"Please select an item first": "Please select an item first",
	"Signin error": "Signin error",
	"New Campaign is created using your device": "New Campaign is created using your device.",
	"adName": "Ad Name",
	"collectionName": "Ad-collection Name",
	"campaignName": "Campaign Name",
	"Ad": "Ad",
	"Collection": "Collection",
	"Campaign": "Campaign",
	"DraggingCardInfo1": "You can change the order of the ads by dragging the 「<span class='mdi mdi-drag'></span>」 of the selected ad.",
	"DraggingCardInfo2": "If you want to save the changes of the order of the ads, be sure to click the「Save」button.",
	"LinePayInfo1": "Please log in to <a target='_blank' href='https://pay.line.me/portal/tw/auth/login'>https://pay.line.me/portal/tw/auth/login</a> to check the relevant settings.",
	"LinePayInfo2": "In Manage Connection Key, copy the Channel ID and Channel Secret Key and fill in below.",
	"LinePayInfo3": "In Manage Payment Server IP, fill in 35.236.171.52.",
	"expertModeInfo": "By Enabling the expert mode, E-commerce & CRM module will become available.",
	"expertModeInfo2": "Expert mode only available for the Prime membership within valid date.",
	"Company": "Company",
	"SimpleAd": "Simple Ads",
	"Default": "Default",
	"Company Message": "Company Message",
	"My ads in device": "My ads in device",
	"Other ads in device": "Other ads in device",
	"AdsInfoCampaignInfo": "Ads Info & Campaign Info",
	"Campaign Info": "Campaign Info",
	"Advertising Info": "Advertising Info",
	"pending": "pending",
	"active": "active",
	"Add New Ads for": "Add New Ads for",
	"Create News Ticker": "Create News Ticker",
	"Tel": "Tel",
	"SmsLimitReach": "The amount of free SMS sent by the merchant on the day has been used up.",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "Please wait patiently for your account in the middle of the review.",
		"Your account does not have a subscription. Please subscribe to post an ad": "Your account does not have a subscription. Please subscribe to post an ad."
	},
	"Swal": {
		"Please select only one video": "Please select only one video",
		"Please select at least one Ads Asset": "Please select at least one Ads Asset",
		"upgradeplan": "Please upgrade your current plan!",
		"exceededmaximum12": "You have exceeded maximum 12 campaigns for your plan.",
		"Next Page": "Next Page",
		"Pleasecreaterolefirst": "Please create user role first!",
		"Pleasecreateroleandteamfirst": "Please create role & team first!",
		"Please update company information": "To be able to create campaign and purchase device, please update company information.",
		"PleaseBuyDevice": "To be able to create campaign, please purchase at least one device.",
		"DetailCompanyInfo": "(Company name, company type, phone-number and address)",
		"blockerText": "Our website is made possible by displaying online advertisements to our visitors.",
		"blockerText2": "Please consider supporting us by disabling your ad blocker.",
		"member_sign_up": {
			"Please register to be part of this club": "Please register to be part of this club."
		},
		"term": {
			"Currently there is no defined terms and conditions yet": "Currently there is no defined terms & conditions yet.",
			"Sorry": "Sorry"
		},
		"More than available stock": "More than available stock",
		"Are you sure?": "Are you sure?",
		"You will not be able to recover this!": "You will not be able to recover this!",
		"Yes, delete it!": "Yes, delete it!",
		"No, cancel plx!": "No, cancel plx!",
		"You must register your company first!": "You must register your company first!",
		"Exceed the maximum number of Ad": "Exceed the maximum number of Ad",
		"Upgrade your account,and create more Advertising": "Upgrade your account,and create more Advertising.",
		"Out of stock": "Out of stock",
		"Please select the variant!": "Please select the variant!",
		"Invalid coupon code": "Invalid coupon code",
		"Invalid coupon code for this product!": "Invalid coupon code for this product!",
		"Valid coupon code": "Valid coupon code",
		"Add success": "Successfully Added!",
		"Add success We will": "Add success,We will send the password to your email",
		"Already in the cart": "Already in the cart",
		"Amount should be more than zero": "Amount should be more than zero!",
		"Bind Success": "Bind Success",
		"Buy device success": "Buy device success",
		"Device type is required": "Device type is required",
		"Purchase type is required": "Purchase type is required",
		"Position is required": "Position is required",
		"Please enter the device number": "Please enter the device number",
		"Edit success": "Edited Successfully!",
		"End date must be greater than start date!": "End date must be greater than start date!",
		"Please select Device!": "Please select Device!",
		"Available maximum file size is 5MB": "Available maximum file size is image 5MB & video 30MB.",
		"Please select a valid image": "Please select a valid image",
		"Please select a valid video": "Please select a valid video",
		"Session time out.Please log in again": "Session time out.Please log in again",
		"Something went wrong": "Something went wrong",
		"Subscription success": "Subscription success",
		"Successfully approved": "Successfully approved!",
		"Successfully generated": "Successfully generated!",
		"Your profile has been successfully updated": "Your profile has been successfully updated",
		"account": {
			"Cancel Subscription success": "Cancel Subscription success",
			"Cancel subscription error": "Cancel subscription error",
			"The current user does not support this feature at this time": "The current user does not support this feature at this time.",
			"This operation is not supported at this time": "This operation is not supported at this time"
		},
		"ad": {
			"Ad created successfully": "Ad created successfully",
			"Ad edited successfully": "Ad edited successfully",
			"Cancel": "Cancel",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "Congratulations!You have created your first ad.Do you like to continue?"
			},
			"Continue": "Continue",
			"Product category is needed!": "Product category is needed!",
			"Product name is needed!": "Product name is needed!",
			"success info": "success info"
		},
		"sign": {
			"Agreement Required": "Agreement Required",
			"Please agree the Terms & Condtions": "Please agree the Terms & Condtions",
			"Please improve the information": "Please improve the information",
			"Business address invalid, please enter again": "Business address invalid, please enter again.",
			"Company type require!": "Company type require!",
			"Invalid recaptcha token": "Invalid recaptcha token",
			"Password doesnot match!": "Password doesnot match!",
			"Please post your ad now": "Please post your ad now.",
			"Purchase fail": "Purchase fail",
			"Purchase success": "Purchase success",
			"The Passcode have been send success": "The Passcode have been send success",
			"The passcode have been send successfully": "The passcode have been send successfully",
			"The password and confirm password not the same": "The password and confirm password not the same",
			"Sign Up success": "Sign Up success",
			"Sign Up fail": "Sign Up fail",
			"Please log in with your email": "Please log in with your email",
			"Landing Page fail": "Submit fail"
		},
		"You need to set up your payment method to purchase devices": "You need to set up your payment method to purchase devices",
		"Payment method is required": "Payment method is required"
	},
	"Sign": {
		"MerchantLogin": "Merchant Sign In",
		"MemberLogin": "Member Sign In",
		"Password": "Password",
		"Email": "Please enter your email",
		"Name": "Name",
		"Send": "Send",
		"Sign In": "Sign In"
	},
	"The ad does not exist or has been deleted": "The ad does not exist or has been deleted",
	"The device has been bound, Please untie it first": {
		"": "The device has been bound, Please untie it first."
	},
	"The device is not exist": {
		"": "The device is not exist."
	},
	"The email is already registered!": "The email is already registered!",
	"The item does not exist or has been deleted": "The item does not exist or has been deleted",
	"The mobile number is already registered!": "The mobile number is already registered!",
	"The user does not exist or has been deleted": "The user does not exist or has been deleted",
	"Update plan error": "Update plan error",
	"User does not exist, Please try again later!": "User does not exist, Please try again later!",
	"You do not have permission": "You do not have permission",
	"ad-template": {
		"edit": {
			"Add Builder Template": "Add Builder Template",
			"Back": "Back",
			"Builder Template": "Builder Template",
			"Edit Builder Template": "Edit Builder Template",
			"Name": "Name",
			"Save": "Save",
			"Status": "Status",
			"Type": "Type"
		},
		"list": {
			"Builder Template": "Builder Template",
			"Builder Template List": "Builder Template List",
			"Name": "Name",
			"Status": "Status",
			"Type": "Type",
			"data-table-title": "AD Transaction List"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "AD Transaction",
			"AD Transaction List": "AD Transaction List",
			"Ads": "Ads",
			"Collection": "Collection",
			"Device": "Device",
			"Type": "Type",
			"Campaign": "Campaign",
			"Number": "Number"
		}
	},
	"admin-nav": {
		"Tutorial": "Tutorial",
		"Logout": "Logout",
		"Manager": "Manager",
		"New Enquiry": "New Enquiry",
		"Online": "Online",
		"Pending Ad": "Pending Ad",
		"Pending User": "Pending User",
		"Profile": "Profile",
		"User": "User",
		"User Manual": "User Manual",
		"Select": "Select"
	},
	"ad": {
		"list": {
			"data-table-title": "Advertising List",
			"Approve": "Approve",
			"Disapprove": "Disapprove",
			"Stop": "Stop"
		}
	},
	"ads": {
		"edit": {
			"Start Time": "Start Time",
			"End Time": "End Time",
			"3D Effect Link": "3D Effect Link",
			"Ad Template": "Ad Template",
			"Add Advertising": "Create an Ad",
			"Additional Discount": "Additional Discount",
			"Advanced": "Advanced",
			"Advertising": "Advertising",
			"Advertising End Date": "Advertising End Date",
			"Approval": "Approval",
			"Approved": "Approved",
			"Back": "Back",
			"Duration (s)": "Duration (s)",
			"Edit Advertising": "Edit Advertising",
			"Extra Link": "Extra Link",
			"Enable QR code": "Enable QR code",
			"Enable": "Enable",
			"Name": "Name",
			"Pending": "Pending",
			"Preview": "Preview",
			"Product Purchase link": "Product Purchase link",
			"Product Purchasing": "Product Purchasing",
			"Promotional Text": "Promotional Text",
			"Reject": "Reject",
			"Rejected": "Rejected",
			"Save": "Save",
			"Second(s)": "Second(s)",
			"Select products for this Ad": "Select product for this Ad",
			"Status": "Status",
			"Upgrade": "Upgrade",
			"Video Url": "Video Url",
			"Warning!": "Warning!",
			"Recent uploaded files": "Recent uploaded files",
			"Currently, you have no videos": "Currently, you have no videos on the cloud storage.Create Ads now.",
			"Currently, you have no images": "Currently, you have no images on the cloud storage.Create Ads now.",
			"Close": "Close",
			"Cancel": "Cancel",
			"Confirm": "Confirm",
			"Enter Name": "Please Enter The Name",
			"Enter Duration (s)": "Please Enter Duration (s)",
			"abbr": {
				"create": "Create your own ads",
				"preview": "Preview the ads",
				"warning content": "Not enough AD balance to public, please upgrade."
			},
			"qrInfoTitle": "Generate QR code for the extra link on the Ad Assets.",
			"qrAlt": "The more Ads assets you choose, the longer it takes to cover the QR code. We will not process videos larger than 50M in size."
		},
		"list": {
			"Advertising": "Advertising",
			"Advertising List": "Advertising List",
			"Approval Status": "Approval Status",
			"Approved": "Approved",
			"Due Date": "Due Date",
			"Duration (s)": "Duration (s)",
			"Name": "Name",
			"Pending": "Pending",
			"Products": "Products",
			"Created Date": "Created Date",
			"Status": "Status",
			"Type": "Type",
			"noMedias": "No preview media"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "AR Link / 3D Link",
			"Ad Name": "Ad Name",
			"Ad Template": "Ad Template",
			"All": "All",
			"Campaign Name": "Campaign Name",
			"Cancel": "Cancel",
			"Collection Name": "Collection Name",
			"Company Type": "Company Type",
			"Contact Me 3D Link": "Contact Me 3D Link",
			"Contact Me Video Link": "Contact Me Video Link",
			"Create your own ads": "Create your own ads",
			"Device List": "Device List",
			"Duration (s)": "Duration (s)",
			"Edit": "Edit",
			"End Date": "End Date",
			"Finish": "Finish",
			"Let me think": "Let me think",
			"Maps": "Maps",
			"Name": "Name",
			"Next": "Next",
			"On The Map": "On The Map",
			"Previous": "Previous",
			"Publish to Device": "Publish to Device",
			"Publishing Device": "Publishing Device",
			"Reject": "Reject",
			"Search": "Search",
			"Second(s)": "Second(s)",
			"Shop Link": "Shop Link",
			"Start Date": "Start Date",
			"Step": "Step%{num}",
			"Submit": "Submit",
			"Success": "Success",
			"Text": "Text",
			"abbr": {
				"another": "Create another one",
				"created": "The advertisement is created.",
				"device select": "Please select the device you want to publish",
				"introduction": "Here you can post ads, promote your own products, restaurants; and choose the device you want to publish, let's get started.",
				"privilege intro": "After you subscribe, you will have the following privileges.",
				"start": "Start to Create your AD Now!",
				"step1": "Step1: Basic Information",
				"step2": "Step2: Design advertisement",
				"upgrate check": "Are you sure you want to upgrade your account？",
				"wait": "Please wait for the platform approval.",
				"welcome": "Welcome to Adsmodo",
				"yes": "Yes, I'm Sure!"
			},
			"another": "Create another one",
			"created": "The advertisement is created.",
			"introduction": "Here you can post ads, promote your own products, restaurants; and choose the device you want to publish, let's get started.",
			"welcome": "Welcome to Adsmodo"
		}
	},
	"website": {
		"list": {
			"data-table-title": "Home Page List"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "Home Page",
				"Home Page List": "Home Page List",
				"Title": "Title",
				"Created At": "Created At",
				"Updated At": "Updated At"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "Add Home Page",
				"Website Link": "Website Link",
				"Company Type List": "Company Type List",
				"ADVERTISEMENT Content": "ADVERTISEMENT Content",
				"About Us": "About Us",
				"Banner": "Banner",
				"Contact Us": "Contact Us",
				"ContactInfo": "Contact Information",
				"person": "Contact person",
				"department": "Department",
				"phone": "Contact number",
				"email": "E-mail",
				"Content": "Content",
				"EditHomePage": "Edit Home Page",
				"How to use": "How to use",
				"Image": "Image",
				"Introduction": "Introduction",
				"Menu Name": "Menu Name",
				"Multiple select": "Multiple select",
				"Project": "Project",
				"SHOPKEEPER Content": "SHOPKEEPER Content",
				"Scenario": "Scenario",
				"Sub Title": "Sub Title",
				"Team Member": "Team Member",
				"Title": "Title",
				"Overall": "Overall",
				"Font Color": "Font Color",
				"Edit": "Edit",
				"Add": "Add",
				"Title Position": "Title Position",
				"Background Transparent": "Background Transparent",
				"Left/Right Image": "",
				"Left Title": "Left Title",
				"Left Content": "Left Content",
				"Right Title": "Right Title",
				"Right Content": "Right Content",
				"Scenario List": "Scenario List",
				"Team List": "Team List",
				"Website": "Website",
				"White": "White",
				"Black": "Black",
				"left": "left",
				"center": "center",
				"right": "right"
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "Edit Scenario",
				"Add Scenario": "Add Scenario",
				"Scenario Image": "Scenario Image",
				"Name": "Name",
				"Description": "Description"
			},
			"list": {
				"Scenario": "Scenario",
				"Scenario List": "Scenario List",
				"Image": "Image",
				"Name": "Name",
				"Description": "Description"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "Edit Menu",
				"Add Menu": "Add Menu",
				"Name": "Name",
				"Menu Type": "Menu Type",
				"Parent Menu": "Parent Menu",
				"Link": "Link",
				"Show On Footer": "Show On Footer",
				"Please enter the name": "Please enter the name",
				"Please enter the link": "Please enter the link"
			},
			"list": {
				"Menu": "Menu",
				"Menu List": "Menu List",
				"Name": "Name",
				"Type": "Type",
				"Parent": "Parent",
				"Link": "Link"
			}
		}
	},
	"already registered!": "already registered!",
	"bcrypt error": "bcrypt error",
	"campaigns": {
		"edit": {
			"Approve Campaign": "Approve Campaign",
			"Add Campaign": "Add Campaign",
			"All": "All",
			"Back": "Back",
			"Cancel": "Cancel",
			"Company Type": "Company Type",
			"Edit Campaign": "Edit Campaign",
			"End Date": "End Date",
			"Finish": "Finish",
			"Maps": "Maps",
			"Name": "Name",
			"On The Map": "On The Map",
			"Publishing Device": "Publishing Device",
			"Save": "Save",
			"Search": "Search",
			"Select Ad Collection": "Select Ad Collection",
			"Start Date": "Start Date",
			"Search devices": "Search devices",
			"abbr": {
				"Select device": "Select device",
				"select": "Please select the device you want to publish"
			},
			"Open Map": "Open Map",
			"Target Devices": "Target Devices",
			"Company": "Company",
			"City": "City",
			"State": "State",
			"Distance": "Distance",
			"Map": "Map",
			"placeholderName": "Name",
			"PleaseSelectAdCollection": "Please select ad collection",
			"YourTargetDevices": "Your target devices",
			"Successfully modified status": "Successfully modified status"
		},
		"list": {
			"Ad Collections": "Ad Collections",
			"Campaigns": "Campaigns",
			"Campaign List": "Campaign List",
			"Devices": "Devices",
			"End Date": "End Date",
			"Name": "Name",
			"Start Date": "Start Date",
			"Status": "Status",
			"View All": "View All",
			"default": "Default",
			"Approve": "Approve",
			"Inavtivate": "Inavtivate",
			"Set Default": "Set Default",
			"Default": "Default",
			"Pending": "Pending",
			"Inactive": "Inactive",
			"Active": "Active"
		}
	},
	"cart": {
		"Checkout as a guest": "Checkout as a guest",
		"Verify Phone": "Verify Phone",
		"OTPCode": "OTP code send to your phone",
		"pleasecode": "Please enter the verification code below.",
		"Enter code": "Passcode",
		"Checkout Order": "Checkout Order",
		"submitted": "Your order has been submitted.",
		"pickupnoti": "Please wait for the pickup notification.",
		"Adsmodo order number": "Adsmodo order number",
		"withouttax": "without tax",
		"Adsmodo": "Adsmodo",
		"Apply": "Apply",
		"Continue Shopping": "Continue Shopping",
		"My Cart": "My Cart",
		"Order Summary": "Order Summary",
		"Price": "Price",
		"Proceed to Checkout": "Proceed to Checkout",
		"Pay with stripe account": "Pay with stripe account",
		"Product": "Product",
		"QTY": "QTY",
		"Seller": "Seller",
		"Shipping": "Shipping",
		"Shipping Fee": "Shipping Fee",
		"Sub Total": "Sub Total",
		"Total": "Total",
		"You save": "You save",
		"Coupon code": "Coupon code",
		"promotion": "Promo Code",
		"Please select your gift Card code": "Please select your gift Card code"
	},
	"ad-collection": {
		"list": {
			"data-table-title": "Ads Collection List",
			"Ads": "Ads",
			"Company": "Company",
			"Name": "Name"
		}
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "Add Ad Collection",
			"Back": "Back",
			"Edit Ad Collection": "Edit Ad Collection",
			"Name": "Name",
			"Publishing Device": "Publishing Device",
			"Save": "Save",
			"Select Ads": "Select Ads",
			"Select Device": "Select Device",
			"Approve all Devices": "Approve all Devices",
			"placeholderName": "Name",
			"placeholderSelectAds": "Please select ads"
		},
		"list": {
			"Ads": "Ads",
			"Ad Collections": "Ad Collections",
			"Ads Collection List": "Ads Collection List",
			"Name": "Name",
			"Associated Ads": "Associated Ads"
		}
	},
	"common": {
		"401": "Sorry, you're not authorized",
		"404": {
			"abbr": {
				"back": "Back to Dashboard",
				"sorry": "Sorry, page not found"
			}
		},
		"Search": "Search",
		"ad-image": {
			"Click to select picture": "Click to select picture"
		},
		"ad-video": {
			"Click to select video": "Click to select video"
		},
		"bind-credit-card": {
			"Bind": "Bind",
			"CVC": "CVC",
			"Card Number:": "Card Number:",
			"MM": "MM",
			"YYYY": "YYYY",
			"Please enter the card number": "Please enter the card number",
			"Please enter the expiry month": "Please enter the expiry month",
			"Please enter a valid month": "Please enter a valid month",
			"Please enter the expiry year": "Please enter the expiry year",
			"Please enter a valid year": "Please enter a valid year",
			"Please enter the card cvc": "Please enter the card cvc",
			"Please enter a valid cvc": "Please enter a valid cvc",
			"Expiry Month": "Expiry Month",
			"Expiry Year": "Expiry Year",
			"Card Cvc": "Card Cvc"
		},
		"select-ad-device": {
			"Device List": "Device List",
			"Let me think": "Let me think",
			"Yes, I'm Sure!": "Yes, I'm Sure!",
			"abbr": {
				"plan": "Plan adsmodo a monthly $100 advertising services",
				"upgrade check": "Are you sure you want to upgrade your account？"
			}
		},
		"tui-image-editor": {
			"Cancel": "Cancel",
			"Edit image": "Edit image",
			"Image Resolution :": "Image Resolution :",
			"Load Assets": "Load Assets",
			"Load Promotion Asset": "Load Promotion Asset",
			"Load assets": "Load assets",
			"Promotion assets": "Promotion assets",
			"Save": "Save"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "Add Company Type",
			"Back": "Back",
			"Edit Company Type": "Edit Company Type",
			"Name": "Name",
			"Save": "Save",
			"description": "Description",
			"Description": "Description",
			"Image": "Image",
			"Icon": "Icon",
			"Company Icon": "Company Icon"
		},
		"list": {
			"Company Type": "Company Type",
			"Company Type List": "Company Type List",
			"Created Date": "Created Date",
			"Name": "Name",
			"Icon": "Icon",
			"Image": "Image",
			"data-table-title": "Company Type List"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "Ad",
			"Ad Collection": "Ad Collection",
			"Back": "Back",
			"Cell Phone": "Cell Phone",
			"Contact Me": "Contact Me",
			"Device": "Device",
			"Email": "Email"
		},
		"list": {
			"Ad": "Ad",
			"Campaign": "Campaign",
			"Collection": "Collection",
			"Contact Me": "Contact Me",
			"Contact Me List": "Contact Me List",
			"Device": "Device",
			"Email": "Email",
			"Phone Number": "Phone Number",
			"action": "action",
			"Mobile": "Mobile",
			"data-table-title": "Contact Me List"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "Business address",
			"Contacts": "Contacts",
			"Contacts List": "Contacts List",
			"Email": "Email",
			"Is Admin": "Is Admin",
			"Mobile": "Mobile",
			"Name": "Name"
		}
	},
	"customer failed: create customer failed": "customer failed: create customer failed",
	"customer failed: create token failed": "customer failed: create token failed",
	"dataTable": {
		"Add": "Add",
		"Are you sure to deactivate it?": "Are you sure to deactivate it?",
		"Are you sure to delete it?": "Are you sure to delete it?",
		"Cancel": "Cancel",
		"Choose your options below and drop one into the inbox of anyone on your list!": "Choose your options below and drop one into the inbox of anyone on your list!",
		"Confirm": "Confirm",
		"Deactivate": "Deactivate",
		"Delete": "Delete",
		"Operate": "Operate",
		"Please select the item to delete": "Please select the item to delete",
		"Please select the loyalty member to give reward": "Please select the loyalty member to give reward!",
		"Reward Gift Card": "Reward Gift Card",
		"This operation cannot be undone": "This operation cannot be undone.",
		"We offer electronic gift cards in any denomination": "We offer electronic gift cards in any denomination.",
		"Info": "Your Subscription changes will only take effect starting from next month.",
		"Name": "Name",
		"Address": "Address",
		"The Location is required": "The Location is required"
	},
	"database error": "database error",
	"buy-device-log": {
		"list": {
			"data-table-title": "Device Purchase Histories"
		}
	},
	"devices": {
		"our Subscription changes will only take effect starting from next month.": "our Subscription changes will only take effect starting from next month.",
		"addCampaign": {
			"Ad Collections": "Ad Collections",
			"Ad Images": "Ad Images",
			"Ad List": "Ad List",
			"Add Campaign": "Add Campaign",
			"Availavle Campaigns": "Availavle Campaigns",
			"Back": "Back",
			"Campaign Detail": "Campaign Detail",
			"Campaign List": "Campaign List",
			"Campaigns": "Campaigns",
			"Device": "Device",
			"Device ID": "Device ID",
			"Device Name": "Device Name",
			"Drag available campaigns here to run on device": "Drag available campaigns here to run on device",
			"Duration(s)": "Duration(s)",
			"End Date": "End Date",
			"Name": "Name",
			"Save": "Save",
			"Start Date": "Start Date",
			"Status": "Status",
			"This ad have no images": "This ad have no images"
		},
		"buy-log": {
			"Buy Device Log": "Device Purchase History",
			"Buy Device Log List": "Device Purchase Histories",
			"Name": "Name",
			"Device Type": "Device Type",
			"Quantity": "Quantity",
			"Status": "Status",
			"Unit Price": "Unit Price",
			"data-table-title": "Device Purchase Histories",
			"Permission": "Permission",
			"Order Number": "Order Number",
			"Total Price": "Total Price",
			"Purchase History": "Purchase History",
			"Receipt": "Receipt",
			"Cancel Order": "Cancel Order",
			"Are you sure you want to cancel this order": "Are you sure you want to cancel this order?",
			"Pending": "Pending",
			"Finished": "Finished",
			"Canceled": "Canceled",
			"Pay Status": "Pay Status",
			"payPending": "Pending",
			"Success": "Success",
			"Pay Now": "Pay Now",
			"Check Pay Status": "Check Pay Status"
		},
		"buy-product": {
			"Back": "Back",
			"Purchase Device": "Buy Device",
			"Device Quantity": "Device Quantity",
			"Location": "Location",
			"Type": "Type",
			"Purchase": "Purchase",
			"Add Location": "Add Location",
			"selectPlacementLocation": "selectPlacementLocation",
			"selectTheDeviceType": "selectTheDeviceType",
			"Quantity": "Quantity",
			"required": "required",
			"not valid": "not valid",
			"Purchase success": "Purchase success",
			"Duration Max": "Max should not be above 4500000000"
		},
		"edit": {
			"Add Device": "Add Device",
			"Are you sure to activate this device ?": "Are you sure to activate this device ?",
			"Are you sure to deactivate this device ?": "Are you sure to deactivate this device ?",
			"Back": "Back",
			"Business Name": "Business Name",
			"Cancel": "Cancel",
			"Confirm": "Confirm",
			"Device ID": "Device ID",
			"Device Info": "Device Info",
			"Device Name": "Device Name",
			"Device Status": "Device Status",
			"Device Type": "Device Type",
			"Edit Device": "Edit Device",
			"Manufacturer": "Manufacturer",
			"Model": "Model",
			"Name": "",
			"Offline": "Offline",
			"Online": "Online",
			"Placement Location": "Placement Location",
			"Platform": "Platform",
			"Save": "Save",
			"Select Ad Collections": "",
			"Serial": "Serial",
			"Toggle": "Toggle",
			"UUID": "UUID",
			"Version": "Version",
			"Your device is now Offline!": "Your device is now Offline!",
			"Your device is now Online!": "Your device is now Online!",
			"Your device need to register via App first !": "Your device need to register via App first !"
		},
		"purchase-log": {
			"Device": "Device",
			"Order No": "Order No",
			"Purchase Log": "Purchase Log",
			"Purchase Log List": "Purchase Log List",
			"Quantity": "Quantity",
			"Subscription": "Subscription",
			"Total Price": "Total Price",
			"Unit Price": "Unit Price",
			"User Name": "User Name",
			"Get Receipt": "Get Receipt"
		},
		"list": {
			"PendingRegistration": "Pending Registration",
			"Ad Collections": "Ad Collections",
			"Businesss Name": "Business Name",
			"Campaigns": "Campaigns",
			"Device ID": "Device ID",
			"Device Info": "Device Info",
			"Devices": "Devices",
			"Device List": "Device List",
			"Name": "Name",
			"Position": "Position",
			"Status": "Status",
			"Approval": "Approval",
			"pendingInfo": "Status pending because still need other devices owner approval(This campaign use other Company devices).",
			"User": "User",
			"Public Name": "Public Name",
			"Location": "Location",
			"ID": "ID",
			"title": "Devices",
			"data-table-title": "Devices List",
			"Type": "Type",
			"App Type": "App Type",
			"Manage Campaigns": "Manage Campaigns",
			"Campaigns in": "Campaigns in",
			"Company": "Company",
			"Start Date": "Start Date",
			"End Date": "End Date",
			"Enable": "Enable",
			"Disable": "Disable",
			"Campaign Name": "Name",
			"Pending": "Pending",
			"Offline": "Offline",
			"Online": "Online",
			"Pending Delete": "Pending Delete",
			"Please register the device on the tablet first": "Please register the device on the tablet first"
		}
	},
	"email": {
		"create-email": {
			"Back": "Back",
			"Email From": "Email From",
			"Message": "Message",
			"Send": "Send",
			"Send To": "Send To",
			"Subject": "Subject",
			"Create Email": "Create Email"
		}
	},
	"email-marketing": {
		"Email Marketing": "Email Marketing",
		"Send Email": "Send Email",
		"Email Log": "Email Log",
		"Email Template": "Email Template"
	},
	"email-log": {
		"edit": {
			"Select users": "Select users",
			"Email Template": "Email Template",
			"Subject": "Subject",
			"Content": "Content",
			"Preview": "Preview",
			"Send": "Send",
			"required": "required",
			"Send success": "Send success",
			"Not found user": "Not found user",
			"My loyalty club": "My loyalty club"
		},
		"list": {
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Date": "Date",
			"To": "To",
			"Email": "Email",
			"Subject": "Subject",
			"Content": "Content",
			"Status": "Status"
		}
	},
	"email-template": {
		"edit": {
			"Name": "Name",
			"Subject": "Subject",
			"Content": "Content",
			"Preview": "Preview",
			"Back": "Back",
			"Save": "Save",
			"required": "required",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!",
			"message": "Use {{ message }} to mark the editable part that will be sent to users.",
			"Convenient code": "Other convenient code:",
			"CompanyLogo message": "Company Logo : {{ companyLogo }}",
			"CompanyName message": "Company Name : {{ companyName }}",
			"Phone message": "Company Phone : {{ companyPhone }}",
			"Website message": "Company Website URL : {{ companyWebsite }}",
			"Address message": "company address : {{ address }}",
			"CurrentTime message": "Current Time : {{ currentTime }}"
		},
		"list": {
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Name": "Name",
			"Subject": "Subject",
			"Preview": "Preview"
		}
	},
	"sms-marketing": {
		"SMS Marketing": "SMS Marketing",
		"Send Message": "Send Message",
		"Message Log": "Message Log",
		"Message Template": "Message Template"
	},
	"sms-log": {
		"edit": {
			"Select users": "Select users",
			"Message Template": "Message Template",
			"Message": "Message",
			"Preview": "Preview",
			"Send": "Send",
			"required": "required",
			"Exceed the limit": "Exceed the limit",
			"Send success": "Send success"
		},
		"list": {
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Date": "Date",
			"To": "To",
			"Phone": "Phone",
			"Message": "Message",
			"Status": "Status",
			"Success": "Success",
			"Fail": "Fail",
			"CurrentlyNotAvailable": "Delete feature is currently not available."
		}
	},
	"sms-template": {
		"edit": {
			"Name": "Name",
			"Content": "Content",
			"Preview": "Preview",
			"Back": "Back",
			"Save": "Save",
			"required": "required",
			"Exceed the limit": "Exceed the limit",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!",
			"message": "Use {{ message }} to mark the editable part that will be sent to users.",
			"Convenient code": "Other convenient code:",
			"CompanyName message": "Company Name : {{ companyName }}",
			"Phone message": "Company Phone : {{ companyPhone }}",
			"Website message": "Company Website URL : {{ companyWebsite }}",
			"Address message": "company address : {{ address }}",
			"CurrentTime message": "Current Time : {{ currentTime }}"
		},
		"list": {
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Name": "Name",
			"Preview": "Preview"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "Add Enquiry",
			"Back": "Back",
			"Edit Enquiry": "Edit Enquiry",
			"Email": "Email",
			"Enquiry Type": "Enquiry Type",
			"Message": "Message",
			"Name": "Name",
			"Phone": "Phone",
			"Reply": "Reply",
			"Save": "Save",
			"Show Enquiry": "Show Enquiry"
		},
		"list": {
			"Created Date": "Created Date",
			"Email": "Email",
			"Enquiry": "Enquiry",
			"Enquiry List": "Enquiry List",
			"Message": "Message",
			"Name": "Name",
			"Reply": "Reply",
			"Type": "Type",
			"data-table-title": "Enquiry List"
		}
	},
	"get ad query error": "get ad query error",
	"get ads database error": "get ads database error",
	"get item database error ": "get item database error ",
	"get item query error": "get item query error",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "3 Digit Security Code on Back of Card",
		"Amount of Gift Card": "Amount of Gift Card",
		"CVC": "CVC",
		"Card Number": "Card Number",
		"Email From": "Email From",
		"Expiration Date": "Expiration Date",
		"For payment confirmation": "For payment confirmation",
		"Generate Gift Card": "Generate Gift Card",
		"Generate Gift Card for the selected loyalty member": "Generate Gift Card for the selected loyalty member",
		"Gift Cards": "Gift Cards",
		"Gift Cards Log": "Gift Cards Log",
		"Recipient Email": "Recipient Email",
		"Recipient Name": "Recipient Name",
		"First Name*": "First Name*",
		"Last Name*": "Last Name*",
		"Recipient Email *": "Recipient Email *",
		"Amount *": "Amount *",
		"list": {
			"data-table-title": "Gift Cards List"
		}
	},
	"home": {
		"Accountant": "Accountant",
		"Add Product to cart": "Add Product to cart",
		"Age": "Age",
		"CRM": "CRM",
		"Complete Purchases": "Complete Purchases",
		"Daily Visits": "Daily Visits",
		"Dashboard": "Dashboard",
		"Device Orders": "Device Orders",
		"Edinburgh": "Edinburgh",
		"From Last 1 Month": "From Last 1 Month",
		"From Last 24 Hours": "From Last 24 Hours",
		"Goal Completion": "Goal Completion",
		"Integration Specialist": "Integration Specialist",
		"Junior Technical Author": "Junior Technical Author",
		"Last 3 Month Ad-Collection Record": "Last 3 Month Ad-Collection Record",
		"Last 3 Month Subscription Record": "Last 3 Month Subscription Record",
		"Last Month": "Last Month",
		"Last week": "Last week",
		"Mail Inbox": "Mail Inbox",
		"Marketplace": "Marketplace",
		"Monthly Earnings": "Monthly Earnings",
		"Monthly Purchases": "Monthly Purchases",
		"Name": "Name",
		"New York": "New York",
		"Office": "Office",
		"Orders in Last 10 months": "Orders in Last 10 months",
		"Pending Ad": "Pending Ad",
		"Pending User": "Pending User",
		"Position": "Position",
		"Recent Contacts": "Recent Contacts",
		"Revenue": "Revenue",
		"Salary": "Salary",
		"Sales Assistant": "Sales Assistant",
		"San Francisco": "San Francisco",
		"Send Inquiries": "Send Inquiries",
		"Senior Javascript Developer": "Senior Javascript Developer",
		"Start date": "Start date",
		"System Architect": "System Architect",
		"Total Subscriptions": "Total Subscriptions",
		"Unique Ad-Viewers": "Unique Ad-Viewers",
		"Visit Premium plan": "Visit Premium plan",
		"Weekly Top 5 Advertisement": "Weekly Top 5 Advertisement",
		"select device error": "select device error",
		"Ad Data Record": "Ad Data Report",
		"Company Data Report": "Company Data Report",
		"Loyalty Member Data Report": "Loyalty Member Data Report",
		"Cart Data Report": "Cart Data Report",
		"Order Data Record": "Order Data Report",
		"Promotion Data Record": "Promotion Data Report",
		"Hour": "Hour",
		"Day": "Day",
		"Month": "Month",
		"Increase": "Increase",
		"Total": "Total",
		"Increment": "Increment",
		"Base": "Base",
		"emptyAlt": "No related records"
	},
	"id required": "id required",
	"image is required to show ad": "image is required to show ad",
	"main-nav": {
		"Sign In": "Sign In",
		"Sign Up": "Sign Up",
		"Loyalty Sign Up": "Loyalty Sign Up",
		"Sign Up Free": "Sign Up Free",
		"Start Sign Up Free": "Sign Up Free",
		"email": "Email",
		"step3": "only 3 steps",
		"service time": "Service hours: Monday ~ Friday (10 am to 7 pm).",
		"privacy policy": "Privacy-policy",
		"webName": "Adsmodo",
		"Terms & Conditions": "Terms & Conditions"
	},
	"member": {
		"About": "About",
		"Back": "Back",
		"Cancel": "Cancel",
		"Clear": "Clear",
		"Confirm": "Confirm",
		"Contact Info": "Contact Info",
		"Description": "Description",
		"Earned Points": "Earned Points",
		"Edit": "Edit",
		"Email Verification Success": "Email Verification Success",
		"Explore": "Explore",
		"Got it!": "Got it!",
		"If you experience any issues with this Reward,please feel free to contact": "If you experience any issues with this Reward,please feel free to contact",
		"LearnMore": "Learn More",
		"Please enter activation code sent to this number": "Please enter activation code sent to this number",
		"Points history": "Points history",
		"Redeem": "Redeem",
		"Redeemable points can be exchanged for rewards at the Rewards page": "Redeemable points can be exchanged for rewards at the Rewards page.",
		"Save": "Save",
		"Share": "Share",
		"Special Deals": "Special Deals",
		"Spent Points": "Spent Points",
		"Terms and Conditions": "Terms and Conditions",
		"What are Redeemable Points?": "What are Redeemable Points?",
		"Your email has been verified.We will redirect you to home page": "Your email has been verified.We will redirect you to home page",
		"nav": {
			"Home": "Home",
			"Recommended Loyalty Club": "Recommended Loyalty Club",
			"My Loyalty Club": "My Loyalty Club",
			"My Order": "My Order",
			"View Schedule": "View Schedule",
			"Make Appointment": "Make Appointment",
			"Notification": "Notification",
			"Setting": "Setting",
			"Merchant Site": "Merchant Site",
			"LeaveAll": "Unsubscribe from club",
			"Unsubscribe": "Unsubscribe",
			"noClubYet": "You don't have loyalty club yet",
			"SuretoUnsubscribeClub": "Are you sure to unsubscribe this club",
			"unsubscribeSuccessful": "Unsubscribe Successful!",
			"Youhavebeen": "You have been unsubscribed from this loyalty club",
			"NoMoreService": "and won't receive any further emails and services from us. Thanks for hanging out with us.",
			"Log Out": "Log Out"
		},
		"profile": {
			"First Name": "First Name",
			"Last Name": "Last Name",
			"Email": "Email",
			"Phone": "Phone",
			"required": "required",
			"not valid": "not valid",
			"Save": "Save"
		},
		"myLoyaltyClub": {
			"Category": "Category",
			"warning": "You are not signed in",
			"warning text": "Sign in to join this club!",
			"All": "All"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "Cake Expired",
			"Cake Used": "Cake Used",
			"Roylaty Member Detail": "Roylaty Member Detail"
		},
		"form": {
			"Join Adsmodo Loyalty Club": "Join Adsmodo Loyalty Club",
			"Select Loyalty Clubs": "Select Loyalty Clubs",
			"Submit": "Submit",
			"Select": "Select",
			"Loyalty Clubs": "Loyalty Clubs"
		},
		"list": {
			"Birthday": "Birthday",
			"Email": "Email",
			"Loyalty Member": "Loyalty Member",
			"Loyalty Member List": "Loyalty Member List",
			"Mobile": "Mobile",
			"Name": "Name",
			"Points": "Points"
		}
	},
	"no permission": "no permission",
	"post:Device update error": "post:Device update error",
	"post:sendPasscode error": "post:sendPasscode error",
	"post:signin account error": "post:signin account error",
	"pre:add ad name validate failed": "pre:add ad name validate failed",
	"pre:audit user query error": "pre:audit user query error",
	"pre:bind have been bound": "pre:bind have been bound",
	"pre:bind verify name failed": "pre:bind verify name failed",
	"pre:bind verify serial failed": "pre:bind verify serial failed",
	"pre:delete database error": "pre:delete database error",
	"pre:find user": "pre:find user",
	"pre:get purchase log database error": "pre:get purchase log database error",
	"pre:member get error": "pre:member get error",
	"pre:missing parameters": "pre:missing parameters",
	"pre:sendPasscode find user error": "pre:sendPasscode find user error",
	"pre:signin device has been bound": "pre:signin device has been bound",
	"pre:signin find user error": "pre:signin find user error",
	"pre:unbind the device is not exist": "pre:unbind the device is not exist",
	"pre:update get campaign database error": "pre:update get campaign database error",
	"pre:update get member database error": "pre:update get member database error",
	"pre:update item query error": "pre:update item query error",
	"pre:update missing parameters": "pre:update missing parameters",
	"pre:verification error": "pre:verification error",
	"product": {
		"edit": {
			"Add Product": "Add Product",
			"Edit Product": "Edit Product",
			"Product Category": "Product Category",
			"Add Category": "Add Category",
			"Name": "Name",
			"Cancel": "Cancel",
			"Save": "Save",
			"Images": "Images",
			"Click to select picture": "Click to select picture",
			"SKU": "SKU",
			"Description": "Description",
			"Unit Price": "Unit Price",
			"Quantity": "Quantity",
			"Variants": "Variants",
			"Add Variants": "Add Variants",
			"Variant Name": "Variant Name",
			"Extra Price": "Extra Price",
			"Extra Options": "Extra Options",
			"Add Options": "Add Options",
			"Option Name": "Option Name",
			"Back": "Back",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!",
			"Please select loyalty club image": "Please select loyalty club image",
			"Please select at least one product image": "Please select at least one product image",
			"Please complete your informations": "Please complete your informations",
			"Please complete your questions": "Please complete your questions"
		},
		"list": {
			"Category": "Category",
			"Image": "Image",
			"SKU": "SKU",
			"Name": "Name",
			"Unit Price": "Unit Price",
			"Quantity": "Quantity",
			"Product List": "Product List"
		},
		"public-detail": {
			"Add to cart": "Add to cart",
			"Available Stock": "Available Stock",
			"Options": "Options",
			"Original": "Original",
			"Please choose the product to buy": "Please choose the product to buy",
			"Price": "Price",
			"Total Price": "Total Price",
			"Product Detail": "Product Detail",
			"Qty": "Qty",
			"Shipping Price": "Shipping Price",
			"ViewMyCart": "View My Cart",
			"Back to product list": "Back to product list"
		},
		"public-list": {
			"Product Catalog": "Product Catalog"
		}
	},
	"ad-product-category": {
		"edit": {
			"Add Product Category": "Add Product Category",
			"Edit Product Category": "Edit Product Category",
			"Name": "Name",
			"Back": "Back",
			"Save": "Save",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!"
		},
		"list": {
			"Name": "Name",
			"Product Category": "Product Category"
		}
	},
	"product-device": {
		"edit": {
			"Add Product Device": "Add Product Device",
			"Back": "Back",
			"Device Name": "Device Name",
			"Device Price": "Device Price",
			"Edit Product Device": "Edit Product Device",
			"Product Device": "Product Device",
			"Save": "Save"
		},
		"list": {
			"data-table-title": "Product Device List",
			"Device Name": "Device Name",
			"Device Price": "Device Price",
			"Product Device": "Product Device",
			"Product Device List": "Product Device List"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "Add Promotion",
			"Back": "Back",
			"Coupon count(s)": "Coupon count(s)",
			"Description": "Description",
			"Edit Promotion": "Edit Promotion",
			"End Date": "End Date",
			"Promotion Image": "Promotion Image",
			"Promotion Type": "Promotion Type",
			"Promotional Text": "Promotional Text",
			"Save": "Save",
			"Start Date": "Start Date",
			"Select Products": "Select Products",
			"Max Purchase Amount": "Max Purchase Amount",
			"Coupon Code": "Coupon Code",
			"Amount": "Amount",
			"Percentage": "Percentage"
		},
		"list": {
			"Coupon Count": "Coupon Count",
			"End Date": "End Date",
			"Name": "Name",
			"Promotion": "Promotion",
			"Promotion List": "Promotion List",
			"Start Date": "Start Date",
			"Type": "Type",
			"data-table-title": "Promotion List"
		},
		"log": {
			"Promotion Log": "Promotion Log",
			"Date": "Date",
			"Customer Name": "Customer Name",
			"Promotion": "Promotion",
			"Received Amount": "Received Amount"
		}
	},
	"reward": {
		"list": {
			"Reward List": "Reward List",
			"Name": "Name",
			"Description": "Description",
			"Points": "Points",
			"Contact Email": "Contact Email",
			"Contact Phone": "Contact Phone",
			"data-table-title": "Reward List"
		},
		"edit": {
			"Reward Edit": "Reward Edit",
			"Reward Add": "Reward Add",
			"Image": "Image",
			"Name": "Name",
			"Reward By": "Reward By",
			"Location": "Location",
			"Points": "Points",
			"Mobile Number": "Mobile Number",
			"Email": "Email",
			"Terms & Conditions": "Terms & Conditions",
			"ADD": "ADD",
			"About us": "About us",
			"Add your Terms & Conditions": "Add your Terms & Conditions"
		}
	},
	"recaptcha error": "recaptcha error",
	"recaptchaToken is required": "recaptcha Token is required",
	"role": {
		"list": {
			"User Role": "User Role",
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Name": "Name"
		},
		"edit": {
			"Appointment Available": "Appointment Available",
			"Add Role": "Add Role",
			"Back": "Back",
			"Edit Role": "Edit Role",
			"Name": "Name",
			"Save": "Save",
			"required": "required",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!",
			"View": "View",
			"Add": "Add",
			"Edit": "Edit",
			"Delete": "Delete",
			"Search": "Search",
			"Dashboard": "Dashboard",
			"Quick Start": "Quick Start",
			"Advertising": "Advertising",
			"Advertising List": "Advertising List",
			"Ad Collection": "Ad Collection",
			"Ads Assets": "Ads Assets",
			"Campaign": "Campaign",
			"Campaign List": "Campaign List",
			"Device": "Device",
			"Device List": "Device List",
			"Purchase History": "Purchase History",
			"E-Commerce": "E-Commerce",
			"Product List": "Product List",
			"Product Category": "Product Category",
			"View Order": "View Order",
			"CRM": "CRM",
			"Loyalty Club": "Loyalty Club",
			"Email Log": "Email Log",
			"Email Template": "Email Template",
			"Message Log": "Message Log",
			"Message Template": "Message Template",
			"Company": "Company",
			"Company List": "Company List",
			"Team List": "Team List",
			"User List": "User List",
			"User Role": "User Role",
			"Schedule": "Schedule"
		}
	},
	"searching": {
		"Ad Collection": "Ad Collection",
		"Ads": "Ads",
		"Apply": "Apply",
		"Campaign": "Campaign",
		"Clear filter": "Clear filter",
		"Collections": "Collections",
		"Detail": "Detail",
		"Device": "Device",
		"Duration": "Duration",
		"Duration(s)": "Duration(s)",
		"End Date": "End Date",
		"Filter by": "Filter by",
		"First name": "First name",
		"ID": "ID",
		"Items": "Items",
		"Last name": "Last name",
		"Name": "Name",
		"No results found for": "No results found for",
		"Position": "Position",
		"Results for": "Results for",
		"Start Date": "Start Date",
		"Status": "Status",
		"Type": "Type",
		"User": "User"
	},
	"company": {
		"list": {
			"Company List": "Company List",
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Logo": "Logo",
			"Name": "Name",
			"Phone": "Phone",
			"Website": "Website"
		},
		"edit": {
			"Add Company": "Add Company",
			"Edit Company": "Edit Company",
			"Logo": "Logo",
			"Name": "Name *",
			"Type": "Type *",
			"Phone": "Phone *",
			"Website": "Website",
			"ZIP Code": "ZIP Code *",
			"Address": "Address *",
			"Back": "Back",
			"Save": "Save",
			"PublishAds": "Publish Ads",
			"required": "required",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!"
		}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "Ad Template Type",
			"Advertising Setting": "Advertising Setting",
			"Day(s) ": "Day(s)",
			"Duration (s)": "Duration (s)",
			"Expiry Date": "Expiry Date",
			"Expires in": "Expires in",
			"Name": "Name",
			"Save": "Save",
			"Second(s)": "Second(s)",
			"Subscription Plan": "Subscription Plan",
			"day": "Day(s)"
		},
		"general": {
			"Default Language": "Default Language",
			"Generel Settings": "Generel Settings",
			"Name": "Name",
			"New User Default Role": "New User Default Role",
			"Save": "Save"
		},
		"product": {
			"Name": "Name",
			"Number": "Number",
			"Price": "Price",
			"Product Name": "Product Name",
			"Product Number": "Product Number",
			"Product Price": "Product Price",
			"Product Service": "Product Service",
			"Product Text": "Product Text",
			"Product Type": "Product Type",
			"Save": "Save",
			"Service": "Service",
			"Subscription Lists": "Subscription Lists",
			"Subscription Name": "Subscription Name",
			"Subscription Type": "Subscription Type",
			"Usage": "Usage"
		}
	},
	"sign": {
		"forget": {
			"Log in with one-time passcode": "Log in with one-time passcode",
			"Enter the email associated with your Adsmodo account": "Enter the email associated with your Adsmodo account",
			"Authentication required": "Authentication required",
			"We sent": "We've sent a One Time Password to your",
			"Please enter it below": "Please enter it below.",
			"OTP will expired in": "OTP will expired in",
			"OTP has expired": "OTP has expired.",
			"Resend OTP": "Resend OTPs",
			"Enter OTP": "Enter OTP",
			"Required": "required",
			"Passcode is required": "Passcode is required",
			"Email is required": "Email is required",
			"Please select your account type": "Please select your account type",
			"Please type your email or mobile": "Please type your email or mobile",
			"mdi-reply": "mdi-reply",
			"Reset your Password": "Reset your Password",
			"Reset my password": "Reset my password",
			"Password Changed": "Password Changed",
			"You can now login with your new password": "You can now login with your new password",
			"Login": "Login",
			"Old Password": "Old Password",
			"New Password": "New Password",
			"Confirm Password": "Confirm Password",
			"Forgot Password": "Forgot Password",
			"Email": "Email",
			"Mobile": "Mobile",
			"Email or mobile": "Email or mobile",
			"Enter the email address or mobile phone number": "Enter the email address or mobile phone number associated with your Adsmodo account.",
			"Continue": "Continue",
			"Reset Password": "Reset Password",
			"Sign In": "Sign In",
			"abbr": {
				"send": "Send me one time passcode"
			}
		},
		"sign-in": {
			"Create an account": "Create an account",
			"Forgot password": "Forgot password",
			"Loyalty Member Sign In": "Loyalty Member Sign In",
			"Sign In": "Sign In",
			"Sign up": "Sign Up",
			"abbr": {
				"send": "Send me one time passcode"
			},
			"Already have an account?": "Already have an account?",
			"Forgot-Password": "Forgot Password?",
			"Quick access with": "Quick access with",
			"Log in with one-time passcode": "Log in with one-time passcode",
			"Failed to get email Please log in to facebook to make your email public": "Failed to get email, please log in to facebook to make your email public"
		},
		"landing-page": {
			"name": "Name",
			"email": "email",
			"Contact Phone": "Contact Phone",
			"Company Name": "Company Name",
			"Address": "Address"
		},
		"errorMessage": {
			"firstName": "First name is required",
			"lastName": "Last name is required",
			"phone": "Phone is required",
			"businessName": "Business name is required",
			"businessPhone": "Business phone is required",
			"webSite": "Web site is required",
			"zipcode": "Zipcode is required",
			"businessAddress": "Business address is required",
			"yourGoal": "Please choose your goal",
			"productNumber": "Please enter product number",
			"card": "Please enter the card",
			"expiryMonth": "Please enter the expiry month",
			"expiryYear": "Please enter the expiry year",
			"cardCvc": "Please enter the card cvc",
			"mail": "Please enter the mail",
			"password": "Please enter the password",
			"confirmPassword": "confirm password",
			"messageisrequired": "message is required"
		},
		"sign-up": {
			"Quick Sign Up": "Quick Sign Up",
			"Quick Sign Up With": "Quick Sign Up With",
			"Complete Sign Up": "Complete Sign Up",
			"At least 8 character": "Password must contain at least 8 characters including uppercase, lowercase letters and numbers.",
			"Loyalty Member": "Loyalty Member",
			"Merchant": "Merchant",
			"AirbnbAdmin": "Airbnb Admin",
			"OK": "OK",
			"Address:": "Address:",
			"Ads My biz": "Ads My biz",
			"All of Above": "All of Above",
			"Business Address": "Business Address",
			"Business Name": "Business Name",
			"Business phone": "Business phone",
			"Card Number:": "Card Number:",
			"Company Type": "Company Type",
			"Confirm": "Confirm",
			"Confirm Information": "Confirm Information",
			"Confirm password": "Confirm password",
			"Create user account": "Create user account",
			"Email": "Email",
			"Email:": "Email:",
			"Extra $": "Extra $",
			"First Name": "First Name",
			"First Name:": "First Name:",
			"Help others": "Help others",
			"Last Name:": "Last Name:",
			"Next": "Next",
			"Password": "Password",
			"Payment Options": "Payment Options",
			"Phone": "Phone",
			"Phone:": "Phone:",
			"Prev": "Prev",
			"Price:": "Price:",
			"Price(month):": "Price(month):",
			"Product:": "Product:",
			"Quantity": "Quantity",
			"Quantity:": "Quantity:",
			"Select Adsmodo Business Assistant": "Select Adsmodo Business Assistant",
			"Subscription Plan": "Subscription Plan",
			"Tell us about your goal": "Tell us about your goal",
			"Web Site": "Website",
			"Zipcode": "Zip Code",
			"abbr": {
				"agree": "I've read and agree with the terms.",
				"business info": "Tell us about your business",
				"web tutorial": "Terms and conditions\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies."
			},
			"last Name": "last Name",
			"Skip Player": "Skip Player",
			"Click OK to sign in": "Click OK to sign in"
		}
	},
	"signin verify error": "signin verify error",
	"signup password error": "signup password error",
	"single": {
		"component": {
			"Contact info": "Contact info",
			"Email address": "Email address",
			"Input your contact info to get coupons and subscribe our service": "Input your contact info to get coupons and subscribe our service.",
			"Input your contact info to get coupons!": "Input your contact info to get coupons!",
			"Phone number": "Phone number",
			"Submit": "Submit",
			"abbr": {
				"get contact info": ""
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "Are you sure to activate this plan?",
			"Are you sure to deactivate this plan?": "Are you sure to deactivate this plan?",
			"Your plan is now Active!": "Your plan is now Active!",
			"Your plan is now Inactive!": "Your plan is now Inactive!",
			"Active": "Active",
			"Inactive": "Inactive",
			"Add Subscription Plan": "Add Subscription Plan",
			"Back": "Back",
			"Edit Subscription Plan": "Edit Subscription Plan",
			"Number": "Number",
			"Price": "Price",
			"Save": "Save",
			"Service": "Service",
			"Subscription Name": "Subscription Name",
			"Subscription Plan": "Subscription Plan",
			"Subscription Type": "Subscription Type",
			"Usage": "Usage",
			"Maximum area range": "Maximum area range",
			"Maximum Area Range": "Maximum Area Range"
		},
		"list": {
			"Price": "Price",
			"Subscription Plan": "Subscription Plan",
			"Subscription Plan List": "Subscription Plan List",
			"Subscription Plan Type": "Subscription Plan Type"
		}
	},
	"user": {
		"account": {
			"Status": "Status",
			"freemembership": "Free membership",
			"primemembership": "Prime membership",
			"validdate": "Valid date",
			"Account Type": "Account Type",
			"Subscription Plan Type": "Subscription Plan Type",
			"Ad Address": "Ad Address",
			"Personal Information": "Personal Information",
			"Save Credit Card": "Save Credit Card",
			"Change Credit Card": "Change Credit Card",
			"Current Credit Card": "Current Credit Card",
			"Saved Payment Methods": "Saved Payment Methods",
			"Bind Status": "Bind Status",
			"Bind credit card": "Bind credit card",
			"Business Address": "Business Address",
			"Business Information": "Business Information",
			"Business Name": "Business Name",
			"Business Phone": "Business Phone",
			"Cancel": "Cancel",
			"Cancel Subscription": "Cancel Subscription",
			"Phone": "Phone",
			"Company Type": "Company Type",
			"Email": "Email",
			"Free": "Free",
			"Hi": "Hi,",
			"Info": "Info",
			"Let me think": "Let me think",
			"Name": "Name",
			"Password": "Password",
			"Product Type": "Product Type",
			"Profile Image": "Profile Image",
			"Redeem": "Redeem",
			"Rewards": "Rewards",
			"Save": "Save",
			"Skip Tour": "Skip Tour",
			"Start Create Ads": "Start Create Ads",
			"Subscription Plan": "Subscription Plan",
			"Web Site": "Website",
			"Zipcode/Postalcode": "Zipcode/Postalcode",
			"First Name": "First Name",
			"Last Name": "Last Name",
			"enterPassword": "Please enter the password",
			"Enter Business Name": "Please enter the Business Name",
			"Enter Business Phone": "Please enter the Business Phone",
			"Enter webSite": "Please enter the webSite",
			"Enter Business Information": "Please enter the Business Information",
			"abbr": {
				"ad collection intro": "An ad collection defines which your ads included in and\n                      each ad collection can include multiple ads.",
				"ad intro": "An ad can have a different image,text,contact me\n                      button,link or video and also the ad image can contain\n                      different types such as 3D,AR or VR.",
				"campaign intro": "                      A campaign is a grouping of ad collection which are\n                      organized by the same business objective. Each campaign\n                      has an objective that must be valid across the ad\n                      collection within that campaign.",
				"cancel subscribe check": "Are you sure to cancel the subscription service？",
				"cancel subscribe info": "After unsubscribing, you will lose all privileges on this website.",
				"privilege info": "After you subscribe, you will have the following privileges.",
				"upgrade check": " Are you sure you want to upgrade your account？",
				"Are you sure you want to change your current plan ?": "Are you sure you want to change your current plan ?",
				"welcome": "Welcome To Adsmodo",
				"yes": "Yes, I'm Sure!",
				"Plan adsmodo a monthly $": "Plan adsmodo a monthly $",
				"advertising services": " advertising services"
			},
			"Monthly": "Monthly",
			"Activated": "Activated",
			"Current Plan": "Current Plan",
			"Change Plan": "Change Plan",
			"Upgrade Now": "Upgrade Now",
			"Last 4 digit": "Last 4 digit",
			"Expiring": "Expiring",
			"My Credit Cards": "My Credit Cards",
			"Update Card": "Update Card",
			"Company Information": "Company Information",
			"Subscribe": "Subscribe",
			"Pay": "Pay",
			"Team Information": "Team Information",
			"required": "required",
			"not valid": "not valid"
		},
		"list": {
			"User List": "User List",
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Name": "Name",
			"Email": "Email",
			"Phone": "Phone",
			"Team": "Team",
			"Role": "Role",
			"Owner": "Owner"
		},
		"edit": {
			"Yourcurrentteamroles": "Your current team's roles",
			"rolewarning": "By changing the role of user here, it may effects your current role access.",
			"avatar": "Avatar",
			"Add User": "Add User",
			"Edit User": "Edit User",
			"First Name": "First Name",
			"Last Name": "Last Name",
			"Email": "Email",
			"Phone": "Phone",
			"Password": "Password",
			"Confirm Password": "Confirm Password",
			"Change Password": "Change Password",
			"Cancel": "Cancel",
			"Team": "Team",
			"Role": "Role",
			"Back": "Back",
			"Save": "Save",
			"required": "required",
			"not valid": "not valid",
			"passwords not match": "passwords not match",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!",
			"jobTitle": "Job title",
			"description": "Description"
		}
	},
	"user permission get error": "user permission get error",
	"user-goal": {
		"edit": {
			"Add User Goal": "Add User Goal",
			"Back": "Back",
			"Edit User Goal": "Edit User Goal",
			"Name": "Name",
			"Save": "Save",
			"User Goal": "User Goal"
		},
		"list": {
			"data-table-title": "User Goal List",
			"Name": "Name",
			"User Goal": "User Goal",
			"User Goal List": "User Goal List"
		}
	},
	"validation errors": "validation errors",
	"pre:update name validate failed": "pre:update name validate failed",
	"Please enter the name": "Please enter the name",
	"pre:add name validate failed": "pre:add name validate failed",
	"First name is required.": "First name is required.",
	"pre:verify error": "pre:verify error",
	"post add adTransaction database error": "post add adTransaction database error",
	"The device has been bound, Please untie it first.": "The device has been bound, Please untie it first.",
	"pre:update user query error": "pre:update user query error",
	"Plan is required": "Plan is required",
	"pre:approved error": "pre:approved error",
	"You do not have the permission!": "You do not have the permission!",
	"Cancel customer subscription error": "Cancel customer subscription error",
	"Get current stripe customer id error": "Get current stripe customer id error",
	"Please enter the price": "Please enter the price",
	"pre:required": "pre:required",
	"pre:validation error": "pre:validation error",
	"pre:get campaign missing parameters": "pre:get campaign missing parameters",
	"Campaign can't be empty": "Campaign can't be empty",
	"pre:out of stock error": "pre:out of stock error",
	"Sorry, The items you choose are no longer available!": "Sorry, The items you choose are no longer available!",
	"User has been exist, Please sign in!": "User has been exist, Please sign in!",
	"no device found": "no device found",
	"No device found with associated user id": "No device found with associated user id",
	"Cannot read property 'mime' of undefined": "Cannot read property 'mime' of undefined",
	"The device is not exist.": "The device is not exist.",
	"Cannot read property 'includes' of undefined": "Cannot read property 'includes' of undefined",
	"Update user error": "Update user error",
	"pre:send verification error": "pre:send verification error",
	"pre:requied error": "pre:requied error",
	"fileType is not a function": "fileType is not a function",
	"Account and password or passcode was required!": "Account and password or passcode was required!",
	"location": {
		"edit": {
			"Name": "Name",
			"Location": "Location",
			"Add Location": "Add location",
			"Close": "Close",
			"Add": "Add",
			"title": "Device location Edit"
		},
		"list": {
			"Device Location List": "Device Location List",
			"Device Location": "Device Location"
		},
		"add": {
			"title": "Device Location Add"
		}
	},
	"pre: can't find the company type": "pre: can't find the company type",
	"The company type was not existed or have been deleted.": "The company type was not existed or have been deleted.",
	"pre:db query error": "pre:db query error",
	"description": "Description",
	"get item database error": "get item database error",
	"pre:sendPasscode validate mobile error": "pre:sendPasscode validate mobile error",
	"Please input your Email or Cell Phone!": "Please input your Email or Cell Phone!",
	"Last name is required.": "Last name is required.",
	"pre:add email validate failed": "pre:add email validate failed",
	"Email is required.": "Email is required.",
	"User create error": "User create error",
	"The email already exists. Please try another one.": "The email already exists. Please try another one.",
	"The company type already exists. Please try another one.": "The company type already exists. Please try another one.",
	"Login is abnormal, please try again later.": "Login is abnormal, please try again later.",
	"header": {
		"Ecommerce": "Ecommerce",
		"Pricing": "Pricing",
		"Get Started": "Get Started",
		"Sign Out": "Sign Out"
	},
	"footer": {
		"copy_right": "© Copyright 2021. All rights reserved."
	},
	"post": "signin account error",
	"my-reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "Valid from 15 Oct 2019 to 15 Jan 2020",
		"points": "points",
		"mdi-check-bold": "mdi-check-bold",
		"mdi-phone": "mdi-phone",
		"mdi-email": "mdi-email",
		"Report Reward issue to": "Report Reward issue to",
		"Use Now": "Use Now"
	},
	"my-reward-list": {
		"Your Rewards": "Your Rewards",
		"Valid till": "Valid till",
		"mdi-content-cut": "mdi-content-cut",
		"Use Now": "Use Now",
		"MERCHANT-TO-FILL-CODE": "MERCHANT-TO-FILL-CODE",
		"Please enter 6-digit code provided by the merchant": "Please enter 6-digit code provided by the merchant",
		"Confirm": "Confirm"
	},
	"member-change-password": {
		"Check": "Check",
		"Validate": "Validate",
		"Verify & Change": "Verify & Change",
		"Finished": "Finished",
		"Hi": "Hi",
		"Verification is in progress for": "Verification is in progress for",
		"mdi-information": "mdi-information",
		"Please verify your identity first": "Please verify your identity first",
		"Your email": "Your email",
		"has been verified": "has been verified",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"sentEmailPrompt": "We’ll send a passcode to your email for setting your password in just a minute",
		"Next": "Next",
		"Cancel": "Cancel",
		"sentPasscodePrompt": "We've sent a passcode to your email",
		"Please check your inbox": "Please check your inbox",
		"Please enter current password": "Please enter current password",
		"mdi-lock": "mdi-lock",
		"Pick a new password": "Pick a new password",
		"Password must contain": "Password must contain",
		"At least 1 upper case letter": "At least 1 upper case letter",
		"At least 1 number (0-9)": "At least 1 number (0-9)",
		"At least 8 character": "Password must contain at least 8 characters including uppercase, lowercase letters and numbers.",
		"Great": "Great",
		"Your password has been updated": "Your password has been updated"
	},
	"security-tab": {
		"mdi-tune": "mdi-tune",
		"Choose": "Choose",
		"Set up": "Set up",
		"Verify": "Verify",
		"Finished": "Finished",
		"Two-factor Authentication": "Two-factor Authentication",
		"Enabled tasks": "Enabled tasks",
		"You can choose a task": "You can choose a task form the list below after you've login.",
		"Text message": "Text message",
		"mdi-message-text": "mdi-message-text",
		"Verification codes are sent by text message": "Verification codes are sent by text message",
		"mdi-shield-lock": "mdi-shield-lock",
		"Authenticator App": "Authenticator App",
		"Use the Authenticator app": "Use the Authenticator app to get verification codes, even when your phone is offline.",
		"How would you": "How would you like to receive your authentication codes?",
		"After entering your password": "After entering your password, you’ll be asked for a second verification step.",
		"Verification codes": "Verification codes are sent by text message.(Required a verified mobile phone number)",
		"SET UP": "SET UP",
		"Cancel": "Cancel",
		"Let's set up": "Let's set up your phone for verification",
		"What phone number": "What phone number do you want to use?",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"mdi-information": "mdi-information",
		"Change": "Change",
		"Two Factor Setup": "Two Factor Setup",
		"Take these few steps to enable": "Take these few steps to enable two-factor authentication and make your account more secure.",
		"Install an authenticator app": "Install an authenticator app on your mobile device.",
		"Recommended options: Google Authenticator": "Recommended options: Google Authenticator,Authy,Microsoft Authenticator",
		"Scan QR code with the authenticator": "Scan QR code with the authenticator.",
		"Enter the 2-step authentication": "Enter the 2-step authentication code provided by your app.",
		"Continue": "Continue",
		"I'm ready": "I'm ready",
		"Back": "Back",
		"Verify your number": "Verify your number",
		"We just sent a text message": "We just sent a text message with a verification code to",
		"Didn't get it? Resend code": "Didn't get it? Resend code.",
		"Scan this QR code": "Scan this QR code with your mobile app.",
		"Then enter the six digit code": "Then enter the six digit code provided by the app:",
		"Can't scan the code": "Can't scan the code?",
		"Great": "Great!",
		"Two-factor Authentication has been": "Two-factor Authentication has been enabled for your account",
		"All future logins will now require": "All future logins will now require you to enter a code supplied by your mobile message or Authenticator App.",
		"Finish": "Finish",
		"Change Password": "Change Password",
		"Two-step verification": "Two-step verification",
		"Set up a unique password to protect your personal account": "Set up a unique password to protect your personal account",
		"Require a security key or code in addition to your password": "Require a security key or code in addition to your password"
	},
	"member-home": {
		"Special offers for you": "Special offers for you",
		"A confirmation email has been sent to": "A confirmation email has been sent to",
		"Click on the confirmation link in the email to verify your account.": "Click on the confirmation link in the email to verify your account",
		"Points": "Points",
		"My loyalty club": "My loyalty club",
		"Recommended loyalty club": "Recommended loyalty club",
		"More loyalty club": "More loyalty club",
		"More than": "More than",
		"clicks on ads": "clicks on ads",
		"Since this is your first login, please click": "Since this is your first login, please click this message to change your password",
		"There are currently": "There are currently",
		"promotional products": "promotional products",
		"Promotion code list": "Promotion code list",
		"Loyalty club name": "Loyalty club name",
		"Promotion code": "Promotion code",
		"Close": "Close",
		"View promo code": "View promo code"
	},
	"member-nav": {
		"Languages": "Languages",
		"Shopping Cart": "Shopping Cart"
	},
	"member-orders": {
		"Order": "Order",
		"Placed on": "Placed on",
		"Return Items": "Return Items"
	},
	"member-profile": {
		"points": "points",
		"Ok": "Ok",
		"Currently you don't have any points history": "Currently you don't have any points history",
		"Make a new purchase to earn reward points": "Make a new purchase to earn reward points",
		"Find More": "Find More"
	},
	"member-setting": {
		"Profile": "Profile",
		"Security": "Security",
		"Payment Method": "Payment Method"
	},
	"order-detail": {
		"Order Detail": "Order Detail",
		"mdi-printer": "mdi-printer",
		"mdi-airplane-takeoff": "mdi-airplane-takeoff",
		"Trace Your Order": "Trace Your Order",
		"Order Number": "Order Number",
		"Order Placed": "Order Placed",
		"Status": "Status",
		"Sales Person": "Sales Person",
		"Sub Total": "Sub Total",
		"Shipping Fee": "Shipping Fee",
		"Total": "Total",
		"Order Summary": "Order Summary",
		"Payment Method": "Payment Method",
		"Ship To": "Ship To",
		"No.18,Corner of Ahlone and Baho Road,Ahlone Township,Yangon": "No.18,Corner of Ahlone and Baho Road,Ahlone Township,Yangon",
		"Shipping Method": "Shipping Method",
		"Standard": "Standard",
		"Contact Information": "Contact Information",
		"Products Summary": "Products Summary"
	},
	"products-detail": {
		"Size Guide": "Size Guide",
		"Size Conversions": "Size Conversions",
		"Shipping price": "Shipping price",
		"Out of stock": "(Out of stock!)",
		"by": "by"
	},
	"reward-list": {
		"Used": "Used",
		"Adsmodo": "Adsmodo",
		"$": "$",
		"OFF YOUR PURCHASE": "OFF YOUR PURCHASE",
		"Gift Code:": "Gift Code:",
		"Valid till": "Valid till"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "Valid from 15 Oct 2019 to 15 Jan 2020",
		"points": "points",
		"mdi-check-bold": "mdi-check-bold",
		"mdi-phone": "mdi-phone",
		"mdi-email": "mdi-email",
		"Report Reward issue to Adsmodo": "Report Reward issue to Adsmodo"
	},
	"return-orders": {
		"Choose items to return": "Choose items to return",
		"Ordered on": "Ordered on",
		"Order Number": "Order Number",
		"Continue": "Continue",
		"Not returnable": "Not returnable",
		"Returnable untill": "Returnable untill",
		"Choose refund method": "Choose refund method",
		"Returning": "Returning",
		"Reason": "Reason",
		"We'll process your refund": "We'll process your refund to Visa ending 1234.",
		"Estimated refund timing": "Estimated refund timing: 2-3 business days after we receive the item.",
		"How do you want to send": "How do you want to send it back?",
		"$0": "$0",
		"Our deliveries will pickup": "Our deliveries will pickup your return package on the next business day.Printer required - you can print the label any time from Your Account or from the email we'll send you.",
		"Refund summary": "Refund summary",
		"Refund Subtotal": "Refund Subtotal:",
		"Shipping fee": "Shipping fee:",
		"Total refund": "Total refund:",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"Your label is ready to print": "Your label is ready to print",
		"Securely pack the items": "Securely pack the items to be returned. Send all the items back to Adsmodo by",
		"mdi-cloud-upload": "mdi-cloud-upload",
		"Print label": "Print label"
	},
	"products-list": {
		"Adsmodo": "Adsmodo",
		"Out of stock": "Out of stock!",
		"Available stock": "Available stock"
	},
	"common-list": {
		"no-data": "No records found",
		"reset": "Reset",
		"warning": "Warning",
		"deactivate-item-warning": "Are you sure you want to deactivate this item?",
		"activate-item-warning": "Are you sure you want to activate this item?",
		"delete-item-warning": "Are you sure you want to delete this item?",
		"delete-items-warning": "Are you sure you want to delete these items?",
		"delete-yes": "Yes",
		"delete-no": "Cancel",
		"operations": "Operations"
	},
	"customer failed": " create customer failed",
	"pre": "add ad name validate failed",
	"request to https": "//www.googleapis.com/oauth2/v4/token failed, reason: read ECONNRESET",
	"Sign in error": "Sign in error",
	"team": {
		"list": {
			"Team List": "Team List",
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Logo": "Logo",
			"Name": "Name",
			"Work Content": "Work Content",
			"Member": "Member",
			"Member List": "Member List",
			"Member Name": "Name",
			"Email": "Email",
			"Phone": "Phone"
		},
		"edit": {
			"Add Team": "Add Team",
			"Edit Team": "Edit Team",
			"Logo": "Logo",
			"Name": "Name",
			"Work Content": "Work Content",
			"Back": "Back",
			"Save": "Save",
			"required": "required",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!"
		}
	},
	"menu": {
		"list": {
			"data-table-title": "Menu List"
		}
	},
	"ad-product": {
		"list": {
			"data-table-title": "Products List"
		}
	},
	"loyalty-club": {
		"list": {
			"Loyalty Club": "Loyalty Club",
			"Add": "Add",
			"Delete": "Delete",
			"Search": "Search",
			"Operations": "Operations",
			"Logo": "Logo",
			"Name": "Name",
			"Introduction": "Introduction",
			"Member": "Member",
			"Invite": "Invite",
			"Member List": "Member List",
			"Invite Users": "Invite Users",
			"Member Name": "Name",
			"Email": "Email",
			"Phone": "Phone",
			"You will invite users": "You will invite users of the designated email to join your loyalty club",
			"Cancel": "Cancel",
			"Send": "Send",
			"required": "required",
			"not valid": "not valid",
			"cannotDeleteClub": "You can't delete the club with members."
		},
		"edit": {
			"Add Loyalty Club": "Add Loyalty Club",
			"Edit Loyalty Club": "Edit Loyalty Club",
			"Logo": "Logo",
			"Name": "Name",
			"Introduction": "Introduction",
			"Back": "Back",
			"Save": "Save",
			"required": "required",
			"Add success": "Successfully Added!",
			"Edit success": "Edited Successfully!",
			"ExternalUrl": "External Url",
			"Question": "Question",
			"Appointment questions": "Appointment questions",
			"Additional Features": "Additional Features",
			"Checkout with my existing merchant account": "Checkout with my existing merchant account.",
			"Checkout as a guest": "Checkout as a guest.",
			"Create a new merchant account and checkout": "Create a new merchant account and checkout."
		}
	},
	"category": {
		"list": {
			"data-table-title": "Product Category List"
		}
	},
	"promotion-log": {
		"list": {
			"data-table-title": "Promotion Log List"
		}
	},
	"Card info is required": "Card info is required",
	"Please register first!": "Please register first!",
	"Create user error": "Create user error",
	"campaign": {
		"list": {
			"data-table-title": "Campaigns List",
			"Deactivate": "Deactivate",
			"Reactivate": "Reactivate",
			"Primary": "Primary",
			"PopularNetwork": "Cross-promotion Network",
			"CPNetwork": "CP Network",
			"SelectNetwork": "Select Cross-promotion Network",
			"AddMyOwnNetwork": "Add My Own Network",
			"EditMyOwnNetwork": "Edit My Own Network",
			"NetworkOwner": "Network Owner"
		},
		"edit": {
			"Company Type": "Company Type",
			"Choose my own device": "Choose my own device",
			"Choose others device": "Choose others device",
			"Select Device": "Select Device",
			"AddMore": "Add More Devices",
			"Filter by Company Type": "Filter by Company Type",
			"Filter by Company": "Filter by Company",
			"Related Devices": "Related Devices",
			"Related Companies": "Related Companies"
		}
	},
	"purchase-log": {
		"list": {
			"data-table-title": "Purchase Log List"
		}
	},
	"order": {
		"View Order": "View Order",
		"All": "All",
		"payPending": "Pending",
		"Success": "Success",
		"Pending": "Pending",
		"Processing": "Processing",
		"Prepared": "Prepared",
		"Finished": "Finished",
		"Canceled": "Canceled",
		"Returned": "Returned",
		"Return-Requested": "Return-Requested",
		"Return-Rejected": "Return-Rejected",
		"Pending:": "Pending: ",
		"Processing:": "Processing: ",
		"Prepared:": "Prepared: ",
		"Finished:": "Finished: ",
		"Canceled:": "Canceled: ",
		"Returned:": "Returned: ",
		"Return-Requested:": "Return-Requested: ",
		"Return-Rejected:": "Return-Rejected:",
		"Search": "Search",
		"Order Number:": "Order Number: ",
		"Table Number:": "Table No.: ",
		"Products": "Products",
		"No": "No.",
		"SKU": "SKU",
		"Product": "Product",
		"Note": "Note",
		"AddOrderNote": "Add order notes",
		"OrderNote": "Order notes",
		"choosepickupTime": "Choose a pickup time",
		"pickupTime": "Pickup Time",
		"Unit Price": "Unit Price",
		"Quantity": "Quantity",
		"Price": "Price",
		"Payment Information": "Payment Information",
		"Customer Name": "Customer Name",
		"Customer Email": "Customer Email",
		"Customer Phone": "Customer Phone",
		"CreatedAt": "Created At",
		"UpdatedAt": "Updated At",
		"Total Price": "Total Price",
		"Payment Method": "Payment Method",
		"Payment Status": "Payment Status",
		"Order Status": "Order Status",
		"Please contact the customer!": "Please contact the customer!",
		"Click to Change": "Click to Change",
		"Order Status: Prepared": "Order Status: Prepared",
		"Send notification to the customer": "Send notification to the customer",
		"Cancel": "Cancel",
		"OK": "OK",
		"Finish Order": "Finish Order",
		"Ensure the customer has picked up": "Ensure the customer has picked up",
		"Confirm Return-Requested": "Confirm Return-Requested",
		"Reject Return-Request": "Reject Return-Request",
		"Ensure the customer has returned the products": "Ensure the customer has returned the products",
		"Ensure you have refunded": "Ensure you have refunded",
		"to the customer": "to the customer",
		"Order Number": "Order Number",
		"Table Number": "Table No.",
		"Detail": "Detail",
		"Cash": "Cash",
		"Merchant": "Merchant",
		"Pay Now": "Pay Now",
		"Operation": "Operation",
		"Cancel This Product": "Cancel This Product",
		"Get Receipt": "Get Receipt",
		"(password hint: customer phone)": "(password hint: customer phone)",
		"Cash on Delivery": "Cash on Delivery",
		"Payment Method: Cash on Delivery": "Payment Method: Cash on Delivery",
		"Please wait for the merchant to contact you!": "Please wait for the merchant to contact you!",
		"Cancel This Order": "Cancel This Order",
		"Return This Order": "Return This Order",
		"Merchant Information": "Merchant Information",
		"Company Name": "Company Name",
		"Phone": "Phone",
		"Address": "Address",
		"Website": "Website",
		"Credit Card": "Credit Card",
		"Payment Method: Credit Card": "Payment Method: Credit Card"
	},
	"Please re-enter the qty number": "Please re-enter the qty number",
	"Please select default club": "Please select default club",
	"Get user error": "Get user error",
	"Discount is invalid": "Discount is invalid",
	"server error": "server error",
	"Variants quantity exceeds total quantity": "Variants quantity exceeds total quantity",
	"Variant SKU should be unique for each": "Variant SKU should be unique for each",
	"purchase pre": " can't find the product config",
	"The product config was not existed or have been deleted.": "The product config was not existed or have been deleted.",
	"homePage": {
		"Price": "Price",
		"monthly": "monthly",
		"learnmore": "Learn More"
	},
	"rules": {
		"required": "required",
		"not valid": "not valid",
		"Name is required": "Name is required",
		"Name is too long": "Name is too long",
		"Job Title is required": "Job Title is required",
		"text is required": "text is required",
		"company is required": "company is required",
		"Category Name is required": "Category Name is required",
		"name is required": "name is required",
		"E-mail is not valid": "E-mail is not valid",
		"summary is required": "summary is required",
		"Loyalty Club is required": "Loyalty Club is required",
		"Product is required": "Product is required",
		"Start Date is required": "Start Date is required",
		"End Date is required": "End Date is required",
		"Discount is required": "Discount is required",
		"Invalid": "Invalid",
		"Required": "required",
		"Min 8 characters": "Min 8 characters",
		"The password you entered not match": "The password you entered not match",
		"E-mail or mobile phone is required": "E-mail or mobile phone is required",
		"First Name is too long": "First Name is too long",
		"Last Name is too long": "Last Name is too long"
	},
	"loadMore": "Load more",
	"Update company error": "Update company error",
	"Business Owner": "Business Owner",
	"Team Member": "Team Member",
	"Profile": "Profile",
	"Member Site": "Member Site",
	"Ad player": "Ad player",
	"Help": "Help",
	"UserManual": "User Manual",
	"Login": "Member Login",
	"Register": "Register",
	"AD": "Advertising",
	"Join Now": "Join Now",
	"Register Now": "Register now",
	"Threesteps": "Open business opportunities",
	"Threesteps2": "Steps",
	"Last updated": "Last updated",
	"UseBasicRole": "Use Basic Role",
	"I have read and agree to the": "I have read and agree to the",
	"Make deposit": "Make deposit",
	"Checkout with my merchant account.": "Checkout with my merchant account.",
	"Checkout as a guest.": "Checkout as a guest.",
	"Sign out": "Sign out",
	"Choose Payment": "Choose Payment",
	"mediaLibrary": {
		"ExpertMode": "Expert Mode",
		"ActivateLinePay": "Activate Line Pay",
		"LineChannelID": "Line Pay (Channel ID)",
		"LineSecretKey": "Line Pay (Channel Secret Key)",
		"company": "company",
		"product": "product",
		"activities": "activities",
		"SystemAdsAssets": "System Ads Assets",
		"CompanyAdsAssets": "Company Ads Assets",
		"selectAltInfo": "Please select your advertising media from the saved Ads Assets or upload directly",
		"backToParent": "Back to parent folder",
		"goIntoChild": "Go into child folder",
		"addFolder": "Add Folder",
		"editItem": "Edit Item",
		"noFilesInfo": "You have not uploaded resources here, please click the colored area below to upload or directly drag the  file to the colored area below. Support uploading pictures, videos and compressed files",
		"okSelect": "OK",
		"filterBy": "Filter by",
		"theme": "Theme",
		"label": "Label",
		"AdsMediaAssets": "Ads Media Assets",
		"selectFromAssets": "Select from saved Ads Assets",
		"Advanced": "Advanced",
		"addtionalOptions": "Addional Options",
		"chooseOrDragFile": "Click to choose a file or drag it here.",
		"imageFileLimit": "Available extensions for images : jpeg, jpg, png, bmp",
		"videoFileLimit": "Available extensions for videos : mp4",
		"MaxFileSize": "Available maximum file size : image 5MB & video 30MB",
		"parentFolder": "Parent Folder",
		"displayName": "Folder Name",
		"zipFolderInfo": "The server is processing your compressed file, please wait for the processing result. If no results are returned for a long time, please try to upload again or contact the administrator.",
		"cancelUploadingAlert": "Do you want to cancel uploading these file(s)?",
		"uploading": "uploading {uploading} item(s)",
		"uploaded": "{uploaded} uploaded",
		"Add": "Add"
	},
	"submit": "Submit",
	"cancel": "Cancel",
	"Clear": "Clear",
	"Add": "Add",
	"quickStart": {
		"No recent notifications": "No recent notifications.",
		"title": "Quick Start",
		"PurchaseDevice": "Purchase Device",
		"CreateAd": "Create Ad",
		"CreateAdCollection": "Create Ad Collection",
		"CreateCampaign": "Create Campaign",
		"prev": "Prev",
		"next": "Next",
		"skip": "Skip",
		"save": "Save",
		"notPurchasedDevice": "You have not purchased any device, you can go to the 'Device' to buy or choose other company’s devices",
		"notFoundDevices": "No devices found",
		"myCompanyDevices": "My Company Devices",
		"otherCompanyDevices": "Other Company Devices",
		"otherCompanyDevicesNearBy": "Near By Devices",
		"Next": "Next",
		"Work Done": "Work Done !",
		"Shortcut": "Shortcut",
		"Manage Advertising": "Manage Advertising",
		"Manage Device Enable Campaigns": "Manage Device & Enable Campaigns",
		"CRM": "CRM",
		"Manage Company": "Manage Company",
		"Skip This Step": "Skip This Step"
	},
	"dashboard": {
		"ads": "Ads",
		"devices": "Devices",
		"campaigns": "Campaigns",
		"loyaltyClubMembers": "Loyalty Club Members",
		"adsLabel": "Total posted ads",
		"devicesLabel": "Total devices you purchased",
		"campaignsLabel": "Total campaigns you created",
		"loyaltyClubMembersLabel": "Total members joined",
		"downloadApp": "Download Adsmodo's App",
		"promoteApp": "Promote your business with FREE Ads",
		"merchantApp": "Merchant Controller App",
		"playerApp": "Ads Player App"
	},
	"team is required.": "team is required.",
	"There are users in the company and cannot be deleted.": "There are users in the company and cannot be deleted.",
	"There are users in the team and cannot be deleted.": "There are users in the team and cannot be deleted.",
	"There are users who are the role and cannot be deleted.": "There are users who are the role and cannot be deleted.",
	"Apply": "Apply",
	"OK": "OK",
	"Cancel": "Cancel",
	"Crop": "Crop",
	"Delete": "Delete",
	"DeleteAll": "Delete All",
	"Undo": "Undo",
	"Redo": "Redo",
	"Reset": "Reset",
	"Rotate": "Rotate",
	"Draw": "Draw",
	"Shape": "Shape",
	"Icon": "Icon",
	"Text": "Text",
	"Mask": "Mask",
	"Filter": "Filter",
	"All rights reserved": "All rights reserved.",
	"The ad has been used, please delete ad collection first.": "The ad has been used, please delete ad collection first.",
	"The ad collection has been used, please delete campaign first.": "The ad collection has been used, please delete campaign first.",
	"Thank you for your message": "Thank you for your message!",
	"We will contact you as soon as possible": "We will contact you as soon as possible",
	"Post Ads in Just Three Steps": "Post Ads in Just Three Steps",
	"There are loyalty clubs in the company and cannot be deleted.": "There are loyalty clubs in the company and cannot be deleted.",
	"schedule": {
		"doctor": {
			"required": "Please select a doctor",
			"scheduled": "The doctor has been scheduled at this moment"
		},
		"Contact Name": "Contact Name",
		"Contact Email": "Contact Email",
		"Contact Mobile": "Contact Mobile",
		"Schedule Time": "Schedule Time",
		"Doctor": "Doctor",
		"Club Name": "Club",
		"Make an appointment": "Make an appointment",
		"description": "Choose a doctor and make an appointment",
		"Select the appointment time": "Select the appointment time",
		"onDuty": "On duty",
		"appointment": "Appointment"
	},
	"Name create error": "Name create error",
	"Duration error": "Duration error",
	"This name already exists. Please try another one.": "This name already exists. Please try another one.",
	"passcode not matched": "passcode not matched",
	"encryption error": "encryption error",
	"User does not exist, Please try again later.": "User does not exist, Please try again later.",
	"Found multiple users, Please use email get the passcode.": "Found multiple users, Please use email get the passcode.",
	"The ad has been used in the active campaign.": "The ad has been used in the active campaign.",
	"Could not update the ad. The ad has been used in the active campaign.": "Could not update the ad. The ad has been used in the active campaign.",
	"Campaign approve failed.": "Campaign approve failed.",
	"Can't approve your own campaign.": "Can't approve your own campaign.",
	"Resource is not found": "Resource is not found",
	"Verification code send succeed": "Verification code send succeed",
	"Cannot delete current company": "Cannot delete current company",
	"Cannot deactivate current company": "Cannot deactivate current company",
	"Cannot deactivate primary company": "Cannot deactivate primary company",
	"Cannot create more than 12 campaigns for Free Plan!": "Cannot create more than 12 campaigns for Free Plan!",
	"Exceed the maximum number of Posting!": "Exceed the maximum number of Posting!",
	"Exceed the maximum number of Posting(12 campaigns) for Free Plan!": "Exceed the maximum number of Posting(12 campaigns) for Free Plan!",
	"Exceed the maximum number of Posting for Free Plan!": "Exceed the maximum number of Posting for Free Plan!",
	"You have exceeded maximum 12 campaigns for your plan!": "You have exceeded maximum 12 campaigns for your plan!",
	"No such order with this email": "moe@mail.com.",
	"moe@mail.comNo such order with this email.": "moe@mail.comNo such order with this email.",
	"You already have an account with this email. Sign in with this email instead.": "You already have an account with this email. Sign in with this email instead.",
	"You already have an account with this email. \nSign in with this email instead.": "You already have an account with this email. \nSign in with this email instead.",
	"You already have an account with this email. \n Sign in with this email instead.": "You already have an account with this email. \n Sign in with this email instead.",
	"Already have an account with this email. \n Please Sign in instead.": "Already have an account with this email. \n Please Sign in instead.",
	"Already have an account with this email.Please sign in instead.": "Already have an account with this email.Please sign in instead.",
	"Already have an account with this email. Please sign in instead.": "Already have an account with this email. Please sign in instead.",
	"Already have an account with this email.  Please sign in instead.": "Already have an account with this email.  Please sign in instead.",
	"You already have an account with this email. Please sign in instead.": "You already have an account with this email. Please sign in instead.",
	"Phone is required.": "Phone is required.",
	"Device": "Device",
	"The device does not exist or has been deleted": "The device does not exist or has been deleted.",
	"New Order has been submitted using your device.": "New Order has been submitted using your device.",
	"please log in first": "please log in first",
	"Update Device Campaigns": "Update Device Campaigns",
	"News ticker is created using your device.": "News ticker is created using your device.",
	"Please select an ad first": "Please select an ad first",
	"Found multiple users, Please use email to get the passcode.": "Found multiple users, Please use email to get the passcode.",
	"User info verify error": "User info verify error",
	"Please input your First Name.": "Please input your First Name.",
	"Line pay error": "Line pay error",
	"Please input Line Pay (Channel ID or Channel Secret Key)": "Please input Line Pay (Channel ID or Channel Secret Key)",
	"Successfully bind with your goole account. You can now use google sign in!": "Successfully bind with your account. You can now sign in!",
	"Successfully bind with your goole account": {
		" You can now use google sign in!": "Successfully bind with your account. You can now sign in!"
	},
	"You have exceeded maximum campaigns for your plan!": "You have exceeded maximum campaigns for your plan!",
	"Current plan had expired!": "Current plan had expired!"
};