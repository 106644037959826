export default {
  namespaced: true,
  state: {
    currentStep: undefined,
    steps_data: {
      ads_tour: [
        {
          url: "/app/ad/add",
          target: "#v-step-6",
          content: `<div style="font-size:15px">You can publish new advertisements here.</div>`,
          params: {
            placement: "right"
          }
        },
        {
          url: "/app/ad/add",
          target: "#v-step-7",
          content: `<div style="font-size:15px">You can preview the videos or images contained in your advertisement here.</div>`,
          params: {
            placement: "right",
            offset: [10, 20]
          }
        },
        {
          url: "/app/ad/all",
          target: "#v-step-5",
          content: `<div style="font-size:15px">You can view the list of advertisements and modify the status of advertisements here.</div>`,
          params: {
            placement: "left"
          }
        },
        {
          url: "/app/ad/all",
          target: "#v-step-8",
          content: `<div style="font-size:15px">You can click here to preview the videos or images included in the advertisement.</div>`,
          params: {
            placement: "left"
          }
        }
      ],
      device_tour: [
        {
          url: "/app/devices/buy-product",
          target: "#v-step-2",
          content: `<div style="font-size:15px">Here you can purchase devices.</div>`,
          params: {
            placement: "left"
          }
        },
        {
          url: "/app/devices/buy-log",
          target: "#v-step-3",
          content: `<div style="font-size:15px">You can view Device Purchase Log here.</div>`,
          params: {
            placement: "right"
          }
        },
        {
          url: "/app/devices/buy-log",
          target: "#v-step-4",
          content: `<div style="font-size:15px">You need receiving equipment.</div>`,
          params: {
            placement: "right"
          }
        }
      ]
    },
    selectedType: undefined
  },
  mutations: {
    save(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  getters: {
    steps(state) {
      if (!state.selectedType) return [];
      return state.steps_data[state.selectedType];
    }
  },
  actions: {
    setStep({ commit }, { currentStep }) {
      commit("save", { currentStep });
    },
    setType({ commit }, { selectedType }) {
      commit("save", { selectedType });
    }
  }
};
