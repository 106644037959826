module.exports = {
	"Account or password was incorrect!": "¡Cuenta o contraseña incorrecta!",
	"Can't get your device info": "No se puede obtener la información de tu dispositivo",
	"Device does not exist or has been deleted, please reselect": "El dispositivo no existe o se ha eliminado, vuelva a seleccionar",
	"Duplicate current plan": "Duplicar plan actual",
	"Email and passcode is required!": "Se requiere correo electrónico y contraseña!",
	"Email or password is incorrect": "Correo electrónico o la contraseña son incorrectos",
	"Find device error": "",
	"Find user error": "Encontrar error de usuario",
	"Get current customer plan is required": "Obtener el plan actual del cliente es obligatorio",
	"Hello": "",
	"Id is required!": "Se requiere identificación!",
	"Inconsistent password": "Contraseña inconsistente",
	"Member does not exist, Please try again later!": "El miembro no existe. ¡Inténtalo de nuevo más tarde!",
	"No id": "Sin identificación",
	"Please enter the device name": "Por favor ingrese el nombre del dispositivo",
	"Please enter your email": "Por favor introduzca su correo electrónico",
	"Please enter your mobile": "Por favor ingrese su móvil",
	"Please select an item first": "Por favor seleccione un artículo primero",
	"Signin error": "Error de inicio de sesión",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "",
		"Your account does not have a subscription. Please subscribe to post an ad": ""
	},
	"Swal": {
		"member_sign_up": {
			"Please register to be part of this club": ""
		},
		"More than available stock": "",
		"Are you sure?": "",
		"You will not be able to recover this!": "",
		"Yes, delete it!": "",
		"No, cancel plx!": "",
		"You must register your company first!": "",
		"Exceed the maximum number of Ad": "",
		"Upgrade your account,and create more Advertising": "",
		"Out of stock": "",
		"Please select the variant!": "",
		"Invalid coupon code": "",
		"Invalid coupon code for this product!": "",
		"Valid coupon code": "",
		"Add success": "Añadir éxito",
		"Already in the cart": "Ya en el carro",
		"Amount should be more than zero": "¡La cantidad debe ser más que cero!",
		"Bind Success": "Bind Success",
		"Buy device success": "Comprar dispositivo exitoso",
		"Device type is required": "Se requiere tipo de dispositivo",
		"Edit success": "Editar éxito",
		"End date must be greater than start date!": "¡La fecha de finalización debe ser mayor que la fecha de inicio!",
		"Please select a valid image": "Por favor seleccione una imagen válida",
		"Please select a valid video": "Por favor seleccione un video válido",
		"Session time out.Please log in again": "Tiempo de espera de sesión. Inicie sesión de nuevo",
		"Something went wrong": "Algo salió mal",
		"Subscription success": "Suscripción exitosa",
		"Successfully approved": "¡Aprobado con éxito!",
		"Successfully generated": "Generado con éxito!",
		"Your profile has been successfully updated": "Tú perfil ha sido actualizado satisfactoriamente",
		"account": {
			"Cancel Subscription success": "Cancelar suscripción exitosa",
			"Cancel subscription error": "Cancelar error de suscripción",
			"The current user does not support this feature at this time": "El usuario actual no admite esta función en este momento.",
			"This operation is not supported at this time": "Esta operación no es compatible en este momento"
		},
		"ad": {
			"Ad created successfully": "Anuncio creado con éxito",
			"Ad edited successfully": "Anuncio editado con éxito",
			"Cancel": "Cancelar",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "¡Felicitaciones! Ha creado su primer anuncio. ¿Desea continuar?"
			},
			"Continue": "Continuar",
			"Product category is needed!": "¡Se necesita categoría de producto!",
			"Product name is needed!": "Se necesita el nombre del producto!",
			"success info": "información de éxito"
		},
		"sign": {
			"Agreement Required": "",
			"Please agree the Terms & Condtions": "",
			"Please improve the information": "Por favor, melhore a informação",
			"Business address invalid, please enter again": "Dirección comercial no válida, ingrese nuevamente.",
			"Company type require!": "Tipo de empresa requiere!",
			"Invalid recaptcha token": "Token recaptcha inválido",
			"Password doesnot match!": "¡Las contraseñas no coinciden!",
			"Please post your ad now": "Por favor publique su anuncio ahora.",
			"Purchase fail": "Compra fallida",
			"Purchase success": "Compra exitosa",
			"The Passcode have been send success": "El código de acceso se ha enviado correctamente",
			"The passcode have been send successfully": "La contraseña se ha enviado correctamente.",
			"The password and confirm password not the same": "La contraseña y la contraseña de confirmación no son las mismas"
		},
		"You need to set up your payment method to purchase devices": "Debes configurar tu método de pago para comprar dispositivos",
		"Payment method is required": "Se requiere método de pago"
	},
	"The ad does not exist or has been deleted": "El anuncio no existe o se ha eliminado.",
	"The device has been bound, Please untie it first": {
		"": "El dispositivo se ha atado. Desátelo primero."
	},
	"The device is not exist": {
		"": "El dispositivo no existe."
	},
	"The email is already registered!": "¡El correo electrónico ya está registrado!",
	"The item does not exist or has been deleted": "El elemento no existe o se ha eliminado.",
	"The mobile number is already registered!": "¡El número de móvil ya está registrado!",
	"The user does not exist or has been deleted": "El usuario no existe o ha sido eliminado",
	"Update plan error": "Error de plan de actualización",
	"User does not exist, Please try again later!": "El usuario no existe. ¡Inténtalo de nuevo más tarde!",
	"You do not have permission": "No tienes permiso",
	"ad-template": {
		"edit": {
			"Add Builder Template": "Agregar plantilla de generador",
			"Back": "atrás",
			"Builder Template": "Plantilla de constructor",
			"Edit Builder Template": "Editar plantilla de constructor",
			"Name": "Nombre",
			"Save": "Salvar",
			"Status": "Estado",
			"Type": "Tipo"
		},
		"list": {
			"Builder Template": "Plantilla de constructor",
			"Builder Template List": "Lista de plantillas de constructor",
			"Name": "Nombre",
			"Status": "Estado",
			"Type": "Tipo",
			"data-table-title": "Lista de plantillas de constructor"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "Transacción AD",
			"AD Transaction List": "Lista de transacciones de AD",
			"Ads": "Anuncios",
			"Collection": "Colección",
			"Device": "Dispositivo",
			"Type": "",
			"Campaign": "",
			"Number": "número"
		}
	},
	"admin-nav": {
		"Tutorial": "",
		"Logout": "Cerrar sesión",
		"Manager": "Gerente",
		"New Enquiry": "Nueva consulta",
		"Online": "En línea",
		"Pending Ad": "Anuncio pendiente",
		"Pending User": "Usuario pendiente",
		"Profile": "Perfil",
		"User": "Usuario",
		"User Manual": "Manual de usuario"
	},
	"ads": {
		"edit": {
			"3D Effect Link": "Enlace de efecto 3D",
			"Ad Template": "Plantilla de anuncio",
			"Add Advertising": "Añadir publicidad",
			"Additional Discount": "Descuento adicional",
			"Advanced": "Avanzado",
			"Advertising": "Publicidad",
			"Advertising End Date": "Fecha de finalización de publicidad",
			"Approval": "Aprobación",
			"Approved": "Aprobado",
			"Back": "atrás",
			"Duration (s)": "Duración (s)",
			"Edit Advertising": "Editar publicidad",
			"Extra Link": "Enlace extra",
			"Name": "Nombre",
			"Pending": "pendiente",
			"Preview": "Avance",
			"Product Purchase link": "Enlace de compra de producto",
			"Product Purchasing": "Compra de productos",
			"Promotional Text": "Texto promocional",
			"Reject": "rechazar",
			"Rejected": "Rechazado",
			"Save": "Salvar",
			"Second(s)": "Segundos)",
			"Select products for this Ad": "Seleccione productos para este anuncio",
			"Status": "Estado",
			"Upgrade": "Mejorar",
			"Video Url": "URL del vídeo",
			"Warning!": "¡Advertencia!",
			"abbr": {
				"create": "Crea tus propios anuncios",
				"preview": "Vista previa de los anuncios",
				"warning content": "No hay suficiente saldo de AD para el público, actualice."
			}
		},
		"list": {
			"Advertising": "Publicidad",
			"Advertising List": "Lista de publicidad",
			"Approval Status": "Estado de aprobación",
			"Approved": "Aprobado",
			"Due Date": "Fecha de vencimiento",
			"Duration (s)": "Duración (s)",
			"Name": "Nombre",
			"Pending": "pendiente",
			"Products": "productos",
			"Created Date": "Fecha de creación",
			"Status": "Estado",
			"Type": "Tipo"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "Enlace AR / Enlace 3D",
			"Ad Name": "Nombre del anuncio",
			"Ad Template": "Plantilla de anuncio",
			"All": "TODOS",
			"Campaign Name": "Nombre de campaña",
			"Cancel": "Cancelar",
			"Collection Name": "Nombre de la colección",
			"Company Type": "Tipo de compañía",
			"Contact Me 3D Link": "Contáctame Enlace 3D",
			"Contact Me Video Link": "Contáctame Enlace de video",
			"Create your own ads": "Crea tus propios anuncios",
			"Device List": "Lista de dispositivos",
			"Duration (s)": "Duración (s)",
			"Edit": "Editar",
			"End Date": "Fecha final",
			"Finish": "Terminar",
			"Let me think": "Déjame pensar",
			"Maps": "Mapas",
			"Name": "Nombre",
			"Next": "Siguiente",
			"On The Map": "En el mapa",
			"Previous": "Anterior",
			"Publish to Device": "Publicar en dispositivo",
			"Publishing Device": "Dispositivo de publicación",
			"Reject": "rechazar",
			"Search": "Buscar",
			"Second(s)": "Segundos)",
			"Shop Link": "Enlace de tienda",
			"Start Date": "Fecha de inicio",
			"Step": "Paso%{num}",
			"Submit": "Enviar",
			"Success": "Éxito",
			"Text": "Texto",
			"abbr": {
				"another": "Crea otro",
				"created": "Se crea el anuncio.",
				"device select": "Seleccione el dispositivo que desea publicar.",
				"introduction": "Aquí puede publicar anuncios, promocionar sus propios productos, restaurantes; y elija el dispositivo que desea publicar, comencemos.",
				"privilege intro": "Después de suscribirse, tendrá los siguientes privilegios.",
				"start": "Comience a crear su anuncio ahora!",
				"step1": "Paso 1: información básica",
				"step2": "Paso 2: Diseño publicitario",
				"upgrate check": "¿Está seguro de que desea actualizar su cuenta?",
				"wait": "Por favor espere la aprobación de la plataforma.",
				"welcome": "Bienvenido a Adsmodo",
				"yes": "¡Sí estoy seguro!"
			},
			"another": "Crea otro",
			"created": "Se crea el anuncio.",
			"introduction": "Aquí puede publicar anuncios, promocionar sus propios productos, restaurantes; y elija el dispositivo que desea publicar, comencemos.",
			"welcome": "Bienvenido a Adsmodo"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "Página de inicio",
				"Home Page List": "Lista de la página de inicio",
				"Title": "Título",
				"Created At": "Creado en",
				"Updated At": "Actualizado en"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "Agregar página de inicio",
				"Website Link": "Enlace al sitio web",
				"Company Type List": "Lista de tipos de empresas",
				"ADVERTISEMENT Content": "Contenido de PUBLICIDAD",
				"About Us": "Sobre nosotros",
				"Banner": "Bandera",
				"Contact Us": "Contáctenos",
				"Content": "Contenido",
				"Edit Home Page": "Editar página de inicio",
				"How to use": "Cómo utilizar",
				"Image": "Imagen",
				"Introduction": "Introducción",
				"Menu Name": "Nombre del menú",
				"Multiple select": "Selección múltiple",
				"Project": "Proyecto",
				"SHOPKEEPER Content": "Contenido de SHOPKEEPER",
				"Scenario": "Guión",
				"Sub Title": "Subtitulo",
				"Team Member": "Miembro del equipo",
				"Title": "Título",
				"Overall": "En general",
				"Font Color": "Color de fuente",
				"White": "Blanco",
				"Black": "Negro",
				"left": "izquierda",
				"center": "centrar",
				"right": "Derecha",
				"Edit": "Editar",
				"Add": "Añadir",
				"Title Position": "Cargo de título",
				"Background Transparent": "Fondo transparente",
				"Left/Right Image": "",
				"Left Title": "Título izquierdo",
				"Left Content": "Contenido izquierdo",
				"Right Title": "Título correcto",
				"Right Content": "Contenido correcto",
				"Scenario List": "Lista de escenarios",
				"Team List": "Lista de equipo",
				"Website": "Sitio web",
				"EditHomePage": "Editar página de inicio"
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "",
				"Add Scenario": "",
				"Scenario Image": "",
				"Name": "",
				"Description": ""
			},
			"list": {
				"Scenario": "",
				"Scenario List": "",
				"Image": "",
				"Name": "",
				"Description": ""
			}
		},
		"team": {
			"edit": {
				"Add Team Member": "Agregar miembro del equipo",
				"Edit Team Member": "Editar miembro del equipo",
				"Image": "Imagen",
				"Job Title": "Título profesional",
				"Name": "Nombre",
				"Text": "Texto"
			},
			"list": {
				"Team": "Equipo",
				"Team List": "Lista de equipos",
				"Image": "Imagen",
				"Job Title": "Título del trabajo",
				"Created At": "Creado en",
				"Ad Type": "Tipo de anuncio",
				"Name": "Nombre",
				"Team Member List": "Lista de miembros del equipo"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "Editar Menu",
				"Add Menu": "Add Menu",
				"Name": "Nome",
				"Menu Type": "Tipo de menu",
				"Parent Menu": "Menu pai",
				"Link": "Link",
				"Show On Footer": "Show On Footer",
				"Please enter the name": "Por favor ingrese el nombre",
				"Please enter the link": "Por favor ingrese el enlace"
			},
			"list": {
				"Menu": "Menu",
				"Menu List": "Lista de Menu",
				"Name": "Nome",
				"Type": "Tipo",
				"Parent": "Pais",
				"Link": "Link"
			}
		}
	},
	"already registered!": "¡ya registrado!",
	"bcrypt error": "error de bcrypt",
	"campaigns": {
		"edit": {
			"Add Campaign": "Añadir campaña",
			"All": "TODOS",
			"Back": "atrás",
			"Cancel": "Cancelar",
			"Company Type": "Tipo de compañía",
			"Edit Campaign": "Editar campaña",
			"End Date": "Fecha final",
			"Finish": "Terminar",
			"Maps": "Mapas",
			"Name": "Nombre",
			"On The Map": "En el mapa",
			"Publishing Device": "Dispositivo de publicación",
			"Save": "Salvar",
			"Search": "Buscar",
			"Select Ad Collection": "Seleccionar colección de anuncios",
			"Search devices": "",
			"Open Map": "Mapa abierto",
			"Start Date": "Fecha de inicio",
			"abbr": {
				"Select device": "Seleccione el dispositivo",
				"select": "Seleccione el dispositivo que desea publicar."
			},
			"Search Devices": "Buscar dispositivos",
			"Target Devices": "Dispositivos de destino",
			"Company": "Empresa",
			"City": "Ciudad",
			"State": "Estado",
			"Distance": "Distancia",
			"Map": "Mapa"
		},
		"list": {
			"Ad Collections": "Colecciones de anuncios",
			"Campaigns": "Campañas",
			"Campaigns List": "lista de campañas",
			"Devices": "Dispositivos",
			"End Date": "Fecha final",
			"Name": "Nombre",
			"Start Date": "Fecha de inicio",
			"Status": "Estado"
		}
	},
	"cart": {
		"Adsmodo": "Adsmodo",
		"Apply": "Aplicar",
		"Continue Shopping": "Seguir comprando",
		"My Cart": "Mi carrito",
		"Order Summary": "Resumen del pedido",
		"Price": "Precio",
		"Proceed to Checkout": "Pasar por la caja",
		"Product": "producto",
		"QTY": "Cantidad",
		"Seller": "Vendedor",
		"Shipping": "Envío",
		"Shipping Fee": "Gastos de envio",
		"Sub Total": "Sub Total",
		"Total": "total",
		"You save": "Ahorras",
		"Coupon code": "Coupon code",
		"promotion": "Promo Code",
		"Please select your gift Card code": "Please select your gift Card code"
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "Agregar colección de anuncios",
			"Back": "atrás",
			"Edit Ad Collection": "Editar colección de anuncios",
			"Name": "Nombre",
			"Publishing Device": "Dispositivo de publicación",
			"Save": "Salvar",
			"Select Ads": "Seleccionar anuncios"
		},
		"list": {
			"Ads": "Anuncios",
			"Ads Collection": "Colección de anuncios",
			"Ads Collection List": "Lista de colección de anuncios",
			"Name": "Nombre"
		}
	},
	"common": {
		"Search": "Search",
		"404": {
			"abbr": {
				"back": "Volver al tablero de instrumentos",
				"sorry": "Lo sentimos, página no encontrada"
			}
		},
		"ad-image": {
			"Click to select picture": "Haga clic para seleccionar la imagen"
		},
		"ad-video": {
			"Click to select video": "Haga clic para seleccionar el video"
		},
		"bind-credit-card": {
			"Bind": "Enlazar",
			"CVC": "CVC",
			"Card Number:": "Número de tarjeta:",
			"MM": "MM",
			"YYYY": "YYYY"
		},
		"select-ad-device": {
			"Device List": "Lista de dispositivos",
			"Let me think": "Déjame pensar",
			"Yes, I'm Sure!": "¡Sí estoy seguro!",
			"abbr": {
				"plan": "Planifique adsmodo un servicio de publicidad mensual de $ 100",
				"upgrade check": "¿Está seguro de que desea actualizar su cuenta?"
			}
		},
		"tui-image-editor": {
			"Cancel": "Cancelar",
			"Edit image": "Editar imagen",
			"Image Resolution :": "Resolución de imagen :",
			"Load Assets": "Cargar activos",
			"Load Promotion Asset": "Activo de promoción de carga",
			"Load assets": "Cargar activos",
			"Promotion assets": "Activos de promoción",
			"Save": "Salvar"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "Añadir tipo de empresa",
			"Back": "atrás",
			"Edit Company Type": "Editar tipo de empresa",
			"Name": "Nombre",
			"Save": "Salvar",
			"description": "Descripción",
			"Description": "Descripción"
		},
		"list": {
			"Company Type": "Tipo de compañía",
			"Company Type List": "Lista de tipo de empresa",
			"Created Date": "Fecha de creacion",
			"Name": "Nombre"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "anuncio",
			"Ad Collection": "Colección de anuncios",
			"Back": "atrás",
			"Cell Phone": "Teléfono móvil",
			"Contact Me": "Contacta me",
			"Device": "Dispositivo",
			"Email": "Email"
		},
		"list": {
			"Ad": "anuncio",
			"Campaign": "campaña",
			"Collection": "Colección",
			"Contact Me": "Contacta me",
			"Contact Me List": "Contacta me Lista",
			"Device": "Dispositivo",
			"Email": "Email",
			"Phone Number": "Número de teléfono",
			"action": "Acción",
			"data-table-title": "Contact Me List"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "Dirección de Negocios",
			"Contacts": "Contactos",
			"Contacts List": "Lista de contactos",
			"Email": "Email",
			"Is Admin": "es admin",
			"Mobile": "Móvil",
			"Name": "Nombre"
		}
	},
	"customer failed: create customer failed": "cliente fallido: crear cliente fallido",
	"customer failed: create token failed": "cliente fallido: creación de token fallido",
	"dataTable": {
		"Add": "Añadir",
		"Are you sure to deactivate it?": "¿Estás seguro de desactivarlo?",
		"Are you sure to delete it?": "¿Está seguro de eliminarlo?",
		"Cancel": "Cancelar",
		"Choose your options below and drop one into the inbox of anyone on your list!": "¡Elija sus opciones a continuación y suelte una en la bandeja de entrada de cualquiera en su lista!",
		"Confirm": "Confirmar",
		"Deactivate": "Desactivar",
		"Delete": "Eliminar",
		"Operate": "Funcionar",
		"Please select the item to delete": "Por favor, seleccione el elemento para eliminar",
		"Please select the loyalty member to give reward": "Por favor, seleccione el miembro de fidelidad para dar recompensa!",
		"Reward Gift Card": "Tarjeta de regalo de recompensa",
		"This operation cannot be undone": "Esta operación no se puede deshacer.",
		"We offer electronic gift cards in any denomination": "Ofrecemos tarjetas de regalo electrónicas en cualquier denominación."
	},
	"database error": "Error de la base de datos",
	"devices": {
		"addCampaign": {
			"Ad Collections": "Colecciones de anuncios",
			"Ad Images": "Imágenes publicitarias",
			"Ad List": "Lista de anuncios",
			"Add Campaign": "Añadir campaña",
			"Availavle Campaigns": "Campañas disponibles",
			"Back": "atrás",
			"Campaign Detail": "Detalle de campaña",
			"Campaign List": "lista de campaña",
			"Campaigns": "Campañas",
			"Device": "Dispositivo",
			"Device ID": "ID del dispositivo",
			"Device Name": "Nombre del dispositivo",
			"Drag available campaigns here to run on device": "Arrastre las campañas disponibles aquí para ejecutarlas en el dispositivo",
			"Duration(s)": "Duración (s)",
			"End Date": "Fecha final",
			"Name": "Nombre",
			"Save": "Salvar",
			"Start Date": "Fecha de inicio",
			"Status": "Estado",
			"This ad have no images": "Este anuncio no tiene imágenes."
		},
		"buy-log": {
			"Buy Device Log": "Comprar registro de dispositivo",
			"Buy Device Log List": "Comprar lista de registro de dispositivos",
			"Name": "Nombre",
			"Quantity": "Cantidad",
			"Status": "Estado",
			"Unit Price": "Precio unitario",
			"Permission": "Permission"
		},
		"buy-product": {
			"Back": "atrás",
			"Buy Device": "Comprar dispositivo",
			"Device Quantity": "Cantidad de dispositivo",
			"Location": "Ubicación",
			"Device Type": "Tipo de dispositivo",
			"Save": "Salvar",
			"Add Location": "Añade una ubicación"
		},
		"edit": {
			"Add Device": "Añadir dispositivo",
			"Are you sure to activate this device ?": "¿Estás seguro de activar este dispositivo?",
			"Are you sure to deactivate this device ?": "¿Estás seguro de desactivar este dispositivo?",
			"Back": "atrás",
			"Business Name": "Nombre del Negocio",
			"Cancel": "Cancelar",
			"Confirm": "Confirmar",
			"Device ID": "ID del dispositivo",
			"Device Info": "Información del dispositivo",
			"Device Name": "Nombre del dispositivo",
			"Device Status": "Estado del dispositivo",
			"Device Type": "Tipo de dispositivo",
			"Edit Device": "Editar dispositivo",
			"Manufacturer": "Fabricante",
			"Model": "Modelo",
			"Name": "Nombre",
			"Offline": "Desconectado",
			"Online": "En línea",
			"Placement Location": "Ubicación de colocación",
			"Platform": "Plataforma",
			"Save": "Salvar",
			"Select Ad Collections": "Seleccionar colecciones de anuncios",
			"Serial": "De serie",
			"Toggle": "Palanca",
			"UUID": "UUID",
			"Version": "Versión",
			"Your device is now Offline!": "¡Su dispositivo ahora está fuera de línea!",
			"Your device is now Online!": "¡Su dispositivo ya está en línea!",
			"Your device need to register via App first !": "¡Su dispositivo debe registrarse primero a través de la aplicación!"
		},
		"list": {
			"Ad Collections": "Colecciones de anuncios",
			"Businesss Name": "Nombre del Negocio",
			"Campaigns": "Campañas",
			"Device ID": "ID del dispositivo",
			"Device Info": "Información del dispositivo",
			"Devices": "Dispositivos",
			"Devices List": "Lista de dispositivos",
			"Name": "Nombre",
			"Position": "Posición",
			"Status": "Estado",
			"User": "Usuario",
			"Public Name": "Nombre publico",
			"Location": "Ubicación",
			"ID": "CARNÉ DE IDENTIDAD",
			"title": "Dispositivos",
			"data-table-title": "Lista de dispositivos"
		},
		"purchase-log": {
			"Device": "Dispositivo",
			"Order No": "N º de pedido",
			"Purchase Log": "Registro de compras",
			"Purchase Log List": "Lista de registro de compras",
			"Quantity": "Cantidad",
			"Subscription": "Suscripción",
			"Total Price": "Precio total",
			"Unit Price": "Precio unitario",
			"User Name": "Nombre de usuario"
		}
	},
	"email": {
		"create-email": {
			"Back": "atrás",
			"Email From": "Email de",
			"Message": "Mensaje",
			"Send": "Enviar",
			"Send To": "enviar a",
			"Subject": "Tema"
		}
	},
	"email-log": {
		"edit": {
			"Back": "atrás",
			"Content": "Contenido",
			"Created At": "Creado en",
			"Email Log": "Registro de correo electrónico",
			"Name": "Nombre",
			"Send Status": "Enviar estado",
			"Subject": "Tema",
			"Template": "Modelo",
			"To": "a",
			"from": "Desde"
		},
		"list": {
			"Email Log": "Registro de correo electrónico",
			"Email Log List": "Lista de registro de correo electrónico",
			"Email template": "Plantilla de correo electrónico",
			"Name": "Nombre",
			"from": "Desde",
			"to": "a"
		}
	},
	"email-template": {
		"edit": {
			"Add Email Template": "Agregar plantilla de correo electrónico",
			"Available keyword": "Palabra clave disponible",
			"Back": "atrás",
			"Edit Email Template": "Editar plantilla de correo electrónico",
			"Email Body": "Cuerpo del correo electronico",
			"Email From": "Email de",
			"Name": "Nombre",
			"Save": "Salvar",
			"Send To": "enviar a",
			"Status": "Estado",
			"Subject": "Tema",
			"abbr": {
				"note": "Solo para uso administrativo de back office; Si el destinatario es múltiple, utilice una coma para dividir."
			},
			"template Type": "Tipo de plantilla"
		},
		"list": {
			"Draft": "Borrador",
			"Email Template": "Plantilla de correo electrónico",
			"Email Template List": "Lista de plantillas de correo electrónico",
			"Name": "Nombre",
			"Published": "Publicado",
			"Status": "Estado",
			"Type": "Tipo",
			"badge-danger": "insignia de peligro",
			"badge-success": "insignia de éxito"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "Añadir consulta",
			"Back": "atrás",
			"Edit Enquiry": "Editar consulta",
			"Email": "Email",
			"Enquiry Type": "Tipo de consulta",
			"Message": "Mensaje",
			"Name": "Nombre",
			"Phone": "Teléfono",
			"Reply": "respuesta",
			"Save": "Salvar",
			"Show Enquiry": "Mostrar consulta"
		},
		"list": {
			"Created Date": "Fecha de creación",
			"Email": "Email",
			"Enquiry": "investigación",
			"Enquiry List": "Lista de consultas",
			"Message": "Mensaje",
			"Name": "Nombre",
			"Reply": "respuesta",
			"Type": "Tipo",
			"data-table-title": "Enquiry List"
		}
	},
	"get ad query error": "obtener error de consulta de anuncio",
	"get ads database error": "obtener error de base de datos de anuncios",
	"get item database error ": "obtener error de la base de datos del elemento",
	"get item query error": "obtener error de consulta de elemento",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "Código de seguridad de 3 dígitos en el reverso de la tarjeta",
		"Amount of Gift Card": "Cantidad de tarjeta de regalo",
		"CVC": "CVC",
		"Card Number": "Numero de tarjeta",
		"Email From": "Email de",
		"Expiration Date": "Fecha de caducidad",
		"For payment confirmation": "Para confirmación de pago",
		"Generate Gift Card": "Generar tarjeta de regalo",
		"Generate Gift Card for the selected loyalty member": "Generar tarjeta de regalo para el miembro de fidelidad seleccionado",
		"Gift Cards": "Tarjetas de regalo",
		"Gift Cards Log": "Registro de tarjetas de regalo",
		"Recipient Email": "Receptor de E-mail",
		"Recipient Name": "Nombre del Recipiente"
	},
	"home": {
		"Accountant": "Contador",
		"Add Product to cart": "Añadir producto al carrito",
		"Age": "años",
		"CRM": "gestión de relaciones con clientes",
		"Complete Purchases": "Compras completas",
		"Daily Visits": "Visitas diarias",
		"Dashboard": "Tablero",
		"Device Orders": "Pedidos de dispositivo",
		"Edinburgh": "Edimburgo",
		"From Last 1 Month": "Desde el último mes",
		"From Last 24 Hours": "De las últimas 24 horas",
		"Goal Completion": "Cumplimiento de objetivos",
		"Integration Specialist": "Especialista en integración",
		"Junior Technical Author": "Autor técnico junior",
		"Last 3 Month Ad-Collection Record": "Último registro de recopilación de anuncios de 3 meses",
		"Last 3 Month Subscription Record": "Último registro de suscripción de 3 meses",
		"Last Month": "El mes pasado",
		"Last week": "La semana pasada",
		"Mail Inbox": "Bandeja de entrada de correo",
		"Marketplace": "Mercado",
		"Monthly Earnings": "Ingresos mensuales",
		"Monthly Purchases": "Compras mensuales",
		"Name": "Nombre",
		"New York": "Nueva York",
		"Office": "oficina",
		"Orders in Last 10 months": "Pedidos en los últimos 10 meses",
		"Pending Ad": "Anuncio pendiente",
		"Pending User": "Usuario pendiente",
		"Position": "Posición",
		"Recent Contacts": "Contactos recientes",
		"Revenue": "Ingresos",
		"Salary": "Salario",
		"Sales Assistant": "Asistente de ventas",
		"San Francisco": "SAN FRANCISCO",
		"Send Inquiries": "Enviar consultas",
		"Senior Javascript Developer": "Desarrollador Javascript Senior",
		"Start date": "Fecha de inicio",
		"System Architect": "sistema arquitecto",
		"Tokyo": "Tokio",
		"Total Subscriptions": "Suscripciones totales",
		"Unique Ad-Viewers": "Visores de anuncios únicos",
		"Visit Premium plan": "Visita el plan Premium",
		"Weekly Top 5 Advertisement": "Anuncio semanal de los 5 principales",
		"select device error": "seleccione error del dispositivo"
	},
	"id required": "Identificación requerida",
	"image is required to show ad": "se requiere imagen para mostrar el anuncio",
	"main-nav": {
		"Sign In": "Registrarse",
		"Sign Up": "Regístrate",
		"Terms & Conditions": "Terms & Conditions"
	},
	"member": {
		"About": "Acerca de",
		"Back": "atrás",
		"Cancel": "Cancelar",
		"Clear": "Claro",
		"Confirm": "Confirmar",
		"Contact Info": "Datos de contacto",
		"Description": "Descripción",
		"Earned Points": "Puntos ganados",
		"Edit": "Editar",
		"Email Verification Success": "Verificación de correo electrónico correcta",
		"Explore": "Explorar",
		"Got it!": "¡Lo tengo!",
		"If you experience any issues with this Reward,please feel free to contact": "Si tienes algún problema con esta Recompensa, no dudes en contactar",
		"LearnMore": "aprende más",
		"Please enter activation code sent to this number": "Por favor ingrese el código de activación enviado a este número",
		"Points history": "Historial de puntos",
		"Redeem": "Redimir",
		"Redeemable points can be exchanged for rewards at the Rewards page": "Los puntos canjeables se pueden canjear por recompensas en la página de Recompensas",
		"Save": "Salvar",
		"Share": "Compartir",
		"Special Deals": "Ofertas especiales",
		"Spent Points": "Puntos gastados",
		"Terms and Conditions": "Términos y Condiciones",
		"What are Redeemable Points?": "¿Qué son los puntos canjeables?",
		"Your email has been verified.We will redirect you to home page": "Su correo electrónico ha sido verificado. Lo redireccionaremos a la página de inicio",
		"nav": {
			"Adsmodo Loyalty Members Club": "Club de miembros de lealtad Adsmodo",
			"Home": "Casa",
			"Log Out": "Cerrar sesión",
			"My rewards": "Mi recompensa",
			"Products": "productos",
			"Profile": "Perfil",
			"Purchase History": "Historial de compras"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "Pastel caducado",
			"Cake Used": "Pastel usado",
			"Roylaty Member Detail": "Detalle de miembro Roylaty"
		},
		"form": {
			"Birthday": "Cumpleaños",
			"Join The Adsmodo Loyalty Club": "Únase al Club de lealtad Adsmodo",
			"Select Business Type*": "Seleccione tipo de negocio *",
			"Submit": "Enviar",
			"Team": "Team"
		},
		"list": {
			"Birthday": "Cumpleaños",
			"Email": "Email",
			"Loyalty Member": "Miembro de lealtad",
			"Loyalty Member List": "Lista de miembros de lealtad",
			"Mobile": "Móvil",
			"Name": "Nombre",
			"Points": "puntos"
		}
	},
	"no permission": "Sin autorización",
	"post:Device update error": "post: error de actualización del dispositivo",
	"post:sendPasscode error": "post: error de código de envío",
	"post:signin account error": "post: error de cuenta de inicio de sesión",
	"pre:add ad name validate failed": "pre: no se pudo validar el nombre del anuncio",
	"pre:audit user query error": "pre: error de consulta de usuario de auditoría",
	"pre:bind have been bound": "pre: bind se ha vinculado",
	"pre:bind verify name failed": "pre: el nombre de verificación de enlace falló",
	"pre:bind verify serial failed": "pre: enlace verificar error en serie",
	"pre:delete database error": "pre: eliminar error de base de datos",
	"pre:find user": "pre: encontrar usuario",
	"pre:get purchase log database error": "pre: obtener error de la base de datos del registro de compras",
	"pre:member get error": "pre: miembro obtiene error",
	"pre:missing parameters": "pre: parámetros faltantes",
	"pre:sendPasscode find user error": "pre: sendPasscode encuentra error de usuario",
	"pre:signin device has been bound": "pre: el dispositivo de inicio de sesión se ha vinculado",
	"pre:signin find user error": "pre: inicio de sesión encontrar error de usuario",
	"pre:unbind the device is not exist": "pre: desvincular el dispositivo no existe",
	"pre:update get campaign database error": "pre: actualización obtener error de base de datos de campaña",
	"pre:update get member database error": "pre: actualizar obtener error de base de datos de miembros",
	"pre:update item query error": "pre: error de consulta de elemento de actualización",
	"pre:update missing parameters": "pre: actualizar los parámetros faltantes",
	"pre:verification error": "pre: error de verificación",
	"product": {
		"ads": {
			"Recommended Products": "Recommended Products",
			"more products": "more products",
			"LearnMore": "Learn More"
		},
		"edit": {
			"Select Category is required": "Select Category is required",
			"Name is required": "Name is required",
			"Currency is required": "Currency is required",
			"giftfile is required": "giftfile is required",
			"You will join": "You will join",
			"club": "club",
			"You have chosen": "You have chosen",
			"clubs": "clubs",
			"Add Options": "Agregar opciones",
			"Add Pricing": "Agregar precios",
			"Add Product": "Añadir Producto",
			"Add more variant option": "Agregar más opción de variante",
			"Add option value": "Agregar valor de opción",
			"Back": "atrás",
			"Create Gift Card": "Crear tarjeta de regalo",
			"Currency": "Moneda*",
			"Description": "Descripción",
			"Edit Product": "Editar producto",
			"Gift Card": "Tarjeta de regalo",
			"Images": "Imágenes",
			"Master price": "Precio maestro",
			"Name": "Nombre",
			"Option": "Opción",
			"Option Types": "Tipos de opciones",
			"Price": "Precio",
			"Price Range": "Rango de precios*",
			"Pricing": "Precios",
			"Qty": "Cantidad",
			"Quantity": "Cantidad",
			"Quantity in Hand": "",
			"Is Returnable": "Es retornable",
			"Size Description": "Descripción del tamaño",
			"Save": "Salvar",
			"Select Category": "Selecciona una categoría*",
			"Shipping Price(Optional)": "Precio de envío (opcional)",
			"Variant Option": "Opción variante"
		},
		"list": {
			"Category": "CATEGORÍA",
			"Image": "Imagen",
			"Name": "Nombre",
			"Price Range": "Rango de precios",
			"Products": "productos",
			"Products List": "Lista de productos"
		},
		"public-detail": {
			"Add to cart": "Añadir a la cesta",
			"Available Stock": "Stock disponible",
			"Options": "Opciones",
			"Original": "original",
			"Please choose the product to buy": "Por favor, elija el producto para comprar",
			"Price": "Precio",
			"Total Price": "Precio total",
			"Product Detail": "Detalle del producto",
			"Qty": "Cantidad",
			"Shipping Price": "Precio de envío"
		},
		"public-list": {
			"Detail": "Detalle",
			"Product Catalog": "Catalogo de producto",
			"Shipping Price": "Precio de envío"
		}
	},
	"ad-product-category": {
		"edit": {
			"Category Name is required": "Category Name is required",
			"Add Product Category": "Agregar categoría de producto",
			"Back": "atrás",
			"Category Name": "nombre de la categoría",
			"Edit Product Category": "Editar categoría de producto",
			"Save": "Salvar"
		},
		"list": {
			"Name": "Nombre",
			"Product Category": "categoria de producto",
			"data-table-title": "Lista de categorías de productos"
		}
	},
	"product-device": {
		"edit": {
			"Add Product Device": "Agregar dispositivo de producto",
			"Back": "atrás",
			"Device Name": "Nombre del dispositivo",
			"Device Price": "precio del dispositivo",
			"Edit Product Device": "Editar dispositivo del producto",
			"Product Device": "Dispositivo del producto",
			"Save": "Salvar"
		},
		"list": {
			"Device Name": "Nombre del dispositivo",
			"Device Price": "precio del dispositivo",
			"Product Device": "Dispositivo del producto",
			"Product Device List": "Lista de dispositivos del producto"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "Agregar promoción",
			"Back": "atrás",
			"Coupon count(s)": "Recuento de cupones",
			"Description": "Descripción",
			"Edit Promotion": "Editar Promoción",
			"End Date": "Fecha final",
			"Promotion Image": "Imagen de promoción",
			"Promotion Type": "Tipo de promoción",
			"Promotional Text": "Texto promocional",
			"Save": "Salvar",
			"Start Date": "Fecha de inicio",
			"Select Products": "Seleccionar productos",
			"Max Purchase Amount": "Cantidad máxima de compra",
			"Coupon Code": "Código promocional",
			"Amount": "Cantidad",
			"Percentage": "Porcentaje"
		},
		"list": {
			"Coupon Count": "Recuento de cupones",
			"End Date": "Fecha final",
			"Name": "Nombre",
			"Promotion": "promoción",
			"Promotion List": "Lista de promociones",
			"Start Date": "Fecha de inicio",
			"Type": "Tipo"
		},
		"log": {
			"Promotion Log": "Registro de promoción",
			"Date": "Fecha",
			"Customer Name": "Nombre del cliente",
			"Promotion": "Promoción",
			"Received Amount": "Cantidad recibida"
		}
	},
	"reward": {
		"list": {
			"Reward List": "",
			"Name": "",
			"Description": "",
			"Points": "",
			"Contact": ""
		},
		"edit": {
			"Reward Edit": "",
			"Reward Add": "",
			"Image": "",
			"Name": "",
			"Reward By": "",
			"Location": "",
			"Points": "",
			"Mobile Number": "",
			"Email": "",
			"Terms & Conditions": "",
			"ADD": "",
			"About us": "",
			"Add your Terms & Conditions": ""
		}
	},
	"recaptcha error": "error recaptcha",
	"recaptchaToken is required": "Se requiere token recaptcha",
	"role": {
		"edit": {
			"Add Role": "Agregar rol",
			"Back": "atrás",
			"Edit Role": "Editar rol",
			"Name": "Nombre",
			"Role Permission": "Permiso de rol",
			"Save": "Salvar"
		},
		"list": {
			"Name": "Nombre",
			"Permission": "Permiso",
			"Role": "papel",
			"Role List": "Lista de roles",
			"data-table-title": "Role List"
		}
	},
	"role-permission": {
		"edit": {
			"Ad Collection Module": "Módulo de recopilación de anuncios",
			"Add Role Permission": "Agregar permiso de rol",
			"Advertising Module": "Módulo de publicidad",
			"Back": "atrás",
			"Campaign Module": "Módulo de campaña",
			"Create": "Crear",
			"Day(s)": "Dias)",
			"Delete": "Borrar",
			"Device Module": "Módulo de dispositivo",
			"Edit Role Permission": "Editar permiso de rol",
			"Expiry Date": "Fecha de caducidad",
			"List": "Lista",
			"Name": "Nombre",
			"Retrieve": "Recuperar",
			"Save": "Salvar",
			"Update": "Actualizar",
			"E-Commerce Module": "módulo de comercio electrónico",
			"CRM Module": "módulo CRM",
			"Level-1": "Nível-1",
			"Level-2": "Nível-2",
			"Level-3": "Nível-3"
		},
		"E-Commerce Module": {
			"Products": "productos",
			"Product Category": "categorías de productos",
			"Promotion": "promoción",
			"Promotion Log": "Diario de promoción",
			"Generate Gift Cards": "Creación de una tarjeta de regalo",
			"Gift Card Log": "Diario de la tarjeta de regalo",
			"Rewards": "recompensa"
		},
		"CRM Module": {
			"Email Log": "Diario de correo electrónico",
			"Email Template": "plantilla de correo electrónico",
			"Loyalty Club": "Club de la lealtad",
			"Enquiry": "consulta"
		},
		"list": {
			"Name": "Nombre",
			"Role Permission": "Permiso de rol",
			"Role Permission List": "Lista de permisos de roles",
			"data-table-title": "Role Permission List"
		}
	},
	"searching": {
		"Ad Collection": "Colección de anuncios",
		"Ads": "Anuncios",
		"Apply": "Aplicar",
		"Campaign": "campaña",
		"Clear filter": "Filtro claro",
		"Collections": "Colecciones",
		"Detail": "Detalle",
		"Device": "Dispositivo",
		"Duration": "Duración",
		"Duration(s)": "Duración (s)",
		"End Date": "Fecha final",
		"Filter by": "Filtrado por",
		"First name": "Nombre de pila",
		"ID": "Carné de identidad",
		"Items": "artículos",
		"Last name": "Apellido",
		"Name": "Nombre",
		"No results found for": "No se encontraron resultados para",
		"Position": "Posición",
		"Results for": "resultados para",
		"Start Date": "Fecha de inicio",
		"Status": "Estado",
		"Type": "Tipo",
		"User": "Usuario"
	},
	"company": {
		"list": {
			"Company": "Company",
			"data-table-title": "company list",
			"Company Logo": "Company Logo",
			"Name": "name",
			"Phone": "Phone",
			"Website": "Website"
		},
		"create": {
			"Save": "Save",
			"Back": "Back",
			"Edit Company": "Edit Company",
			"Create Company": "Create Company",
			"Business Name is required": "Business Name is required",
			"Company Type is required": "Company Type is required",
			"Business phone is required": "Business phone is required",
			"Web Site is required": "Web Site is required",
			"Zipcode is required": "Zipcode is required",
			"Business Address is required": "Business Address is required",
			"Business Logo": "Business Logo",
			"Business Name": "Business Name",
			"Company Type": "Company Type",
			"Business phone": "Business phone",
			"Web Site": "Web Site",
			"Zipcode": "Zipcode",
			"Business Address": "Business Address"
		},
		"orderlist": {
			"Date": "",
			"Customer Name": "",
			"Product": "",
			"Qty": "",
			"Total Price": "",
			"Pay Status": "",
			"Finish State": ""
		},
		"createChildCompanyAndStripe": {
			"Create Company Stripe Account": "",
			"Edit Company Stripe Account": "",
			"Name": "",
			"Birthday": "",
			"Email": "",
			"SSN": "",
			"Account Number": "",
			"Routing Number": "",
			"Company Name": "",
			"MCC": "",
			"City": "",
			"Address Line 1": "",
			"Postal code": "",
			"State": "",
			"Phone": ""
		}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "Tipo de plantilla de anuncio",
			"Advertising Setting": "Ambiente publicitario",
			"Day(s) ": "Dias)",
			"Duration (s)": "Duración (s)",
			"Expiry Date": "Fecha de caducidad",
			"Expires in": "",
			"Name": "Nombre",
			"Save": "Salvar",
			"Second(s)": "Segundos)",
			"Subscription Plan": "Plan de suscripción",
			"day": "Dias)"
		},
		"general": {
			"Default Language": "Idioma predeterminado",
			"Generel Settings": "Configuraciones de Generel",
			"Name": "Nombre",
			"New User Default Role": "Nuevo rol predeterminado del usuario",
			"Save": "Salvar"
		},
		"product": {
			"Name": "Nombre",
			"Number": "número",
			"Price": "Precio",
			"Product Name": "nombre del producto",
			"Product Number": "Número de producto",
			"Product Price": "Precio del producto",
			"Product Service": "Servicio de producto",
			"Product Text": "Texto del producto",
			"Product Type": "Tipo de producto",
			"Save": "Salvar",
			"Service": "Servicio",
			"Subscription Lists": "Listas de suscripción",
			"Subscription Name": "Nombre de suscripción",
			"Subscription Type": "tipo de suscripción",
			"Usage": "Uso"
		}
	},
	"sign": {
		"forget": {
			"Authentication required": "Autenticação exigida",
			"We sent": "Enviamos UMA Senha única vez para o e-mail",
			"Please enter it below": "Por favor, introduza-o lá EM baixo.",
			"OTP will expired in": "Uma Senha Temporal VAI expirar em",
			"OTP has expired": "Uma Senha Temporal expirou.",
			"Resend OTP": "Resend One Time Password",
			"Enter OTP": "Enter One Time Password",
			"Required": "Necessário",
			"Passcode is required": "Código De Passe é necessário",
			"Email is required": "Email é necessário",
			"Please select your account type": "Seleccione por favor o Tipo de conta",
			"Please type your email or mobile": "Por favor, Digite SEU email ou celular",
			"mdi-reply": "mdi-resposta",
			"Reset your Password": "Reinicie SUA senha",
			"Reset my password": "Reinicie minha senha",
			"Password Changed": "Senha mudada",
			"You can now login with your new password": "Você Pode Agora login com SUA Nova senha",
			"Login": "Login",
			"Old Password": "Old Password",
			"New Password": "New Password",
			"Confirm Password": "Confirmar senha",
			"Forgot Password": "Senha esquecida",
			"Email or mobile": "e-mail ou número de telefone celular",
			"Enter the email address or mobile phone number": "Indique o endereço de e-mail Ou o número de telefone celular associado à SUA conta Adsmodo.",
			"Continue": "Continue",
			"Reset Password": "Restablecer la contraseña",
			"Sign In": "Registrarse",
			"abbr": {
				"send": "Envíame una contraseña"
			}
		},
		"sign-in": {
			"Create an account": "Crea una cuenta",
			"Forgot password": "¿Se te olvidó tu contraseña?",
			"Loyalty Member Sign In": "Miembro de lealtad Iniciar sesión",
			"Sign In": "Registrarse",
			"abbr": {
				"send": "Envíame una contraseña"
			},
			"Already have an account?": "",
			"Forgot-Password": "¿Se te olvidó tu contraseña?"
		},
		"sign-up": {
			"Quick Sign Up": "",
			"Complete Sign Up": "",
			"Address:": "Dirección:",
			"Ads My biz": "Ads My biz",
			"All of Above": "Todas las de arriba",
			"Business Address": "Dirección de Negocios",
			"Business Name": "Nombre del Negocio",
			"Business phone": "Teléfono de negocios",
			"Card Number:": "Número de tarjeta:",
			"Company Type": "Tipo de compañía",
			"Confirm": "Confirmar",
			"Confirm Information": "Confirmar información",
			"Confirm password": "Confirmar contraseña",
			"Create user account": "Crear cuenta de usuario",
			"Email": "Email",
			"Email:": "Email:",
			"Extra $": "$ Extra",
			"First Name": "Nombre de pila",
			"First Name:": "Nombre de pila:",
			"Help others": "Ayuda a otros",
			"Last Name:": "apellido:",
			"Next": "Siguiente",
			"Password": "Contraseña",
			"Payment Options": "Opciones de pago",
			"Phone": "Teléfono",
			"Phone:": "teléfono:",
			"Prev": "prev",
			"Price:": "Precio:",
			"Price(month):": "Precio (mes):",
			"Product:": "Producto:",
			"Quantity": "Cantidad",
			"Select Adsmodo Business Assistant": "Seleccione Adsmodo Business Assistant",
			"Subscription Plan": "Plan de suscripción",
			"Tell us about your goal": "Cuéntanos sobre tu objetivo",
			"Web Site": "sitio web",
			"Zipcode": "código postal",
			"abbr": {
				"agree": "He leído y acepto los términos.",
				"business info": "Cuéntanos sobre tu negocio",
				"web tutorial": "Términos y condiciones"
			},
			"last Name": "Apellido"
		}
	},
	"signin verify error": "iniciar sesión verificar error",
	"signup password error": "error de contraseña de registro",
	"single": {
		"component": {
			"Contact info": "Datos de contacto",
			"Email address": "Dirección de correo electrónico",
			"Input your contact info to get coupons and subscribe our service": "Ingrese su información de contacto para obtener cupones y suscribirse a nuestro servicio.",
			"Input your contact info to get coupons!": "Ingrese su información de contacto para obtener cupones!",
			"Phone number": "Número de teléfono",
			"Submit": "Enviar",
			"abbr": {
				"get contact info": ""
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "¿Estás seguro de activar este plan?",
			"Are you sure to deactivate this plan?": "¿Estás seguro de desactivar este plan?",
			"Your plan is now Active!": "¡Tu plan está en marcha!",
			"Your plan is now Inactive!": "¡Tu plan no funciona!",
			"Active": "Activo",
			"Inactive": "Inactivo",
			"Add Subscription Plan": "Agregar plan de suscripción",
			"Back": "atrás",
			"Edit Subscription Plan": "Editar plan de suscripción",
			"Number": "número",
			"Price": "Precio",
			"Save": "Salvar",
			"Service": "Servicio",
			"Subscription Name": "Nombre de suscripción",
			"Subscription Plan": "Plan de suscripción",
			"Subscription Type": "tipo de suscripción",
			"Usage": "Uso",
			"Maximum Area Range": "Rango de área máxima"
		},
		"list": {
			"Price": "Precio",
			"Subscription Plan": "Plan de suscripción",
			"Subscription Plan List": "Lista de planes de suscripción",
			"Subscription Plan Type": "Tipo de plan de suscripción"
		}
	},
	"user": {
		"account": {
			"Account Type": "Tipo de cuenta",
			"Subscription Plan Type": "",
			"Ad Address": "Dirección del anuncio",
			"Basic Information": "Información básica",
			"Save Credit Card": "Salvar tarjeta de crédito",
			"Change Credit Card": "",
			"Current Credit Card": "",
			"Saved Payment Methods": "",
			"Bind Status": "Estado de enlace",
			"Bind credit card": "Vincular tarjeta de crédito",
			"Business Address": "Dirección de Negocios",
			"Business Information": "Información de negocios",
			"Business Name": "Nombre del Negocio",
			"Business Phone": "Teléfono de negocios",
			"Cancel": "Cancelar",
			"Cancel Subscription": "Cancelar suscripción",
			"Cell Phone": "Teléfono móvil",
			"Company Type": "Tipo de compañía",
			"Email": "Email",
			"Free": "Gratis",
			"Hi": "Hola",
			"Info": "información",
			"Let me think": "Déjame pensar",
			"Name": "Nombre",
			"Password": "Contraseña",
			"Product Type": "Tipo de producto",
			"Profile Image": "Imagen de perfil",
			"Redeem": "Redimir",
			"Rewards": "Recompensas",
			"Save": "Salvar",
			"Skip Tour": "Omitir recorrido",
			"Start Create Ads": "Comience a crear anuncios",
			"Subscription Plan": "Plan de suscripción",
			"Web Site": "sitio web",
			"Zipcode/Postalcode": "Código Postal / Código Postal",
			"abbr": {
				"ad collection intro": "Una colección de anuncios define cuáles son sus anuncios incluidos y\n                      cada colección de anuncios puede incluir múltiples anuncios.",
				"ad intro": "Un anuncio puede tener una imagen, texto diferente, contácteme\n                      botón, enlace o video y también la imagen del anuncio puede contener\n                      diferentes tipos como 3D, AR o VR.",
				"campaign intro": "",
				"cancel subscribe check": "¿Estás seguro de cancelar el servicio de suscripción?",
				"cancel subscribe info": "Después de darse de baja, perderá todos los privilegios en este sitio web.",
				"privilege info": "Después de suscribirse, tendrá los siguientes privilegios.",
				"upgrade check": " ¿Está seguro de que desea actualizar su cuenta?",
				"welcome": "Bienvenido a Adsmodo",
				"yes": "¡Sí estoy seguro!"
			},
			"Monthly": "",
			"Activated": "",
			"Current Plan": "",
			"Change Plan": "",
			"Upgrade Now": "",
			"Last 4 digit": "",
			"Expiring": ""
		},
		"edit": {
			"Add User": "Agregar usuario",
			"Back": "atrás",
			"Can adding ads": "Puede agregar anuncios",
			"Edit User": "editar usuario",
			"Email": "Email",
			"Is Admin": "es admin",
			"Mobile": "Móvil",
			"Name": "Nombre",
			"Password": "Contraseña",
			"Save": "Salvar",
			"User Role": "Rol del usuario",
			"team": "Team"
		},
		"list": {
			"Email": "Email",
			"Is Admin": "es admin",
			"Mobile": "Móvil",
			"Name": "Nombre",
			"Role": "papel",
			"Users": "Usuarios",
			"Users List": "Lista de usuarios",
			"data-table-title": "User List"
		}
	},
	"user permission get error": "permiso de usuario obtener error",
	"user-goal": {
		"edit": {
			"Add User Goal": "Añadir objetivo de usuario",
			"Back": "atrás",
			"Edit User Goal": "Editar objetivo de usuario",
			"Name": "Nombre",
			"Save": "Salvar",
			"User Goal": "Objetivo del usuario"
		},
		"list": {
			"Name": "Nombre",
			"User Goal": "Objetivo del usuario",
			"User Goal List": "Lista de objetivos del usuario"
		}
	},
	"validation errors": "errores de validación",
	"location": {
		"edit": {
			"Name": "Nombre",
			"Location": "Ubicación",
			"Add Location": "Añade una ubicación",
			"Close": "Cerca",
			"Add": "Añadir"
		},
		"list": {
			"Device Location": "Ubicación del dispositivo",
			"Device Location List": "Lista de dispositivos"
		}
	},
	"description": "Descripción",
	"View More Cases": "Ver más casos",
	"Contact Us": "Contactenos",
	"Login is abnormal, please try again later.": "Login is abnormal, please try again later.",
	"header": {
		"Sign Out": "Elsaluti",
		"Ecommerce": "Komerco",
		"Pricing": "Prezoj",
		"Get Started": "Komenciĝi"
	},
	"footer": {
		"copy_right": "Kopirajto 2018-2019. Ĉiuj rajtoj rezervitaj."
	},
	"security-tab": {
		"mdi-tune": "mdi-tune",
		"Choose": "Choose",
		"Set up": "Set up",
		"Verify": "Verify",
		"Finished": "Finished",
		"Two-factor Authentication": "Two-factor Authentication",
		"Enabled tasks": "Enabled tasks",
		"You can choose a task": "You can choose a task form the list below after you've login.",
		"Text message": "Text message",
		"mdi-message-text": "mdi-message-text",
		"Verification codes are sent by text message": "Verification codes are sent by text message",
		"mdi-shield-lock": "mdi-shield-lock",
		"Authenticator App": "Authenticator App",
		"Use the Authenticator app": "Use the Authenticator app to get verification codes, even when your phone is offline.",
		"How would you": "How would you like to receive your authentication codes?",
		"After entering your password": "After entering your password, you’ll be asked for a second verification step.",
		"Verification codes": "Verification codes are sent by text message.(Required a verified mobile phone number)",
		"SET UP": "SET UP",
		"Cancel": "Cancel",
		"Let's set up": "Let's set up your phone for verification",
		"What phone number": "What phone number do you want to use?",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"mdi-information": "mdi-information",
		"Change": "Change",
		"Two Factor Setup": "Two Factor Setup",
		"Take these few steps to enable": "Take these few steps to enable two-factor authentication and make your account more secure.",
		"Install an authenticator app": "Install an authenticator app on your mobile device.",
		"Recommended options: Google Authenticator": "Recommended options: Google Authenticator,Authy,Microsoft Authenticator",
		"Scan QR code with the authenticator": "Scan QR code with the authenticator.",
		"Enter the 2-step authentication": "Enter the 2-step authentication code provided by your app.",
		"Continue": "Continue",
		"I'm ready": "I'm ready",
		"Back": "Back",
		"Verify your number": "Verify your number",
		"We just sent a text message": "We just sent a text message with a verification code to",
		"Didn't get it? Resend code": "Didn't get it? Resend code.",
		"Scan this QR code": "Scan this QR code with your mobile app.",
		"Then enter the six digit code": "Then enter the six digit code provided by the app:",
		"Can't scan the code": "Can't scan the code?",
		"Great": "Great!",
		"Two-factor Authentication has been": "Two-factor Authentication has been enabled for your account",
		"All future logins will now require": "All future logins will now require you to enter a code supplied by your mobile message or Authenticator App.",
		"Finish": "Finish"
	},
	"reward-list": {
		"Used": "Used",
		"Adsmodo": "Adsmodo",
		"$": "$",
		"OFF YOUR PURCHASE": "OFF YOUR PURCHASE",
		"Gift Code:": "Gift Code:",
		"Valid till": "Valid till"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "Valid from 15 Oct 2019 to 15 Jan 2020",
		"points": "points",
		"mdi-check-bold": "mdi-check-bold",
		"mdi-phone": "mdi-phone",
		"mdi-email": "mdi-email",
		"Report Reward issue to Adsmodo": "Report Reward issue to Adsmodo"
	},
	"return-orders": {
		"Choose items to return": "Choose items to return",
		"Ordered on": "Ordered on",
		"Order Number": "Order Number",
		"Continue": "Continue",
		"Not returnable": "Not returnable",
		"Returnable untill": "Returnable untill",
		"Choose refund method": "Choose refund method",
		"Returning": "Returning",
		"Reason": "Reason",
		"We'll process your refund": "We'll process your refund to Visa ending 1234.",
		"Estimated refund timing": "Estimated refund timing: 2-3 business days after we receive the item.",
		"How do you want to send": "How do you want to send it back?",
		"$0": "$0",
		"Our deliveries will pickup": "Our deliveries will pickup your return package on the next business day.Printer required - you can print the label any time from Your Account or from the email we'll send you.",
		"Refund summary": "Refund summary",
		"Refund Subtotal": "Refund Subtotal:",
		"Shipping fee": "Shipping fee:",
		"Total refund": "Total refund:",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"Your label is ready to print": "Your label is ready to print",
		"Securely pack the items": "Securely pack the items to be returned. Send all the items back to Adsmodo by",
		"mdi-cloud-upload": "mdi-cloud-upload",
		"Print label": "Print label"
	},
	"products-list": {
		"Adsmodo": "Adsmodo",
		"Out of stock": "Out of stock!",
		"Available stock": "Available stock"
	},
	"common-list": {
		"no-data": "Neniuj registroj trovitaj",
		"reset": "Restarigi",
		"warning": "Averto",
		"delete-item-warning": "Ĉu vi certas, ke vi volas forigi ĉi tiun eron? La operacio de forigo ne povas esti returnita",
		"delete-items-warning": "Ĉu vi certas, ke vi volas forigi ĉi tiujn erojn? La operacio de forigo ne povas esti returnita",
		"delete-yes": "Jes",
		"delete-no": "Nuligi",
		"operations": "Funkciado"
	},
	"member-home": {
		"Special offers for you": "Special offers for you",
		"A confirmation email has been sent to": "A confirmation email has been sent to",
		"Click on the confirmation link in the email to verify your account.": "Click on the confirmation link in the email to verify your account",
		"Points": "Points",
		"My loyalty club": "My loyalty club",
		"Recommended loyalty club": "Recommended loyalty club",
		"More loyalty club": "More loyalty club",
		"More than": "More than",
		"clicks on ads": "clicks on ads",
		"Since this is your first login, please click": "Since this is your first login, please click this message to change your password",
		"There are currently": "There are currently",
		"promotional products": "promotional products",
		"Promotion code list": "Promotion code list",
		"Loyalty club name": "Loyalty club name",
		"Promotion code": "Promotion code",
		"Close": "Close",
		"View promo code": "View promo code"
	}
}
;