import _ from "lodash";
import api from "../../services/api";

export default {
  namespaced: true,
  state: {
    adCollection: [{ name: "waitting" }],
    ads: []
  },
  getters: {},
  actions: {
    async fetchList(store) {
      const response = await api.getadCollection();
      if (response.data) {
        store.commit("save", {
          payload: response.data.adCollection,
          keyPath: "adCollection"
        });
        store.commit("save", {
          payload: response.data.ads,
          keyPath: "ads"
        });
      }
    },
    async fetchAdsList(store, { payload }) {
      const { id } = payload;
      const response = await api.getadCollection(id);
      if (response.data) {
        store.commit("save", {
          payload: response.data.ads,
          keyPath: "ads"
        });
      }
    },
    clear(store) {
      store.commit("clear");
    }
  },
  mutations: {
    save(state, action) {
      _.set(state, action.keyPath, action.payload);
    },
    clear(state) {
      state.adCollection = [];
      state.ads = [];
    }
  }
};
