import _ from "lodash";
import api from "../../services/api";

export default {
  namespaced: true,
  state: {
    data: [],
    total: 0,
    expandList:[],
    unwithdrawCnt:0
  },
  getters: {},
  actions: {
    async fetchList(store, { payload }) {
      const { endpoint, search, filters, limit, skip, sort } = payload;
      const response = await api.getList(endpoint, search, filters, limit, skip, sort);
      if (response.data) {
        for(let i=0;i<response.data.results.length;i++){
          response.data.results[i].index=i;
        }
        store.commit("save", {
          payload: response.data.results,
          keyPath: "data"
        });
        store.commit("save", {
          payload: response.data.total,
          keyPath: "total"
        });
        if(response.data.unwithdrawCnt!=null){
          store.commit("save", {
            payload: response.data.unwithdrawCnt,
            keyPath: "unwithdrawCnt"
          });
        }
      }
    },
    async fetchListAndExpandList(store,{payload}){
      let { endpoint, search, filters, limit, skip, sort ,expandPayload,expandEndpoint,expandOptions} = payload;
      const response = await api.getList(endpoint, search, filters, limit, skip, sort);
      // console.log(expandOptions);
      if (response.data) {
        for(let i=0;i<response.data.results.length;i++){
          response.data.results[i].index=i;
        }
        let tmpList=[];
        if(expandEndpoint!=''){
          for(let i=0;i<response.data.results.length;i++){
            filters='{"hotelId":"'+response.data.results[i].id+'"}';
            if(expandOptions[i]==null){
              limit=payload.limit;
              skip=payload.skip;
              sort='';
              search='';
              let expandResult=await api.getList(expandEndpoint, search,filters, limit, skip, sort);
              tmpList.push(expandResult.data.results);
            }else{
              let {search,limit,skip,sort} = expandOptions[i];
              let expandResult=await api.getList(expandEndpoint, search,filters, limit, skip, sort);
              tmpList.push(expandResult.data.results);
            }
          }
        }
        

        
        store.commit("save", {
          payload: tmpList,
          keyPath: "expandList"
        });
        store.commit("save", {
          payload: response.data.results,
          keyPath: "data"
        });
        store.commit("save", {
          payload: response.data.total,
          keyPath: "total"
        });
      }
    },
    async deleteItem(x, { payload: { id, endpoint } }) {
      return await api.deleteItems(endpoint, { id });
    },
    async getItem(x, { id, endpoint }) {
      return await api.getItem(endpoint, id);
    },
    async createOrUpdateItem(x, { id, endpoint, data }) {
      if (id) return await api.updateItem(endpoint, id, data);
      else return await api.createItem(endpoint, data);
    },
    clear(store) {
      store.commit("clear");
    }
  },
  mutations: {
    save(state, action) {
      _.set(state, action.keyPath, action.payload);
    },
    clear(state) {
      state.data = [];
      state.total = 0;
    }
  }
};
