import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import _ from "lodash";
import encryption from "./encryption";

const namespace = uuidv4(location.host, uuidv4.URL);

function setItem(name, value) {
  let r = value;
  if (!_.isString(r)) {
    r = JSON.stringify(value);
  }
  localStorage.setItem(name, r);
}

function getItem(name) {
  let r = localStorage.getItem(name);
  return JSON.parse(r);
}

export const setToken = token => {
  const token_uuid = `global_${uuidv4("token", namespace)}_t`;
  localStorage.setItem(token_uuid, token);
};

export const getToken = () => {
  const key = _.find(_.keys(localStorage), x => /global_.*_t$/.test(x));
  return localStorage.getItem(key) || "";
};

export const clear = () => {
  let lang = localStorage.getItem("locale");
  localStorage.clear();
  localStorage.setItem("locale", lang);
  Cookies.remove("customer");
  Cookies.remove("token");
  Cookies.remove("portal");
};

export const getRole = () => {
  return getItem("r");
};

export const setRole = role => {
  setItem("r", role);
};

export const getUser = () => {
  return getItem("u");
};

export const setUser = user => {
  setItem("u", user);
};

export const setFirebaseToken = firebaseToken => {
  localStorage.setItem("firebaseToken", firebaseToken);
  localStorage.setItem("firebaseTimeout", Date.parse(new Date()));
};

export const getFirebaseToken = () => {
  return {
    firebaseToken: localStorage.getItem("firebaseToken"),
    firebaseTimeout: localStorage.getItem("firebaseTimeout")
  };
};

export const requireAuth = (to, from, next) => {
  if (/401|404/.test(to.path)) {
    return next();
  }
  var pathname = window.location.pathname;

  if(pathname.includes('/linePaySuccess')){
    return next();
  }

  if(pathname == '/app/account'){
    return next();
  }

  if(pathname.includes('/app/mobile')){
    return next();
  }

  if(pathname.includes('/app/player')){
    return next();
  }

  if(pathname.includes('/app/company/edit/')){
    return next();
  }

  let roleInfo = getRole();
  let userInfo = getUser();
  if (!roleInfo || !userInfo) {
    location.href = "/sign-in";
  }
  if (roleInfo && userInfo && userInfo.isAdmin) {
    next();
  } else {
    let endpoint = to.meta.endpoint;
    if (!endpoint) {
      const regex = /app\/([a-zA-Z|-]*)\/?/;
      endpoint = regex.exec(to.path) ? regex.exec(to.path)[1] : undefined;
    }
    if (!endpoint) {
      next();
    }
    if (roleInfo.permissions[endpoint] >= _.toInteger(to.meta.permission)) {
      next();
    }
    // else {
    //   pathname.includes('/app/mobile') ? next("/app/mobile/401") : next("/app/401");
    // }
  }
};

export function validateLogin() {
  let roleInfo = getRole();
  let userInfo = getUser();
  if (!roleInfo || !userInfo) {
    swal("Login is required", "please log in and then submit", "warning", () => {
      window.location.href = "/sign-in";
    });
    return false;
  }
  return true;
}

export function validateLoginAppointment() {
  let userInfo = getUser();
  if (!userInfo) {
    return false;
  }
  return true;
}

export const setThirdLogin = params => {
  params.expire = new Date().getTime() + 30 * 60 * 1000;
  const thirdLogin = encryption.genSign(JSON.stringify(params));
  sessionStorage.setItem("thirdLogin", thirdLogin);
};

export const getThirdLogin = () => {
  try {
    const thirdData = JSON.parse(encryption.deSign(sessionStorage.getItem("thirdLogin")));
    if (thirdData.expire && thirdData.expire <= new Date().getTime()) {
      return null;
    }
    return thirdData;
  } catch (error) {
    return null;
  }
};
