export default [{
    path: "/membership-form",
    component: () => import("components/loyalty-public/member-form.vue")
  },
  {
    path: "/sign-in",
    component: () => import("components/sign/member-sign-in.vue")
  },
  {
    path: "/member-sign-in",
    component: () => import("components/sign/sign-in.vue")
  },
  {
    path: "/sign-up",
    component: () => import("components/sign/sign-up.vue")
  },
  {
    path: "/hotel-sign-up",
    component: () => import("components/sign/hotel-sign-up.vue")
  },
  {
    path: "/restaurant-sign-up",
    component: () => import("components/sign/restaurant-sign-up.vue")
  },
  {
    path: "/landing/:id",
    component: () => import("components/sign/landing-page.vue")
  },
  {
    path: "/cp-local-business",
    component: () => import("components/sign/cp_local_business.vue")
  },
  {
    path: "/kiosk",
    component: () => import("components/sign/kiosk.vue")
  },
  {
    path: "/forget-password",
    component: () => import("components/sign/forget.vue")
  },
  {
    path: "/privacy-policy",
    component: () => import("components/sign/privacy-policy.vue")
  },
  {
    path: "/term-condition",
    component: () => import("components/sign/term-condition.vue")
  },
  {
    path: "/mobile/en/term-condition",
    alias: "/mobile/zhtw/term-condition",
    component: () => import("components/sign/term-condition.vue")
  },
  {
    path: "/purchase-product",
    component: () => import("components/sign/purchase-product.vue")
  },
  {
    path: "/homepage2",
    component: () => import("components/website/mainpage.vue")
  },
  {
    path: "/usermanual",
    component: () => import("components/website/usermanual.vue")
  },
  {
    path: "/apkversion",
    component: () => import("components/website/apkversionpage.vue")
  },
  {
    path: "/services/*",
    component: () => import("components/website/serviceDetail.vue")
  },
  {
    path: "/",
    component: () => import("components/website/mainpage2.vue"),

  },
  {
    path: "/airbnb",
    component: () => import("components/website/airbnb_mainpage.vue"),

  }
];
