import api from "../../services/api";
import _ from "lodash";

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state: {
    current_subscription: {
      product: {
        name: ""
      }
    }
  },
  getters: {},
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },
    getCurrentSubscription({ commit }) {
      api.getCurrentSubscription().then(res => {
        commit("updateValue", {
          current_subscription: _.get(res, "data.results") || {}
        });
      });
    },
    async changeSubscription(x, { plan, hostSite }) {
      await api.changeMySubscriptionPlan({ plan, hostSite });
    },
    async cancelSubscription() {
      await api.cancelSubscription();
    }
  }
};
