import { map, filter } from "lodash";
import memberApi from "res/services/memberApi";

export default {
  namespaced: true,
  state: {
    allData: []
  },
  getters: {
    ddlData: state => {
      return map(state.allData, x => ({
        text: `${x.name?.first} ${x.name?.last}`,
        value: x.id
      }));
    },
    doctorEvents(state, getters, rootState) {
      return map(
        filter(rootState.memberLoyaltyClub.events, event => event.category === "schedule"),
        x => ({
          id: x.id,
          name: "On duty",
          color: "#4CAF50",
          start: x.start,
          end: x.end,
          timed: true
        })
      );
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    fetchList({ commit }, { club_id }) {
      memberApi.getClubDoctors(club_id).then(res => {
        if (res.data) {
          commit("updateValue", {
            allData: res.data.data
          });
        }
      });
    },
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    }
  }
};
