module.exports = {
	"Account or password was incorrect!": "계정 또는 비밀번호가 잘못되었습니다!",
	"Can't get your device info": "기기 정보를 얻을 수 없습니다",
	"Device does not exist or has been deleted, please reselect": "기기가 없거나 삭제되었습니다. 다시 선택하십시오",
	"Duplicate current plan": "현재 계획 중복",
	"Email and passcode is required!": "이메일과 비밀번호가 필요합니다!",
	"Email or password is incorrect": "이메일 또는 비밀번호가 잘못되었습니다",
	"Find device error": "",
	"Find user error": "사용자 오류 찾기",
	"Get current customer plan is required": "현재 고객 계획이 필요합니다",
	"Hello": "",
	"Id is required!": "ID가 필요합니다!",
	"Inconsistent password": "일치하지 않는 비밀번호",
	"Member does not exist, Please try again later!": "회원이 존재하지 않습니다. 나중에 다시 시도하십시오!",
	"No id": "아이디 없음",
	"Please enter the device name": "장치 이름을 입력하십시오",
	"Please enter your email": "이메일을 입력 해주세요",
	"Please enter your mobile": "휴대폰을 입력하십시오",
	"Please select an item first": "먼저 항목을 선택하십시오",
	"Signin error": "로그인 오류",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "",
		"Your account does not have a subscription. Please subscribe to post an ad": ""
	},
	"Swal": {
		"member_sign_up": {
			"Please register to be part of this club": "이 클럽의 일원이 되려면 등록하십시오."
		},
		"More than available stock": "",
		"Are you sure?": "",
		"You will not be able to recover this!": "",
		"Yes, delete it!": "",
		"No, cancel plx!": "",
		"You must register your company first!": "",
		"Exceed the maximum number of Ad": "",
		"Upgrade your account,and create more Advertising": "",
		"Out of stock": "",
		"Please select the variant!": "",
		"Invalid coupon code": "",
		"Invalid coupon code for this product!": "",
		"Valid coupon code": "",
		"Add success": "성공 추가",
		"Already in the cart": "이미 장바구니에 있습니다.",
		"Amount should be more than zero": "금액은 0보다 커야합니다!",
		"Bind Success": "바인드 성공",
		"Buy device success": "장치 구매 성공",
		"Device type is required": "장치 유형이 필요합니다",
		"Edit success": "성공 편집",
		"End date must be greater than start date!": "종료 날짜는 시작 날짜보다 커야합니다!",
		"Please select a valid image": "유효한 이미지를 선택하십시오",
		"Please select a valid video": "유효한 비디오를 선택하십시오",
		"Session time out.Please log in again": "세션 시간이 초과되었습니다. 다시 로그인하십시오",
		"Something went wrong": "문제가 발생했습니다.",
		"Subscription success": "가입 성공",
		"Successfully approved": "성공적으로 승인되었습니다!",
		"Successfully generated": "성공적으로 생성되었습니다!",
		"Your profile has been successfully updated": "프로필이 성공적으로 업데이트되었습니다",
		"account": {
			"Cancel Subscription success": "구독 성공 취소",
			"Cancel subscription error": "가입 오류 취소",
			"The current user does not support this feature at this time": "현재 사용자는이 기능을 지원하지 않습니다.",
			"This operation is not supported at this time": "현재이 작업은 지원되지 않습니다"
		},
		"ad": {
			"Ad created successfully": "광고가 성공적으로 생성되었습니다",
			"Ad edited successfully": "광고가 성공적으로 수정되었습니다",
			"Cancel": "취소",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "축하합니다! 첫 광고를 만들었습니다. 계속 하시겠습니까?"
			},
			"Continue": "잇다",
			"Product category is needed!": "제품 카테고리가 필요합니다!",
			"Product name is needed!": "상품명이 필요합니다!",
			"success info": "성공 정보"
		},
		"sign": {
			"Agreement Required": "",
			"Please agree the Terms & Condtions": "",
			"Please improve the information": "정 보 를 개선 하 십시오.",
			"Business address invalid, please enter again": "사업장 주소가 잘못되었습니다. 다시 입력하십시오.",
			"Company type require!": "회사 유형이 필요합니다!",
			"Invalid recaptcha token": "잘못된 승인 토큰",
			"Password doesnot match!": "비밀번호가 일치하지 않습니다!",
			"Please post your ad now": "지금 광고를 게시하십시오.",
			"Purchase fail": "구매 실패",
			"Purchase success": "구매 성공",
			"The Passcode have been send success": "암호가 성공적으로 전송되었습니다",
			"The passcode have been send successfully": "비밀번호가 성공적으로 전송되었습니다",
			"The password and confirm password not the same": "비밀번호와 비밀번호 확인이 동일하지 않음"
		},
		"You need to set up your payment method to purchase devices": "기기를 구매하려면 결제 수단을 설정해야합니다",
		"Payment method is required": "지불 방법이 필요합니다"
	},
	"The ad does not exist or has been deleted": "광고가 존재하지 않거나 삭제되었습니다",
	"The device has been bound, Please untie it first": {
		"": "장치가 연결되었습니다. 먼저 분리하십시오."
	},
	"The device is not exist": {
		"": "장치가 존재하지 않습니다."
	},
	"The email is already registered!": "이메일이 이미 등록되었습니다!",
	"The item does not exist or has been deleted": "아이템이 존재하지 않거나 삭제되었습니다",
	"The mobile number is already registered!": "휴대 전화 번호는 이미 등록되었습니다!",
	"The user does not exist or has been deleted": "사용자가 존재하지 않거나 삭제되었습니다",
	"Update plan error": "업데이트 계획 오류",
	"User does not exist, Please try again later!": "사용자가 존재하지 않습니다. 나중에 다시 시도하십시오!",
	"You do not have permission": "당신은 권한이 없습니다",
	"ad-template": {
		"edit": {
			"Add Builder Template": "빌더 템플릿 추가",
			"Back": "뒤로",
			"Builder Template": "빌더 템플릿",
			"Edit Builder Template": "빌더 템플릿 편집",
			"Name": "이름",
			"Save": "구하다",
			"Status": "지위",
			"Type": "유형"
		},
		"list": {
			"Builder Template": "빌더 템플릿",
			"Builder Template List": "빌더 템플릿 목록",
			"Name": "이름",
			"Status": "지위",
			"Type": "유형",
			"data-table-title": "빌더 템플릿 목록"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "광고 거래",
			"AD Transaction List": "광고 거래 목록",
			"Ads": "광고",
			"Collection": "수집",
			"Device": "장치",
			"Type": "",
			"Campaign": "",
			"Number": "번호"
		}
	},
	"admin-nav": {
		"Tutorial": "",
		"Logout": "로그 아웃",
		"Manager": "매니저",
		"New Enquiry": "새로운 문의",
		"Online": "온라인",
		"Pending Ad": "대기중인 광고",
		"Pending User": "대기중인 사용자",
		"Profile": "윤곽",
		"User": "사용자",
		"User Manual": "사용 설명서"
	},
	"ads": {
		"edit": {
			"3D Effect Link": "3D 효과 링크",
			"Ad Template": "광고 템플릿-",
			"Add Advertising": "광고 추가",
			"Additional Discount": "추가 할인",
			"Advanced": "많은",
			"Advertising": "광고하는",
			"Advertising End Date": "광고 종료일",
			"Approval": "승인, 찬성, 시인,인가",
			"Approved": "승인 됨",
			"Back": "뒤로",
			"Duration (s)": "지속 시간",
			"Edit Advertising": "광고 수정",
			"Extra Link": "추가 링크",
			"Name": "이름",
			"Pending": "대기 중",
			"Preview": "시사",
			"Product Purchase link": "제품 구매 링크",
			"Product Purchasing": "제품 구매",
			"Promotional Text": "홍보 문구",
			"Reject": "받지 않다",
			"Rejected": "거부 됨",
			"Save": "구하다",
			"Second(s)": "초",
			"Select products for this Ad": "이 광고에 맞는 제품을 선택하십시오",
			"Status": "지위",
			"Upgrade": "업그레이드",
			"Video Url": "비디오 URL",
			"Warning!": "경고!",
			"abbr": {
				"create": "나만의 광고 만들기",
				"preview": "광고 미리보기",
				"warning content": "AD 잔액이 부족하여 업그레이드하십시오."
			}
		},
		"list": {
			"Advertising": "광고하는",
			"Advertising List": "광고 목록",
			"Approval Status": "승인 상태",
			"Approved": "승인 됨",
			"Due Date": "마감일",
			"Duration (s)": "지속 시간",
			"Name": "이름",
			"Pending": "대기 중",
			"Products": "제작품",
			"Created Date": "작성일",
			"Status": "지위",
			"Type": "유형"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "AR 링크 / 3D 링크",
			"Ad Name": "광고 이름",
			"Ad Template": "광고 템플릿-",
			"All": "모든",
			"Campaign Name": "캠페인 이름",
			"Cancel": "취소",
			"Collection Name": "컬렉션 이름",
			"Company Type": "회사 유형",
			"Contact Me 3D Link": "저에게 연락 3D 링크",
			"Contact Me Video Link": "저에게 연락 비디오 링크",
			"Create your own ads": "나만의 광고 만들기",
			"Device List": "장치 목록",
			"Duration (s)": "지속 시간",
			"Edit": "편집하다",
			"End Date": "종료일",
			"Finish": "끝",
			"Let me think": "내가 생각하자",
			"Maps": "지도",
			"Name": "이름",
			"Next": "다음 것",
			"On The Map": "지도에서",
			"Previous": "너무 이른",
			"Publish to Device": "기기에 게시",
			"Publishing Device": "출판 장치",
			"Reject": "받지 않다",
			"Search": "수색",
			"Second(s)": "초",
			"Shop Link": "상점 링크",
			"Start Date": "시작일",
			"Step": "단계 %{num}",
			"Submit": "제출",
			"Success": "성공",
			"Text": "본문",
			"abbr": {
				"another": "다른 것을 만드십시오",
				"created": "광고가 만들어집니다.",
				"device select": "게시하려는 기기를 선택하십시오",
				"introduction": "여기에서 광고를 게시하고 자신의 제품, 식당을 홍보 할 수 있습니다. 게시하려는 기기를 선택하고 시작하겠습니다.",
				"privilege intro": "가입하면 다음과 같은 권한이 있습니다.",
				"start": "지금 AD를 만드십시오!",
				"step1": "1 단계 : 기본 정보",
				"step2": "2 단계 : 디자인 광고",
				"upgrate check": "계정을 업그레이드 하시겠습니까?",
				"wait": "플랫폼 승인을 기다리십시오.",
				"welcome": "Adsmodo에 오신 것을 환영합니다",
				"yes": "예, 그래요!"
			},
			"another": "다른 것을 만드십시오",
			"created": "광고가 만들어집니다.",
			"introduction": "여기에서 광고를 게시하고 자신의 제품, 식당을 홍보 할 수 있습니다. 게시하려는 기기를 선택하고 시작하겠습니다.",
			"welcome": "Adsmodo에 오신 것을 환영합니다"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "홈페이지",
				"Home Page List": "홈페이지 목록",
				"Title": "표제",
				"Created At": "에 만든",
				"Updated At": "업데이트"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "홈 페이지 추가",
				"Website Link": "웹 사이트 링크",
				"Company Type List": "회사 유형 목록",
				"ADVERTISEMENT Content": "광고 내용",
				"About Us": "회사 소개",
				"Banner": "배너",
				"Contact Us": "문의하기",
				"Content": "함유량",
				"Edit Home Page": "홈페이지 편집",
				"How to use": "사용하는 방법",
				"Image": "영상",
				"Introduction": "소개",
				"Menu Name": "메뉴 이름",
				"Multiple select": "다중 선택",
				"Project": "계획",
				"SHOPKEEPER Content": "구매자 콘텐츠",
				"Scenario": "대본",
				"Sub Title": "소제목",
				"Team Member": "팀 구성원",
				"Title": "표제",
				"Overall": "사무용 겉옷",
				"Font Color": "글자 색",
				"White": "하얀",
				"Black": "검은",
				"left": "왼쪽",
				"center": "중앙",
				"right": "오른쪽",
				"Edit": "편집하다",
				"Add": "",
				"Title Position": "직책",
				"Background Transparent": "투명한 배경",
				"Left/Right Image": "",
				"Left Title": "왼쪽 제목",
				"Left Content": "왼쪽 내용",
				"Right Title": "올바른 제목",
				"Right Content": "올바른 콘텐츠",
				"Scenario List": "시나리오 목록",
				"Team List": "팀리스트",
				"Website": "웹 사이트",
				"EditHomePage": "홈페이지 편집"
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "",
				"Add Scenario": "",
				"Scenario Image": "",
				"Name": "",
				"Description": ""
			},
			"list": {
				"Scenario": "",
				"Scenario List": "",
				"Image": "",
				"Name": "",
				"Description": ""
			}
		},
		"team": {
			"edit": {
				"Add Team Member": "팀원 추가",
				"Edit Team Member": "팀원 편집",
				"Image": "영상",
				"Job Title": "직책",
				"Name": "이름",
				"Text": "본문"
			},
			"list": {
				"Team": "팀",
				"Team List": "팀 목록",
				"Image": "이미지",
				"Job Title": "작업 제목",
				"Created At": "작성일",
				"Ad Type": "광고 유형",
				"Name": "이름",
				"Team Member List": "팀 멤버 목록"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "메뉴 편집",
				"Add Menu": "메뉴 추가",
				"Name": "이름",
				"Menu Type": "메뉴 유형",
				"Parent Menu": "부모 메뉴",
				"Link": "링크",
				"Show On Footer": "바닥 글에 표시",
				"Please enter the name": "이름을 입력하십시오",
				"Please enter the link": "링크를 입력하십시오"
			},
			"list": {
				"Menu": "메뉴",
				"Menu List": "메뉴 목록",
				"Name": "이름",
				"Type": "유형",
				"Parent": "부모",
				"Link": "링크"
			}
		}
	},
	"already registered!": "이미 등록!",
	"bcrypt error": "암호화 오류",
	"campaigns": {
		"edit": {
			"Add Campaign": "캠페인 추가",
			"All": "모든",
			"Back": "뒤로",
			"Cancel": "취소",
			"Company Type": "회사 유형",
			"Edit Campaign": "캠페인 수정",
			"End Date": "종료일",
			"Finish": "끝",
			"Maps": "지도",
			"Name": "이름",
			"On The Map": "지도에서",
			"Publishing Device": "출판 장치",
			"Save": "구하다",
			"Search": "수색",
			"Select Ad Collection": "광고 모음 선택",
			"Search devices": "",
			"Open Map": "지도 열기",
			"Target Devices": "대상 장치",
			"Start Date": "시작일",
			"abbr": {
				"Select device": "기기 선택",
				"select": "게시하려는 기기를 선택하십시오"
			},
			"Search Devices": "검색 장치",
			"Company": "회사",
			"City": "도시",
			"State": "상태",
			"Distance": "거리",
			"Map": "지도"
		},
		"list": {
			"Ad Collections": "광고 컬렉션",
			"Campaigns": "캠페인",
			"Campaigns List": "캠페인 목록",
			"Devices": "장치들",
			"End Date": "종료일",
			"Name": "이름",
			"Start Date": "시작일",
			"Status": "지위"
		}
	},
	"cart": {
		"Adsmodo": "아조도도",
		"Apply": "대다",
		"Continue Shopping": "쇼핑을 계속",
		"My Cart": "내 카트",
		"Order Summary": "주문 요약",
		"Price": "가격",
		"Proceed to Checkout": "Checkout으로 이동",
		"Product": "생성물",
		"QTY": "수량",
		"Seller": "파는 사람",
		"Shipping": "배송",
		"Shipping Fee": "배송료",
		"Sub Total": "소계",
		"Total": "합계",
		"You save": "당신은 저장",
		"Coupon code": "Coupon code",
		"promotion": "Promo Code",
		"Please select your gift Card code": "Please select your gift Card code"
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "광고 모음 추가",
			"Back": "뒤로",
			"Edit Ad Collection": "컬렉션 편집",
			"Name": "이름",
			"Publishing Device": "출판 장치",
			"Save": "구하다",
			"Select Ads": "광고 선택"
		},
		"list": {
			"Ads": "광고",
			"Ads Collection": "광고 수집",
			"Ads Collection List": "광고 수집 목록",
			"Name": "이름"
		}
	},
	"common": {
		"Search": "Search",
		"404": {
			"abbr": {
				"back": "대시 보드로 돌아 가기",
				"sorry": "죄송합니다. 페이지를 찾을 수 없습니다"
			}
		},
		"ad-image": {
			"Click to select picture": "클릭하여 사진을 선택하십시오"
		},
		"ad-video": {
			"Click to select video": "비디오를 선택하려면 클릭하십시오"
		},
		"bind-credit-card": {
			"Bind": "묶다",
			"CVC": "CVC",
			"Card Number:": "카드 번호:",
			"MM": "MM",
			"YYYY": "YYYY"
		},
		"select-ad-device": {
			"Device List": "장치 목록",
			"Let me think": "내가 생각하자",
			"Yes, I'm Sure!": "예, 그래요!",
			"abbr": {
				"plan": "월 $ 100 광고 서비스 광고 계획",
				"upgrade check": "계정을 업그레이드 하시겠습니까?"
			}
		},
		"tui-image-editor": {
			"Cancel": "취소",
			"Edit image": "이미지 편집",
			"Image Resolution :": "이미지 해상도 :",
			"Load Assets": "자산로드",
			"Load Promotion Asset": "프로모션 자산로드",
			"Load assets": "자산로드",
			"Promotion assets": "프로모션 자산",
			"Save": "구하다"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "회사 유형 추가",
			"Back": "뒤로",
			"Edit Company Type": "회사 유형 편집",
			"Name": "이름",
			"Save": "구하다",
			"description": "기술",
			"Description": "기술"
		},
		"list": {
			"Company Type": "회사 유형",
			"Company Type List": "회사 유형 목록",
			"Created Date": "만든 날짜",
			"Name": "이름"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "기원 후",
			"Ad Collection": "광고 수집",
			"Back": "뒤로",
			"Cell Phone": "휴대 전화",
			"Contact Me": "저에게 연락",
			"Device": "장치",
			"Email": "이메일"
		},
		"list": {
			"Ad": "기원 후",
			"Campaign": "운동",
			"Collection": "수집",
			"Contact Me": "저에게 연락",
			"Contact Me List": "저에게 연락하십시오",
			"Device": "장치",
			"Email": "이메일",
			"Phone Number": "전화 번호",
			"action": "동작",
			"data-table-title": "Contact Me List"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "사업장 주소",
			"Contacts": "콘택트 렌즈",
			"Contacts List": "연락처 목록",
			"Email": "이메일",
			"Is Admin": "관리자인가?",
			"Mobile": "변하기 쉬운",
			"Name": "이름"
		}
	},
	"customer failed: create customer failed": "고객 실패 : 고객 생성 실패",
	"customer failed: create token failed": "고객 실패 : 토큰 생성 실패",
	"dataTable": {
		"Add": "더하다",
		"Are you sure to deactivate it?": "비활성화 하시겠습니까?",
		"Are you sure to delete it?": "삭제 하시겠습니까?",
		"Cancel": "취소",
		"Choose your options below and drop one into the inbox of anyone on your list!": "아래에서 옵션을 선택하고 목록에있는 사람의받은 편지함에 넣습니다.",
		"Confirm": "확인",
		"Deactivate": "비활성화",
		"Delete": "지우다",
		"Operate": "작동",
		"Please select the item to delete": "삭제할 항목을 선택하십시오",
		"Please select the loyalty member to give reward": "포인트 멤버십을 선택하여 보상하십시오!",
		"Reward Gift Card": "보상 기프트 카드",
		"This operation cannot be undone": "이 작업은 취소 할 수 없습니다.",
		"We offer electronic gift cards in any denomination": "우리는 모든 명칭으로 전자 기프트 카드를 제공합니다."
	},
	"database error": "데이터베이스 오류",
	"devices": {
		"addCampaign": {
			"Ad Collections": "광고 컬렉션",
			"Ad Images": "광고 이미지",
			"Ad List": "광고 목록",
			"Add Campaign": "캠페인 추가",
			"Availavle Campaigns": "사용 가능한 캠페인",
			"Back": "뒤로",
			"Campaign Detail": "캠페인 세부 사항",
			"Campaign List": "캠페인 목록",
			"Campaigns": "캠페인",
			"Device": "장치",
			"Device ID": "장치 아이디",
			"Device Name": "장치 이름",
			"Drag available campaigns here to run on device": "사용 가능한 캠페인을 여기로 드래그하여 기기에서 실행",
			"Duration(s)": "지속 시간",
			"End Date": "종료일",
			"Name": "이름",
			"Save": "구하다",
			"Start Date": "시작일",
			"Status": "지위",
			"This ad have no images": "이 광고에는 이미지가 없습니다"
		},
		"buy-log": {
			"Buy Device Log": "장치 로그 구매",
			"Buy Device Log List": "장치 로그리스트 구매",
			"Name": "이름",
			"Quantity": "수량",
			"Status": "지위",
			"Unit Price": "단가",
			"Permission": "Permission"
		},
		"buy-product": {
			"Back": "뒤로",
			"Buy Device": "장치 구매",
			"Device Quantity": "장치 수량",
			"Location": "위치",
			"Device Type": "기기 종류",
			"Save": "구하다",
			"Add Location": "위치 추가"
		},
		"edit": {
			"Add Device": "기기 추가",
			"Are you sure to activate this device ?": "이 장치를 활성화 하시겠습니까?",
			"Are you sure to deactivate this device ?": "이 기기를 비활성화 하시겠습니까?",
			"Back": "뒤로",
			"Business Name": "비즈니스 이름",
			"Cancel": "취소",
			"Confirm": "확인",
			"Device ID": "장치 아이디",
			"Device Info": "기기 정보",
			"Device Name": "장치 이름",
			"Device Status": "장치 상태",
			"Device Type": "기기 종류",
			"Edit Device": "장치 편집",
			"Manufacturer": "제조사",
			"Model": "모델",
			"Name": "이름",
			"Offline": "오프라인",
			"Online": "온라인",
			"Placement Location": "배치 위치",
			"Platform": "플랫폼",
			"Save": "구하다",
			"Select Ad Collections": "광고 모음 선택",
			"Serial": "연속물",
			"Toggle": "비녀장",
			"UUID": "UUID",
			"Version": "번역",
			"Your device is now Offline!": "기기가 오프라인 상태입니다!",
			"Your device is now Online!": "장치가 온라인 상태입니다!",
			"Your device need to register via App first !": "기기는 먼저 앱을 통해 등록해야합니다!"
		},
		"list": {
			"Ad Collections": "광고 컬렉션",
			"Businesss Name": "비즈니스 이름",
			"Campaigns": "캠페인",
			"Device ID": "장치 아이디",
			"Device Info": "기기 정보",
			"Devices": "장치들",
			"Devices List": "장치 목록",
			"Name": "이름",
			"Position": "위치",
			"Status": "지위",
			"User": "사용자",
			"Public Name": "공개 이름",
			"Location": "위치",
			"ID": "신분증",
			"title": "장치들",
			"data-table-title": "장치 목록"
		},
		"purchase-log": {
			"Device": "장치",
			"Order No": "주문 번호",
			"Purchase Log": "구매 내역",
			"Purchase Log List": "구매 로그 목록",
			"Quantity": "수량",
			"Subscription": "신청",
			"Total Price": "총 가격",
			"Unit Price": "단가",
			"User Name": "사용자 이름"
		}
	},
	"email": {
		"create-email": {
			"Back": "뒤로",
			"Email From": "보낸 사람",
			"Message": "메시지",
			"Send": "보내다",
			"Send To": "보내기",
			"Subject": "제목"
		}
	},
	"email-log": {
		"edit": {
			"Back": "뒤로",
			"Content": "함유량",
			"Created At": "에 만든",
			"Email Log": "이메일 로그",
			"Name": "이름",
			"Send Status": "상태 보내기",
			"Subject": "제목",
			"Template": "주형",
			"To": "에",
			"from": "...에서"
		},
		"list": {
			"Email Log": "이메일 로그",
			"Email Log List": "이메일 로그리스트",
			"Email template": "이메일 템플릿",
			"Name": "이름",
			"from": "...에서",
			"to": "에"
		}
	},
	"email-template": {
		"edit": {
			"Add Email Template": "이메일 템플릿 추가",
			"Available keyword": "사용 가능한 키워드",
			"Back": "뒤로",
			"Edit Email Template": "이메일 템플릿 편집",
			"Email Body": "이메일 본문",
			"Email From": "보낸 사람",
			"Name": "이름",
			"Save": "구하다",
			"Send To": "보내기",
			"Status": "지위",
			"Subject": "제목",
			"abbr": {
				"note": "백 오피스 관리자 전용 수신자가 여러 명인 경우 쉼표를 사용하여 분할하십시오."
			},
			"template Type": "템플릿 유형"
		},
		"list": {
			"Draft": "초안",
			"Email Template": "이메일 템플릿",
			"Email Template List": "이메일 템플릿 목록",
			"Name": "이름",
			"Published": "게시 됨",
			"Status": "지위",
			"Type": "유형",
			"badge-danger": "배지 위험",
			"badge-success": "배지 성공"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "문의 추가",
			"Back": "뒤로",
			"Edit Enquiry": "문의 편집",
			"Email": "이메일",
			"Enquiry Type": "문의 유형",
			"Message": "메시지",
			"Name": "이름",
			"Phone": "전화",
			"Reply": "댓글",
			"Save": "구하다",
			"Show Enquiry": "문의 표시"
		},
		"list": {
			"Created Date": "작성일",
			"Email": "이메일",
			"Enquiry": "문의",
			"Enquiry List": "문의 목록",
			"Message": "메시지",
			"Name": "이름",
			"Reply": "댓글",
			"Type": "유형",
			"data-table-title": "Enquiry List"
		}
	},
	"get ad query error": "광고 검색어 오류가 발생했습니다",
	"get ads database error": "광고 데이터베이스 오류 받기",
	"get item database error ": "품목 데이터베이스 오류 가져 오기",
	"get item query error": "항목 쿼리 오류가 발생합니다",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "카드 뒷면의 3 자리 보안 코드",
		"Amount of Gift Card": "기프트 카드 금액",
		"CVC": "CVC",
		"Card Number": "카드 번호",
		"Email From": "보낸 사람",
		"Expiration Date": "만료일",
		"For payment confirmation": "결제 확인을 위해",
		"Generate Gift Card": "기프트 카드 생성",
		"Generate Gift Card for the selected loyalty member": "선택한 포인트 멤버를위한 기프트 카드 생성",
		"Gift Cards": "기프트 카드",
		"Gift Cards Log": "기프트 카드 로그",
		"Recipient Email": "이메일 수령인",
		"Recipient Name": "받는 사람 이름"
	},
	"home": {
		"Accountant": "회계사",
		"Add Product to cart": "장바구니에 제품 추가",
		"Age": "나이",
		"CRM": "고객 관계 관리",
		"Complete Purchases": "구매 완료",
		"Daily Visits": "일일 방문수",
		"Dashboard": "계기반",
		"Device Orders": "장치 주문",
		"Edinburgh": "에든버러",
		"From Last 1 Month": "지난 1 개월부터",
		"From Last 24 Hours": "지난 24 시간부터",
		"Goal Completion": "목표 달성",
		"Integration Specialist": "통합 전문가",
		"Junior Technical Author": "주니어 기술 저자",
		"Last 3 Month Ad-Collection Record": "지난 3 개월간 광고 수집 기록",
		"Last 3 Month Subscription Record": "지난 3 개월 구독 기록",
		"Last Month": "지난 달",
		"Last week": "지난주",
		"Mail Inbox": "우편함",
		"Marketplace": "시장",
		"Monthly Earnings": "월간 수입",
		"Monthly Purchases": "월별 구매",
		"Name": "이름",
		"New York": "뉴욕",
		"Office": "사무실",
		"Orders in Last 10 months": "지난 10 개월 동안의 주문",
		"Pending Ad": "대기중인 광고",
		"Pending User": "대기중인 사용자",
		"Position": "위치",
		"Recent Contacts": "최근 계약들",
		"Revenue": "수익",
		"Salary": "봉급",
		"Sales Assistant": "판매원",
		"San Francisco": "샌프란시스코",
		"Send Inquiries": "문의 보내기",
		"Senior Javascript Developer": "선임 자바 스크립트 개발자",
		"Start date": "시작일",
		"System Architect": "시스템 아키텍트",
		"Tokyo": "도쿄",
		"Total Subscriptions": "총 구독",
		"Unique Ad-Viewers": "고유 한 광고 뷰어",
		"Visit Premium plan": "프리미엄 플랜 방문",
		"Weekly Top 5 Advertisement": "주간 톱 5 광고",
		"select device error": "장치 오류를 선택하십시오"
	},
	"id required": "필요한 ID",
	"image is required to show ad": "광고를 게재하려면 이미지가 필요합니다",
	"main-nav": {
		"Sign In": "로그인",
		"Sign Up": "가입",
		"Terms & Conditions": "Terms & Conditions"
	},
	"member": {
		"About": "약",
		"Back": "뒤로",
		"Cancel": "취소",
		"Clear": "명확한",
		"Confirm": "확인",
		"Contact Info": "연락 정보",
		"Description": "기술",
		"Earned Points": "적립 포인트",
		"Edit": "편집하다",
		"Email Verification Success": "이메일 확인 성공",
		"Explore": "탐색",
		"Got it!": "알았다!",
		"If you experience any issues with this Reward,please feel free to contact": "이 보상에 문제가 발생하면 언제든지 문의하십시오",
		"LearnMore": "더 알아보기",
		"Please enter activation code sent to this number": "이 번호로 전송 된 활성화 코드를 입력하십시오",
		"Points history": "포인트 이력",
		"Redeem": "도로 사다",
		"Redeemable points can be exchanged for rewards at the Rewards page": "보상 페이지에서 사용 가능한 포인트를 보상으로 교환 할 수 있습니다",
		"Save": "저장하기",
		"Share": "공유",
		"Special Deals": "특별 거래",
		"Spent Points": "사용 된 포인트",
		"Terms and Conditions": "이용 약관",
		"What are Redeemable Points?": "사용 가능한 포인트는 무엇입니까?",
		"Your email has been verified.We will redirect you to home page": "귀하의 이메일이 확인되었습니다. 홈페이지로 이동합니다",
		"nav": {
			"Adsmodo Loyalty Members Club": "Adsmodo 로열티 회원 클럽",
			"Home": "집",
			"Log Out": "로그 아웃",
			"My rewards": "내 보상",
			"Products": "제작품",
			"Profile": "윤곽",
			"Purchase History": "구매 내역"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "케이크 만료",
			"Cake Used": "케이크 사용",
			"Roylaty Member Detail": "Roylaty 회원 정보"
		},
		"form": {
			"Birthday": "생신",
			"Join The Adsmodo Loyalty Club": "Adsmodo 로열티 클럽 가입",
			"Select Business Type*": "사업 유형 선택 *",
			"Submit": "제출",
			"Team": "Team"
		},
		"list": {
			"Birthday": "생신",
			"Email": "이메일",
			"Loyalty Member": "충성도 회원",
			"Loyalty Member List": "로열티 회원 목록",
			"Mobile": "변하기 쉬운",
			"Name": "이름",
			"Points": "포인트들"
		}
	},
	"no permission": "비허가",
	"post:Device update error": "post : 장치 업데이트 오류",
	"post:sendPasscode error": "post : sendPasscode 오류",
	"post:signin account error": "게시물 : 로그인 계정 오류",
	"pre:add ad name validate failed": "사전 : 광고 이름 추가 확인 실패",
	"pre:audit user query error": "사전 감사 사용자 쿼리 오류",
	"pre:bind have been bound": "pre : bind가 바인딩되었습니다",
	"pre:bind verify name failed": "사전 : 바인드 이름 확인 실패",
	"pre:bind verify serial failed": "사전 : 바인드 확인 직렬 실패",
	"pre:delete database error": "사전 : 데이터베이스 삭제 오류",
	"pre:find user": "사전 : 찾기 사용자",
	"pre:get purchase log database error": "사전 : 구매 로그 데이터베이스 오류 가져 오기",
	"pre:member get error": "사전 : 회원에게 오류가 발생했습니다",
	"pre:missing parameters": "사전 : 누락 매개 변수",
	"pre:sendPasscode find user error": "pre : sendPasscode 사용자 찾기 오류",
	"pre:signin device has been bound": "사전 : 로그인 장치가 바인딩되었습니다",
	"pre:signin find user error": "사전 : 로그인 사용자 오류 찾기",
	"pre:unbind the device is not exist": "pre : unbind 장치가 존재하지 않습니다",
	"pre:update get campaign database error": "사전 : 업데이트 캠페인 데이터베이스 가져 오기 오류",
	"pre:update get member database error": "사전 : 갱신 멤버 데이터베이스 가져 오기 오류",
	"pre:update item query error": "사전 : 업데이트 항목 쿼리 오류",
	"pre:update missing parameters": "사전 : 누락 된 매개 변수 업데이트",
	"pre:verification error": "사전 : 확인 오류",
	"product": {
		"ads": {
			"Recommended Products": "Recommended Products",
			"more products": "more products",
			"LearnMore": "Learn More"
		},
		"edit": {
			"Select Category is required": "Select Category is required",
			"Name is required": "Name is required",
			"Currency is required": "Currency is required",
			"giftfile is required": "giftfile is required",
			"You will join": "You will join",
			"club": "club",
			"You have chosen": "You have chosen",
			"clubs": "clubs",
			"Add Options": "옵션 추가",
			"Add Pricing": "가격 추가",
			"Add Product": "제품 추가",
			"Add more variant option": "변형 옵션 추가",
			"Add option value": "옵션 값 추가",
			"Back": "뒤로",
			"Create Gift Card": "기프트 카드 만들기",
			"Currency": "통화*",
			"Description": "기술",
			"Edit Product": "제품 편집",
			"Gift Card": "기프트 카드",
			"Images": "이미지",
			"Master price": "주가",
			"Name": "이름",
			"Option": "선택권",
			"Option Types": "옵션 유형",
			"Price": "가격",
			"Price Range": "가격 범위*",
			"Pricing": "가격",
			"Qty": "수량",
			"Quantity": "수량",
			"Quantity in Hand": "",
			"Is Returnable": "반품 가능",
			"Size Description": "크기 설명",
			"Save": "구하다",
			"Select Category": "카테고리 선택 *",
			"Shipping Price(Optional)": "배송비 (선택 사항)",
			"Variant Option": "변형 옵션"
		},
		"list": {
			"Category": "범주",
			"Image": "영상",
			"Name": "이름",
			"Price Range": "가격 범위",
			"Products": "제작품",
			"Products List": "제품리스트"
		},
		"public-detail": {
			"Add to cart": "장바구니에 담기",
			"Available Stock": "유효한 주식",
			"Options": "옵션",
			"Original": "기발한",
			"Please choose the product to buy": "구매할 제품을 선택하십시오",
			"Price": "가격",
			"Total Price": "총 가격",
			"Product Detail": "제품 상세 정보",
			"Qty": "수량",
			"Shipping Price": "배송비"
		},
		"public-list": {
			"Detail": "세부 묘사",
			"Product Catalog": "제품 카탈로그",
			"Shipping Price": "배송비"
		}
	},
	"ad-product-category": {
		"edit": {
			"Category Name is required": "Category Name is required",
			"Add Product Category": "제품 카테고리 추가",
			"Back": "뒤로",
			"Category Name": "카테고리 이름",
			"Edit Product Category": "제품 카테고리 편집",
			"Save": "구하다"
		},
		"list": {
			"Name": "이름",
			"Product Category": "제품 카테고리",
			"data-table-title": "제품 카테고리 목록"
		}
	},
	"product-device": {
		"edit": {
			"Add Product Device": "제품 장치 추가",
			"Back": "뒤로",
			"Device Name": "장치 이름",
			"Device Price": "장치 가격",
			"Edit Product Device": "제품 장치 편집",
			"Product Device": "제품 장치",
			"Save": "구하다"
		},
		"list": {
			"Device Name": "장치 이름",
			"Device Price": "장치 가격",
			"Product Device": "제품 장치",
			"Product Device List": "제품 장치 목록"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "프로모션 추가",
			"Back": "뒤로",
			"Coupon count(s)": "쿠폰 수",
			"Description": "기술",
			"Edit Promotion": "프로모션 수정",
			"End Date": "종료일",
			"Promotion Image": "프로모션 이미지",
			"Promotion Type": "프로모션 유형",
			"Promotional Text": "홍보 문구",
			"Save": "구하다",
			"Start Date": "시작일",
			"Select Products": "제품 선택",
			"Max Purchase Amount": "최대 구매 금액",
			"Coupon Code": "쿠폰 코드",
			"Amount": "양",
			"Percentage": "백분율"
		},
		"list": {
			"Coupon Count": "쿠폰 수",
			"End Date": "종료일",
			"Name": "이름",
			"Promotion": "프로모션",
			"Promotion List": "프로모션 목록",
			"Start Date": "시작일",
			"Type": "유형"
		},
		"log": {
			"Promotion Log": "프로모션 로그",
			"Date": "데이트",
			"Customer Name": "고객 이름",
			"Promotion": "프로모션",
			"Received Amount": "수령 금액"
		}
	},
	"reward": {
		"list": {
			"Reward List": "",
			"Name": "",
			"Description": "",
			"Points": "",
			"Contact": ""
		},
		"edit": {
			"Reward Edit": "",
			"Reward Add": "",
			"Image": "",
			"Name": "",
			"Reward By": "",
			"Location": "",
			"Points": "",
			"Mobile Number": "",
			"Email": "",
			"Terms & Conditions": "",
			"ADD": "",
			"About us": "",
			"Add your Terms & Conditions": ""
		}
	},
	"recaptcha error": "recaptcha 오류",
	"recaptchaToken is required": "recaptcha 토큰이 필요합니다",
	"role": {
		"edit": {
			"Add Role": "역할 추가",
			"Back": "뒤로",
			"Edit Role": "역할 편집",
			"Name": "이름",
			"Role Permission": "역할 권한",
			"Save": "구하다"
		},
		"list": {
			"Name": "이름",
			"Permission": "허가",
			"Role": "역할",
			"Role List": "역할 목록",
			"data-table-title": "Role List"
		}
	},
	"role-permission": {
		"edit": {
			"Ad Collection Module": "광고 수집 모듈",
			"Add Role Permission": "역할 권한 추가",
			"Advertising Module": "광고 모듈",
			"Back": "뒤로",
			"Campaign Module": "캠페인 모듈",
			"Create": "몹시 떠들어 대다",
			"Day(s)": "일",
			"Delete": "지우다",
			"Device Module": "장치 모듈",
			"Edit Role Permission": "역할 권한 편집",
			"Expiry Date": "만료일",
			"List": "명부",
			"Name": "이름",
			"Retrieve": "검색",
			"Save": "구하다",
			"Update": "최신 정보",
			"E-Commerce Module": "전자상거래 모듈",
			"CRM Module": "CRM 모듈",
			"Level-1": "1 급",
			"Level-2": "2 급",
			"Level-3": "3 급"
		},
		"E-Commerce Module": {
			"Products": "제품",
			"Product Category": "제품 카 테 고리",
			"Promotion": "프로 모 션",
			"Promotion Log": "프로 모 션 로그",
			"Generate Gift Cards": "선물 카드 생 성",
			"Gift Card Log": "선물 카드 로그",
			"Rewards": "보상"
		},
		"CRM Module": {
			"Email Log": "이메일 로그",
			"Email Template": "이메일 템 플 릿",
			"Loyalty Club": "충성 클럽",
			"Enquiry": "조회"
		},
		"list": {
			"Name": "이름",
			"Role Permission": "역할 권한",
			"Role Permission List": "역할 권한 목록",
			"data-table-title": "Role Permission List"
		}
	},
	"searching": {
		"Ad Collection": "광고 수집",
		"Ads": "광고",
		"Apply": "대다",
		"Campaign": "운동",
		"Clear filter": "필터 지우기",
		"Collections": "컬렉션",
		"Detail": "세부 묘사",
		"Device": "장치",
		"Duration": "지속",
		"Duration(s)": "지속 시간",
		"End Date": "종료일",
		"Filter by": "필터링 기준",
		"First name": "이름",
		"ID": "신분증",
		"Items": "항목",
		"Last name": "성",
		"Name": "이름",
		"No results found for": "에 대한 검색 결과가 없습니다.",
		"Position": "위치",
		"Results for": "에 대한 결과",
		"Start Date": "시작일",
		"Status": "지위",
		"Type": "유형",
		"User": "사용자"
	},
	"company": {
		"list": {
			"Company": "Company",
			"data-table-title": "company list",
			"Company Logo": "Company Logo",
			"Name": "name",
			"Phone": "Phone",
			"Website": "Website"
		},
		"create": {
			"Save": "Save",
			"Back": "Back",
			"Edit Company": "Edit Company",
			"Create Company": "Create Company",
			"Business Name is required": "Business Name is required",
			"Company Type is required": "Company Type is required",
			"Business phone is required": "Business phone is required",
			"Web Site is required": "Web Site is required",
			"Zipcode is required": "Zipcode is required",
			"Business Address is required": "Business Address is required",
			"Business Logo": "Business Logo",
			"Business Name": "Business Name",
			"Company Type": "Company Type",
			"Business phone": "Business phone",
			"Web Site": "Web Site",
			"Zipcode": "Zipcode",
			"Business Address": "Business Address"
		},
		"orderlist": {
			"Date": "",
			"Customer Name": "",
			"Product": "",
			"Qty": "",
			"Total Price": "",
			"Pay Status": "",
			"Finish State": ""
		},
		"createChildCompanyAndStripe": {
			"Create Company Stripe Account": "",
			"Edit Company Stripe Account": "",
			"Name": "",
			"Birthday": "",
			"Email": "",
			"SSN": "",
			"Account Number": "",
			"Routing Number": "",
			"Company Name": "",
			"MCC": "",
			"City": "",
			"Address Line 1": "",
			"Postal code": "",
			"State": "",
			"Phone": ""
		}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "광고 템플릿 유형",
			"Advertising Setting": "광고 설정",
			"Day(s) ": "일",
			"Duration (s)": "지속 시간",
			"Expiry Date": "만료일",
			"Expires in": "",
			"Name": "이름",
			"Save": "구하다",
			"Second(s)": "초",
			"Subscription Plan": "가입 계획",
			"day": "일"
		},
		"general": {
			"Default Language": "기본 설정 언어",
			"Generel Settings": "제네럴 설정",
			"Name": "이름",
			"New User Default Role": "새로운 사용자 기본 역할",
			"Save": "구하다"
		},
		"product": {
			"Name": "이름",
			"Number": "번호",
			"Price": "가격",
			"Product Name": "상품명",
			"Product Number": "제품 번호",
			"Product Price": "제품 가격",
			"Product Service": "제품 서비스",
			"Product Text": "제품 텍스트",
			"Product Type": "상품 유형",
			"Save": "구하다",
			"Service": "서비스",
			"Subscription Lists": "구독 목록",
			"Subscription Name": "구독 이름",
			"Subscription Type": "가입 유형",
			"Usage": "용법"
		}
	},
	"sign": {
		"forget": {
			"Authentication required": "검증 이 필요 합 니 다.",
			"We sent": "우 리 는 이메일 에 일회 성 비밀 번 호 를 보 냈 습 니 다.",
			"Please enter it below": "아래 에 입력 하 십시오.",
			"OTP will expired in": "일회 성 암호 가 만 료 됩 니 다",
			"OTP has expired": "일회용 암호 가 만 료 되 었 습 니 다.",
			"Resend OTP": "비밀번호 다시 보 내 주세요",
			"Enter OTP": "일회용 비밀번호 입력",
			"Required": "필수 선택",
			"Passcode is required": "비밀번호 필요 함",
			"Email is required": "이메일 은 필수 입 니 다",
			"Please select your account type": "계 정 형식 을 선택 하 십시오",
			"Please type your email or mobile": "이메일 이나 핸드폰 을 입력 하 십시오",
			"mdi-reply": "mdi 답장",
			"Reset your Password": "비밀번호 리 셋",
			"Reset my password": "비밀번호 초기 화",
			"Password Changed": "비밀번호 가 변경 되 었 습 니 다",
			"You can now login with your new password": "새로운 비밀번호 로 로그 인 할 수 있 습 니 다",
			"Login": "로그 인",
			"Old Password": "오래된 비밀번호",
			"New Password": "새로운 비밀번호",
			"Confirm Password": "비밀번호 확인",
			"Forgot Password": "비밀번호 까 먹 음",
			"Email or mobile": "이메일 또는 핸드폰 번호",
			"Continue": "계속",
			"Enter the email address or mobile phone number": "Adsmodo 계 정과 연 결 된 이메일 주 소 를 입력 하거나 이동 전화 번 호 를 입력 하 십시오.",
			"Reset Password": "암호를 재설정",
			"Sign In": "로그인",
			"abbr": {
				"send": "한 번만 패스 코드 보내기"
			}
		},
		"sign-in": {
			"Create an account": "계정 만들기",
			"Forgot password": "비밀번호를 잊으 셨나요?",
			"Loyalty Member Sign In": "로열티 회원 로그인",
			"Sign In": "로그인",
			"abbr": {
				"send": "한 번만 패스 코드 보내기"
			},
			"Already have an account?": "",
			"Forgot-Password": "비밀번호를 잊으 셨나요?"
		},
		"sign-up": {
			"Quick Sign Up": "빠른 가입",
			"Complete Sign Up": "가입 완료",
			"Address:": "주소:",
			"Ads My biz": "내 비즈 광고",
			"All of Above": "위의 모든",
			"Business Address": "사업장 주소",
			"Business Name": "비즈니스 이름",
			"Business phone": "업무 폰",
			"Card Number:": "카드 번호:",
			"Company Type": "회사 유형",
			"Confirm": "확인",
			"Confirm Information": "정보 확인",
			"Confirm password": "비밀번호 확인",
			"Create user account": "사용자 계정 만들기",
			"Email": "이메일",
			"Email:": "이메일:",
			"Extra $": "추가 $",
			"First Name": "이름",
			"First Name:": "이름:",
			"Help others": "다른 이들을 돕다",
			"Last Name:": "성:",
			"Next": "다음 것",
			"Password": "암호",
			"Payment Options": "결제 옵션",
			"Phone": "전화",
			"Phone:": "전화:",
			"Prev": "이전",
			"Price:": "가격 :",
			"Price(month):": "가격 (월) :",
			"Product:": "생성물:",
			"Quantity": "수량",
			"Select Adsmodo Business Assistant": "Adsmodo 비즈니스 어시스턴트 선택",
			"Subscription Plan": "가입 계획",
			"Tell us about your goal": "당신의 목표에 대해 알려주십시오",
			"Web Site": "웹 사이트",
			"Zipcode": "우편 번호",
			"abbr": {
				"agree": "약관을 읽고 동의했습니다.",
				"business info": "귀하의 비즈니스에 대해 알려주십시오",
				"web tutorial": "이용 약관"
			},
			"last Name": "성"
		}
	},
	"signin verify error": "로그인 확인 오류",
	"signup password error": "가입 비밀번호 오류",
	"single": {
		"component": {
			"Contact info": "연락 정보",
			"Email address": "이메일 주소",
			"Input your contact info to get coupons and subscribe our service": "연락처 정보를 입력하여 쿠폰을 받고 서비스를 구독하십시오.",
			"Input your contact info to get coupons!": "쿠폰을 받으려면 연락처 정보를 입력하십시오!",
			"Phone number": "전화 번호",
			"Submit": "제출",
			"abbr": {
				"get contact info": ""
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "이 계획을 활성화 하시겠습니까?",
			"Are you sure to deactivate this plan?": "이 계획을 비활성화 하시겠습니까?",
			"Your plan is now Active!": "귀하의 계획은 현재 활성화되었습니다!",
			"Your plan is now Inactive!": "귀하의 계획이 현재 비활성화되었습니다!",
			"Active": "유효한",
			"Inactive": "비활성",
			"Add Subscription Plan": "가입 계획 추가",
			"Back": "뒤로",
			"Edit Subscription Plan": "가입 계획 편집",
			"Number": "번호",
			"Price": "가격",
			"Save": "구하다",
			"Service": "서비스",
			"Subscription Name": "구독 이름",
			"Subscription Plan": "가입 계획",
			"Subscription Type": "가입 유형",
			"Usage": "용법",
			"Description": "",
			"Maximum Area Range": "최대 면적"
		},
		"list": {
			"Price": "가격",
			"Subscription Plan": "가입 계획",
			"Subscription Plan List": "가입 계획 목록",
			"Subscription Plan Type": "가입 계획 유형"
		}
	},
	"user": {
		"account": {
			"Account Type": "계정 유형",
			"Subscription Plan Type": "",
			"Ad Address": "광고 주소",
			"Basic Information": "기본 정보",
			"Save Credit Card": "신용 카드 바인드",
			"Change Credit Card": "",
			"Current Credit Card": "",
			"Saved Payment Methods": "",
			"Bind Status": "바인드 상태",
			"Bind credit card": "신용 카드 저장",
			"Business Address": "사업장 주소",
			"Business Information": "비즈니스 정보",
			"Business Name": "비즈니스 이름",
			"Business Phone": "업무 폰",
			"Cancel": "취소",
			"Cancel Subscription": "구독 취소",
			"Cell Phone": "휴대 전화",
			"Company Type": "회사 유형",
			"Email": "이메일",
			"Free": "비어 있는",
			"Hi": "안녕하세요",
			"Info": "정보",
			"Let me think": "내가 생각하자",
			"Name": "이름",
			"Password": "암호",
			"Product Type": "상품 유형",
			"Profile Image": "프로필 이미지",
			"Redeem": "도로 사다",
			"Rewards": "보상",
			"Save": "구하다",
			"Skip Tour": "스킵 투어",
			"Start Create Ads": "광고 만들기 시작",
			"Subscription Plan": "가입 계획",
			"Web Site": "웹 사이트",
			"Zipcode/Postalcode": "우편 번호 / 우편 번호",
			"abbr": {
				"ad collection intro": "광고 모음은 광고에 포함 된 광고를 정의하고\n                      각 광고 모음에는 여러 개의 광고가 포함될 수 있습니다.",
				"ad intro": "광고는 다른 이미지, 텍스트를 가질 수 있습니다. 저에게 연락하십시오.\n                      버튼, 링크 또는 동영상 및 광고 이미지에\n                      3D, AR 또는 VR과 같은 다른 유형.",
				"campaign intro": "",
				"cancel subscribe check": "구독 서비스를 취소 하시겠습니까?",
				"cancel subscribe info": "구독을 취소하면이 웹 사이트에 대한 모든 권한이 상실됩니다.",
				"privilege info": "가입하면 다음과 같은 권한이 있습니다.",
				"upgrade check": " 계정을 업그레이드 하시겠습니까?",
				"welcome": "Adsmodo에 오신 것을 환영합니다",
				"yes": "예, 그래요!"
			},
			"Monthly": "",
			"Activated": "",
			"Current Plan": "",
			"Change Plan": "",
			"Upgrade Now": "",
			"Last 4 digit": "",
			"Expiring": ""
		},
		"edit": {
			"Add User": "사용자 추가",
			"Back": "뒤로",
			"Can adding ads": "광고 추가 가능",
			"Edit User": "사용자 수정",
			"Email": "이메일",
			"Is Admin": "관리자인가?",
			"Mobile": "변하기 쉬운",
			"Name": "이름",
			"Password": "암호",
			"Save": "구하다",
			"User Role": "사용자 역할",
			"team": "Team"
		},
		"list": {
			"Email": "이메일",
			"Is Admin": "관리자인가?",
			"Mobile": "변하기 쉬운",
			"Name": "이름",
			"Role": "역할",
			"Users": "사용자",
			"Users List": "사용자 목록",
			"data-table-title": "User List"
		}
	},
	"user permission get error": "사용자 권한 오류가 발생했습니다",
	"user-goal": {
		"edit": {
			"Add User Goal": "사용자 목표 추가",
			"Back": "뒤로",
			"Edit User Goal": "사용자 목표 수정",
			"Name": "이름",
			"Save": "구하다",
			"User Goal": "사용자 목표"
		},
		"list": {
			"Name": "이름",
			"User Goal": "사용자 목표",
			"User Goal List": "사용자 목표 목록"
		}
	},
	"validation errors": "유효성 검사 오류",
	"location": {
		"edit": {
			"Name": "이름",
			"Location": "위치",
			"Add Location": "위치 추가",
			"Close": "닫기",
			"Add": "더하다",
			"title": "장치 위치 편집"
		},
		"list": {
			"Device Location": "장치 위치",
			"Device Location List": "장치 위치 목록"
		},
		"add": {
			"title": "장치 위치 추가"
		}
	},
	"description": "기술",
	"View More Cases": "사례 더보기",
	"Contact Us": "연락하기",
	"device-location-edit": "장치 위치 편집",
	"Device Location Edit": "장치 위치 편집",
	"Login is abnormal, please try again later.": "Login is abnormal, please try again later.",
	"header": {
		"Ecommerce": "Ecommerce",
		"Pricing": "Pricing",
		"Get Started": "Get Started",
		"Sign Out": "Sign Out"
	},
	"footer": {
		"copy_right": "Copyright 2018-2019. All rights reserved."
	},
	"security-tab": {
		"mdi-tune": "mdi-tune",
		"Choose": "Choose",
		"Set up": "Set up",
		"Verify": "Verify",
		"Finished": "Finished",
		"Two-factor Authentication": "Two-factor Authentication",
		"Enabled tasks": "Enabled tasks",
		"You can choose a task": "You can choose a task form the list below after you've login.",
		"Text message": "Text message",
		"mdi-message-text": "mdi-message-text",
		"Verification codes are sent by text message": "Verification codes are sent by text message",
		"mdi-shield-lock": "mdi-shield-lock",
		"Authenticator App": "Authenticator App",
		"Use the Authenticator app": "Use the Authenticator app to get verification codes, even when your phone is offline.",
		"How would you": "How would you like to receive your authentication codes?",
		"After entering your password": "After entering your password, you’ll be asked for a second verification step.",
		"Verification codes": "Verification codes are sent by text message.(Required a verified mobile phone number)",
		"SET UP": "SET UP",
		"Cancel": "Cancel",
		"Let's set up": "Let's set up your phone for verification",
		"What phone number": "What phone number do you want to use?",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"mdi-information": "mdi-information",
		"Change": "Change",
		"Two Factor Setup": "Two Factor Setup",
		"Take these few steps to enable": "Take these few steps to enable two-factor authentication and make your account more secure.",
		"Install an authenticator app": "Install an authenticator app on your mobile device.",
		"Recommended options: Google Authenticator": "Recommended options: Google Authenticator,Authy,Microsoft Authenticator",
		"Scan QR code with the authenticator": "Scan QR code with the authenticator.",
		"Enter the 2-step authentication": "Enter the 2-step authentication code provided by your app.",
		"Continue": "Continue",
		"I'm ready": "I'm ready",
		"Back": "Back",
		"Verify your number": "Verify your number",
		"We just sent a text message": "We just sent a text message with a verification code to",
		"Didn't get it? Resend code": "Didn't get it? Resend code.",
		"Scan this QR code": "Scan this QR code with your mobile app.",
		"Then enter the six digit code": "Then enter the six digit code provided by the app:",
		"Can't scan the code": "Can't scan the code?",
		"Great": "Great!",
		"Two-factor Authentication has been": "Two-factor Authentication has been enabled for your account",
		"All future logins will now require": "All future logins will now require you to enter a code supplied by your mobile message or Authenticator App.",
		"Finish": "Finish"
	},
	"reward-list": {
		"Used": "Used",
		"Adsmodo": "Adsmodo",
		"$": "$",
		"OFF YOUR PURCHASE": "OFF YOUR PURCHASE",
		"Gift Code:": "Gift Code:",
		"Valid till": "Valid till"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "Valid from 15 Oct 2019 to 15 Jan 2020",
		"points": "points",
		"mdi-check-bold": "mdi-check-bold",
		"mdi-phone": "mdi-phone",
		"mdi-email": "mdi-email",
		"Report Reward issue to Adsmodo": "Report Reward issue to Adsmodo"
	},
	"return-orders": {
		"Choose items to return": "Choose items to return",
		"Ordered on": "Ordered on",
		"Order Number": "Order Number",
		"Continue": "Continue",
		"Not returnable": "Not returnable",
		"Returnable untill": "Returnable untill",
		"Choose refund method": "Choose refund method",
		"Returning": "Returning",
		"Reason": "Reason",
		"We'll process your refund": "We'll process your refund to Visa ending 1234.",
		"Estimated refund timing": "Estimated refund timing: 2-3 business days after we receive the item.",
		"How do you want to send": "How do you want to send it back?",
		"$0": "$0",
		"Our deliveries will pickup": "Our deliveries will pickup your return package on the next business day.Printer required - you can print the label any time from Your Account or from the email we'll send you.",
		"Refund summary": "Refund summary",
		"Refund Subtotal": "Refund Subtotal:",
		"Shipping fee": "Shipping fee:",
		"Total refund": "Total refund:",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"Your label is ready to print": "Your label is ready to print",
		"Securely pack the items": "Securely pack the items to be returned. Send all the items back to Adsmodo by",
		"mdi-cloud-upload": "mdi-cloud-upload",
		"Print label": "Print label"
	},
	"products-list": {
		"Adsmodo": "Adsmodo",
		"Out of stock": "Out of stock!",
		"Available stock": "Available stock"
	},
	"common-list": {
		"no-data": "기록 없음",
		"reset": "초기화",
		"warning": "경고",
		"delete-item-warning": "이 항목을 삭제 하시겠습니까? 삭제 조작을 롤백 할 수 없습니다",
		"delete-items-warning": "이 항목을 삭제 하시겠습니까? 삭제 조작을 롤백 할 수 없습니다",
		"delete-yes": "예",
		"delete-no": "취소",
		"operations": "조작"
	},
	"member-home": {
		"Special offers for you": "Special offers for you",
		"A confirmation email has been sent to": "A confirmation email has been sent to",
		"Click on the confirmation link in the email to verify your account.": "Click on the confirmation link in the email to verify your account",
		"Points": "Points",
		"My loyalty club": "My loyalty club",
		"Recommended loyalty club": "Recommended loyalty club",
		"More loyalty club": "More loyalty club",
		"More than": "More than",
		"clicks on ads": "clicks on ads",
		"Since this is your first login, please click": "Since this is your first login, please click this message to change your password",
		"There are currently": "There are currently",
		"promotional products": "promotional products",
		"Promotion code list": "Promotion code list",
		"Loyalty club name": "Loyalty club name",
		"Promotion code": "Promotion code",
		"Close": "Close",
		"View promo code": "View promo code"
	}
}
;