import api from "../../services/api";
import { setUser, setToken, clear } from "../../utils/authority";
import _ from "lodash";

export default {
  namespaced: true,
  strict: true,
  state: {
    name: {
      first: "",
      last: ""
    },
    role: {},
    userMenus: [],
    file: {},
    isAdvertiser: null,
    isAdmin: false,
    FileFile: null,
    FilePreview: null,
    myCards: []
  },
  getters: {
    avatar: state => state.file.url
  },
  actions: {
    async fetchMe(store) {
      const response = await api.getMe();
      if (response.data) {
        const userInfo = response.data;
        store.commit("save", userInfo);
        setUser(_.pick(userInfo, ["isAdmin", "isAdvertiser", "isMember", "isTeamMember"]));
      }
    },
    updateUser({ commit }, payload) {
      commit("save", payload);
    },
    async getMyCards({ commit }) {
      const response = await api.getMyCards();
      if (response.data) {
        commit("save", {
          myCards: response.data.card
        });
      }
    },
    async signout({ commit }) {
      await api.signOut();
      clear();
      commit("clear");
    }
  },
  mutations: {
    save(state, payload) {
      state = Object.assign(state, payload);
    },
    clear(state) {
      state = Object.assign(state, {
        name: {
          first: "",
          last: ""
        },
        role: {},
        userMenus: [],
        file: {},
        isAdvertiser: null,
        isAdmin: false,
        myCards: []
      });
    }
  }
};
