<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-card-title class="justify-center">
        <v-avatar :class="options.type" size="72px">
          <v-icon size="56px" class="white--text">{{ icons[options.type] }}</v-icon>
        </v-avatar>
      </v-card-title>
      <v-card-title class="justify-center">
        <v-sheet>
          {{ options.title }}
        </v-sheet>
      </v-card-title>

      <v-card-text v-show="!!options.message" class="pa-4">{{ options.message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" @click.native="agree">{{ $t("OK") }}</v-btn>
        <v-btn v-if="options.showCancelButton" dark color="secondary" @click.native="cancel">{{ $t("Cancel") }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
export default {
  name: "Confirmation",
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: "primary",
      width: 450,
      zIndex: 200,
      message: null,
      title: null,
      type: null,
      showCancelButton: false
    },
    icons: {
      error: "mdi-close",
      success: "mdi-check",
      warning: "fa fa-exclamation",
      info: "fa fa-info"
    }
  }),
  mounted() {
    this.$root.$confirm = this.expose;
    window.swal = this.expose;
  },
  methods: {
    expose(...args) {
      const title = _.find(args, x => _.isString(x));
      const callback = _.find(args, x => _.isFunction(x));
      const options = _.find(args, x => _.isObject(x));
      const message = typeof args[1] === "string" ? args[1] : null;
      const type = typeof args[2] === "string" ? args[2] : null;
      this.open(title, message, type, options, callback);
    },
    open(title, message, type = "success", options = {}, callback = undefined) {
      this.dialog = true;

      this.options.type = type;
      this.options.title = title;
      this.options.message = message;
      this.options = Object.assign(this.options, options);
      if (callback) {
        this.resolve = callback;
        return;
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>

<style></style>
