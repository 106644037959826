import api from "../../services/api";

export default {
  namespaced: true,
  state: {
    current: []
  },
  getters: {},
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },
    async fetchMyMenus({ commit }) {
      const res = await api.getCurrentMenus();
      if (res.data) {
        commit("updateValue", {
          current: res.data.results
        });
      }
    }
  }
};
