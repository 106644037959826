import request from "../utils/request";

// restful api
const getList = (endpoint, search, filters, limit, skip, sort, expandRelationshipFields = true) => {
  return request.get(`/${endpoint}/list`, {
    search,
    filters,
    limit,
    skip,
    sort,
    expandRelationshipFields,
    t: Date.now().valueOf()
  });
};

const getItem = (endpoint, id) => {
  return request.get(`/${endpoint}/list/${id}`, {
    t: Date.now().valueOf()
  });
};

const createItem = (endpoint, data) => {
  return request.post(`/${endpoint}/list`, data);
};

const updateItem = (endpoint, id, data) => {
  return request.put(`/${endpoint}/list/${id}`, data);
};

const deleteItems = (endpoint, id) => {
  return request._delete(`/${endpoint}/list`, id);
};

const getCustomerUrl = (url, data) => {
  return request.get(`${url}`, data);
};

const addClub = id => {
  return request.post("/user/join-loyalty-club", {
    loyaltyClubId: id
  });
};

const getMemberList = (showItem, type) => {
  return request.post("/club/list", {
    showItem: showItem,
    type: type
  });
};

const getNotificationList = () => {
  return request.get("/member/get-invitation-message");
};

const getMemberInfo = () => {
  return request.get("/user/info");
};

const getLoyaltyClubById = id => {
  return request.get(`/loyalty-club/${id}`);
};

const inviteMembersToTheClub = (email, clubId) => {
  return request.post("/user/loyalty-club-all/invite-members", {
    userEmail: email,
    clubId: clubId
  });
};

const inviteMembersToTheTeam = (email, teamId) => {
  return request.post("/user/team/invite-members", {
    userEmail: email,
    teamId: teamId
  });
};
const addLoyaltyClub = form => {
  return request.post("/loyalty-club/add", form);
};

// features api

const sendContact = contact => {
  return request.post("/contact-us", contact);
};

const sendtryout = tryout => {
  return request.post("/tryout", tryout);
};

const saveEmail = email => {
  return request.post("/saveEmail", email);
};

const getMe = () => {
  return request.get("/user/info");
};

const getadCollection = id => {
  return request.get("/public/ad-collection/list", {
    id: id
  });
};

const updateMe = data => {
  return request.put("/user/me", data);
};

const getCurrentSubscription = () => {
  return request.get("/subscription/current", {
    t: Date.now().valueOf()
  });
};

const getMyCards = () => {
  return request.get("/user/get-card-list", {
    t: Date.now().valueOf()
  });
};

const addNewCard = data => {
  return request.post("/user/update-stripe-customer", data);
};

const changeMySubscriptionPlan = data => {
  return request.post("/user/update-subscription-plan", data);
};

const cancelSubscription = () => {
  return request._delete("/user/cancel-subscription");
};

const getNotificationMessage = () => {
  return request.get("/message/list");
};

const getUnreadMessage = () => {
  return request.get("/message/list/unread");
};

const sendMessage = data => {
  return request.post("/message/list", data);
};

const markAsReadMessage = id => {
  return request.put(`/message/list/${id}`);
};

const deleteMessage = id => {
  return request._delete(`/message/list/${id}`);
};

const sendMsgByCondition = data => {
  return request.post("/message/list/by_condition", data);
};

const addNewConpany = params => {
  return request.post("/company/list", params);
};

// const addNewHotel = params => {
//   return request.post("/airbnb/merchantCreateHotel", params);
// };

const getOneCompany = id => {
  return request.get(`/company/list/${id}`);
};

const updateConpany = params => {
  return request.post("/company/update", params);
};

const updateClub = params => {
  return request.post("/loyalty-club/edit", params);
};

const getMyBusinessAddress = () => {
  return request.get("/address/my_business_address");
};

const getCurrentRole = () => {
  return request.get("/role/current");
};
const getCurrentMenus = () => {
  return request.get("/menu/system/List");
};

const getAvailableAds = () => {
  return request.get("/ad/availableList?", {
    t: Date.now().valueOf()
  });
};

const createhotel = data => {
  return request.post("/airbnb/merchantCreateHotel", data);
};

const signOut = () => {
  return request.get("/sign-out");
};

const signupBuisness = data => {
  return request.post("/admin-sign-up", data);
};

const purchasePlayer = data => {
  return request.post("/purchase-player", data);
};

const getBusinessCompany = () => {
  return request.get("/company/get-current");
};

const setBusinessCompany = id => {
  return request.get(`/company/set-business/${id}`);
};

const sendClubMessage = params => {
  return request.post("/send-message/loyalty-club-message", params);
};

const getAdressById = id => {
  return request.get(`/address/list/${id}`);
};

const getClubUsersAndUsers = () => {
  return request.get("/send-message/get-All-users");
};

const sendSmsMessage = params => {
  return request.post("/send-message/send-phone-message", params);
};

const getAdReport = params => {
  return request.get("/report/get-ads-data", params);
};

const getLoyaltyMemberReportNew = params => {
  return request.get("/report/get-loyalty-member-new", params);
};

const getPromotionReport = params => {
  return request.get("/report/get-promotion-data", params);
};

const getLoyatlyReport = params => {
  return request.get("/report/get-loyalty-member", params);
};

const search = (q, limit, skip) => {
  return request.get("/search", {
    q,
    limit,
    skip
  });
};

const getLoyaltyClubMemberList = params => {
  return request.get(`/loyalty-club-member/list/${params}`);
};

const getCompanyReport = params => {
  return request.get("/report/get-company-data", params);
};

const approvingLoyaltyClub = (id, status, reason) => {
  return request.post("/loyalty-club/approving", {
    id,
    status,
    reason
  });
};

const getDeploymentInfo = () => {
  return request.get("/deployment-info/latest", {
    t: Date.now().valueOf()
  });
};

const getZipPrcProgress = name => {
  return request.get("/media-library/get-zip-progress", {
    name
  });
};
// const checkEcpayPayStatus = orderNo => {
//   return request.get("/payment/checkEcpayPayStatus", orderNo);
// };
export default {
  get: request.get,
  post: request.post,
  getList,
  createItem,
  createhotel,
  getItem,
  updateItem,
  deleteItems,
  sendContact,
  sendtryout,
  getNotificationList,
  getMemberList,
  addClub,
  getMemberInfo,
  getMe,
  updateMe,
  getCurrentSubscription,
  getMyCards,
  addNewCard,
  changeMySubscriptionPlan,
  cancelSubscription,
  getLoyaltyClubById,
  getadCollection,
  getNotificationMessage,
  getUnreadMessage,
  sendMessage,
  markAsReadMessage,
  deleteMessage,
  sendMsgByCondition,
  inviteMembersToTheClub,
  inviteMembersToTheTeam,
  addNewConpany,
  addLoyaltyClub,
  getOneCompany,
  updateConpany,
  updateClub,
  getMyBusinessAddress,
  getCurrentRole,
  getCurrentMenus,
  getAvailableAds,
  signOut,
  getCustomerUrl,
  getBusinessCompany,
  setBusinessCompany,
  sendClubMessage,
  signupBuisness,
  purchasePlayer,
  getAdressById,
  getClubUsersAndUsers,
  sendSmsMessage,
  getAdReport,
  getPromotionReport,
  getLoyatlyReport,
  getLoyaltyMemberReportNew,
  search,
  getLoyaltyClubMemberList,
  getCompanyReport,
  approvingLoyaltyClub,
  getDeploymentInfo,
  getZipPrcProgress,
  saveEmail
};
