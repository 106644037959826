import api from "../../services/api";
import _ from "lodash";
export default {
  namespaced: true,
  state: {
    list: []
  },
  getters: {
    categories(state) {
      return _.uniq(_.map(state.list, "category")).filter(x => !!x);
    },
    groups(state) {
      return _.groupBy(state.list, "category");
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async fetchList({ commit }) {
      const res = await api.getList("permission");
      commit("updateValue", {
        list: res.data.results
      });
    }
  }
};
