import api from "../../services/api";
import _ from "lodash";

export default {
  namespaced: true,
  strict: process.env.NODE_ENV !== "production",
  state: {
    membership_products: []
  },
  getters: {},
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },
    getMembershipProducts({ commit }) {
      api.getList("product", {}, JSON.stringify({ type: { value: ["membership"] } })).then(res => {
        commit("updateValue", {
          membership_products: _.get(res, "data.results")
        });
      });
    }
  }
};
