import api from "../../services/api";
import messageModule from "../../../config/message-module";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    list: [],
    unreadMessageList: []
  },
  getters: {
    count(state) {
      return state.unreadMessageList.length || 0;
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },

    getNotificationMessage({ commit }) {
      api.getList("message", "", undefined, 0, 0, "-status,-createdAt").then(res => {
        _.map(res.data.results, item => {
          _.map(messageModule, item2 => {
            if (item.module === item2.value) {
              item.icon = item2.icon;
            }
          });
        });
        commit("updateValue", {
          list: _.get(res, "data.results")
        });
      });
    },

    getUnreadMessage({ commit }) {
      api.getUnreadMessage().then(res => {
        commit("updateValue", {
          unreadMessageList: _.get(res, "data.results")
        });
      });
    },

    sendMessage({ commit }, payload) {
      api.sendMessage(payload).then(() => {
        commit("updateValue");
      });
    },

    sendMsgByCondition({ commit }, payload) {
      api.sendMsgByCondition(payload).then(() => {
        commit("updateValue");
      });
    },

    markAsReadMessage({ dispatch }, payload) {
      api.markAsReadMessage(payload).then(() => {
        dispatch("getNotificationMessage");
        dispatch("getUnreadMessage");
      });
    },

    deleteMessage({ dispatch }, payload) {
      api.deleteMessage(payload).then(() => {
        dispatch("getNotificationMessage");
        dispatch("getUnreadMessage");
      });
    }
  }
};
