import request from "../utils/request";

const getProductList = () => {
  return request.get("/ad-product/member/list");
};

const getClubList = () => {
  return request.get("/public/loyalty-club-all/list");
};

const getGiftCard = () => {
  return request.get("/giftcard/listByCompany");
};

const checkPromotion = params => {
  return request.post("/promotion/checkPromotion", params);
};

const getMemberHomeProduct = () => {
  return request.get("/ad-product/member-home/list");
};

const getMyClubs = (filters, sort, skip, limit) => {
  return request.get("/club/joined", { filters, sort, skip, limit });
};

const getNotMyClubs = (filters, sort, skip, limit) => {
  return request.get("/club/un-joined", { filters, sort, skip, limit });
};

const getClubById = id => {
  return request.get(`/public/loyalty-club/list/${id}`);
};

const createSchedule = schedule => {
  return request.post("/schedule/list", schedule);
};

const getClubEvents = (userId, clubId) => {
  return request.get(userId ? "/schedule/list" : "/public/schedule/list", {
    club: clubId,
    page: 1,
    pageSize: -1
  });
};

const getClubDoctors = club_id => {
  return request.get("/doctors/getByClub", { club_id });
};

const getDoctorEvents = ({ doctor_id, filters }) => {
  return request.get(`/doctors/${doctor_id}/events`, filters);
};

export default {
  getProductList,
  getClubList,
  getGiftCard,
  checkPromotion,
  getMemberHomeProduct,
  getMyClubs,
  getNotMyClubs,
  getClubById,
  createSchedule,
  getClubEvents,
  getClubDoctors,
  getDoctorEvents
};
