import api from "../../services/api";
export default {
  namespaced: true,
  state: {
    myTeams: [],
    selected: undefined
  },
  getters: {
    current(state) {
      if (state.selected) {
        return state.selected;
      }
      return state.myTeams.length ? state.myTeams[0] : {};
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async fetchMyTeams({ commit }) {
      const res = await api.getList("team");
      if (res.data) {
        commit("updateValue", {
          myTeams: res.data.results
        });
      }
    }
  }
};
