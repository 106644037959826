module.exports = {
	"Account or password was incorrect!": "အကောင့် သို့မဟုတ် လျို့ဝှက်နံပါတ် မှားနေပါသည်!",
	"Can't get your device info": "သင်၏ကြော်ငြာဘုတ် သတင်းအချက်အလက်ကို မရနိုင်ပါ",
	"Device does not exist or has been deleted, please reselect": "သင်၏ ကြော်ငြာဘုတ်မရှိ သို့မဟုတ် ဖျက်ခံရသောကြောင့် ကျေးဇူးပြု၍ပြန်လည်ရွေးချယ်ပါ",
	"Duplicate current plan": "လက်ရှိ အစီအစဉ်မှာ ရှုပ်ထွေးနေပါသည်",
	"Email and passcode is required!": "အီးမေးလ် သို့မဟုတ် လျို့ဝှက်နံပါတ် လိုအပ်ပါသည်!",
	"Email or password is incorrect": "အီးမေးလ် သို့မဟုတ် လျို့ဝှက်နံပါတ်မှားယွင်းနေပါသည်",
	"Find device error": "ကြော်ငြာဘုတ် အမှားအား ရှာဖွေပါ",
	"Find user error": "အသုံးပြုသူအမှား ရှာဖွေပါ",
	"Get current customer plan is required": "ဝယ်ယူသူအတွက် လက်ရှိအစီအစဥ် လိုအပ်သောကြောင့် ရယူပါ",
	"Hello": "မင်္ဂလာပါ",
	"Id is required!": "အိုင်ဒီ လိုအပ်ပါသည်!",
	"Inconsistent password": "လျို့ဝှက်နံပါတ် ကွဲလွဲနေပါသည်",
	"Member does not exist, Please try again later!": "အသင်း၀င်မရှိပါ ကျေးဇူးပြု၍ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ!",
	"No id": "အိုင်ဒီ မရှိပါ",
	"Please enter the device name": "ကျေးဇူးပြု၍ ကြော်ငြာဘုတ် အမည်အားရိုက်ထည့်ပါ",
	"Please enter your email": "ကျေးဇူးပြု၍ သင်၏အီးမေးလ်အားရိုက်ထည့်ပါ",
	"Please enter your mobile": "ကျေးဇူးပြု၍ သင်၏မိုဘိုင်းလ်ဖုန်း အားရိုက်ထည့်ပါ",
	"Please select an item first": "ကျေးဇူးပြု၍ အကြောင်းအရာတစ်ခုခုကို ပထမဦးစွာ ရွေးချယ်ပါ",
	"Signin error": "အဝင်မှားယွင်းနေပါသည်",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "",
		"Your account does not have a subscription. Please subscribe to post an ad": ""
	},
	"Swal": {
		"member_sign_up" : {
			"Please register to be part of this club":""
		},
		"More than available stock": "",
		"Are you sure?": "",
		"You will not be able to recover this!": "",
		"Yes, delete it!": "",
		"No, cancel plx!": "",
		"You must register your company first!": "",
		"Exceed the maximum number of Ad": "",
		"Upgrade your account,and create more Advertising": "",
		"Out of stock": "",
		"Please select the variant!": "",
		"Invalid coupon code": "",
		"Invalid coupon code for this product!": "",
		"Valid coupon code": "",
		"Add success": "ပေါင်းထည့်မှု အောင်မြင်ပါသည်",
		"Already in the cart": "စျေးခြင်းတွင်ရှိပြီးဖြစ်ပါသည်",
		"Amount should be more than zero": "ပမာဏသည် 0ထက်များရပါမည်!",
		"Bind Success": "ပေါင်းစည်းမှု အောင်မြင်ပါသည်",
		"Buy device success": "ကြော်ငြာဘုတ်ဝယ်ယူခြင်း အောင်မြင်ပါသည်",
		"Device type is required": "ကြော်ငြာဘုတ် အမျိုးအစား လိုအပ်ပါသည်",
		"Edit success": "တည်းဖြတ်မှု အောင်မြင်ပါသည်",
		"End date must be greater than start date!": "ပြီးဆုံးသည့်ရက်စွဲသည် စတင်သည့်ရက်စွဲထက် ပိုရမည်!",
		"Please select a valid image": "ကျေးဇူးပြု၍ ​မှန်ကန်သော ဓာတ်ပုံအားရွေးချယ်ပါ",
		"Please select a valid video": "ကျေးဇူးပြု၍ မှန်ကန်သော ဗီဒီယိုအားရွေးချယ်ပါ",
		"Session time out.Please log in again": "အချိန်လွန်သွားပါပြီ၊ ကျေးဇူးပြု၍နောက်တစ်ကြိမ်ထပ်မံ၀င်ရောက်ပါ",
		"Something went wrong": "တစ်စုံတစ်ခု မှားယွင်းနေပါသည်",
		"Subscription success": "အသင်းဝင်ခြင်းအောင်မြင်ပါသည်",
		"Successfully approved": "အောင်မြင်စွာ လက်ခံပြီးပါပြီ!",
		"Successfully generated": "အောင်မြင်စွာ ထုတ်လုပ်ပြီးပါသည်!",
		"Your profile has been successfully updated": "သင်၏ကိုယ်ရေးအချက်အလက်ကိုအောင်မြင်စွာ မွမ်းမံပြီးပါပြီ",
		"account": {
			"Cancel Subscription success": "အသင်းဝင်ကြေး ပေးသွင်းခြင်းကို ဖျက်သိမ်းမှုအောင်မြင်ပါသည်",
			"Cancel subscription error": "အသင်းဝင်ကြေးပေးသွင်းခြင်းကို ဖျက်သိမ်းမှု မှားယွင်းနေပါသည်",
			"The current user does not support this feature at this time": "လက်ရှိအသုံးပြုသူသည် ယခုအချိန်တွင် ဤအရာအားမထောက်ခံပါ",
			"This operation is not supported at this time": "လုပ်ဆောင်ချက်အား ယခုအချိန်တွင် အားမပေးပါ"
		},
		"ad": {
			"Ad created successfully": "ကြော်ငြာအား အောင်မြင်စွာ ထည့်သွင်းပြီးပါပြီ",
			"Ad edited successfully": "ကြော်ငြာအား အောင်မြင်စွာ တည်းဖျက်ပြီးပါပြီ",
			"Cancel": "ပယ်ဖျက်ရန်",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "ဂုဏ်ယူပါတယ် သင့်ရဲ့ ပထမဆုံးကြော်ငြာကို အောင်မြင်စွာ ဖန်တီးပြီးပါပြီ ၊ ရှေ့ဆက်ချင်ပါသလား?"
			},
			"Continue": "ရှေ့ဆက်ရန်",
			"Product category is needed!": "ကုန်ပစ္စည်းအမျိုးအစား လိုအပ်ပါသည်!",
			"Product name is needed!": "ကုန်ပစ္စည်းအမည် လိုအပ်ပါသည်!",
			"success info": "သတင်းအချက်အလက် အောင်မြင်ပါသည်"
		},
		"sign": {
			"Agreement Required": "",
			"Please agree the Terms & Condtions": "",
			"Please improve the information": "Please improve the information",
			"Business address invalid, please enter again": "လုပ်ငန်းလိပ်စာမှားယွင်းနေပါသည် ကျေးဇူးပြု၍ ထပ်မံရိုက်ထည့်ပါ",
			"Company type require!": "ကုမ္ပဏီ အမျိုးအစား လိုအပ်ပါသည်!",
			"Invalid recaptcha token": "ဖမ်းယူသော တိုကင်မှားယွင်းနေပါသည်",
			"Password doesnot match!": "လျို့ဝှက်နံပါတ် မှားယွင်းနေပါသည်!",
			"Please post your ad now": "ကျေးဇူးပြု၍ လူကြီးမင်း၏ကြော်ငြာအား ယခုပဲ စတင်လိုက်ပါ",
			"Purchase fail": "ဝယ်ယူမှု မအောင်မြင်ပါ",
			"Purchase success": "ဝယ်ယူမှု အောင်မြင်ပါသည်",
			"The Passcode have been send success": "လျို့ဝှက်နံပါတ်အား အောင်မြင်စွာ ပေးပို့လိုက်ပါသည်",
			"The password and confirm password not the same": "လျို့ဝှက်နံပါတ် နှစ်ခု မတူညီပါ"
		}
	},
	"The ad does not exist or has been deleted": "ကြော်ငြာမရှိပါ သို့မဟုတ် ဖျက်ခံရပါသည်",
	"The device has been bound, Please untie it first": {
		"": "ကြော်ငြာအား ချိတ်ဆက်ပြီးပါပြီ ကျေးဇူးပြု၍ ပထမဦးစွာ ချိတ်ဆက်မှုကို ပြန်လည် ဖြေပါ"
	},
	"The device is not exist": {
		"": "ကြော်ငြာဘုတ် မရှိပါ"
	},
	"The email is already registered!": "စာရင်းသွင်းပြီးသား အီးမေးလ်ဖြစ်ပါသည်!",
	"The item does not exist or has been deleted": "အကြောင်းအရာမရှိ သို့မဟုတ် ဖျက်ခံရပါသည်",
	"The mobile number is already registered!": "စာရင်းသွင်းပြီးသား မိုဘိုင်းလ်ဖုန်းနံပါတ်ဖြစ်ပါသည်!",
	"The user does not exist or has been deleted": "အသုံးပြုသူမရှိ သို့မဟုတ် ဖျက်ခံရပါသည်",
	"Update plan error": "အစီအစဥ် မွမ်းမံမှု မှားယွင်းနေပါသည်",
	"User does not exist, Please try again later!": "အသုံးပြုသူမရှိပါ ကျေးဇူးပြု၍ နောက်တစ်ကြိမ်ထပ်မံကြိုးစားပါ!",
	"You do not have permission": "သင့်မှာ ခွင့်ပြုချက်မရှိပါ",
	"ad-template": {
		"edit": {
			"Add Builder Template": "တည်ဆောက်မှုပုံစံ ပေါင်းထည့်ပါ",
			"Back": "နောက်သို့",
			"Builder Template": "တည်ဆောက်မှုပုံစံ",
			"Edit Builder Template": "တည်ဆောက်မှုပုံစံ တည်းဖြတ်ပါ",
			"Name": "အမည်",
			"Save": "သိမ်းရန်",
			"Status": "စတေးတပ်",
			"Type": "အမျိုးအစား"
		},
		"list": {
			"Builder Template": "တည်ဆောက်မှုပုံစံ",
			"Builder Template List": "တည်ဆောက်မှုပုံစံ စာရင်း",
			"Name": "အမည်",
			"Status": "စတေးတပ်",
			"Type": "အမျိုးအစား",
			"data-table-title": "တည်ဆောက်မှုပုံစံ စာရင်း"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "ကြော်ငြာလုပ်ငန်း",
			"AD Transaction List": "ကြော်ငြာလုပ်ငန်းစာရင်း",
			"Ads": "ကြော်ငြာများ",
			"Collection": "စုစည်းမှု",
			"Device": "ကြော်ငြာဘုတ်",
			"Type": "",
			"Campaign": "",
			"Number": "နံပါတ်"
		}
	},
	"admin-nav": {
		"Tutorial": "",
		"Logout": "ထွက်ရန်",
		"Manager": "Manager",
		"New Enquiry": "စုံစမ်းမေးမြန်းမှု အသစ်",
		"Online": "အွန်လိုင်း",
		"Pending Ad": "ဆိုင်းငံထားသော ကြော်ငြာ",
		"Pending User": "ဆိုင်းငံထားသော အသုံးပြုသူ",
		"Profile": "ကိုယ်ရေးအချက်အလက်",
		"User": "အသုံးပြုသူ",
		"User Manual": "အသုံးပြုသူလက်စွဲ"
	},
	"ads": {
		"edit": {
			"3D Effect Link": "3D သက်ရောက်ချိတ်ဆက်မှု",
			"Ad Template": "ကြော်ငြာပုံစံ",
			"Add Advertising": "ကြော်ငြာ ထည့်ရန်",
			"Additional Discount": "ထပ်ဆောင်း လျှော့စျေး",
			"Advanced": "ပို၍",
			"Advertising": "ကြော်ငြာ",
			"Advertising End Date": "ကြော်ငြာ ပြီးဆုံးမည့်ရက်စွဲ",
			"Approval": "ထောက်ခံခြင်း",
			"Approved": "ထောက်ခံပြီး",
			"Back": "နောက်သို့",
			"Duration (s)": "ကြာချိန်(များ)",
			"Edit Advertising": "ကြော်ငြာတည်းဖြတ်ရန်",
			"Extra Link": "အပို လင့်ခ်",
			"Name": "အမည်",
			"Pending": "ဆိုင်းငံ့ထားသည်",
			"Preview": "နမူနာကြည့်ရန်",
			"Product Purchase link": " ကုန်ပစ္စည်းဝယ်ရန် လင့်ခ်",
			"Product Purchasing": "ကုန်ပစ္စည်းဝယ်ယူခြင်း",
			"Promotional Text": "ပရိုမိုးရှင်း စာသား",
			"Reject": "ငြင်းပယ်ရန်",
			"Rejected": "ငြင်းပယ်ခဲ့ပြီး",
			"Save": "သိမ်းရန်",
			"Second(s)": "စက္ကန့်(များ)",
			"Select products for this Ad": "ကြော်ငြာအတွက် ကုန်ပစ္စည်းအားရွေးချယ်ပါ",
			"Status": "စတေးတပ်",
			"Upgrade": "မွမ်းမံရန်",
			"Video Url": "ဗီဒီယိုဖိုင်၏ လင့်ခ်",
			"Warning!": "သတိပေးချက်!",
			"abbr": {
				"create": "စတင်ဖန်တီးရန်",
				"preview": "နမူနာကြည့်ရှုရန်",
				"warning content": "သတိပေးချက် အကြောင်းအရာ"
			}
		},
		"list": {
			"Advertising": "ကြော်ငြာ",
			"Advertising List": "ကြော်ငြာစာရင်း",
			"Approval Status": "လက်ခံမည့် စတေးတပ်",
			"Approved": "လက်ခံပြီး",
			"Due Date": "သက်တမ်းစေ့မည့်ရက်စွဲ",
			"Duration (s)": "ကြာချိန်(များ)",
			"Name": "အမည်",
			"Pending": "ဆိုင်းငံ့ထားသည်",
			"Products": "ကုန်ပစ္စည်းများ",
			"Created Date": "",
			"Status": "စတေးတပ်",
			"Type": "အမျိုးအစား"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "ARလင့်ခ်/ 3Dလင့်ခ်",
			"Ad Name": "ကြော်ငြာ အမည်",
			"Ad Template": "ကြော်ငြာပုံစံ",
			"All": "အားလုံး",
			"Campaign Name": "ကမ်ပိန်းအမည်",
			"Cancel": "ဖျက်သိမ်းပါ",
			"Collection Name": "စုစည်းမှု အမည်",
			"Company Type": "ကုမ္ပဏီ အမျိုးအစား",
			"Contact Me 3D Link": "ဆက်သွယ်ရန် 3D လင့်ခ်",
			"Contact Me Video Link": "ဆက်သွယ်ရန် ဗီဒီယိုလင့်ခ်",
			"Create your own ads": "ကြော်ငြာများအား ကိုယ်တိုင်စတင်ဖန်တီးပါ",
			"Device List": "ကြော်ငြာဘုတ်စာရင်း",
			"Duration (s)": "ကြာချိန်",
			"Edit": "တည်းဖြတ်ရန်",
			"End Date": "ပြီးဆုံးမည့်ရက်စွဲ",
			"Finish": "ပြီးဆုံးသည်",
			"Let me think": "စဥ်းစားမည်",
			"Maps": "မြေပုံများ",
			"Name": "အမည်",
			"Next": "နောက်သို့",
			"On The Map": "မြေပုံတွင်",
			"Previous": "ပြန်၍",
			"Publish to Device": "ကြော်ငြာဘုတ်သို့ ထုတ်ဝေပါ",
			"Publishing Device": "ကြော်​ငြာဘုတ်သို့ထုတ်ဝေနေသည်",
			"Reject": "ပယ်ဖျက်ရန်",
			"Search": "ရှာဖွေရန်",
			"Second(s)": "စက္ကန့်(များ)",
			"Shop Link": "ဆိုင် လင့်ခ်",
			"Start Date": "စတင်သည့်ရက်စွဲ",
			"Step": "အဆင့်",
			"Submit": "တင်သွင်းရန်",
			"Success": "အောင်မြင်သည်",
			"Text": "စာသား",
			"abbr": {
				"another": "အခြားတခု",
				"created": "ဖန်တီးပြီးပါပြီ",
				"device select": "ကြော်ငြာဘုတ်အားရွေးချယ်ပါ",
				"introduction": "နိဒါန်း",
				"privilege intro": "အထူးအခွင့်အရေး",
				"start": "စတင်လိုက်ရန်",
				"step1": "အဆင့်၁",
				"step2": "အဆင့်၂",
				"upgrate check": "စစ်ဆေးမှုမွမ်းမံရန်",
				"wait": "စောင့်ဆိုင်းရန်",
				"welcome": "ကြိုဆိုပါသည်",
				"yes": "သေချာပါသည်"
			},
			"another": "အခြားတခု",
			"created": "ဖန်တီးပြီးပါပြီ",
			"introduction": "နိဒါန်း",
			"welcome": "ကြိုဆိုပါသည်"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "ပင်မစာမျက်နှ",
				"Home Page List": "ပင်မစာမျက်နှ",
				"Title": "ခေါင်းစဥ်",
				"Created At": "Created At",
				"Updated At": "မှာ updated"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "ပင်မစာမျက်နှာထည့်ပါ",
				"Website Link": "ဝက်ဘ်ဆိုက်လင့်ခ်",
				"Company Type List": "ကုမ္ပဏီအမျိုးအစားစာရင်",
				"ADVERTISEMENT Content": "ကြော်ငြာ မာတိကာ",
				"About Us": "ကျွန်တို့အကြောင်း",
				"Banner": "ခေါင်းစီးစာတန်း",
				"Contact Us": "ဆက်သွယ်ရန်",
				"Content": "မာတိကာ",
				"Edit Home Page": "ပင်မစာမျက်နှာကို တည်း​ဖြတ်ရန်",
				"How to use": "အသုံးပြုနည်း",
				"Image": "ဓာတ်ပုံ",
				"Introduction": "နိဒါန်း",
				"Menu Name": "မီနူးအမည်",
				"Multiple select": "တစ်ခုထက်မက ရွေးချယ်ရန်",
				"Project": "ပရောဂျက်",
				"SHOPKEEPER Content": "ဆိုင်ရှင် မာတိကာ",
				"Scenario": "အညွှန်း",
				"Sub Title": "ခေါင်းစဥ်ခွဲ",
				"Team Member": "အသင်းဝင်",
				"Title": "ခေါင်းစဥ်",
				"Overall": "",
				"Font Color": "",
				"White": "အဖြူ",
				"Black": "အနက်ရောင်",
				"left": "ဘယ်ဘက်",
				"center": "စင်တာ",
				"right": "မှန်တယ်",
				"Edit": "",
				"Add": "",
				"Title Position": "",
				"Background Transparent": "",
				"Left/Right Image": "",
				"Left Title": "Left Title",
				"Left Content": "",
				"Right Title": "",
				"Right Content": "",
				"Scenario List": "",
				"Team List": "",
				"Website": ""
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "",
				"Add Scenario": "",
				"Scenario Image": "",
				"Name": "",
				"Description": ""
			},
			"list": {
				"Scenario": "",
				"Scenario List": "",
				"Image": "",
				"Name": "",
				"Description": ""
			}
		},
		"team": {
			"edit": {
				"Add Team Member": "အသင်းဝင် ပေါင်းထည့်ရန်",
				"Edit Team Member": "အသင်းဝင်တည်းဖြတ်ရန်",
				"Image": "ဓာတ်ပုံ",
				"Job Title": "အလုပ်အကိုင်",
				"Name": "အမည်",
				"Text": "စာသား"
			},
			"list": {
				"Team": "Team",
				"Team List": "Team List",
				"Image": "Image",
				"Job Title": "Job Title",
				"Created At": "Created At",
				"Ad Type": "ကြော်ငြာအမျိုးအစား",
				"Name": "အမည်",
				"Team Member List": "အသင်းဝင်စာရင်း"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "Edit Menu",
				"Add Menu": "Add Menu",
				"Name": "Name",
				"Menu Type": "Menu Type",
				"Parent Menu": "Parent Menu",
				"Link": "Link",
				"Show On Footer": "Show On Footer",
				"Please enter the name": "Please enter the name",
				"Please enter the link": "Please enter the link"
			},
			"list": {
				"Menu": "Menu",
				"Menu List": "Menu List",
				"Name": "Name",
				"Type": "Type",
				"Parent": "Parent",
				"Link": "Link"
			}
		}
	},
	"already registered!": "စာရင်းသွင်းပြီးသားဖြစ်ပါသည်!",
	"bcrypt error": "မှားယွင်းမှု",
	"campaigns": {
		"edit": {
			"Add Campaign": "ကမ်ပိန်းပေါင်းထည့်ရန်",
			"All": "အားလုံး",
			"Back": "နောက်သို့",
			"Cancel": "ဖျက်သိမ်းရန်",
			"Company Type": "ကုမ္ပဏီ အမျိုးအစား",
			"Edit Campaign": "ကမ်ပိန်း တည်းဖြတ်ရန်",
			"End Date": "ပြီးဆုံးသည့်ရက်စွဲ",
			"Finish": "ပြီးဆုံးသည်",
			"Maps": "မြေပုံများ",
			"Name": "အမည်",
			"On The Map": "မြေပုံပေါ်တွင်",
			"Publishing Device": "ကြော်ငြာဘုတ်သို့ထုတ်ဝေနေသည်",
			"Save": "သိမ်းရန်",
			"Search": "ရှာရန်",
			"Select Ad Collection": "ကြော်ငြာစုစည်းမှု ရွေးချယ်ရန်",
			"Search devices": "",
			"Open Map": "Open Map",
			"Target Devices": "",
			"Start Date": "စတင်သည့်ရက်စွဲ",
			"abbr": {
				"Select device": "ကြော်ငြာဘုတ်ရွေးချယ်ရန်",
				"select": "ရွေးချယ်ရန်"
			},
			"Search Devices": "",
			"Company": "ကုမ္ပဏီ",
			"City": "City",
			"State": "ပြည်နယ်",
			"Distance": "Distance",
			"Map": "မြေပုံ"
		},
		"list": {
			"Ad Collections": "ကြော်ငြာစုစည်းမှုများ",
			"Campaigns": "ကမ်ပိန်းများ",
			"Campaigns List": "ကမ်ပိန်းများ စာရင်း",
			"Devices": "ကြော်ငြာဘုတ်များ",
			"End Date": "ပြီးဆုံးမည့်ရက်စွဲ",
			"Name": "အမည်",
			"Start Date": "စတင်သည့်ရက်စွဲ",
			"Status": "စတေးတပ်"
		}
	},
	"cart": {
		"Adsmodo": "Adsmodo",
		"Apply": "လုပ်ဆောင်ရန်",
		"Continue Shopping": "ဆက်လက်၀ယ်ယူရန်",
		"My Cart": "ကျွန်ပ်၏စျေးခြင်း",
		"Order Summary": "အော်ဒါအသေးစိတ်အချက်အလက်",
		"Price": "စျေးနှုန်း",
		"Proceed to Checkout": "ငွေရှင်းရန် လုပ်ဆောင်ပါ",
		"Product": "ကုန်ပစ္စည်း",
		"QTY": "အရေအတွက်",
		"Seller": "ရောင်းသူ",
		"Shipping": "ပို့ဆောင်ခြင်း",
		"Shipping Fee": "ပို့ဆောင်ခ",
		"Sub Total": "အခွဲစုစုပေါင်း",
		"Total": "စုစုပေါင်း",
		"You save": "သိမ်းမည်",
		"Coupon code": "Coupon code",
		"promotion": "Promo Code",
		"Please select your gift Card code": "Please select your gift Card code"
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "ကြော်ငြာစုစည်းမှု ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Edit Ad Collection": "ကြော်ငြာစုစည်းမှု တည်းဖြတ်ရန်",
			"Name": "အမည်",
			"Publishing Device": "ကြော်ငြာဘုတ်သို့ထုတ်ဝေနေသည်",
			"Save": "သိမ်းရန်",
			"Select Ads": "ကြော်ငြာများကိုရွေးချယ်ပါ"
		},
		"list": {
			"Ads": "ကြော်ငြာများ",
			"Ads Collection": "ကြော်ငြာများစုစည်းမှု",
			"Ads Collection List": "ကြော်ငြာများ စုစည်းမှုစာရင်း",
			"Name": "အမည်"
		}
	},
	"common": {
		"Search": "Search",
		"404": {
			"abbr": {
				"back": "နောက်သို့",
				"sorry": "စာမျက်နှာ ရှာမတွေ့ပါ"
			}
		},
		"ad-image": {
			"Click to select picture": "ဓာတ်ပုံရွေးချယ်ရန်နှိပ်ပါ"
		},
		"ad-video": {
			"Click to select video": "ဗီဒီယိုရွေးချယ်ရန်နှိပ်ပါ"
		},
		"bind-credit-card": {
			"Bind": "ချိတ်ဆက်ရန်",
			"CVC": "စီဗွီစီ",
			"Card Number:": "ကဒ်နံပါတ်:",
			"MM": "လ",
			"YYYY": "နှစ်"
		},
		"select-ad-device": {
			"Device List": "ကြော်ငြာဘုတ်စာရင်း",
			"Let me think": "စဥ်းစားမည်",
			"Yes, I'm Sure!": "သေချာပါသည်",
			"abbr": {
				"plan": "လစဥ် $100ကြော်ငြာကြေးဖြင့် Asmodoအစီအစဥ်ရယူပါ",
				"upgrade check": "သင်၏ အကောင့်ကို မွမ်းမံရန် သေချာပါသလား?"
			}
		},
		"tui-image-editor": {
			"Cancel": "ဖျက်သိမ်းရန်",
			"Edit image": "ဓာတ်ပုံအားတည်းဖြတ်ရန်",
			"Image Resolution :": "ဓာတ်ပုံအရည်အသွေး:",
			"Load Assets": "ပစ္စည်းများထည့်သွင်းရန်",
			"Load Promotion Asset": "ပရိုမိုးရှင်းပစ္စည်းများထည့်သွင်းရန်",
			"Load assets": "ပစ္စည်းများထည့်သွင်းရန်",
			"Promotion assets": "ပရိုမိုးရှင်းပစ္စည်းများ",
			"Save": "သိမ်းရန်"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "ကုမ္ပဏီအမျိုးအစားထည့်သွင်းရန်",
			"Back": "နောက်သို့",
			"Edit Company Type": "ကုမ္ပဏီအမျိုးအစားတည်းဖြတ်ရန်",
			"Name": "အမည်",
			"Save": "သိမ်းရန်",
			"Description": "ဖော်ပြချက်"
		},
		"list": {
			"Company Type": "ကုမ္ပဏီ အမျိုးအစား",
			"Company Type List": "ကုမ္ပဏီအမျိုးအစားစာရင်း",
			"Created Date": "စတင်ဖန်တီးသောရက်စွဲ",
			"Name": "အမည်"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "ကြော်ငြာ",
			"Ad Collection": "ကြော်ငြာ စုစည်းမှု",
			"Back": "နောက်သို့",
			"Cell Phone": "ဆဲလ်ဖုန်း",
			"Contact Me": "ဆက်သွယ်ရန်",
			"Device": "ကြော်ငြာဘုတ်",
			"Email": "အီးမေးလ်"
		},
		"list": {
			"Ad": "ကြော်ငြာ",
			"Campaign": "ကမ်ပိန်း",
			"Collection": "စုစည်းမှု",
			"Contact Me": "ဆက်သွယ်ရန်",
			"Contact Me List": "ဆက်သွယ်ရန်စာရင်း",
			"Device": "ကြော်ငြာဘုတ်",
			"Email": "အီးမေးလ်",
			"Phone Number": "ဖုန်းနံပါတ်",
			"action": "လုပ်ဆောင်ချက်",
			"data-table-title": "Contact Me List"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "လုပ်ငန်းလိပ်စာ",
			"Contacts": "အဆက်အသွယ်များ",
			"Contacts List": "အဆက်အသွယ် စာရင်း",
			"Email": "အီးမေးလ်",
			"Is Admin": "အက်ဒမင်",
			"Mobile": "မိုဘိုင်းလ်",
			"Name": "အမည်"
		}
	},
	"customer failed: create customer failed": "ဝယ်ယူသူ မအောင်မြင်ပါ: ဝယ်ယူသူ ဖန်တီးခြင်း မအောင်မြင်ပါ",
	"customer failed: create token failed": "စျေး၀ယ်သူမအောင်မြင်မှု: တိုကင်ဖန်တီးမှုမအောင်မြင်ပါ",
	"dataTable": {
		"Add": "ပေါင်းထည့်ရန်",
		"Are you sure to deactivate it?": "ခေတ္တရပ်နားရန်​​ သေချာပါသလား",
		"Are you sure to delete it?": "ဖျက်ရန်သေချာပါသလား",
		"Cancel": "ပယ်ဖျက်ရန်",
		"Choose your options below and drop one into the inbox of anyone on your list!": "အောက်ပါထဲမှတစ်ခုအားရွေးချယ်၍ စာရင်းထဲတွင်ရှိသော မည်သူမဆို၏စာအိတ်ထဲသို့ထည့်ပါ",
		"Deactivate": "ခေတ္တရပ်နားရန်​​",
		"Delete": "ဖျက်သိမ်းရန်",
		"Operate": "လုပ်ဆောင်ရန်",
		"Confirm": "အတည်ပြုသည်",
		"Please select the loyalty member to give reward": "ကျေးဇူးပြု၍ အမာခံအသင်းဝင်အား ဆုချီးမြှင့်ရန်ရွေးချယ်ပါ",
		"Please select the item to delete": "ကျေးဇူးပြု၍ ဖျက်ရန်အကြောင်းအရာကိုရွေးချယ်ပါ",
		"Reward Gift Card": "ဆုလက်ဆောင်ကဒ်ရယူရန်",
		"This operation cannot be undone": "လုပ်ဆောင်ချက် ပြီးဆုံးရန် သေချာသည်",
		"We offer electronic gift cards in any denomination": "အီလက်ထရောနစ် လက်ဆောင်ကဒ်များကို မည်သည့်အသင်းဝင်အမျိုးအစားမဆို ပေးအပ်ပါသည်"
	},
	"database error": "ဒေတာဘေ့စ် မှားယွင်းမှု",
	"devices": {
		"addCampaign": {
			"Ad Collections": "ကြော်ငြာစုစည်းမှုများ",
			"Ad Images": "ကြော်ငြာဓာတ်ပုံများ",
			"Ad List": "ကြော်ငြာ စာရင်း",
			"Add Campaign": "ကမ်ပိန်းထည့်သွင်းရန်",
			"Available Campaigns": "ရနိုင်သော ကမ်ပိန်းများ",
			"Back": "ပြန်၍",
			"Campaign Detail": "ကမ်ပိန်းအသေးစိတ်အချက်အလက်",
			"Campaign List": "ကမ်ပိန်းစာရင်း",
			"Campaigns": "ကမ်ပိန်းများ",
			"Device": "ကြော်ငြာဘုတ်",
			"Device ID": "ကြော်ငြာဘုတ် အိုင်ဒီ",
			"Device Name": "ကြော်ငြာဘုတ် အမည်",
			"Drag available campaigns here to run on device": "ရနိုင်သောကမ်ပိန်းများကို ကြော်ငြာဘုတ်တွင်ပြသနိုင်ရန် ဤနေရာသို့ဖိဆွဲပါ",
			"Duration(s)": "ကြာချိန်(များ)",
			"End Date": "ပြီးဆုံးမည့်ရက်စွဲ",
			"Name": "အမည်",
			"Save": "သိမ်းရန်",
			"Start Date": "စတင်သည့် ရက်စွဲ",
			"Status": "စတေးတပ်",
			"This ad have no images": "ဤကြော်ငြာတွင် ဓာတ်ပုံမရှိပါ"
		},
		"buy-log": {
			"Buy Device Log": "ကြော်ငြာဘုတ်မှတ်တမ်းအား ဝယ်ယူရန်",
			"Buy Device Log List": "ကြော်ငြာဘုတ်မှတ်တမ်းစာရင်းဝယ်ယူရန်",
			"Name": "အမည်",
			"Quantity": "အရေအတွက်",
			"Status": "စတေးတပ်",
			"Unit Price": "တစ်ခုချင်းစျေးနှုန်း",
			"Permission": "Permission"
		},
		"buy-product": {
			"Back": "နောက်သို့",
			"Buy Device": "ကြော်ငြာဘုတ်ဝယ်ယူရန်",
			"Device Quantity": "ကြော်ငြာဘုတ်ပမာဏ",
			"Location": "Location",
			"Device Type": "ကြော်ငြာဘုတ် အမျိုးအစား",
			"Save": "သိမ်းရန်"
		},
		"edit": {
			"Add Device": "ကြော်ငြာဘုတ် ထည့်သွင်းရန်",
			"Are you sure to activate this device ?": "ဤကြော်ငြာဘုတ်အား ဖွင့်ရန်သေချာပါသလား",
			"Are you sure to deactivate this device ?": "ဤကြော်ငြာဘုတ်အား ခေတ္တရပ်နားရန်သေချာပါသလား",
			"Back": "နောက်သို့",
			"Business Name": "လုပ်ငန်းအမည်",
			"Cancel": "ဖျက်သိမ်းရန်",
			"Confirm": "Confirm",
			"Device ID": "ကြော်ငြာဘုတ် အိုင်ဒီ",
			"Device Info": "ကြော်ငြာဘုတ် သတင်းအချက်အလက်",
			"Device Name": "ကြော်ငြာဘုတ်အမည်",
			"Device Status": "ကြော်ငြာဘုတ်စတေးတပ်",
			"Device Type": "ကြော်ငြာဘုတ် အမျိုးအစား",
			"Edit Device": "ကြော်ငြာဘုတ် တည်းဖြတ်ရန်",
			"Manufacturer": "ကုန်ထုတ်လုပ်ငန်း သို့ ကုမ္ပဏီ",
			"Model": "မော်ဒယ်",
			"Name": "အမည်",
			"Offline": "အော့ဖ်လိုင်း",
			"Online": "အွန်လိုင်း",
			"Placement Location": "တည်နေရာချရန်",
			"Platform": "ပလက်ဖောင်း",
			"Save": "သိမ်းရန်",
			"Select Ad Collections": "ကြော်ငြာစုစည်းမှုများကို ရွေးချယ်ရန်",
			"Serial": "အစဥ်လိုက်",
			"Toggle": "တစ်လှည့်စီ",
			"UUID": "ယူယူအိုင်ဒီ",
			"Version": "ဗားရှင်း",
			"Your device is now Offline!": "သင်၏ ကြော်ငြာဘုတ်က အော့ဖ်လိုင်း အခြေအနေတွင်ရှိပါသည်",
			"Your device is now Online!": "သင်၏ ကြော်ငြာဘုတ်က အွန်လိုင်းအခြေအနေတွင်ရှိပါသည်",
			"Your device need to register via App first !": "သင်၏ကြော်ငြာဘုတ်ကို ပထမဦးစွာအပလီကေးရှင်းမှတဆင့် စာရင်းသွင်းရန်လိုအပ်ပါသည်!"
		},
		"list": {
			"Ad Collections": "ကြော်ငြာစုစည်းမှုများ",
			"Business Name": "လုပ်ငန်းအမည်",
			"Campaigns": "ကမ်ပိန်းများ",
			"Device ID": "ကြော်ငြာဘုတ် အိုင်ဒီ",
			"Device Info": "ကြော်ငြာဘုတ်သတင်းအချက်အလက်",
			"Devices": "ကြော်ငြာဘုတ်များ",
			"Devices List": "ကြော်ငြာဘုတ်များ စာရင်း",
			"Name": "အမည်",
			"Position": "နေရာ",
			"Status": "စတေးတပ်",
			"User": "အသုံးပြုသူ",
			"title": "ကြော်ငြာဘုတ်များ",
			"data-table-title": "ကြော်ငြာဘုတ်များ စာရင်း"
		},
		"purchase-log": {
			"Device": "ကြော်ငြာဘုတ်",
			"Order No": "အော်ဒါနံပါတ်",
			"Purchase Log": "ဝယ်ယူသည့် မှတ်တမ်း",
			"Purchase Log List": "ဝယ်ယူသည့်မှတ်တမ်း စာရင်း",
			"Quantity": "ပမာဏ",
			"Subscription": "အသင်းဝင်ခြင်း",
			"Total Price": "စျေးနှုန်းစုစုပေါင်း",
			"Unit Price": "တစ်ခုချင်းစျေးနှုန်း",
			"User Name": "အသုံးပြုသူ အမည်"
		}
	},
	"email": {
		"create-email": {
			"Back": "နောက်သို့",
			"Email From": "အီးမေးလ်ပုံစံ",
			"Message": "မက်ဆေ့ချ်",
			"Send": "ပို့ရန်",
			"Send To": " သို့ ပို့ရန်",
			"Subject": "အကြောင်းအရာ"
		}
	},
	"email-log": {
		"edit": {
			"Back": "နောက်သို့",
			"Content": "မာတိကာ",
			"Created At": "ဖန်တီးခဲ့သည်",
			"Email Log": "အီးမေးလ် မှတ်တမ်း",
			"Name": "အမည်",
			"Send Status": "စတေးတပ်အား ပို့ရန်",
			"Subject": "အကြောင်းအရာ",
			"Template": "ပုံစံ",
			"To": "သို့",
			"from": "မှ"
		},
		"list": {
			"Email Log": "အီးမေးလ် မှတ်တမ်း",
			"Email Log List": "အီးမေးလ် မှတ်တမ်းစာရင်း",
			"Email template": "အီးမေးပုံစံ",
			"Name": "အမည်",
			"from": "မှ",
			"to": "သို့"
		}
	},
	"email-template": {
		"edit": {
			"Add Email Template": "အီးမေးလ်ပုံစံ ပေါင်းထည့်ရန်",
			"Available keyword": "ရနိုင်သောစာလုံးများ",
			"Back": "နောက်သို့",
			"Edit Email Template": "အီးမေးလ်ပုံစံ တည်းဖြတ်ရန် ",
			"Email Body": "အီးမေးလ် စာကိုယ်",
			"Email From": "မှ အီးမေးလ်",
			"Name": "အမည်",
			"Save": "သိမ်းရန်",
			"Send To": "သို့ ပို့ရန်",
			"Status": "စတေးတပ်",
			"Subject": "အကြောင်းအရာ",
			"abbr": {
				"note": "ရုံးတွင် အက်ဒမင်တစ်ယောက်သာလျှင်သုံးသည့်အတွက် လက်ခံရရှိသူမှာအများဖြစ်လျှင် ကော်မာဖြင့်အုပ်စုခွဲပါ "
			},
			"template Type": "ပုံစံအမျိုးအစား"
		},
		"list": {
			"Draft": "မူကြမ်း",
			"Email Template": "အီးမေးလ် ပုံစံ",
			"Email Template List": "အီးမေးလ် ပုံစံစာရင်း",
			"Name": "အမည်",
			"Published": "ပုံနှိပ်ထုတ်ဝေခဲ့ပြီး",
			"Status": "စတေးတပ်",
			"Type": "အမျိုးအစား",
			"badge-danger": "စိုးရိမ်ဖွယ် အမှတ်တံဆိပ်",
			"badge-success": "အောင်မြင်သော အမှတ်တံဆိပ်"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "စုံစမ်းစစ်ဆေးမှု ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Edit Enquiry": "စုံစမ်းစစ်ဆေးမှု တည်းဖြတ်ရန်",
			"Email": "အီးမေးလ်",
			"Enquiry Type": "စုံစမ်းစစ်ဆေးမှု အမျိုးအစား",
			"Message": "မက်ဆေ့ချ်",
			"Name": "အမည်",
			"Phone": "ဖုန်း",
			"Reply": "အကြောင်းပြန်ရန်",
			"Save": "သိမ်းရန်",
			"Show Enquiry": "စုံစမ်းစစ်ဆေးမှုကို ပြသရန်"
		},
		"list": {
			"Created Date": "ဖန်တီးခဲ့သော ရက်စွဲ",
			"Email": "အီးမေးလ်",
			"Enquiry": "စုံစမ်းစစ်ဆေးမှု",
			"Enquiry List": "စုံစမ်းစစ်ဆေးမှု စာရင်း",
			"Message": "မက်ဆေ့ချ်",
			"Name": "အမည်",
			"Reply": "အကြောင်းပြန်ရန်",
			"Type": "အမျိုးအစား",
			"data-table-title": "Enquiry List"
		}
	},
	"get ad query error": "ကြော်ငြာ မေးမြန်းမှု အမှားများကို ရယူပါ",
	"get ads database error": "ကြော်ငြာ ဒေတာဘေ့စ်အမှားများကို ရယူပါ",
	"get item database error ": "အကြောင်းအရာ ဒေတာဘေ့စ်အမှားများကိုရယူပါ ",
	"get item query error": "အကြောင်းအရာ မေးမြန်းမှုအမှားကိုရယူပါ",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "ကဒ်အနောက်ဘက်မှ လျို့ဝှက်ကုဒ်နံပါတ်၃လုံး",
		"Amount of Gift Card": "လက်ဆောင်ကဒ်ပမာဏ",
		"CVC": "စီဗွီစီ",
		"Card Number": "ကဒ်နံပါတ်",
		"Email From": "မှ အီးမေးလ်",
		"Expiration Date": "သက်တမ်းကုန်ဆုံးမည့်ရက်စွဲ",
		"For payment confirmation": "ငွေပေးချေမှုအတည်ပြုချက်",
		"Generate Gift Card": "လက်ဆောင်ကဒ်ထုတ်လုပ်ရန်",
		"Generate Gift Card for the selected loyalty member": "ရွေးချယ်ထားသောအမာခံအသင်း၀င်အတွက် လက်ဆောင်ကဒ်ထုတ်လုပ်ရန်",
		"Gift Cards": "လက်ဆောင်ကဒ်များ",
		"Gift Cards Log": "လက်ဆောင်ကဒ်များမှတ်တမ်း",
		"Recipient Email": "လက်ခံရရှိသူ အီးမေးလ်",
		"Recipient Name": "လက်ခံရရှိသူ အမည်"
	},
	"home": {
		"Accountant": "Accountant",
		"Add Product to cart": "ကုန်ပစ္စည်းအား ဈေးခြင်းထဲသို့ထည့်ပါ",
		"Age": "အသက်",
		"CRM": "စီအာအမ်",
		"Complete Purchases": "ဝယ်ယူမှု ပြီးဆုံးသည်",
		"Daily Visits": "နေ့စဥ် လည်ပတ်မှုများ",
		"Dashboard": "ဒက်ရှ်ဘုတ်",
		"Device Orders": "ကြော်ငြာဘုတ် အော်ဒါများ",
		"Edinburgh": "အက်ဒင်ဘရာ",
		"From Last 1 Month": "လွန်ခဲ့သော ၁လမှ",
		"From Last 24 Hours": "လွန်ခဲ့သော ၂၄နာရီမှ",
		"Goal Completion": "ပြီးဆုံးခြင်း",
		"Integration Specialist": "Integration Specialist",
		"Junior Technical Author": "Junior Technical Author",
		"Last 3 Month Ad-Collection Record": "လွန်ခဲ့သော ၃လမှ ကြော်ငြာ စုစည်းမှု မှတ်တမ်း",
		"Last 3 Month Subscription Record": "လွန်ခဲ့သော ၃လမှ စာရင်းသွင်းခြင်း မှတ်တမ်း",
		"Last Month": "လွန်ခဲ့သော လ",
		"Last week": "လွန်ခဲ့သော သတင်းပတ်",
		"Mail Inbox": "အီးမေးလ် စာတိုက်",
		"Marketplace": "စျေးကွက်",
		"Monthly Earnings": "လစဥ်ဝင်ငွေ",
		"Monthly Purchases": "လစဥ်ဝယ်ယူမှုများ",
		"Name": "အမည်",
		"New York": "နယူးယောက်",
		"Office": "ရုံး",
		"Orders in Last 10 months": "လွန်ခဲ့သော၁၀လမှ အော်ဒါများ",
		"Pending Ad": "ဆိုင်းငံထားသော ကြော်ငြာ",
		"Pending User": "ဆိုင်းငံထားသော အသုံးပြုသူ",
		"Position": "နေရာ အခြေအနေ",
		"Recent Contacts": "မကြာသေးမီက အဆက်အသွယ်များ",
		"Revenue": "အခွန်",
		"Salary": "လစာ",
		"Sales Assistant": " Sale Assistant",
		"San Francisco": "ဆန်ဖရန်စစ်စကို",
		"Send Inquiries": "စုံစမ်းစစ်ဆေးမှုများ ပေးပို့ရန်",
		"Senior Javascript Developer": "Senior Javascript Developer",
		"Start date": "စတင်သည့် ရက်စွဲ",
		"System Architect": "System Architect",
		"Tokyo": "တိုကျို",
		"Total Subscriptions": "စုစုပေါင်း စာရင်းသွင်းခြင်းများ",
		"Unique Ad-Viewers": "ထူးခြားသော ကြော်ငြာကြည့်ရှုသူများ",
		"Visit Premium plan": "ပရီမီယမ် အစီအစဉ်သို့ သွားရန်",
		"Weekly Top 5 Advertisement": "အပတ်စဥ် ထိပ်တန်း ကြော်ငြာ ၅ခု",
		"select device error": "ကြော်ငြာဘုတ်အမှားကို ရွေးချယ်ပါ"
	},
	"id required": "အိုင်ဒီလိုအပ်ပါသည်",
	"image is required to show ad": "ကြော်ငြာပြသရန် ဓာတ်ပုံလိုအပ်သည်",
	"main-nav": {
		"Sign In": "ဝင်ရောက်ရန်",
		"Sign Up": "ဝင်ရောက်ရန် စာရင်းသွင်းပါ",
		"Terms & Conditions": "Terms & Conditions"
	},
	"member": {
		"About": "အသင်းဝင်အကြောင်း",
		"Back": "နောက်သို့",
		"Cancel": "ပယ်ဖျက်ရန်",
		"Clear": "ရှင်းလင်းရန်",
		"Confirm": "အတည်ပြုသည်",
		"Contact Info": "အဆက်အသွယ် သတင်းအချက်အလက်",
		"Description": "ဖော်ပြချက်",
		"Earned Points": "ရရှိထားသော ပွိုင့်များ",
		"Edit": "တည်းဖြတ်ရန်",
		"Email Verification Success": "အီးမေးလ်အတည်ပြုခြင်းအောင်မြင်ပါသည်",
		"Explore": "စူးစမ်းရှာဖွေရန်",
		"Got it!": "ရယူလိုက်ပါ!",
		"If you experience any issues with this Reward,please feel free to contact": "ဆုလက်ဆောင်နှင့်ပက်သတ်၍ ပြဿနာတစ်စုံတစ်ရာရှိလျှင် ဆက်သွယ်ပါ",
		"LearnMore": "ပို၍",
		"Please enter activation code sent to this number": "ပေးပို့ထားသောကုဒ်အား ရိုက်ထည့်ပါ",
		"Points history": "ပွိုင့်များမှတ်တမ်း",
		"Redeem": "ရယူရန်",
		"Redeemable points can be exchanged for rewards at the Rewards page": "ရရှိထားသောလက်ဆောင်များကို ဆုလက်ဆောင်စာမျက်နှာတွင်လဲလှယ်နိုင်ပါသည်",
		"Save": "သိမ်းရန်",
		"Share": "မျှ၀ေရန်",
		"Special Deals": "အထူးအဆက်အသွယ်များ",
		"Spent Points": "သုံးစွဲပြီးသော ပွိုင့်များ",
		"Terms and Conditions": "သက်မှတ်ချက်များနှင့် အခြေအနေများ",
		"What are Redeemable Points?": "မည်သည့်ပွိုင့်များကို ရယူနိုင်သလဲ?",
		"Your email has been verified.We will redirect you to home page": "သင်၏ အီးမေးလ်ကိုအတည်ပြုပြီးပါပြီ၊ ပင်မစာမျက်နှာသို့ ညွှန်းပေးပါမည်",
		"nav": {
			"Adsmodo Loyalty Members Club": "Adsmodo၏ အမာခံအသင်းဝင် ကလပ်ဘ်",
			"Home": "ပင်မ",
			"Log Out": "ထွက်ရန်",
			"My rewards": "ကျွန်ပ်၏လက်ဆောင်များ",
			"Products": "ကုန်ပစ္စည်းများ",
			"Profile": "ကိုယ်ရေးအချက်အလက်",
			"Purchase History": "၀ယ်ယူမှုမှတ်တမ်း"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "ကိတ်ခ် သက်တမ်းကုန်ဆုံးမည့်ရက်စွဲ",
			"Cake Used": "ကိတ်ခ် အသုံးပြုခဲ့သည့်ရက်စွဲ",
			"Roylaty Member Detail": "အမာခံ အသင်းဝင်အသေးစိတ်အချက်အလက်"
		},
		"form": {
			"Birthday": "မွေးနေ့",
			"Join The Adsmodo Loyalty Club": "Adsmodo အမာခံအဖွဲ့အစည်းသို့ ဝင်ရောက်ရန်",
			"Select Business Type*": "လုပ်ငန်းအမျိုးအစားရွေးချယ်ပါ*",
			"Submit": "တင်သွင်းရန်",
			"Team": "Team"
		},
		"list": {
			"Birthday": "မွေးနေ့",
			"Email": "အီးမေးလ်",
			"Loyalty Member": "အမာခံအသင်းဝင်",
			"Loyalty Member List": "အမာခံအသင်းဝင်စာရင်း",
			"Mobile": "မိုဘိုင်းလ်",
			"Name": "အမည်",
			"Points": "ပွိုင့်များ"
		}
	},
	"no permission": "ခွင့်ပြုချက်မရှိပါ",
	"post:Device update error": "ကြော်ငြာ မွမ်းမံမှုအမှား",
	"post:sendPasscode error": "လျို့ဝှက်နံပါတ်အမှားကိုပေးပို့ရန်",
	"post:signin account error": "အကောင့်ဝင်ရောက်ခြင်းအမှား",
	"pre:add ad name validate failed": "ကြော်ငြာအမည် အထောက်အထားပေါင်းထည့်ခြင်း မအောင်မြင်ပါ",
	"pre:audit user query error": "စာရင်းစစ်စုံစမ်းမေးမြန်းမှုအမှား",
	"pre:bind have been bound": "တွဲစပ်ပြီးပါပြီ",
	"pre:bind verify name failed": "အတည်ပြုအမည် တွဲစပ်ခြင်းမအောင်မြင်ပါ",
	"pre:bind verify serial failed": "အစဥ်လိုက် အတည်ပြုတွဲစပ်ခြင်း မအောင်မြင်ပါ",
	"pre:delete database error": "ဒေတာဘေ့စ်အမှားကိုဖျက်ရန်",
	"pre:find user": "အသုံးပြုသူကိုရှာဖွေရန်",
	"pre:get purchase log database error": "အဝယ်မှတ်တမ်းဒေတာဘေ့စ် အမှားကိုရယူပါ",
	"pre:member get error": "အသင်းဝင်ရယူမှုအမှား",
	"pre:missing parameters": "လွတ်နေသော နေရာများကို ဖြည့်စွက်ပါ",
	"pre:sendPasscode find user error": "အသုံးပြုသူအမှားကိုရှာဖွေ၍ လျို့ဝှက်နံပါတ်ပေးပို့ပါ",
	"pre:signin device has been bound": "ကြော်ငြာဘုတ် ဝင်ရောက်ခြင်းကို တွဲစပ်ပြီးပါပြီ",
	"pre:signin find user error": "အသုံးပြုသူ ရှာဖွေခြင်း ဝင်ရောက်မှုအမှား",
	"pre:unbind the device is not exist": "မရှိသောကြော်ငြာဘုတ်အား တွဲစပ်မှုဖြုတ်ပါ",
	"pre:update get campaign database error": "ကမ်ပိန်း ဒေတာဘေ့စ်အမှား ရယူမှုကို မွမ်းမံပါ",
	"pre:update get member database error": "အသင်းဝင် ဒေတာဘေ့စ်အမှား ရယူမှုကို မွမ်းမံပါ",
	"pre:update item query error": "အကြောင်းအရာ မေးမြန်းစုံစမ်းခြင်းအမှားကို မွမ်းမံပါ",
	"pre:update missing parameters": "လွတ်နေသော နေရာများကို ဖြည့်စွက်ပါ",
	"pre:verification error": "သက်သေအထောက်အထားအမှား",
	"product": {
		"ads": {
			"Recommended Products": "Recommended Products",
			"more products": "more products",
			"LearnMore": "Learn More"
		},
		"edit": {
			"Select Category is required": "Select Category is required",
			"Name is required": "Name is required",
			"Currency is required": "Currency is required",
			"giftfile is required": "giftfile is required",
			"You will join": "You will join",
			"club": "club",
			"You have chosen": "You have chosen",
			"clubs": "clubs",
			"Add Options": "ရွေးချယ်မှုများ ပေါင်းထည့်ရန်",
			"Add Pricing": "စျေးနှုန်းပေါင်းထည့်ရန်",
			"Add Product": "ကုန်ပစ္စည်းပေါင်းထည့်ရန်",
			"Add more variant option": "မူကွဲ ရွေးချယ်မှုများပေါင်းထည့်ရန်",
			"Add option value": "ရွေးချယ်မှုတန်ဖိုးအားပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Create Gift Card": "လက်ဆောင်ကဒ်ဖန်တီးရန်",
			"Currency": "ငွေကြေးစံစနစ်",
			"Description": "ဖော်ပြချက်",
			"Edit Product": "ကုန်ပစ္စည်းအား တည်းဖြတ်ရန်",
			"Gift Card": "လက်ဆောင်ကဒ်",
			"Images": "ဓာတ်ပုံများ",
			"Master price": "မာစတာ စျေးနှုန်း",
			"Name": "အမည်",
			"Option": "ရွေးချယ်မှု",
			"Option Types": "ရွေးချယ်မှု အမျိုးအစားများ",
			"Price": "စျေးနှုန်း",
			"Price Range": "စျေးနှုန်းအပိုင်းအခြား",
			"Pricing": "စျေးနှုန်း",
			"Qty": "အရေအတွက်",
			"Quantity": "အ​​​ရေအတွက်",
			"Quantity in Hand": "",
			"Is Returnable": "ပြန်လာနိုင်ပြီ",
			"Size Description": "အရွယ်အစားဖော်ပြချက်",
			"Save": "သိမ်းရန်",
			"Select Category": "အမျိုးအစား ရွေးချယ်ရန်",
			"Shipping Price(Optional)": "ပို့ဆောင်ခ(ရွေးချယ်ခွင့်)",
			"Variant Option": "မူကွဲရွေးချယ်မှု"
		},
		"list": {
			"Category": "အမျိုးအစား",
			"Image": "ဓာတ်ပုံ",
			"Name": "အမည်",
			"Price Range": "စျေးနှုန်း အပိုင်းအခြား",
			"Products": "ကုန်ပစ္စည်းများ",
			"Products List": "ကုန်ပစ္စည်းများစာရင်း"
		},
		"public-detail": {
			"Add to cart": "စျေးခြင်းသို့ပေါင်းထည့်ရန်",
			"Available Stock": "ရနိုင်သောကုန်ပစ္စည်းပမာဏ",
			"Options": "ရွေးချယ်မှုများ",
			"Original": "မူလ",
			"Please choose the product to buy": "ကျေးဇူးပြု၍ ဝယ်ယူရန် ကုန်ပစ္စည်းကိုရွေးချယ်ပါ",
			"Price": "စျေးနှုန်း",
			"Total Price": "စျေးနှုန်းစုစုပေါင်း",
			"Product Detail": "ကုန်ပစ္စည်းအသေးစိတ်အချက်အလက်",
			"Qty": "အရေအတွက်",
			"Shipping Price": "ပို့ဆောင်ခနှုန်းထား"
		},
		"public-list": {
			"Detail": "အသေးစိတ်အချက်အလက်",
			"Product Catalog": "ကုန်ပစ္စည်း စာရင်း",
			"Shipping Price": "ပို့ဆောင်ခနှုန်းထား"
		}
	},
	"ad-product-category": {
		"edit": {
			"Category Name is required": "Category Name is required",
			"Add Product Category": "ကုန်ပစ္စည်း အမျိုးအစားပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Category Name": "အမျိုးအစားအမည်",
			"Edit Product Category": "ကုန်ပစ္စည်းအမျိုးအစားကို တည်းဖြတ်ရန်",
			"Save": "သိမ်းရန်"
		},
		"list": {
			"Name": "အမည်",
			"Product Category": "ကုန်ပစ္စည်းအမျိုးအစား",
			"data-table-title": "ကုန်ပစ္စည်းအမျိုးအစားစာရင်း"
		}
	},
	"product-device": {
		"edit": {
			"Add Product Device": "ကုန်ပစ္စည်းကြော်ငြာဘုတ်ကို ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Device Name": "ကြော်ငြာဘုတ်အမည်",
			"Device Price": "ကြော်ငြာဘုတ်စျေးနှုန်း",
			"Edit Product Device": "ကုန်ပစ္စည်းကြော်ငြာဘုတ်ကို တည်းဖြတ်ရန်",
			"Product Device": "ကုန်ပစ္စည်း ကြော်ငြာဘုတ်",
			"Save": "သိမ်းမည်"
		},
		"list": {
			"Device Name": "ကြော်ငြာဘုတ်အမည်",
			"Device Price": "ကြော်ငြာဘုတ်စျေးနှုန်း",
			"Product Device": "ကုန်ပစ္စည်း ကြော်ငြာဘုတ်",
			"Product Device List": "ကုန်ပစ္စည်း ကြော်ငြာဘုတ်စာရင်း"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "ပရိုမိုးရှင်း ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Coupon count(s)": "ကူပွန်အရေအတွက်(များ)",
			"Description": "ဖော်ပြချက်",
			"Edit Promotion": "ပရိုမိုးရှင်း တည်းဖြတ်ရန်",
			"End Date": "ပြီးဆုံးသည့် ရက်စွဲ",
			"Promotion Image": "ပရိုမိုးရှင်း ဓာတ်ပုံ",
			"Promotion Type": "ပရိုမိုးရှင်း အမျိုးအစား",
			"Promotional Text": "ပရိုမိုးရှင်း စာသား",
			"Save": "သိမ်းရန်",
			"Start Date": "စတင်သည့်ရက်စွဲ",
			"Select Products": "ထုတ်ကုန်ရွေးပါ",
			"Max Purchase Amount": "အများဆုံးဝယ်ယူမည့်ပမာဏ",
			"Coupon Code": "ကူပွန်ကုဒ်",
			"Amount": "ပမာဏ",
			"Percentage": "ရာခိုင်နှုန်"
		},
		"list": {
			"Coupon Count": "ကူပွန်အရေအတွက်",
			"End Date": "ပြီးဆုံးသည့်ရက်စွဲ",
			"Name": "အမည်",
			"Promotion": "ပရိုမိုးရှင်း",
			"Promotion List": "ပရိုမိုးရှင်း စာရင်း",
			"Start Date": "စတင်သည့်ရက်စွဲ",
			"Type": "အမျိုးအစား"
		},
		"log": {
			"Promotion Log": "အရောင်းမြှင့်တင်ရေးမှတ်တမ်",
			"Date": "နေ့စွဲ",
			"Customer Name": "ဖောက်သည်အမည်",
			"Promotion": "အရောင်းမြှင့်တင်ရေ",
			"Received Amount": "လက်ခံရရှိသည့်ပမာဏ"
		}
	},
	"reward": {
		"list": {
			"Reward List": "",
			"Name": "",
			"Description": "",
			"Points": "",
			"Contact": ""
		},
		"edit": {
			"Reward Edit": "",
			"Reward Add": "",
			"Image": "",
			"Name": "",
			"Reward By": "",
			"Location": "",
			"Points": "",
			"Mobile Number": "",
			"Email": "",
			"Terms & Conditions": "",
			"ADD": "",
			"About us": "",
			"Add your Terms & Conditions": ""
		}
	},
	"recaptcha error": "ဖမ်းယူမှု အမှား",
	"recaptchaToken is required": "ဖမ်းယူမှု တိုကင်လိုအပ်ပါသည်",
	"role": {
		"edit": {
			"Add Role": "ကဏ္ဍ ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Edit Role": "ကဏ္ဍ တည်းဖြတ်ရန်",
			"Name": "အမည်",
			"Role Permission": "ကဏ္ဍ ခွင့်ပြုချက်",
			"Save": "သိမ်းရန်"
		},
		"list": {
			"Name": "အမည်",
			"Permission": "ခွင့်ပြုချက်",
			"Role": "ကဏ္ဍ",
			"Role List": "ကဏ္ဍ စာရင်း",
			"data-table-title": "Role List"
		}
	},
	"role-permission": {
		"edit": {
			"Ad Collection Module": "ကြော်ငြာစုစည်းမှု မော်ကျူး",
			"Add Role Permission": "ကဏ္ဍခွင့်ပြုချက် ပေါင်းထည့်ရန်",
			"Advertising Module": "ကြော်ငြာမော်ကျူး",
			"Back": "နောက်သို့",
			"Campaign Module": "ကမ်ပိန်းမော်ကျူး",
			"Create": "ဖန်တီးရန်",
			"Day(s)": "ရက်(များ)",
			"Delete": "ဖျက်ရန်",
			"Device Module": "ကြော်ငြာဘုတ် မော်ကျူး",
			"Edit Role Permission": "ကဏ္ဍခွင့်ပြုချက် တည်းဖြတ်ရန်",
			"Expiry Date": "သက်တမ်းကုန်ဆုံးသည့် ရက်စွဲ",
			"List": "စာရင်း",
			"Name": "အမည်",
			"Retrieve": "ထုတ်ရန်",
			"Save": "သိမ်းရန်",
			"Update": "မွမ်းမံရန်",
			"E-Commerce Module": "E-Commerce Module",
			"CRM Module": "CRM Module",
			"Level-1": "Level-1",
			"Level-2": "Level-2",
			"Level-3": "Level-3"
		},
		"E-Commerce Module": {
			"Products": "Products",
			"Product Category": "Product Category",
			"Promotion": "Promotion",
			"Promotion Log": "Promotion Log",
			"Generate Gift Cards": "Generate Gift Cards",
			"Gift Card Log": "Gift Card Log",
			"Rewards": "Rewards"
		},
		"CRM Module": {
			"Email Log": "Email Log",
			"Email Template": "Email Template",
			"Loyalty Club": "Loyalty Club",
			"Enquiry": "Enquiry"
		},
		"list": {
			"Name": "အမည်",
			"Role Permission": "ကဏ္ဍ ခွင့်ပြုချက်",
			"Role Permission List": "ကဏ္ဍခွင့်ပြုချက် စာရင်း",
			"data-table-title": "Role Permission List"
		}
	},
	"searching": {
		"Ad Collection": "ကြော်ငြာစုစည်းမှု",
		"Ads": "ကြော်ငြာများ",
		"Apply": "သုံးရန်",
		"Campaign": "ကမ်ပိန်း",
		"Clear filter": "စစ်ထုတ်မှုကို ပယ်ဖျက်ရန်",
		"Collections": "စုစည်းမှုများ",
		"Detail": "အသေးစိတ်အချက်အလက်",
		"Device": "ကြော်ငြာဘုတ်များ",
		"Duration": "ကြာချိန်",
		"Duration(s)": "ကြာချိန်(များ)",
		"End Date": "အဆုံးသတ်သည့်ရက်စွဲ",
		"Filter by": "စစ်ထုတ်သူ",
		"First name": "ပထမအမည်",
		"ID": "အိုင်ဒီ",
		"Items": "အကြောင်းအရာများ",
		"Last name": "နောက်ဆုံးအမည်",
		"Name": "အမည်",
		"No results found for": "ရှာမတွေ့ပါ",
		"Position": "တည်နေရာ အနေအထား",
		"Results for": "တွေ့ရှိချက်",
		"Start Date": "စတင်သည့် ရက်စွဲ",
		"Status": "စတေးတပ်",
		"Type": "အမျိုးအစား",
		"User": "အသုံးပြုသူ"
	},
	"company": {
		"list": {
			"Company": "Company",
			"data-table-title": "company list",
			"Company Logo": "Company Logo",
			"Name": "name",
			"Phone": "Phone",
			"Website": "Website"
		},
		"create": {
			"Save": "Save",
			"Back": "Back",
			"Edit Company": "Edit Company",
			"Create Company": "Create Company",
			"Business Name is required": "Business Name is required",
			"Company Type is required": "Company Type is required",
			"Business phone is required": "Business phone is required",
			"Web Site is required": "Web Site is required",
			"Zipcode is required": "Zipcode is required",
			"Business Address is required": "Business Address is required",
			"Business Logo": "Business Logo",
			"Business Name": "Business Name",
			"Company Type": "Company Type",
			"Business phone": "Business phone",
			"Web Site": "Web Site",
			"Zipcode": "Zipcode",
			"Business Address": "Business Address"
		},
		"orderlist": {
			"Date": "",
			"Customer Name": "",
			"Product": "",
			"Qty": "",
			"Total Price": "",
			"Pay Status": "",
			"Finish State": ""
		},
		"createChildCompanyAndStripe": {
			"Create Company Stripe Account": "",
			"Edit Company Stripe Account": "",
			"Name": "",
			"Birthday": "",
			"Email": "",
			"SSN": "",
			"Account Number": "",
			"Routing Number": "",
			"Company Name": "",
			"MCC": "",
			"City": "",
			"Address Line 1": "",
			"Postal code": "",
			"State": "",
			"Phone": ""
		}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "ကြော်ငြာပုံစံအမျိုးအစား",
			"Advertising Setting": "ကြော်ငြာ ဆက်တင်",
			"Day(s) ": "နေ့ရက်(များ)",
			"Duration (s)": "ကြာချိန် (များ)",
			"Expiry Date": "ကုန်ဆုံးမည့် ရက်စွဲ",
			"Expires in": "",
			"Name": "အမည်",
			"Save": "သိမ်းရန်",
			"Second(s)": "စက္ကန့်(များ)",
			"Subscription Plan": "စာရင်းသွင်း အစီအစဉ်",
			"day": "နေ့ရက်(များ)"
		},
		"general": {
			"Default Language": "မူလ ဘာသာစကား",
			"Generel Settings": "အထွေထွေ ဆက်တင်",
			"Name": "အမည်",
			"New User Default Role": "အသုံးပြုသူ မူရင်းကဏ္ဍအသစ်",
			"Save": "သိမ်းရန်"
		},
		"product": {
			"Name": "အမည်",
			"Number": "နံပါတ်",
			"Price": "စျေးနှုန်း",
			"Product Name": "ကုန်ပစ္စည်းအမည်",
			"Product Number": "ကုန်ပစ္စည်းနံပါတ်",
			"Product Price": "ကုန်ပစ္စည်းစျေးနှုန်း",
			"Product Service": "ကုန်ပစ္စည်း ဆားဗစ်စ်",
			"Product Text": "ကုန်ပစ္စည်းစာသား",
			"Product Type": "ကုန်ပစ္စည်းအမျိုးအစား",
			"Save": "သိမ်းရန်",
			"Service": "ဆားဗစ်စ်",
			"Subscription Lists": "အသင်းဝင်စာရင်းများ",
			"Subscription Name": "အသင်းဝင်အမည်",
			"Subscription Type": "အသင်းဝင်အမျိုးအစား",
			"Usage": "အသုံးပြုမှု"
		}
	},
	"sign": {
		"forget": {
			"Authentication required": "Authentication required",
			"We sent": "We've sent a One Time Password to the email",
			"Please enter it below": "Please enter it below.",
			"OTP will expired in": "OTP will expired in",
			"OTP has expired": "OTP has expired.",
			"Resend OTP": "Resend OTPs",
			"Enter OTP": "Enter OTP",
			"Required": "Required",
			"Passcode is required": "Passcode is required",
			"Email is required": "Email is required",
			"Please select your account type": "Please select your account type",
			"Please type your email or mobile": "Please type your email or mobile",
			"mdi-reply": "mdi-reply",
			"Reset your Password": "Reset your Password",
			"Reset my password": "Reset my password",
			"Password Changed": "Password Changed",
			"You can now login with your new password": "You can now login with your new password",
			"Login": "Login",
			"Old Password": "Old Password",
			"New Password": "New Password",
			"Confirm Password": "Confirm Password",
			"Forgot Password": "Forgot Password",
			"Email or mobile": "Email or mobile",
			"Enter the email address or mobile phone number": "Enter the email address or mobile phone number associated with your Adsmodo account.",
			"Continue": "Continue",
			"Reset Password": "လျို့ဝှက်နံပါတ်ကို ပြန်ထုတ်ရန်",
			"Sign In": "ဝင်ရောက်ရန်",
			"abbr": {
				"send": "ပေးပို့ရန်"
			}
		},
		"sign-in": {
			"Create an account": "အကောင့်ဖန်တီးလိုရန်",
			"Forgot-Password": "လျို့ဝှက်နံပါတ် မေ့နေပါသလား",
			"Loyalty Member Sign In": "အမာခံအသင်းဝင် ၀င်ရောက်ရန်",
			"Sign In": "ဝင်ရောက်ရန်",
			"abbr": {
				"send": "ပေးပို့ရန်"
			},
			"Already have an account?": ""
		},
		"sign-up": {
			"Quick Sign Up": "",
			"Complete Sign Up": "",
			"Address:": "လိပ်စာ",
			"Ads My biz": "ကျွန်ုပ် ကြော်ငြာများ",
			"All of Above": "အားလုံး",
			"Business Address": "လုပ်ငန်းလိပ်စာ",
			"Business Name": "လုပ်ငန်းအမည်",
			"Business phone": "လုပ်ငန်းဖုန်းနံပါတ်",
			"Card Number:": "ကဒ်နံပါတ်:",
			"Company Type": "ကုမ္ပဏီ အမျိုးအစား",
			"Confirm": "သေချာသည်",
			"Confirm Information": "သတင်းအချက်အလက် သေချာပါသည်",
			"Confirm password": "လျို့ဝှက်နံပါတ် သေချာပါသည်",
			"Create user account": "အသုံးပြုသူအကောင့် ဖန်တီးရန်",
			"Email": "အီးမေးလ်",
			"Email:": "အီးမေးလ်:",
			"Extra $": "အပို $",
			"First Name": "ပထမအမည်",
			"Help others": "အခြားသူများကိုကူညီရန်",
			"Last Name:": "နောက်ဆုံးအမည်:",
			"Next": "နောက်",
			"Password": "လျို့ဝှက်နံပါတ်",
			"Payment Options": "ငွေပေးချေရန် ရွေးချယ်မှုများ",
			"Phone": "ဖုန်း",
			"Prev": "အရင်",
			"Price:": "စျေးနှုန်း:",
			"Price(month):": "စျေးနှုန်း(လစဥ်):",
			"Product:": "ကုန်ပစ္စည်း",
			"Quantity": "အရေအတွက်",
			"Select Adsmodo Business Assistant": "Adsmodo ၏ Business Assistant ကိုရွေးချယ်ပါ",
			"Subscription Plan": "အသင်းဝင်ခြင်း အစီအစဉ်",
			"Tell us about your goal": "သင့်ရဲ့ ရည်မှန်းချက်ကိုပြောပါ",
			"Web Site": "ဝက်ဘ်ဆိုက်ဒ်",
			"Zipcode": "အက္ခရာသင်္ကေတကုဒ်",
			"abbr": {
				"agree": "ဖတ်ပြီး သဘောတူပါသည်",
				"business info": "သင်၏ လုပ်ငန်းအကြောင်းအရာ",
				"web tutorial": "Terms and conditions\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies.\n\t\t\t\t\t\t\t\t\t\t\t\tAt w3schools.com you will learn how to make a website. We offer free tutorials in all web development technologies."
			},
			"last Name": "နောက်ဆုံးအမည်"
		}
	},
	"signin verify error": "လျို့ဝှက်နံပါတ် အထောက်အထားအမှား",
	"signup password error": "လျို့ဝှက်နံပါတ် ဝင်ရောက်ခြင်းမှားယွင်းမှု",
	"single": {
		"component": {
			"Contact info": "အဆက်အသွယ် သတင်းအချက်အလက်",
			"Email address": "အီးမေးလ် လိပ်စာ",
			"Input your contact info to get coupons and subscribe our service": "ကျွန်ုပ်တို့ ဆားဗစ်စ်ကို အသင်းဝင်၍ကူပွန်များရရှိရန် သင့်၏ အဆက်အသွယ်ကိုထည့်သွင်းပါ",
			"Input your contact info to get coupons!": "ကူပွန်များရရှိရန် သင့်၏ အဆက်အသွယ်ကိုထည့်သွင်းပါ!",
			"Phone number": "ဖုန်းနံပါတ်",
			"Submit": "တင်သွင်းရန်",
			"abbr": {
				"get contact info": "အဆက်အသွယ်သတင်းအချက်အလက်ကိုရယူရန်"
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "",
			"Are you sure to deactivate this plan?": "",
			"Your plan is now Active!": "",
			"Your plan is now Inactive!": "",
			"Active": "",
			"Inactive": "",
			"Add Subscription Plan": "အသင်းဝင်အစီအစဥ် ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Edit Subscription Plan": "အသင်းဝင်အစီအစဥ် ပြုပြင်ရန်",
			"Number": "နံပါတ်",
			"Price": "စျေးနှုန်း",
			"Save": "သိမ်းရန်",
			"Service": "ဆားဗစ်စ်",
			"Subscription Name": "အသင်းဝင်အမည်",
			"Subscription Plan": "အသင်းဝင် အစီအစဥ်",
			"Subscription Type": "အသင်းဝင် အမျိုးအစား",
			"Usage": "အသုံးပြုမှု",
			"Description": ""
		},
		"list": {
			"Price": "စျေးနှုန်း",
			"Subscription Plan": "အသင်းဝင် အစီအစဥ်",
			"Subscription Plan List": "အသင်းဝင် အစီအစဥ်စာရင်း",
			"Subscription Plan Type": "အသင်းဝင် အစီအစဥ်အမျိုးအစား"
		}
	},
	"user": {
		"account": {
			"Account Type": "အကောင့်အမျိုးအစား",
			"Subscription Plan Type": "",
			"Ad Address": "ကြော်ငြာလိပ်စာ",
			"Basic Information": "အခြေခံသတင်းအချက်အလက်",
			"Save Credit Card": "ခရက်ဒစ်ကဒ် သိမ်းရန်",
			"Change Credit Card": "",
			"Current Credit Card": "",
			"Saved Payment Methods": "",
			"Bind Status": "စတေးတပ်တွဲရန်",
			"Bind credit card": "ခရက်ဒစ်ကဒ် တွဲရန်",
			"Business Address": "လုပ်ငန်းလိပ်စာ",
			"Business Information": "လုပ်ငန်းသတင်းအချက်အလက်",
			"Business Name": "လုပ်ငန်းအမည်",
			"Business Phone": "လုပ်ငန်းဖုန်းနံပါတ်",
			"Cancel": "ဖျက်သိမ်းရန်",
			"Cancel Subscription": "အသင်းဝင်ခြင်း ဖျက်သိမ်းရန်",
			"Cell Phone": "ဆဲလ်ဖုန်းနံပါတ်",
			"Company Type": "ကုမ္ပဏီအမျိုးအစား",
			"Email": "အီးမေးလ်",
			"Free": "လွတ်နေသည်",
			"Hi": "မင်္ဂလာပါ",
			"Info": "သတင်းအချက်အလက်",
			"Let me think": "စဥ်းစားမည်",
			"Name": "အမည်",
			"Password": "လျို့ဝှက်နံပါတ်",
			"Product Type": "ကုန်ပစ္စည်းအမျိုးအစား",
			"Profile Image": "ကိုယ်ပိုင် ဓာတ်ပုံ",
			"Redeem": "ရယူရန်",
			"Rewards": "ဆုလက်ဆောင်များ",
			"Save": "သိမ်းရန်",
			"Skip Tour": "ခရီးထွက်ခြင်း",
			"Start Create Ads": "ကြော်ငြာများ စတင်ဖန်တီးပါ",
			"Subscription Plan": "အသင်းဝင်အစီအစဥ်",
			"Web Site": "ဝက်ဘ်ဆိုဒ်",
			"Zipcode/Postalcode": "အက္ခရာသင်္ကေတကုဒ်",
			"abbr": {
				"ad collection intro": "An ad collection defines which your ads included in and\n                      each ad collection can include multiple ads.",
				"ad intro": "An ad can have a different image,text,contact me\n                      button,link or video and also the ad image can contain\n                      different types such as 3D,AR or VR.",
				"campaign intro": "                      A campaign is a grouping of ad collection which are\n                      organized by the same business objective. Each campaign\n                      has an objective that must be valid across the ad\n                      collection within that campaign.",
				"cancel subscribe check": "အသင်းဝင်ခြင်းမှနှုတ်ထွက်ရန်သေချာပါသလား？",
				"cancel subscribe info": "အသင်းဝင်မှနှုတ်ထွက်ပြီးလျှင်ဤဆိုက်ဒ်၏ ခံစားခွင့်များအားလုံးဆုံးရှုံးပါလိမ့်မည်",
				"privilege info": "အသင်းဝင်ပြီးလျှင်အောက်ပါခံစားခွင့်များ ရရှိပါမည်",
				"upgrade check": " သင်၏အကောင့်ကိုမွမ်းမံရန်သေချာပါသလား？",
				"welcome": "Adsmodoမှ ကြိုဆိုပါသည်",
				"yes": "သေချာပါသည်!"
			},
			"Monthly": "",
			"Activated": "",
			"Current Plan": "",
			"Change Plan": "",
			"Upgrade Now": "",
			"Last 4 digit": "",
			"Expiring": ""
		},
		"edit": {
			"Add User": "အသုံးပြုသူ ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Can adding ads": "ကြော်ငြာများ ပေါင်းထည့်နိုင်ပါသည်",
			"Edit User": "အသုံးပြုသူ တည်းဖြတ်ရန်",
			"Email": "အီးမေးလ်",
			"Is Admin": "အက်ဒမင်",
			"Mobile": "မိုဘိုင်းလ်",
			"Name": "အမည်",
			"Password": "လျို့ဝှက်နံပါတ်",
			"Save": "သိမ်းရန်",
			"User Role": "အသုံးပြုသူကဏ္ဍ",
			"team": "Team"
		},
		"list": {
			"Email": "အီးမေး",
			"Is Admin": "အက်ဒမင်",
			"Mobile": "မိုဘိုင်းလ်",
			"Name": "အမည်",
			"Role": "ကဏ္ဍ",
			"Users": "အသုံးပြုသူများ",
			"Users List": "အသုံးပြုသူ စာရင်း",
			"data-table-title": "User List"
		}
	},
	"user permission get error": "အသုံးပြုသူခွင့်ပြုချက်ရယူမှု အမှား",
	"user-goal": {
		"edit": {
			"Add User Goal": "အသုံးပြုသူ ရည်ရွယ်ချက်ပေါင်းထည့်ရန်",
			"Back": "နောက်သို့",
			"Edit User Goal": "အသုံးပြုသူရည်ရွယ်ချက် တည်းဖြတ်ရန်",
			"Name": "အမည်",
			"Save": "သိမ်းရန်",
			"User Goal": "အသုံးပြုသူ ရည်မှန်းချက်"
		},
		"list": {
			"Name": "အမည်",
			"User Goal": "အသုံးပြုသူ ရည်မှန်းချက်",
			"User Goal List": "အသုံးပြုသူ ရည်မှန်းချက်စာရင်း"
		}
	},
	"validation errors": "သက်သေအထောက်အထားအမှားများ",
	"View More Cases": "ထပ်မံကြည့်ရှုရန်ရန်",
	"Contact Us": "ဆက်သွယ်ရန်",
	"Ecommerce": "စီးပွားရေး",
	"device-location-edit": "Device Location Edit",
	"location": {
		"add": {
			"title": "Device Location Add"
		},
		"list": {
			"Device Location": "Device Location"
		},
		"edit": {
			"title": "Device location Edit"
		}
	},
	"Login is abnormal, please try again later.": "Login is abnormal, please try again later.",
	"header": {
		"Ecommerce": "Ecommerce",
		"Pricing": "Pricing",
		"Get Started": "Get Started",
		"Sign Out": "Sign Out"
	},
	"footer": {
		"copy_right": "Copyright 2018-2019. All rights reserved."
	},
	"security-tab": {
		"mdi-tune": "mdi-tune",
		"Choose": "Choose",
		"Set up": "Set up",
		"Verify": "Verify",
		"Finished": "Finished",
		"Two-factor Authentication": "Two-factor Authentication",
		"Enabled tasks": "Enabled tasks",
		"You can choose a task": "You can choose a task form the list below after you've login.",
		"Text message": "Text message",
		"mdi-message-text": "mdi-message-text",
		"Verification codes are sent by text message": "Verification codes are sent by text message",
		"mdi-shield-lock": "mdi-shield-lock",
		"Authenticator App": "Authenticator App",
		"Use the Authenticator app": "Use the Authenticator app to get verification codes, even when your phone is offline.",
		"How would you": "How would you like to receive your authentication codes?",
		"After entering your password": "After entering your password, you’ll be asked for a second verification step.",
		"Verification codes": "Verification codes are sent by text message.(Required a verified mobile phone number)",
		"SET UP": "SET UP",
		"Cancel": "Cancel",
		"Let's set up": "Let's set up your phone for verification",
		"What phone number": "What phone number do you want to use?",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"mdi-information": "mdi-information",
		"Change": "Change",
		"Two Factor Setup": "Two Factor Setup",
		"Take these few steps to enable": "Take these few steps to enable two-factor authentication and make your account more secure.",
		"Install an authenticator app": "Install an authenticator app on your mobile device.",
		"Recommended options: Google Authenticator": "Recommended options: Google Authenticator,Authy,Microsoft Authenticator",
		"Scan QR code with the authenticator": "Scan QR code with the authenticator.",
		"Enter the 2-step authentication": "Enter the 2-step authentication code provided by your app.",
		"Continue": "Continue",
		"I'm ready": "I'm ready",
		"Back": "Back",
		"Verify your number": "Verify your number",
		"We just sent a text message": "We just sent a text message with a verification code to",
		"Didn't get it? Resend code": "Didn't get it? Resend code.",
		"Scan this QR code": "Scan this QR code with your mobile app.",
		"Then enter the six digit code": "Then enter the six digit code provided by the app:",
		"Can't scan the code": "Can't scan the code?",
		"Great": "Great!",
		"Two-factor Authentication has been": "Two-factor Authentication has been enabled for your account",
		"All future logins will now require": "All future logins will now require you to enter a code supplied by your mobile message or Authenticator App.",
		"Finish": "Finish"
	},
	"reward-list": {
		"Used": "Used",
		"Adsmodo": "Adsmodo",
		"$": "$",
		"OFF YOUR PURCHASE": "OFF YOUR PURCHASE",
		"Gift Code:": "Gift Code:",
		"Valid till": "Valid till"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "Valid from 15 Oct 2019 to 15 Jan 2020",
		"points": "points",
		"mdi-check-bold": "mdi-check-bold",
		"mdi-phone": "mdi-phone",
		"mdi-email": "mdi-email",
		"Report Reward issue to Adsmodo": "Report Reward issue to Adsmodo"
	},
	"return-orders": {
		"Choose items to return": "Choose items to return",
		"Ordered on": "Ordered on",
		"Order Number": "Order Number",
		"Continue": "Continue",
		"Not returnable": "Not returnable",
		"Returnable untill": "Returnable untill",
		"Choose refund method": "Choose refund method",
		"Returning": "Returning",
		"Reason": "Reason",
		"We'll process your refund": "We'll process your refund to Visa ending 1234.",
		"Estimated refund timing": "Estimated refund timing: 2-3 business days after we receive the item.",
		"How do you want to send": "How do you want to send it back?",
		"$0": "$0",
		"Our deliveries will pickup": "Our deliveries will pickup your return package on the next business day.Printer required - you can print the label any time from Your Account or from the email we'll send you.",
		"Refund summary": "Refund summary",
		"Refund Subtotal": "Refund Subtotal:",
		"Shipping fee": "Shipping fee:",
		"Total refund": "Total refund:",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"Your label is ready to print": "Your label is ready to print",
		"Securely pack the items": "Securely pack the items to be returned. Send all the items back to Adsmodo by",
		"mdi-cloud-upload": "mdi-cloud-upload",
		"Print label": "Print label"
	},
	"products-list": {
		"Adsmodo": "Adsmodo",
		"Out of stock": "Out of stock!",
		"Available stock": "Available stock"
	},
	"common-list": {
		"no-data": "റെക്കോർഡുകൾ കണ്ടെത്തിയില്ല",
		"reset": "പുന .സജ്ജമാക്കുക",
		"warning": "മുന്നറിയിപ്പ്",
		"delete-item-warning": "ഈ ഇനം ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ? ഇല്ലാതാക്കൽ പ്രവർത്തനം തിരികെ കൊണ്ടുപോകാൻ കഴിയില്ല",
		"delete-items-warning": "ഈ ഇനങ്ങൾ ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ? ഇല്ലാതാക്കൽ പ്രവർത്തനം തിരികെ കൊണ്ടുപോകാൻ കഴിയില്ല",
		"delete-yes": "അതെ",
		"delete-no": "റദ്ദാക്കുക",
		"operations": "പ്രവർത്തനം"
	},
	"member-home": {
		"Special offers for you": "Special offers for you",
		"A confirmation email has been sent to": "A confirmation email has been sent to",
		"Click on the confirmation link in the email to verify your account.": "Click on the confirmation link in the email to verify your account",
		"Points": "Points",
		"My loyalty club": "My loyalty club",
		"Recommended loyalty club": "Recommended loyalty club",
		"More loyalty club": "More loyalty club",
		"More than": "More than",
		"clicks on ads": "clicks on ads",
		"Since this is your first login, please click": "Since this is your first login, please click this message to change your password",
		"There are currently": "There are currently",
		"promotional products": "promotional products",
		"Promotion code list": "Promotion code list",
		"Loyalty club name": "Loyalty club name",
		"Promotion code": "Promotion code",
		"Close": "Close",
		"View promo code": "View promo code"
	}
}
;