module.exports = {
	"Account or password was incorrect!": "帳戶或密碼不正確!",
	"New order submitted": "新訂單提交。",
	"Order Updated": "訂單更新。",
	"Order Number": "訂單編號：",
	"Table Number": "桌號：",
	"Clear Search": "清除搜尋",
	"Inmydevice": "我的裝置",
	"InOthersdevice": "他人裝置",
	"Campaign Approval": "推廣審核",
	"Please approve this campaign": "請審核此推廣",
	"Can't get your device info": "無法獲取您的裝置訊息",
	"Device does not exist or has been deleted, please reselect": "裝置不存在或已被刪除，請重新選擇",
	"Duplicate current plan": "複製當前計劃",
	"Email and passcode is required!": "電子郵件和密碼為必填！",
	"Email or password is incorrect": "電子郵件或密碼不正確",
	"Find device error": "找尋裝置錯誤",
	"Find user error": "找尋用戶錯誤",
	"Get current customer plan is required": "需要獲取當前客戶計劃",
	"Hello": "你好",
	"Id is required!": "必須輸入ID！",
	"Inconsistent password": "密碼不一致",
	"Member does not exist, Please try again later!": "成員不存在，請稍後再試！",
	"No id": "沒有ID",
	"Please enter the device name": "請輸入裝置名稱",
	"Please enter your email": "請輸入您的電子郵件",
	"Please enter your mobile": "請輸入您的手機號碼",
	"Please select an item first": "請先選擇一個項目",
	"Signin error": "登入錯誤",
	"New Campaign is created using your device": "使用您的裝置以建立新推廣活動。",
	"adName": "廣告名稱",
	"collectionName": "廣告類別名稱",
	"campaignName": "推廣名稱",
	"Ad": "廣告",
	"Collection": "類別",
	"Campaign": "推廣",
	"DraggingCardInfo1": "您可按著「<span class='mdi mdi-drag'></span>」托動所選取的廣告來變更播放順序.",
	"DraggingCardInfo2": "變更播放順序後請務必點擊「保存」按鈕.",
	"LinePayInfo1": "請先登入 <a target='_blank' href='https://pay.line.me/portal/tw/auth/login'>https://pay.line.me/portal/tw/auth/login</a> 查詢相關設定值。",
	"LinePayInfo2": "於管理連接金鑰處，記下 Channel ID 以及 Channel Secret Key並且填入下方。",
	"LinePayInfo3": "於管理付款伺服器IP處，填入35.236.171.52 。",
	"expertModeInfo": "當啟用專家模式，E-commerce和CRM模式將可使用",
	"expertModeInfo2": "專家模式功能僅供有效的付費會員使用",
	"Company": "公司",
	"SimpleAd": "簡易廣告",
	"Default": "預設模板",
	"Company Message": "信件主題",
	"My ads in device": "我在設備中的廣告",
	"Other ads in device": "設備中的其他廣告",
	"AdsInfoCampaignInfo": "廣告信息和活動信息",
	"Campaign Info": "活動信息",
	"Advertising Info": "廣告信息",
	"pending": "審查中",
	"active": "等待審核",
	"Add New Ads for": "添加新廣告",
	"Create News Ticker": "創建新聞代碼",
	"Tel": "Tel",
	"SmsLimitReach": "商家當日免費發送簡訊量已使用完畢。",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "帳戶審核中，請稍後",
		"Your account does not have a subscription. Please subscribe to post an ad": "您的帳戶沒有訂閱方案。請訂閱以發布廣告"
	},
	"Swal": {
		"Please select only one video": "請只選擇一個視頻",
		"Please select at least one Ads Asset": "請選擇至少一種廣告資產",
		"upgradeplan": "請升級您當前的計劃",
		"exceededmaximum12": "您的計劃已超過最多 12 個廣告系列。",
		"Next Page": "下一頁",
		"Pleasecreaterolefirst": "請先建立用戶角色！",
		"Pleasecreateroleandteamfirst": "請先建立角色和團隊！",
		"Please update company information": "為了能夠建立活動和購買裝置，請更新公司訊息。",
		"PleaseBuyDevice": "為了能夠建立活動，請至少購買一台裝置。",
		"DetailCompanyInfo": "（公司名稱，公司類型，電話號碼和地址）",
		"blockerText": "Adsmodo透過展示線上廣告讓訪客了解您",
		"blockerText2": "請禁用廣告攔截器以利顯示最佳優化頁面。",
		"member_sign_up": {
			"Please register to be part of this club": "請註冊以成為該俱樂部的成員"
		},
		"term": {
			"Currently there is no defined terms and conditions yet": "目前尚無明確的用戶條款及條件",
			"Sorry": "抱歉"
		},
		"More than available stock": "數量超過可用庫存",
		"Are you sure?": "你確定嗎？",
		"You will not be able to recover this!": "你將無法還原！",
		"Yes, delete it!": "是的，刪除它！",
		"No, cancel plx!": "不，請取消",
		"You must register your company first!": "你必須先註冊你的公司！",
		"Exceed the maximum number of Ad": "超過廣告數量上限",
		"Upgrade your account,and create more Advertising": "升級您的帳戶，以建立更多廣告",
		"Out of stock": "缺貨",
		"Please select the variant!": "請選擇產品種類！",
		"Invalid coupon code": "無效優惠券代碼",
		"Invalid coupon code for this product!": "此產品的優惠券代碼無效！",
		"Valid coupon code": "有效優惠券代碼",
		"Add success": "增加成功",
		"Add success We will": "增加成功,我們會將密碼寄至您的電子郵件。",
		"Already in the cart": "已經在購物車中",
		"Amount should be more than zero": "金額必須大於零！",
		"Bind Success": "綁定成功",
		"Buy device success": "購買裝置成功",
		"Device type is required": "裝置類型為必填項",
		"Purchase type is required": "購買類型為必填項",
		"Position is required": "位置為必填項",
		"Please enter the device number": "請輸入裝置編號",
		"Edit success": "編輯成功",
		"End date must be greater than start date!": "結束日期必須晚於開始日期！",
		"Please select Device!": "請選擇一個裝置!",
		"Available maximum file size is 5MB": "可用的最大文件大小為 image 5MB & video 30MB。",
		"Please select a valid image": "請選擇一個有效的圖片",
		"Please select a valid video": "請選擇一個有效的影片",
		"Session time out.Please log in again": "工作階段超時。請重新登入",
		"Something went wrong": "出錯了",
		"Subscription success": "訂閱成功",
		"Successfully approved": "已獲批准",
		"Successfully generated": "產生成功",
		"Your profile has been successfully updated": "您的個人資料已成功更新",
		"account": {
			"Cancel Subscription success": "取消訂閱成功",
			"Cancel subscription error": "取消訂閱錯誤",
			"The current user does not support this feature at this time": "目前用戶不支援此功能。",
			"This operation is not supported at this time": "目前不支援此操作"
		},
		"ad": {
			"Ad created successfully": "廣告建立成功",
			"Ad edited successfully": "廣告編輯成功",
			"Cancel": "取消",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "恭喜！您已經建立了第一個廣告。您想繼續嗎？"
			},
			"Continue": "繼續",
			"Product category is needed!": "需要產品類別！",
			"Product name is needed!": "需要產品名稱！",
			"success info": "成功資訊"
		},
		"sign": {
			"Agreement Required": "需要同意",
			"Please agree the Terms & Condtions": "請同意條款和條件",
			"Please improve the information": "請改進資訊。",
			"Business address invalid, please enter again": "商家地址無效，請重新輸入。",
			"Company type require!": "公司類型要求！",
			"Invalid recaptcha token": "無效的驗證碼",
			"Password doesnot match!": "密碼不相同！",
			"Please post your ad now": "請立即發布您的廣告。",
			"Purchase fail": "購買失敗",
			"Purchase success": "購買成功",
			"The Passcode have been send success": "密碼已發送成功",
			"The passcode have been send successfully": "密碼已成功發送",
			"The password and confirm password not the same": "密碼和確認密碼不相同",
			"Sign Up success": "註冊成功",
			"Sign Up fail": "註冊失敗",
			"Please log in with your email": "請使用您的Email登入",
			"Landing Page fail": "提交失敗",
			"Landing Page Success": "提交成功"
		},
		"You need to set up your payment method to purchase devices": "您需要設置付款方式才能購買裝置",
		"Payment method is required": "需要付款方式"
	},
	"Sign": {
		"MerchantLogin": "商家登入",
		"MemberLogin": "會員登入",
		"Password": "密碼",
		"Email": "請輸入電子信箱",
		"Name": "姓名",
		"Send": "送出",
		"Sign In": "登入"
	},
	"The ad does not exist or has been deleted": "廣告不存在或已被刪除",
	"The device has been bound, Please untie it first": {
		"": "裝置已被綁定，請先取消綁定"
	},
	"The device is not exist": {
		"": "裝置不存在"
	},
	"The email is already registered!": "該電子郵件已被註冊！",
	"The item does not exist or has been deleted": "項目不存在或已被刪除",
	"The mobile number is already registered!": "手機號碼已被註冊！",
	"The user does not exist or has been deleted": "用戶不存在或已被刪除",
	"Update plan error": "更新計劃錯誤",
	"User does not exist, Please try again later!": "用戶不存在，請稍後再試！",
	"You do not have permission": "您沒有權限",
	"ad-template": {
		"edit": {
			"Add Builder Template": "新增廣告模板",
			"Back": "返回",
			"Builder Template": "廣告模板",
			"Edit Builder Template": "編輯廣告模板",
			"Name": "名字",
			"Save": "保存",
			"Status": "狀態",
			"Type": "種類"
		},
		"list": {
			"Builder Template": "廣告模板",
			"Builder Template List": "廣告模板清單",
			"Name": "名字",
			"Status": "狀態",
			"Type": "種類",
			"data-table-title": "廣告模板清單"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "廣告交易",
			"AD Transaction List": "廣告交易清單",
			"Ads": "廣告",
			"Collection": "分類",
			"Device": "裝置",
			"Type": "類型",
			"Campaign": "推廣活動",
			"Number": "數量"
		}
	},
	"admin-nav": {
		"Tutorial": "教學",
		"Logout": "登出",
		"Manager": "經理",
		"New Enquiry": "新查詢",
		"Online": "在線",
		"Pending Ad": "待處理廣告",
		"Pending User": "待處理的用戶",
		"Profile": "個人資料",
		"User": "用戶",
		"User Manual": "用戶指南",
		"Select": "選擇"
	},
	"ads": {
		"edit": {
			"Start Time": "開始時間",
			"End Time": "結束時間",
			"3D Effect Link": "3D特效超連結",
			"Ad Template": "廣告模板",
			"Add Advertising": "新增廣告",
			"Additional Discount": "額外折扣",
			"Advanced": "進階選項",
			"Advertising": "廣告",
			"Advertising End Date": "到期日",
			"Approval": "同意",
			"Approved": "已同意",
			"Back": "返回",
			"Duration (s)": "廣告長度(秒)",
			"Edit Advertising": "編輯廣告",
			"Extra Link": "附加連結",
			"Enable QR code": "是否開啟二維碼",
			"Enable": "啟用",
			"Name": "名字",
			"Pending": "審查中",
			"Preview": "預覽",
			"Product Purchase link": "產品購買連結",
			"Product Purchasing": "產品採購",
			"Promotional Text": "促銷文字",
			"Reject": "拒絕",
			"Rejected": "已拒絕",
			"Save": "保存",
			"Second(s)": "秒",
			"Select products for this Ad": "選擇套用此廣告的產品",
			"Status": "狀態",
			"Upgrade": "升級",
			"Video Url": "影片超連結",
			"Warning!": "警告",
			"Recent uploaded files": "最近上傳的文件",
			"Currently, you have no videos": "目前，您沒有上傳影片。點擊以立即建立廣告。",
			"Currently, you have no images": "目前，您的沒有上傳圖片。點擊以立即建立廣告。",
			"Close": "關閉",
			"Cancel": "取消",
			"Confirm": "確認",
			"Enter Name": "請輸入名字",
			"Enter Duration (s)": "請輸入廣告長度(秒)",
			"abbr": {
				"create": "建立廣告",
				"preview": "預覽廣告",
				"warning content": "廣告不足，請更新"
			},
			"qrInfoTitle": "為附加連結生成QrCode，新增到廣告圖片或影片上",
			"qrAlt": "您選擇的廣告資源越多，新增二維碼所需的時間就越長。影片大小需小於50MB的。"
		},
		"list": {
			"Advertising": "廣告",
			"Advertising List": "廣告清單",
			"Approval Status": "審查狀態",
			"Approved": "已同意",
			"Due Date": "到期日",
			"Duration (s)": "廣告長度(秒)",
			"Name": "名字",
			"Pending": "審查中",
			"Products": "商品",
			"Created Date": "建立日期",
			"Status": "狀態",
			"Type": "類別",
			"noMedias": "沒有可預覽媒體"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "AR連結 / 3D連結",
			"Ad Name": "廣告名稱",
			"Ad Template": "廣告模板",
			"All": "全部",
			"Campaign Name": "推廣活動名稱",
			"Cancel": "取消",
			"Collection Name": "類別",
			"Company Type": "公司類別",
			"Contact Me 3D Link": "聯絡我3D連結",
			"Contact Me Video Link": "聯絡我影片連結",
			"Create your own ads": "建立自己的廣告",
			"Device List": "裝置清單",
			"Duration (s)": "廣告長度(秒)",
			"Edit": "編輯",
			"End Date": "結束日期",
			"Finish": "完成",
			"Let me think": "再考慮一下",
			"Maps": "地圖",
			"Name": "名字",
			"Next": "下一步",
			"On The Map": "地圖上",
			"Previous": "上一步",
			"Publish to Device": "發佈給裝置",
			"Publishing Device": "正在發佈給裝置",
			"Reject": "拒絕",
			"Search": "搜尋",
			"Second(s)": "秒",
			"Shop Link": "商店連結",
			"Start Date": "開始日期",
			"Step": "步驟%{num}",
			"Submit": "提交",
			"Success": "成功",
			"Text": "文本",
			"abbr": {
				"another": "建立另一個",
				"created": "廣告已建立",
				"device select": "選擇你要發布到的裝置",
				"introduction": "你可以透過這個網站發布廣告，宣傳自己的產品、餐廳，並選擇撥放廣告的裝置，讓我們開始吧",
				"privilege intro": "在您訂閱後，可以享受以下特惠",
				"start": "開始建立廣告!",
				"step1": "第一步:基本資料",
				"step2": "步驟2:設計廣告",
				"upgrate check": "確定要升級帳號嗎?",
				"wait": "請等待審核",
				"welcome": "歡迎來到艾斯通Adsmodo",
				"yes": "我確定"
			},
			"another": "再建立一個",
			"created": "廣告已建立",
			"introduction": "你可以透過這個網站發布廣告，宣傳自己的產品、餐廳，並選擇想要撥放廣告的裝置",
			"welcome": "歡迎來到Adsmodo"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "主頁",
				"Home Page List": "主頁列表",
				"Title": "標題",
				"Created At": "建立於",
				"Updated At": "更新於"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "新增主頁",
				"Website Link": "網站連結",
				"Company Type List": "公司類型列表",
				"ADVERTISEMENT Content": "廣告內容",
				"About Us": "關於我們",
				"Banner": "橫幅banner",
				"Contact Us": "聯絡我們",
				"ContactInfo": "台灣聯繫資訊",
				"person": "聯絡人",
				"department": "部門",
				"phone": "聯絡電話",
				"email": "電子郵件",
				"Content": "內容",
				"Edit Home Page": "編輯主頁",
				"How to use": "如何使用",
				"Image": "圖片",
				"Introduction": "介紹",
				"Menu Name": "選單名稱",
				"Multiple select": "多種選擇",
				"Project": "專案",
				"SHOPKEEPER Content": "店主介紹",
				"Scenario": "情境",
				"Sub Title": "副標題",
				"Team Member": "團隊人員",
				"Title": "標題",
				"Overall": "總體",
				"Font Color": "字體顏色",
				"White": "白",
				"Black": "黑",
				"left": "左",
				"center": "中",
				"right": "右",
				"Edit": "編輯",
				"Add": "填加",
				"Title Position": "標題位置",
				"Background Transparent": "背景透明",
				"Left/Right Image": "左/右圖像",
				"Left Title": "左標題",
				"Left Content": "左邊的內容",
				"Right Title": "右邊的標題",
				"Right Content": "右邊的內容",
				"Scenario List": "情境列表",
				"Team List": "團隊名單",
				"Website": "網站"
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "編輯情境",
				"Add Scenario": "添加情境",
				"Scenario Image": "情境圖像",
				"Name": "姓名",
				"Description": "描述"
			},
			"list": {
				"Scenario": "情境",
				"Scenario List": "情境列表",
				"Image": "圖片",
				"Name": "姓名",
				"Description": "描述"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "編輯選單",
				"Add Menu": "新增選單",
				"Name": "姓名",
				"Menu Type": "選單類型",
				"Parent Menu": "母選單",
				"Link": "連結",
				"Show On Footer": "在頁尾上顯示",
				"Please enter the name": "請輸入名稱",
				"Please enter the link": "請輸入連結"
			},
			"list": {
				"Menu": "選單",
				"Menu List": "選單列表",
				"Name": "姓名",
				"Type": "類型",
				"Parent": "母",
				"Link": "連結"
			}
		}
	},
	"already registered!": "已經註冊過了！",
	"bcrypt error": "加密錯誤",
	"campaigns": {
		"edit": {
			"Approve Campaign": "核可推廣活動",
			"Ad Collection": "廣告類別",
			"Add Campaign": "新增推廣活動",
			"All": "全部",
			"Back": "返回",
			"Cancel": "取消",
			"Company Type": "公司類別",
			"Edit Campaign": "編輯推廣活動",
			"End Date": "結束日期",
			"Finish": "完成",
			"Maps": "地圖",
			"Name": "名字",
			"On The Map": "位置",
			"Publishing Device": "發佈至裝置",
			"Save": "保存",
			"Search": "搜尋",
			"Select Ad Collection": "選擇廣告分類",
			"Search devices": "搜索裝置",
			"Open Map": "打開地圖",
			"Target Devices": "目標裝置",
			"Start Date": "開始日期",
			"abbr": {
				"Select device": "選擇裝置",
				"select": "請選擇你要發佈至的裝置"
			},
			"Search Devices": "搜索裝置",
			"Company": "公司",
			"City": "城市",
			"State": "州",
			"Distance": "距離",
			"Map": "地圖",
			"placeholderName": "請輸入姓名",
			"PleaseSelectAdCollection": "請選擇廣告集",
			"YourTargetDevices": "您的目標裝置",
			"Successfully modified status": "修改狀態成功"
		},
		"list": {
			"Ad Collections": "廣告類別",
			"Campaigns": "推廣活動",
			"Campaign List": "推廣活動清單",
			"Devices": "裝置",
			"End Date": "結束日期",
			"Name": "名字",
			"Start Date": "開始日期",
			"Status": "狀態",
			"View All": "查看全部",
			"default": "已設為預設",
			"Approve": "批准",
			"Inavtivate": "停用",
			"Set Default": "設為預設",
			"Default": "預設",
			"Pending": "等待審核",
			"Inactive": "停用",
			"Active": "已啟用"
		}
	},
	"cart": {
		"Checkout as a guest": "以訪客身份結帳",
		"Verify Phone": "驗證電話",
		"OTPCode": "OTP 代碼發送到您的手機",
		"pleasecode": "請輸入以下代碼以驗證您的電話號碼",
		"Enter code": "輸入代碼",
		"Checkout Order": "結帳單",
		"submitted": "您的訂單已提交。",
		"pickupnoti": "請等待取件通知。",
		"Adsmodo order number": "Adsmodo 訂單號",
		"withouttax": "不含稅",
		"Adsmodo": "艾斯通",
		"Apply": "應用",
		"Continue Shopping": "繼續購物",
		"My Cart": "購物車",
		"Order Summary": "我的訂單",
		"Price": "價錢",
		"Proceed to Checkout": "進行結帳",
		"Product": "產品",
		"QTY": "數量",
		"Seller": "賣家",
		"Shipping": "運送",
		"Shipping Fee": "運送費用",
		"Sub Total": "小計",
		"Total": "總",
		"You save": "您保存",
		"Coupon code": "優惠券代碼",
		"promotion": "Promo Code促銷代碼",
		"Please select your gift Card code": "請選擇您的禮物卡代碼"
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "新增廣告類別",
			"Back": "返回",
			"Edit Ad Collection": "編輯廣告分類",
			"Name": "名字",
			"Publishing Device": "發佈裝置",
			"Save": "保存",
			"Select Ads": "選擇廣告",
			"Select Device": "選擇裝置",
			"Approve all Devices": "核准所有裝置",
			"placeholderName": "請輸入姓名",
			"placeholderSelectAds": "請選擇廣告"
		},
		"list": {
			"Ads": "廣告",
			"Ad Collections": "廣告類別",
			"Ads Collection List": "分類清單",
			"Name": "名字",
			"Associated Ads": "相關廣告"
		}
	},
	"common": {
		"404": {
			"abbr": {
				"back": "返回主頁面",
				"sorry": "抱歉，無法找到當前網頁"
			}
		},
		"Search": "搜尋",
		"ad-image": {
			"Click to select picture": "點擊選擇圖片"
		},
		"ad-video": {
			"Click to select video": "點擊選擇影片"
		},
		"bind-credit-card": {
			"Bind": "綁定",
			"CVC": "安全碼(CVC)",
			"Card Number:": "卡片號碼:",
			"MM": "月份(MM)",
			"YYYY": "年份(YYYY)",
			"Please enter the card number": "請輸入卡號",
			"Please enter the expiry month": "請輸入有效期限",
			"Please enter a valid month": "請輸入有效月份",
			"Please enter the expiry year": "請輸入有效期限",
			"Please enter a valid year": "請輸入有效年份",
			"Please enter the card cvc": "請輸入卡的安全碼(CVC)",
			"Please enter a valid cvc": "請輸入有效的安全碼(CVC)",
			"Expiry Month": "到期月",
			"Expiry Year": "到期年",
			"Card Cvc": "信用卡Cvc"
		},
		"select-ad-device": {
			"Device List": "裝置清單",
			"Let me think": "再考慮一下",
			"Yes, I'm Sure!": "好，我確定!",
			"abbr": {
				"plan": "以100美金/月的方案訂閱我們",
				"upgrade check": "確定要升級帳號嗎?"
			}
		},
		"tui-image-editor": {
			"Cancel": "取消",
			"Edit image": "編輯圖片",
			"Image Resolution :": "圖片解析度",
			"Load Assets": "載入資源",
			"Load Promotion Asset": "載入促銷資源",
			"Load assets": "載入資源",
			"Promotion assets": "促銷資源",
			"Save": "保存"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "新增公司類別",
			"Back": "返回",
			"Edit Company Type": "編輯公司類別",
			"Name": "名稱",
			"Save": "保存",
			"description": "描述",
			"Description": "描述",
			"Image": "圖片",
			"Icon": "圖標",
			"Company Icon": "公司圖示"
		},
		"list": {
			"Company Type": "公司類別",
			"Company Type List": "公司類別清單",
			"Created Date": "建立日期",
			"Name": "名稱",
			"Icon": "圖標",
			"Image": "圖片"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "廣告",
			"Ad Collection": "廣告類別",
			"Back": "返回",
			"Cell Phone": "手機號碼",
			"Contact Me": "聯絡我們",
			"Device": "裝置",
			"Email": "信箱"
		},
		"list": {
			"Ad": "廣告",
			"Campaign": "推廣活動",
			"Collection": "分類",
			"Contact Me": "聯絡我們",
			"Contact Me List": "聯絡資訊清單",
			"Device": "裝置",
			"Email": "信箱",
			"Phone Number": "電話號碼",
			"action": "動作",
			"data-table-title": "聯絡資訊清單"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "公司地址",
			"Contacts": "聯絡人",
			"Contacts List": "聯絡人清單",
			"Email": "信箱",
			"Is Admin": "管理員權限",
			"Mobile": "電話號碼",
			"Name": "名字"
		}
	},
	"customer failed: create customer failed": "客戶失敗：建立客戶失敗",
	"customer failed: create token failed": "客戶失敗：建立失敗",
	"dataTable": {
		"Add": "新增",
		"Are you sure to deactivate it?": "您確定要停用它嗎？",
		"Are you sure to delete it?": "您確定要刪除它嗎？",
		"Cancel": "取消",
		"Choose your options below and drop one into the inbox of anyone on your list!": "選擇下列選項，並寄至聯繫人列表的收件箱！",
		"Confirm": "確認",
		"Deactivate": "停用",
		"Delete": "刪除",
		"Operate": "操作",
		"Please select the item to delete": "請選擇要刪除的項目。",
		"Please select the loyalty member to give reward": "請選擇會員給予獎勵！",
		"Reward Gift Card": "獎勵禮品卡",
		"This operation cannot be undone": "此操作無法撤消。",
		"We offer electronic gift cards in any denomination": "我們提供任何面額的電子禮品卡。",
		"Info": "您的訂閱更改將從下個月開始生效。",
		"Name": "名稱",
		"Address": "地址",
		"The Location is required": "位置為必填項"
	},
	"database error": "數據庫錯誤",
	"devices": {
		"addCampaign": {
			"Ad Collections": "廣告類別",
			"Ad Images": "廣告圖片",
			"Ad List": "廣告清單",
			"Add Campaign": "增加推廣活動",
			"Availavle Campaigns": "可用推廣活動",
			"Back": "返回",
			"Campaign Detail": "推廣活動詳細資料",
			"Campaign List": "推廣活動清單",
			"Campaigns": "推廣活動",
			"Device": "裝置",
			"Device ID": "裝置ID",
			"Device Name": "裝置名稱",
			"Drag available campaigns here to run on device": "將可用的推廣活動拖到此處以在裝置上投放",
			"Duration(s)": "持續時間",
			"End Date": "結束日期",
			"Name": "名稱",
			"Save": "保存",
			"Start Date": "開始日期",
			"Status": "狀態",
			"This ad have no images": "這則廣告沒有圖片"
		},
		"buy-log": {
			"Buy Device Log": "裝置購買紀錄",
			"Buy Device Log List": "裝置購買紀錄清單",
			"Name": "名稱",
			"Device Type": "裝置類型",
			"Quantity": "數量",
			"Status": "狀態",
			"Unit Price": "單價",
			"Permission": "允許",
			"Order Number": "訂單編號",
			"Total Price": "總價",
			"Purchase History": "購買歷史",
			"Receipt": "收據",
			"Cancel Order": "取消訂單",
			"Are you sure you want to cancel this order": "你確定要取消這筆訂單嗎？",
			"Pending": "訂單成立",
			"Finished": "訂單完成",
			"Canceled": "訂單取消",
			"Pay Status": "付款狀態",
			"payPending": "尚未付款",
			"Success": "保存成功",
			"Pay Now": "前往付款",
			"Check Pay Status": "確認付款狀況"
		},
		"buy-product": {
			"Back": "返回",
			"Purchase Device": "購買裝置",
			"Device Quantity": "裝置數量",
			"Location": "地點",
			"Type": "類型",
			"Save": "保存",
			"Add Location": "新增位置",
			"selectPlacementLocation": "選擇放置位置",
			"selectTheDeviceType": "選擇裝置類型",
			"Quantity": "數量",
			"Purchase": "訂購",
			"required": "必填",
			"not valid": "無效的",
			"Purchase success": "訂購成功",
			"Duration Max": "最大值不應超過4500000000"
		},
		"edit": {
			"Add Device": "新增裝置",
			"Are you sure to activate this device ?": "您確定要啟用此裝置嗎？",
			"Are you sure to deactivate this device ?": "您確定要停用此裝置嗎？",
			"Back": "返回",
			"Business Name": "商家名稱",
			"Cancel": "取消",
			"Confirm": "確認",
			"Device ID": "裝置ID",
			"Device Info": "裝置資訊",
			"Device Name": "裝置名稱",
			"Device Status": "裝置狀態",
			"Device Type": "裝置類型",
			"Edit Device": "編輯裝置",
			"Manufacturer": "生產者",
			"Model": "型號",
			"Name": "名字",
			"Offline": "離線",
			"Online": "在線",
			"Placement Location": "展示位置",
			"Platform": "平台",
			"Save": "保存",
			"Select Ad Collections": "選擇廣告分類",
			"Serial": "串行",
			"Toggle": "切換",
			"UUID": "UUID",
			"Version": "版",
			"Your device is now Offline!": "您的裝置現已離線！",
			"Your device is now Online!": "您的裝置現已在線！",
			"Your device need to register via App first !": "您的裝置需要先透過App註冊！"
		},
		"list": {
			"PendingRegistration": "待註冊",
			"Ad Collections": "廣告類別",
			"Businesss Name": "商業名稱",
			"Campaigns": "推廣活動",
			"Device ID": "裝置ID",
			"Device Info": " 裝置資訊",
			"Devices": "裝置",
			"Device List": "裝置清單",
			"Name": "名稱",
			"Position": "位置",
			"Status": "狀態",
			"Approval": "同意",
			"pendingInfo": "待處理，因為仍需要其他裝置所有者的批准（此廣告活動投放至其他公司裝置）。",
			"User": "使用者",
			"Public Name": "公開名稱",
			"Location": "位置",
			"ID": "ID",
			"title": "裝置",
			"data-table-title": "裝置清單",
			"Type": "類型",
			"App Type": "應用程式類型",
			"Manage Campaigns": "管理推廣活動",
			"Campaigns in": "發布到",
			"Company": "公司",
			"Start Date": "開始日期",
			"End Date": "結束日期",
			"Enable": "啟用",
			"Disable": "停用",
			"Campaign Name": "推廣活動名稱",
			"Pending": "待綁定",
			"Offline": "已停用",
			"Pending Delete": "待刪除",
			"Online": "已啟用",
			"Please register the device on the tablet first": "請先在平板上綁定裝置"
		},
		"purchase-log": {
			"Device": "裝置",
			"Order No": "訂購編號",
			"Purchase Log": "購買紀錄",
			"Purchase Log List": "購買紀錄清單",
			"Quantity": "數量",
			"Subscription": "訂閱方案",
			"Total Price": "總價格",
			"Unit Price": "每單位價格",
			"User Name": "使用者名稱",
			"Get Receipt": "取得收據"
		}
	},
	"email": {
		"create-email": {
			"Back": "返回",
			"Email From": "Email寄件者",
			"Message": "訊息",
			"Send": "寄送",
			"Send To": "收件者",
			"Subject": "主旨"
		}
	},
	"email-marketing": {
		"Email Marketing": "電子郵件行銷",
		"Send Email": "發送郵件",
		"Email Log": "郵件紀錄",
		"Email Template": "郵件模板"
	},
	"email-log": {
		"edit": {
			"Select users": "選擇用戶",
			"Email Template": "郵件模板",
			"Subject": "主旨",
			"Content": "內容",
			"Preview": "預覽",
			"Send": "發送",
			"required": "必填",
			"Send success": "發送成功",
			"Not found user": "找不到用戶",
			"My loyalty club": "我的會員俱樂部"
		},
		"list": {
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Date": "時間",
			"To": "收件者",
			"Email": "電子郵件",
			"Subject": "主旨",
			"Content": "內容",
			"Status": "狀態"
		}
	},
	"email-template": {
		"edit": {
			"Name": "名稱",
			"Subject": "主旨",
			"Content": "內容",
			"Preview": "預覽",
			"Back": "返回",
			"Save": "保存",
			"required": "必填",
			"Add success": "新增成功",
			"Edit success": "編輯成功",
			"message": "在郵件模板中使用 {{ }} 作為可修改標記, 發送郵件時，將替換為您輸入內容。例如：{{ message }} 將替換為你在發送郵件時輸入的內容",
			"Convenient code": "其他標記：",
			"CompanyLogo message": "公司logo標誌 : {{ companyLogo }}",
			"CompanyName message": "公司名稱 : {{ companyName }}",
			"Phone message": "公司電話 : {{ companyPhone }}",
			"Website message": "公司網站 : {{ companyWebsite }}",
			"Address message": "公司地址 : {{ address }}",
			"CurrentTime message": "當前時間 : {{ currentTime }}"
		},
		"list": {
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Name": "名稱",
			"Subject": "主旨",
			"Preview": "預覽"
		}
	},
	"sms-marketing": {
		"SMS Marketing": "簡訊行銷",
		"Send Message": "發送簡訊",
		"Message Log": "簡訊紀錄",
		"Message Template": "簡訊模板"
	},
	"sms-log": {
		"edit": {
			"Select users": "選擇用戶",
			"Message Template": "簡訊模板",
			"Message": "訊息",
			"Preview": "預覽",
			"Send": "發送",
			"required": "必填",
			"Exceed the limit": "超過字數限制",
			"Send success": "發送成功"
		},
		"list": {
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Date": "時間",
			"To": "收件者",
			"Phone": "行動電話",
			"Message": "訊息",
			"Status": "狀態",
			"Success": "保存成功",
			"Fail": "失敗",
			"CurrentlyNotAvailable": "刪除功能目前不可用。"
		}
	},
	"sms-template": {
		"edit": {
			"Name": "名稱",
			"Content": "內容",
			"Preview": "預覽",
			"Back": "返回",
			"Save": "保存",
			"required": "必填",
			"Exceed the limit": "超過字數限制",
			"Add success": "新增成功",
			"Edit success": "編輯成功",
			"message": "在簡訊模板中使用 {{ }} 作為可編輯部份, 發送簡訊時，將替換為你在發送簡訊時輸入的內容。例如：{{ message }} 將替換為你在發送郵件時輸入的內容",
			"Convenient code": "其他標記：",
			"CompanyName message": "公司名稱 : {{ companyName }}",
			"Phone message": "公司電話 : {{ companyPhone }}",
			"Website message": "公司網站 : {{ companyWebsite }}",
			"Address message": "公司地址 : {{ address }}",
			"CurrentTime message": "當前時間 : {{ currentTime }}",
			"Please select loyalty club image": "請選擇會員俱樂部圖片",
			"Please select at least one product image": "請選擇至少一張產品圖片",
			"Please complete your informations": "請填寫您的資訊",
			"Please complete your questions": "請完成您的問題"
		},
		"list": {
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Name": "名稱",
			"Preview": "預覽"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "新增查詢",
			"Back": "返回",
			"Edit Enquiry": "編輯查詢",
			"Email": "信箱",
			"Enquiry Type": "查詢類別",
			"Message": "訊息",
			"Name": "名字",
			"Phone": "電話",
			"Reply": "回覆",
			"Save": "保存",
			"Show Enquiry": "顯示查詢"
		},
		"list": {
			"Created Date": "建立日期",
			"Email": "信箱",
			"Enquiry": "查詢",
			"Enquiry List": "查詢清單",
			"Message": "資訊",
			"Name": "名字",
			"Reply": "回覆",
			"Type": "類別",
			"data-table-title": "查詢清單"
		}
	},
	"get ad query error": "收到廣告查詢錯誤",
	"get ads database error": "獲取廣告數據庫錯誤",
	"get item database error ": "獲取項目數據庫錯誤",
	"get item query error": "獲取項目查詢錯誤",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "卡背面的3位安全碼",
		"Amount of Gift Card": "禮品卡金額",
		"CVC": "安全碼(CVC)",
		"Card Number": "卡號",
		"Email From": "電郵來自",
		"Expiration Date": "截止日期",
		"For payment confirmation": "確認付款",
		"Generate Gift Card": "產生禮品卡",
		"Generate Gift Card for the selected loyalty member": "為選定的會員產生禮品卡",
		"Gift Cards": "禮品卡",
		"Gift Cards Log": "禮品卡紀錄",
		"Recipient Email": "收件人電子郵件",
		"Recipient Name": "收件人姓名",
		"First Name*": "名字*",
		"Last Name*": "姓*",
		"Recipient Email *": "收件人電子郵件 *",
		"Amount *": "總量 *",
		"list": {
			"data-table-title": "禮品卡清單"
		}
	},
	"home": {
		"Accountant": "會計師",
		"Add Product to cart": "新增產品到購物車",
		"Age": "年齡",
		"CRM": "客戶關係管理",
		"Complete Purchases": "完成購買",
		"Daily Visits": "每日到訪數",
		"Dashboard": "控制台",
		"Device Orders": "裝置訂購數",
		"Edinburgh": "愛丁堡",
		"From Last 1 Month": "來自最近1個月",
		"From Last 24 Hours": "來自最近24小時",
		"Goal Completion": "目標完成進度",
		"Integration Specialist": "整合專家",
		"Junior Technical Author": "初級技術作者",
		"Last 3 Month Ad-Collection Record": "近3月廣告分類紀錄",
		"Last 3 Month Subscription Record": "近3月訂閱紀錄",
		"Last Month": "上個月",
		"Last week": "上週",
		"Mail Inbox": "信件",
		"Marketplace": "市場",
		"Monthly Earnings": "月收入",
		"Monthly Purchases": "每月購買量",
		"Name": "名字",
		"New York": "紐約",
		"Office": "辦公室",
		"Orders in Last 10 months": "近10月訂購數",
		"Pending Ad": "審查中廣告",
		"Pending User": "審查中用戶",
		"Position": "位置",
		"Recent Contacts": "最近聯絡人",
		"Revenue": "營收",
		"Salary": "薪水",
		"Sales Assistant": "銷售助理",
		"San Francisco": "舊金山",
		"Send Inquiries": "發送查詢",
		"Senior Javascript Developer": "中級Javascript開發人員",
		"Start date": "開始日期",
		"System Architect": "系統結構師",
		"Tokyo": "東京",
		"Total Subscriptions": "總訂閱數",
		"Unique Ad-Viewers": "不重複廣告觀看人數",
		"Visit Premium plan": "查看進階方案",
		"Weekly Top 5 Advertisement": "每週前5名廣告",
		"select device error": "選擇裝置錯誤",
		"Ad Data Record": "廣告數據報告",
		"Company Data Report": "公司數據報告",
		"Loyalty Member Data Report": "俱樂會部員數據報告",
		"Cart Data Report": "購物車數據報告",
		"Order Data Record": "訂單數據報告",
		"Promotion Data Record": "促銷數據報告",
		"Hour": "小時",
		"Day": "天",
		"Month": "月",
		"Increase": "增加",
		"Total": "總量",
		"Increment": "增量",
		"Base": "基數",
		"emptyAlt": "還沒有相關記錄"
	},
	"id required": "id必填",
	"image is required to show ad": "為展示廣告，圖片為必填",
	"main-nav": {
		"Sign In": "登入",
		"Sign Up": "註冊",
		"Loyalty Sign Up": "俱樂部會員註冊",
		"Sign Up Free": "免費註冊",
		"Start Sign Up Free": "開始免費註冊",
		"email": "請在此輸入電子郵件",
		"step3": "只需三步驟即可發布",
		"service time": "服務時間   星期一至五 上午 10 點至晚上 7 點",
		"privacy policy": "隱私權政策",
		"webName": "Adsmodo",
		"Terms & Conditions": "條款及細則"
	},
	"member": {
		"About": "關於",
		"Back": "返回",
		"Cancel": "取消",
		"Clear": "清除",
		"Confirm": "確認",
		"Contact Info": "聯絡資料",
		"Description": "描述",
		"Earned Points": "賺取積分",
		"Edit": "編輯",
		"Email Verification Success": "電子郵件驗證成功",
		"Explore": "探索",
		"Got it!": "得到它了！",
		"If you experience any issues with this Reward,please feel free to contact": "如果在使用獎勵的過程中遇到問題，請與我們聯繫",
		"LearnMore": "了解更多",
		"Please enter activation code sent to this number": "請輸入發送到該號碼的啟用碼",
		"Points history": "積分記錄",
		"Redeem": "兌換積分",
		"Redeemable points can be exchanged for rewards at the Rewards page": "在獎勵頁面，可將兌換積分兌換成獎勵",
		"Save": "儲存",
		"Share": "分享",
		"Special Deals": "特別優惠",
		"Spent Points": "消費積分",
		"Terms and Conditions": "條款和條件",
		"What are Redeemable Points?": "什麼是可兌換積分？",
		"Your email has been verified.We will redirect you to home page": "您的電子郵件已通過驗證。我們會將您重導向至主頁",
		"nav": {
			"Home": "首頁",
			"Recommended Loyalty Club": "推薦的會員俱樂部",
			"My Loyalty Club": "我的會員俱樂部",
			"My Order": "我的訂單",
			"View Schedule": "查看時間表",
			"Make Appointment": "預約時間",
			"Setting": "設定",
			"Notification": "通知",
			"Merchant Site": "商家網站",
			"LeaveAll": "退訂俱樂部",
			"Unsubscribe": "退訂",
			"noClubYet": "您還沒有會員俱樂部",
			"SuretoUnsubscribeClub": "您確定要退訂這個俱樂部嗎",
			"unsubscribeSuccessful": "退訂成功！",
			"Youhavebeen": "您已退訂該會員俱樂部",
			"NoMoreService": "並且不會再收到我們的相關電子郵件和服務。感謝您花時間在我們的服務上。",
			"Log Out": "登出"
		},
		"profile": {
			"First Name": "名字",
			"Last Name": "姓氏",
			"Email": "電子郵件",
			"Phone": "電話",
			"required": "必填",
			"not valid": "無效的",
			"Save": "保存"
		},
		"myLoyaltyClub": {
			"Category": "分類",
			"warning": "您尚未登入",
			"warning text": "登入並加入俱樂部！",
			"All": "全部"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "蛋糕已過期",
			"Cake Used": "已使用過的蛋糕",
			"Roylaty Member Detail": "忠誠會員詳情"
		},
		"form": {
			"Join Adsmodo Loyalty Club": "加入艾斯通會員俱樂部",
			"Select Loyalty Clubs": "選擇會員俱樂部",
			"Submit": "送出",
			"Select": "已選擇",
			"Loyalty Clubs": "個會員俱樂部"
		},
		"list": {
			"Birthday": "生日",
			"Email": "電子郵件",
			"Loyalty Member": "會員",
			"Loyalty Member List": "會員列表",
			"Mobile": "手機號碼",
			"Name": "姓名",
			"Points": "點數"
		}
	},
	"no permission": "沒有權限",
	"post:Device update error": "下一條：裝置更新錯誤",
	"post:sendPasscode error": "下一條：發送通行碼錯誤",
	"post:signin account error": "下一條：登入帳戶錯誤",
	"pre:add ad name validate failed": "上一條：新增廣告名稱驗證失敗",
	"pre:audit user query error": "上一條：審核用戶查詢錯誤",
	"pre:bind have been bound": "上一條：已綁定",
	"pre:bind verify name failed": "上一條：綁定驗證名稱失敗",
	"pre:bind verify serial failed": "上一條：綁定驗證碼失敗",
	"pre:delete database error": "上一條：刪除數據庫錯誤",
	"pre:find user": "上一條：查找用戶",
	"pre:get purchase log database error": "上一條：獲取購買記錄數據庫錯誤",
	"pre:member get error": "上一條：成員取得錯誤",
	"pre:missing parameters": "上一條：缺少參數範圍",
	"pre:sendPasscode find user error": "上一條：發送通行碼查找用戶錯誤",
	"pre:signin device has been bound": "上一條：登入裝置已綁定",
	"pre:signin find user error": "上一條：登入發現用戶錯誤",
	"pre:unbind the device is not exist": "上一條：取消綁定該裝置不存在",
	"pre:update get campaign database error": "上一條：更新獲取推廣活動數據庫錯誤",
	"pre:update get member database error": "上一篇：更新獲取會員數據庫錯誤",
	"pre:update item query error": "上一條：更新項目查詢錯誤",
	"pre:update missing parameters": "上一條：更新缺少參數範圍",
	"pre:verification error": "上一條：驗證錯誤",
	"product": {
		"edit": {
			"Add Product": "新增產品",
			"Edit Product": "編輯產品",
			"Product Category": "產品類別",
			"Add Category": "新增類別",
			"Name": "名稱",
			"Cancel": "取消",
			"Save": "保存",
			"Images": "圖片",
			"Click to select picture": "點擊選擇圖片",
			"SKU": "存貨單位",
			"Description": "描述",
			"Unit Price": "單價",
			"Quantity": "數量",
			"Variants": "產品種類",
			"Add Variants": "新增種類",
			"Variant Name": "種類名稱",
			"Extra Price": "額外加價",
			"Extra Options": "附加選項",
			"Add Options": "新增選項",
			"Option Name": "選項名稱",
			"Back": "返回",
			"Add success": "新增成功",
			"Edit success": "編輯成功"
		},
		"list": {
			"Category": "類別",
			"Image": "圖片",
			"SKU": "存貨單位",
			"Name": "名稱",
			"Unit Price": "單價",
			"Quantity": "數量",
			"Product List": "產品清單"
		},
		"public-detail": {
			"Add to cart": "添加到購物車",
			"Available Stock": "可用庫存",
			"Options": "選項",
			"Original": "原來的",
			"Please choose the product to buy": "請選擇要購買的產品",
			"Price": "價格",
			"Total Price": "總價",
			"Product Detail": "產品信息",
			"Qty": "數量",
			"Shipping Price": "運費價格",
			"ViewMyCart": "查看我的購物車",
			"Back to product list": "返回產品列表"
		},
		"public-list": {
			"Product Catalog": "產品目錄"
		}
	},
	"ad-product-category": {
		"edit": {
			"Add Product Category": "新增產品類別",
			"Edit Product Category": "編輯產品類別",
			"Name": "名稱",
			"Back": "返回",
			"Save": "保存",
			"Add success": "新增成功",
			"Edit success": "編輯成功"
		},
		"list": {
			"Name": "名稱",
			"Product Category": "產品類別"
		}
	},
	"product-device": {
		"edit": {
			"data-table-title": "產品裝置清單",
			"Add Product Device": "新增產品裝置",
			"Back": "返回",
			"Device Name": "裝置名稱",
			"Device Price": "裝置價格",
			"Edit Product Device": "編輯產品裝置",
			"Product Device": "產品裝置",
			"Save": "保存"
		},
		"list": {
			"Device Name": "裝置名稱",
			"Device Price": "裝置價格",
			"Product Device": "產品裝置",
			"Product Device List": "產品裝置清單"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "新增促銷",
			"Back": "返回",
			"Coupon count(s)": "折扣價格",
			"Description": "介紹",
			"Edit Promotion": "編輯促銷",
			"End Date": "結束日期",
			"Promotion Image": "促銷圖片",
			"Promotion Type": "促銷類別",
			"Promotional Text": "促銷文字",
			"Save": "保存",
			"Start Date": "開始日期",
			"Select Products": "選擇產品",
			"Max Purchase Amount": "最大購買金額",
			"Coupon Code": "優惠券代碼",
			"Amount": "金額",
			"Percentage": "百分比"
		},
		"list": {
			"Coupon Count": "折扣價格",
			"End Date": "結束日期",
			"Name": "名字",
			"Promotion": "促銷",
			"Promotion List": "促銷清單",
			"Start Date": "開始日期",
			"Type": "類別",
			"data-table-title": "促銷清單"
		},
		"log": {
			"Promotion Log": "促銷紀錄",
			"Date": "日期",
			"Customer Name": "顧客姓名",
			"Promotion": "促銷",
			"Received Amount": "已收金額"
		}
	},
	"reward": {
		"list": {
			"Reward List": "獎勵清單",
			"Name": "名稱",
			"Description": "描述",
			"Points": "點數",
			"Contact": "聯繫",
			"data-table-title": "獎勵清單",
			"Contact Email": "聯絡郵件",
			"Contact Phone": "聯繫電話"
		},
		"edit": {
			"Reward Edit": "編輯獎勵",
			"Reward Add": "新增獎勵",
			"Image": "圖片",
			"Name": "姓名",
			"Reward By": "獎勵來自",
			"Location": "地點",
			"Points": "點數",
			"Mobile Number": "手機號碼",
			"Email": "電子郵件",
			"Terms & Conditions": "條款和條件",
			"ADD": "新增",
			"About us": "關於我們",
			"Add your Terms & Conditions": "新增您的條款和條件"
		}
	},
	"recaptcha error": "驗證碼錯誤",
	"recaptchaToken is required": "必須提供驗證碼",
	"role": {
		"list": {
			"User Role": "使用者角色",
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Name": "名稱"
		},
		"edit": {
			"Appointment Available": "可預約",
			"Add Role": "新增角色",
			"Back": "返回",
			"Edit Role": "編輯角色",
			"Name": "名稱",
			"Save": "保存",
			"required": "必填",
			"Add success": "新增成功",
			"Edit success": "編輯成功",
			"View": "查看",
			"Add": "新增",
			"Edit": "編輯",
			"Delete": "刪除",
			"Search": "搜尋",
			"Dashboard": "數據總覽",
			"Quick Start": "簡易上架",
			"Advertising": "廣告",
			"Advertising List": "廣告清單",
			"Ad Collection": "廣告類別",
			"Ads Assets": "廣告資源",
			"Campaign": "推廣活動",
			"Campaign List": "推廣活動清單",
			"Device": "裝置",
			"Device List": "裝置清單",
			"Purchase History": "購買歷史",
			"E-Commerce": "電子商務",
			"Product List": "產品清單",
			"Product Category": "產品類別",
			"View Order": "查看訂單",
			"CRM": "客戶關係管理",
			"Loyalty Club": "會員俱樂部",
			"Email Log": "郵件紀錄",
			"Email Template": "郵件模板",
			"Message Log": "簡訊紀錄",
			"Message Template": "簡訊模板",
			"Company": "公司",
			"Company List": "公司清單",
			"Team List": "團隊清單",
			"User List": "用戶清單",
			"User Role": "用戶角色",
			"Schedule": "日程表"
		}
	},
	"searching": {
		"Ad Collection": "廣告集",
		"Ads": "廣告",
		"Apply": "應用",
		"Campaign": "推廣活動",
		"Clear filter": "清除篩選器",
		"Collections": "分類",
		"Detail": "詳情",
		"Device": "裝置",
		"Duration": "持續時間",
		"Duration(s)": "持續時間(秒)",
		"End Date": "結束日期",
		"Filter by": "篩選",
		"First name": "名字",
		"ID": "ID",
		"Items": "物品",
		"Last name": "姓氏",
		"Name": "名稱",
		"No results found for": "查無結果",
		"Position": "位置",
		"Results for": "結果",
		"Start Date": "開始日期",
		"Status": "狀態",
		"Type": "類型",
		"User": "用戶"
	},
	"company": {
		"list": {
			"Company List": "公司清單",
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Logo": "logo標誌",
			"Name": "名稱",
			"Phone": "電話",
			"Website": "網站"
		},
		"edit": {
			"Add Company": "建立公司",
			"Edit Company": "編輯公司",
			"Logo": "logo標誌",
			"Name": "名稱 *",
			"Type": "類型 *",
			"Phone": "電話 *",
			"Website": "網站",
			"ZIP Code": "郵遞區號 *",
			"Address": "地址 *",
			"Back": "返回",
			"Save": "保存",
			"PublishAds": "發佈廣告",
			"required": "必填",
			"Add success": "新增成功",
			"Edit success": "編輯成功"
		}
	},
	"airbnb": {
		"hotel": {
			"Logo": "品牌logo標誌",
			"Create Hotel": "建立旅館",
			"name": "名稱"
		},
		"room": {}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "廣告模板類別",
			"Advertising Setting": "廣告設定",
			"Day(s) ": "天",
			"Duration (s)": "長度(秒)",
			"Expiry Date": "到期時間",
			"Expires in": "何時過期",
			"Name": "名字",
			"Save": "保存",
			"Second(s)": "秒",
			"Subscription Plan": "訂閱方案",
			"day": "天"
		},
		"general": {
			"Default Language": "預設語言",
			"Generel Settings": "基礎設定",
			"Name": "名字",
			"New User Default Role": "新使用者預設角色",
			"Save": "保存"
		},
		"product": {
			"Name": "名字",
			"Number": "數量",
			"Price": "價格",
			"Product Name": "名稱",
			"Product Number": "數量",
			"Product Price": "價格",
			"Product Service": "服務",
			"Product Text": "敘述",
			"Product Type": "類別",
			"Save": "保存",
			"Service": "服務",
			"Subscription Lists": "訂閱清單",
			"Subscription Name": "訂閱名稱",
			"Subscription Type": "訂閱類別",
			"Usage": "用途"
		}
	},
	"sign": {
		"forget": {
			"Log in with one-time passcode": "使用一次性驗證碼登入",
			"Enter the email associated with your Adsmodo account": "輸入您在艾斯通註冊的電子郵件",
			"Authentication required": "需要驗證",
			"We sent": "我們已將一次性密碼發送給您",
			"Please enter it below": "請在下面輸入",
			"OTP will expired in": "OTP密碼將過期",
			"OTP has expired": "OTP密碼已過期。",
			"Resend OTP": "重發OTP密碼",
			"Enter OTP": "輸入OTP密碼",
			"Required": "必填",
			"Passcode is required": "必填密碼",
			"Email is required": "必填電子郵件",
			"Please select your account type": "請選擇您的帳戶類型",
			"Please type your email or mobile": "請輸入您的電子郵件或手機號碼",
			"mdi-reply": "mdi圖示 回覆",
			"Reset your Password": "重設密碼",
			"Reset my password": "重設我的密碼",
			"Password Changed": "密碼已更改",
			"You can now login with your new password": "現在可以使用新密碼登入",
			"Login": "登入",
			"Old Password": "舊密碼",
			"New Password": "新密碼",
			"Confirm Password": "確認密碼",
			"Forgot Password": "忘記密碼",
			"Email": "電子郵件",
			"Mobile": "電話",
			"Email or mobile": "電子郵寄或手機號碼",
			"Enter the email address or mobile phone number": "輸入與您的艾斯通帳戶的電子郵件或手機號碼。",
			"Reset Password": "重設密碼",
			"Continue": "送出",
			"Sign In": "登入",
			"abbr": {
				"send": "發送驗證碼"
			}
		},
		"sign-in": {
			"Create an account": "建立帳戶",
			"Forgot password": "忘記密碼",
			"Loyalty Member Sign In": "俱樂部會員登入",
			"Sign up": "商家註冊",
			"Sign In": "登入",
			"abbr": {
				"send": "發送驗證碼"
			},
			"Already have an account?": "是否已有帳號？",
			"Forgot-Password": "忘記密碼？",
			"Quick access with": "快速登入",
			"Log in with one-time passcode": "使用一次性驗證碼登入",
			"Failed to get email Please log in to facebook to make your email public": "獲取email失敗，請先登入facebook公開您的email"
		},
		"landing-page": {
			"name": "姓名",
			"email": "電子郵件",
			"Contact Phone": "連絡電話",
			"Company Name": "公司名稱",
			"Address": "連絡地址"
		},
		"errorMessage": {
			"firstName": "名字為必填項",
			"lastName": "姓氏為必填項",
			"phone": "電話為必填項",
			"businessName": "公司名稱為必填項",
			"businessPhone": "商務電話號碼為必填項",
			"webSite": "網站為必填項",
			"zipcode": "郵遞區號為必填項",
			"businessAddress": "公司地址為必填項",
			"yourGoal": "請選擇您的目標",
			"productNumber": "請輸入產品編號",
			"card": "請輸入信用卡",
			"expiryMonth": "請輸入到期月份",
			"expiryYear": "請輸入有效期限",
			"cardCvc": "請輸入信用卡安全碼(CVC)",
			"mail": "請出入電子郵件",
			"password": "請輸入密碼",
			"confirmPassword": "確認密碼",
			"messageisrequired": "請輸入資訊"
		},
		"sign-up": {
			"Quick Sign Up": "快速註冊",
			"Quick Sign Up With": "快速註冊",
			"Complete Sign Up": "完全註冊",
			"At least 8 character": "密碼必須至少包含8個字符，包括大寫、小寫字母和數字",
			"Loyalty Member": "俱樂部會員",
			"Merchant": "商家",
			"AirbnbAdmin": "愛彼迎管理員",
			"OK": "確定",
			"Address:": "住址:",
			"Ads My biz": "為我的店發布廣告",
			"All of Above": "全選",
			"Business Address": "公司地址",
			"Business Name": "公司名稱",
			"Business phone": "公司電話",
			"Card Number:": "信用卡號碼:",
			"Company Type": "公司類型",
			"Confirm": "確認",
			"Confirm Information": "確認資料",
			"Confirm password": "確認密碼",
			"Create user account": "建立使用者帳戶",
			"Email": "電子郵件",
			"Email:": "電子郵件：",
			"Extra $": "附加產值",
			"First Name": "名字",
			"First Name:": "名字：",
			"Help others": "幫助其他人",
			"Last Name:": "姓氏：",
			"Next": "下一步",
			"Password": "密碼",
			"Payment Options": "付費選項",
			"Phone": "行動電話",
			"Phone:": "行動電話：",
			"Prev": "上一步",
			"Price:": "價格：",
			"Price(month):": "價格(每月):",
			"Product:": "產品：",
			"Quantity": "數量",
			"Quantity:": "數量：",
			"Select Adsmodo Business Assistant": "選擇艾斯通商業助手",
			"Subscription Plan": "訂閱計畫",
			"Tell us about your goal": "您的目標",
			"Web Site": "網站",
			"Zipcode": "郵遞區號",
			"abbr": {
				"agree": "我已閱讀並同意",
				"business info": "公司資訊",
				"web tutorial": "您可以在w3schools學習如何製作個人網頁，我們也提供免費的網頁開發教學"
			},
			"last Name": "姓氏",
			"Skip Player": "先不購買",
			"Click OK to sign in": "點擊 OK 立即登入",
			"required": "必填"
		}
	},
	"signin verify error": "登入驗證錯誤",
	"signup password error": "註冊密碼錯誤",
	"single": {
		"component": {
			"Contact info": "聯絡方式",
			"Email address": "信箱",
			"Input your contact info to get coupons and subscribe our service": "輸入您的聯絡方式以獲得折價券",
			"Input your contact info to get coupons!": "留下聯絡方式以獲得折價券",
			"Phone number": "電話號碼",
			"Submit": "註冊",
			"abbr": {
				"get contact info": "獲取聯繫資訊"
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "您確定要啟用此計劃嗎？",
			"Are you sure to deactivate this plan?": "您確定要停用此計劃嗎？",
			"Your plan is now Active!": "您的計劃現已啟用！",
			"Your plan is now Inactive!": "您的計劃現在停用！",
			"Active": "有效",
			"Inactive": "無效",
			"Add Subscription Plan": "新增訂閱計劃",
			"Back": "返回",
			"Edit Subscription Plan": "編輯訂閱計劃",
			"Number": "數量",
			"Price": "價錢",
			"Save": "保存",
			"Service": "服務",
			"Subscription Name": "訂閱名稱",
			"Subscription Plan": "訂閱計劃",
			"Subscription Type": "訂閱類型",
			"Usage": "用法",
			"Description": "描述",
			"Maximum area range": "最大面積範圍",
			"Maximum Area Range": "最大面積範圍"
		},
		"list": {
			"Price": "價錢",
			"Subscription Plan": "訂閱計劃",
			"Subscription Plan List": "訂閱計劃清單",
			"Subscription Plan Type": "訂閱計劃類型"
		}
	},
	"user": {
		"account": {
			"Status": "Status",
			"freemembership": "免費會員",
			"primemembership": "付費會員",
			"validdate": "有效日期",
			"Account Type": "帳號類別",
			"Subscription Plan Type": "訂閱計劃類型",
			"Ad Address": "廣告地址",
			"Personal Information": "個人資料",
			"Save Credit Card": "保存信用卡",
			"Change Credit Card": "更改信用卡",
			"Current Credit Card": "目前信用卡",
			"Saved Payment Methods": "已儲存的付款方式",
			"Bind Status": "綁定狀態",
			"Bind credit card": "綁定信用卡",
			"Business Address": "店家地址",
			"Business Information": "店家資訊",
			"Business Name": "店家名稱",
			"Business Phone": "店家電話",
			"Cancel": "取消",
			"Cancel Subscription": "取消訂閱",
			"Phone": "電話",
			"Company Type": "公司類別",
			"Email": "電子郵件",
			"Free": "免費",
			"Hi": "你好，",
			"Info": "資料",
			"Let me think": "再考慮一下",
			"Name": "名字",
			"Password": "密碼",
			"Product Type": "產品類別",
			"Profile Image": "大頭貼",
			"Redeem": "積分",
			"Rewards": "獲利",
			"Save": "保存",
			"Skip Tour": "跳過教學",
			"Start Create Ads": "開始建立廣告",
			"Subscription Plan": "訂閱方案",
			"Web Site": "網站",
			"Zipcode/Postalcode": "郵遞區號",
			"First Name": "名字",
			"Last Name": "姓氏",
			"enterPassword": "請輸入密碼",
			"Enter Business Name": "請輸入店家名稱",
			"Enter Business Phone": "請輸入店家電話",
			"Enter webSite": "請輸入商業網站",
			"Enter Business Information": "請輸入商家資訊",
			"abbr": {
				"ad collection intro": "廣告類別可設定你的廣告的種類，且每個廣告分類可以包含多個廣告",
				"ad intro": "廣告可以包含不同的圖片、文字、聯絡資訊按鈕、連結、影片等，同時圖片的類型可以包含3D、AR、VR等",
				"campaign intro": " 推廣活動包含多個廣告集",
				"cancel subscribe check": "確定要取消訂閱嗎?",
				"cancel subscribe info": "取消訂閱後，您將失去所有在此網站上的優惠",
				"privilege info": "在您訂閱以後，可以享受以下特權",
				"upgrade check": "確定要升級帳號嗎?",
				"welcome": "歡迎來到愛斯通",
				"Are you sure you want to change your current plan ?": "您確定要更改當前計劃嗎 ?",
				"yes": "我確定",
				"Plan adsmodo a monthly $": "每月計劃在adsmodo付出多少錢",
				"advertising services": "廣告服務"
			},
			"Monthly": "每月一次",
			"Activated": "已啟用",
			"Current Plan": "當前計劃",
			"Change Plan": "變更計劃",
			"Upgrade Now": "現在升級",
			"Last 4 digit": "後四位數",
			"Expiring": "即將到期",
			"My Credit Cards": "我的信用卡",
			"Update Card": "更新信用卡",
			"Company Information": "公司資訊",
			"Subscribe": "訂閱",
			"Pay": "前往付款",
			"Team Information": "團隊資訊",
			"required": "必填",
			"not valid": "無效的"
		},
		"list": {
			"User List": "用戶清單",
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Name": "姓名",
			"Email": "電子郵件",
			"Phone": "電話",
			"Team": "團隊",
			"Role": "角色",
			"Owner": "所有者"
		},
		"edit": {
			"Yourcurrentteamroles": "您在當前團隊的角色",
			"rolewarning": "如果在此處更改用戶的角色，可能會影響您目前角色可使用的功能。",
			"avatar": "照片",
			"Add User": "新增用戶",
			"Edit User": "編輯用戶",
			"First Name": "名字",
			"Last Name": "姓氏",
			"Email": "電子郵件",
			"Phone": "行動電話",
			"Password": "密碼",
			"Confirm Password": "確認密碼",
			"Change Password": "修改密碼",
			"Cancel": "取消",
			"Team": "團隊",
			"Role": "角色",
			"Back": "返回",
			"Save": "保存",
			"required": "必填",
			"not valid": "無效的",
			"passwords not match": "密碼與確認密碼不一致",
			"Add success": "新增成功",
			"Edit success": "編輯成功",
			"jobTitle": "職稱",
			"description": "描述"
		}
	},
	"user permission get error": "用戶權限獲取錯誤",
	"user-goal": {
		"edit": {
			"Add User Goal": "新增用戶目標",
			"Back": "返回",
			"Edit User Goal": "修改用戶目標",
			"Name": "名字",
			"Save": "保存",
			"User Goal": "用戶目標"
		},
		"list": {
			"data-table-title": "用戶目標列表",
			"Name": "名字",
			"User Goal": "用戶目標",
			"User Goal List": "用戶目標清單"
		}
	},
	"location": {
		"edit": {
			"Name": "名稱",
			"Location": "位置",
			"Add Location": "新增位置",
			"Close": "關",
			"Add": "添加",
			"title": "裝置位置編輯"
		},
		"list": {
			"Device Location List": "裝置位置列表",
			"Device Location": "裝置位置"
		},
		"add": {
			"title": "裝置位置新增"
		}
	},
	"validation errors": "驗證錯誤",
	"View More Cases": "查看更多",
	"Contact Us": "聯繫我們",
	"Login is abnormal, please try again later.": "登入失敗，請稍後重試！",
	"header": {
		"Ecommerce": "產品",
		"Pricing": "價格",
		"Get Started": "開始",
		"Sign Out": "登出"
	},
	"footer": {
		"copy_right": "版權所有2021。 版權所有。"
	},
	"security-tab": {
		"mdi-tune": "mdi圖示-調整",
		"Choose": "選擇",
		"Set up": "設定",
		"Verify": "驗證",
		"Finished": "完成",
		"Two-factor Authentication": "雙重身份驗證",
		"Enabled tasks": "已啟用的任務",
		"You can choose a task": "登入後，你可以從列表中選擇任務。",
		"Text message": "簡訊",
		"mdi-message-text": "mdi圖示 簡訊文字",
		"Verification codes are sent by text message": "驗證碼通過簡訊發送",
		"mdi-shield-lock": "mdi圖示盾牌鎖",
		"Authenticator App": "身份驗證APP",
		"Use the Authenticator app": "即使您的電話處於離線狀態，也可以使用身份驗證APP獲取驗證碼",
		"How would you": "您想如何接收驗證碼？",
		"After entering your password": "輸入密碼後，將要求您進行第二步驗證。",
		"Verification codes": "通過簡訊發送驗證碼。（需要經過驗證的手機號碼）",
		"SET UP": "設定",
		"Cancel": "取消",
		"Let's set up": "我們設定手機進行驗證",
		"What phone number": "您要使用什麼電話號碼？",
		"mdi-checkbox-marked-circle": "mdi圓形勾選欄",
		"mdi-information": "mdi圖示資訊",
		"Change": "更改",
		"Two Factor Setup": "雙重驗證設置",
		"Take these few steps to enable": "執行以下幾步以啟用雙重身份驗證，並使您的帳戶更安全。",
		"Install an authenticator app": "在您的行動裝置上安裝身份驗證APP。",
		"Recommended options: Google Authenticator": "推薦選項：Google Authenticator，Authy，Microsoft Authenticator",
		"Scan QR code with the authenticator": "使用身份驗證程式掃描QR碼。",
		"Enter the 2-step authentication": "輸入APP提供的雙重身份驗證代碼。",
		"Continue": "繼續",
		"I'm ready": "我準備好了",
		"Back": "返回",
		"Verify your number": "驗證您的電話號碼",
		"We just sent a text message": "我們剛剛發送了一條驗證碼簡訊給您",
		"Didn't get it? Resend code": "沒有收到？重新發送驗證碼。",
		"Scan this QR code": "使用您的行動裝置APP掃描此QR碼。",
		"Then enter the six digit code": "然後輸入APP提供的六位數代碼：",
		"Can't scan the code": "無法掃描代碼？",
		"Great": "好!",
		"Two-factor Authentication has been": "已為您的帳戶啟用了雙重身份驗證",
		"All future logins will now require": "未來所有登入都需透過簡訊或驗證APP提供的代碼。",
		"Finish": "完成",
		"Change Password": "修改密碼",
		"Two-step verification": "兩步驟驗證",
		"Set up a unique password to protect your personal account": "設置特殊密碼以保護您的個人帳戶",
		"Require a security key or code in addition to your password": "除密碼外還需要安全密鑰或代碼"
	},
	"reward-list": {
		"Used": "已使用",
		"Adsmodo": "艾斯通",
		"$": "$",
		"OFF YOUR PURCHASE": "關閉您的購買",
		"Gift Code:": "禮品代碼：",
		"Valid till": "有效期至"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "有效期為2019年10月15日至2020年1月15日",
		"points": "點數",
		"mdi-check-bold": "mdi圖示-粗體勾",
		"mdi-phone": "mdi圖示-手機",
		"mdi-email": "mdi圖示-電子郵件",
		"Report Reward issue to Adsmodo": "向艾斯通報告回饋問題"
	},
	"return-orders": {
		"Choose items to return": "選擇退貨品項",
		"Ordered on": "訂購日期",
		"Order Number": "訂單號",
		"Continue": "繼續",
		"Not returnable": "不可退貨",
		"Returnable untill": "可重複使用，直到",
		"Choose refund method": "選擇退款方式",
		"Returning": "返回",
		"Reason": "原因",
		"We'll process your refund": "我們將處理您的Visa的退款，後四碼為1234。",
		"Estimated refund timing": "預計退款時間：我們收到商品後的2-3個工作日。",
		"How do you want to send": "您想如何將其寄回？",
		"$0": "$0",
		"Our deliveries will pickup": "物流業者將在下一個工作日取回您的包裹。需要請您從帳戶或電子郵件中列印標籤。",
		"Refund summary": "退款摘要",
		"Refund Subtotal": "退款小計：",
		"Shipping fee": "運輸費用：",
		"Total refund": "總退款：",
		"mdi-checkbox-marked-circle": " mdi圖示圓形勾選欄",
		"Your label is ready to print": "您的標籤已可列印",
		"Securely pack the items": "仔細安全地打包要退回的物品。將所有物品寄回給艾斯通 ",
		"mdi-cloud-upload": "mdi圖示上傳雲端",
		"Print label": "列印標籤"
	},
	"products-list": {
		"Adsmodo": "艾斯通",
		"Out of stock": "缺貨！",
		"Available stock": "可用庫存"
	},
	"my-reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "有效期：2019年10月15日至2020年1月15日",
		"points": "點數",
		"mdi-check-bold": "mdi圖示粗體勾",
		"mdi-phone": "mdi圖示電話",
		"mdi-email": "mdi圖示墊子郵件",
		"Report Reward issue to": "回報回饋問題至",
		"Use Now": "立即使用"
	},
	"my-reward-list": {
		"Your Rewards": "你的獎勵",
		"Valid till": "有效期至",
		"mdi-content-cut": "mdi圖示剪下",
		"Use Now": "立即使用",
		"MERCHANT-TO-FILL-CODE": "商品代碼",
		"Please enter 6-digit code provided by the merchant": "請輸入賣家提供的6位數字",
		"Confirm": "確認"
	},
	"post": "signin account error",
	"member-change-password": {
		"Check": "檢查",
		"Validate": "驗證",
		"Verify & Change": "驗證和更改",
		"Finished": "完成",
		"Hi": "你好",
		"Verification is in progress for": "正在驗證",
		"mdi-information": "mdi圖示資訊",
		"Please verify your identity first": "請先驗證你的身份",
		"Your email": "你的電子郵件",
		"has been verified": "已通過驗證",
		"mdi-checkbox-marked-circle": "mdi圖示圓形勾選欄",
		"sentEmailPrompt": "為設置您的密碼，我們將在一分鐘內將密碼發送到您的電子郵件",
		"Next": "下一步",
		"Cancel": "取消",
		"sentPasscodePrompt": "我們已將密碼發送到您的電子郵件",
		"Please check your inbox": "請檢查您的收件箱",
		"Please enter current password": "請輸入當前密碼",
		"mdi-lock": "mdi圖示鎖",
		"Pick a new password": "選擇新密碼",
		"Password must contain": "密碼必須包含",
		"At least 1 upper case letter": "至少一個大寫字母",
		"At least 1 number (0-9)": "至少1個數字(0-9)",
		"At least 8 character": "密碼必須至少包含8個字符，包括大寫、小寫字母和數字",
		"Great": "大量",
		"Your password has been updated": "您的密碼已更新"
	},
	"member-home": {
		"Special offers for you": "為您提供特別優惠",
		"A confirmation email has been sent to": "已將確認電子郵件發送到",
		"Click on the confirmation link in the email to verify your account.": "請點擊電子郵件中的確認連結以驗證您的帳戶。",
		"mdi-trophy-award": "mdi圖示獎盃",
		"Points": "點數",
		"My loyalty club": "我加入的會員俱樂部",
		"Recommended loyalty club": "推薦的俱樂部",
		"More loyalty club": "更多俱樂部",
		"More than": "超過",
		"clicks on ads": "點擊廣告",
		"Since this is your first login, please click": "由於您第一次登入，請先點擊本條資訊進行修改密碼",
		"There are currently": "當前有",
		"promotional products": "產品正在促銷中",
		"Promotion code list": "促銷產品列表",
		"Loyalty club name": "俱樂部名字",
		"Promotion code": "優惠碼",
		"Close": "關閉",
		"View promo code": "查看促銷碼"
	},
	"member-nav": {
		"mdi-earth": "mdi圖示地球",
		"mdi-cart-outline": "mdi圖示購物車輪廓"
	},
	"member-orders": {
		"Order": "訂單",
		"Placed on": "放置在",
		"Return Items": "退貨物品"
	},
	"member-profile": {
		"mdi-star": "mdi圖示星形",
		"points": "積分",
		"mdi-email": "mdi圖示郵箱",
		"mdi-phone": "mdi圖示電話",
		"mdi-home": "mdi圖示家",
		"mdi-checkbox-marked-circle": "mdi圖示圓形勾選欄",
		"mdi-information": "mdi圖示資訊",
		"Ok": "同意",
		"Currently you don't have any points history": "目前您還沒有積分紀錄",
		"Make a new purchase to earn reward points": "新購買可獲得積分獎勵",
		"Find More": "了解更多"
	},
	"member-setting": {
		"Profile": "簡介",
		"Security": "安全"
	},
	"order-detail": {
		"Order Detail": "訂單詳情",
		"mdi-printer": "mdi圖示列印機",
		"mdi-airplane-takeoff": "mdi圖示飛機起飛",
		"Trace Your Order": "追蹤您的訂單",
		"Order Number": "訂單號",
		"Order Placed": "已下單",
		"Status": "狀態",
		"Sales Person": "銷售人員",
		"Sub Total": "小計",
		"Shipping Fee": "運費",
		"Total": "總計",
		"Order Summary": "訂單摘要",
		"Payment Method": "付款方式",
		"Ship To": "寄送至",
		"No.18,Corner of Ahlone and Baho Road,Ahlone Township,Yangon": "仰光市阿龍鎮阿龍和巴霍路轉角18號",
		"Shipping Method": "送貨方式",
		"Standard": "標準",
		"Contact Information": "聯繫人資訊",
		"Products Summary": "產品摘要"
	},
	"products-detail": {
		"Size Guide": "尺寸指南",
		"Size Conversions": "尺寸轉換",
		"Shipping price": "運費",
		"Out of stock": "缺貨!",
		"by": "通過"
	},
	"common-list": {
		"no-data": "沒有找到記錄",
		"reset": "重置",
		"warning": "警告",
		"deactivate-item-warning": "您確定要停用此項目嗎？",
		"activate-item-warning": "您確定要激活此項目嗎？",
		"delete-item-warning": "你確定要刪除這筆資料嗎？",
		"delete-items-warning": "你確定要刪除這些資料嗎？",
		"delete-yes": "確定刪除",
		"delete-no": "取消刪除",
		"operations": "操作"
	},
	"ad": {
		"list": {
			"data-table-title": "新增廣告",
			"Approve": "同意",
			"Disapprove": "不同意",
			"Stop": "停止"
		}
	},
	"ad-collection": {
		"list": {
			"data-table-title": "分類清單",
			"Ads": "廣告",
			"Company": "公司",
			"Name": "名稱"
		}
	},
	"campaign": {
		"list": {
			"data-table-title": "推廣清單",
			"Deactivate": "關閉選項",
			"Reactivate": "啟動選項",
			"Primary": "主要",
			"PopularNetwork": "互相推廣網絡",
			"CPNetwork": "互相推廣網絡",
			"SelectNetwork": "選擇互相推廣網絡",
			"AddMyOwnNetwork": "新增我自己的網絡",
			"EditMyOwnNetwork": "編輯我自己的網絡",
			"NetworkOwner": "網絡所有者"
		},
		"edit": {
			"Company Type": "公司類型",
			"Choose my own device": "選擇我自己的裝置",
			"Choose others device": "選擇其他裝置",
			"Select Device": "選擇裝置",
			"AddMore": "新增更多裝置",
			"Filter by Company Type": "按公司類型篩選",
			"Filter by Company": "按公司篩選",
			"Related Devices": "相關裝置",
			"Related Companies": "相關公司"
		}
	},
	"buy-device-log": {
		"list": {
			"data-table-title": "裝置購買紀錄清單"
		}
	},
	"website": {
		"list": {
			"data-table-title": "首頁清單"
		}
	},
	"team": {
		"list": {
			"Team List": "團隊清單",
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Logo": "logo標誌",
			"Name": "名稱",
			"Work Content": "工作內容",
			"Member": "成員",
			"Member List": "成員列表",
			"Member Name": "姓名",
			"Email": "電子郵件",
			"Phone": "電話"
		},
		"edit": {
			"Add Team": "新增團隊",
			"Edit Team": "編輯團隊",
			"Logo": "logo標誌",
			"Name": "名稱",
			"Work Content": "工作內容",
			"Back": "返回",
			"Save": "保存",
			"required": "必填",
			"Add success": "新增成功",
			"Edit success": "編輯成功"
		}
	},
	"menu": {
		"list": {
			"data-table-title": "選單清單"
		}
	},
	"ad-product": {
		"list": {
			"data-table-title": "產品清單"
		}
	},
	"category": {
		"list": {
			"data-table-title": "產品類別清單"
		}
	},
	"promotion-log": {
		"list": {
			"data-table-title": "促銷記錄清單"
		}
	},
	"purchase-log": {
		"list": {
			"data-table-title": "購買紀錄清單"
		}
	},
	"loyalty-club": {
		"list": {
			"Loyalty Club": "會員俱樂部",
			"Add": "新增",
			"Delete": "刪除",
			"Search": "搜尋",
			"Operations": "操作",
			"Logo": "logo標誌",
			"Name": "名稱",
			"Introduction": "介紹",
			"Member": "會員",
			"Invite": "邀請",
			"Member List": "會員列表",
			"Invite Users": "邀請用戶",
			"Member Name": "姓名",
			"Email": "電子郵件",
			"Phone": "行動電話",
			"You will invite users": "您將邀請指定電子郵件的用戶加入您的會員俱樂部",
			"Cancel": "取消",
			"Send": "發送",
			"required": "必填",
			"not valid": "無效的",
			"cannotDeleteClub": "您無法刪除具有成員的俱樂部。"
		},
		"edit": {
			"Add Loyalty Club": "建立會員俱樂部",
			"Edit Loyalty Club": "編輯會員俱樂部",
			"Logo": "logo標誌",
			"Name": "名稱",
			"Introduction": "介紹",
			"Back": "返回",
			"Save": "保存",
			"required": "必填",
			"Add success": "新增成功",
			"Edit success": "編輯成功",
			"ExternalUrl": "外部連結",
			"Question": "問題",
			"Appointment questions": "預約問題",
			"Additional Features": "附加功能",
			"Checkout with my existing merchant account": "使用我的商家帳戶結帳",
			"Checkout as a guest": "以訪客身份結帳",
			"Create a new merchant account and checkout": "建立新商家帳戶並結帳"
		}
	},
	"order": {
		"View Order": "查看訂單",
		"All": "全部",
		"payPending": "尚未付款",
		"Success": "付款成功",
		"Pending": "等待處理",
		"Processing": "處理中",
		"Prepared": "準備完成",
		"Finished": "訂單完成",
		"Canceled": "訂單已取消",
		"Returned": "訂單已退貨",
		"Return-Requested": "退貨請求",
		"Return-Rejected": "拒絕退貨",
		"Pending:": "等待處理：",
		"Processing:": "處理中：",
		"Prepared:": "準備完成：",
		"Finished:": "訂單完成：",
		"Canceled:": "訂單已取消：",
		"Returned:": "訂單已退貨：",
		"Return-Requested:": "退貨請求：",
		"Return-Rejected:": "拒絕退貨：",
		"Search": "搜尋",
		"Order Number:": "訂單編號：",
		"Table Number:": "桌號：",
		"Products": "產品",
		"No": "編號",
		"SKU": "存貨單位",
		"Product": "產品",
		"Note": "備註",
		"AddOrderNote": "添加訂單備註",
		"OrderNote": "訂單備註",
		"choosepickupTime": "選擇取貨時間",
		"pickupTime": "取貨時間",
		"Unit Price": "單價",
		"Quantity": "數量",
		"Price": "小計",
		"Payment Information": "付款資訊",
		"Customer Name": "客戶姓名",
		"Customer Email": "客戶電子郵件",
		"Customer Phone": "客戶行動電話",
		"CreatedAt": "訂單建立日期",
		"UpdatedAt": "訂單最後更新日期",
		"Total Price": "總價",
		"Payment Method": "付款方式",
		"Payment Status": "付款狀態",
		"Order Status": "訂單狀態",
		"Please contact the customer!": "請聯絡客戶！",
		"Click to Change": "點擊改變訂單狀態",
		"Order Status: Prepared": "訂單狀態：準備完成",
		"Send notification to the customer": "寄送通知給客戶",
		"Cancel": "取消",
		"OK": "確定",
		"Finish Order": "完成訂單",
		"Ensure the customer has picked up": "確定客戶已完成取貨",
		"Confirm Return-Requested": "確認退貨請求",
		"Reject Return-Request": "拒絕退貨請求",
		"Ensure the customer has returned the products": "確定客戶已經完成退貨手續",
		"Ensure you have refunded": "確定已退款",
		"to the customer": "給客戶",
		"Order Number": "訂單編號",
		"Table Number": "桌號",
		"Detail": "詳情",
		"Cash": "取貨付款",
		"Merchant": "商家",
		"Pay Now": "前往付款",
		"Operation": "操作",
		"Cancel This Product": "取消這項產品",
		"Get Receipt": "取得收據",
		"(password hint: customer phone)": "（密碼提示：客戶手機號碼）",
		"Cash on Delivery": "取貨付款",
		"Payment Method: Cash on Delivery": "付款方式：取貨付款",
		"Please wait for the merchant to contact you!": "請等待商家聯絡您！",
		"Cancel This Order": "取消這筆訂單",
		"Return This Order": "請求退貨",
		"Merchant Information": "商家資訊",
		"Company Name": "商家",
		"Phone": "電話",
		"Address": "地址",
		"Website": "網站",
		"Credit Card": "Credit Card",
		"Payment Method: Credit Card": "付款方式：信用卡"
	},
	"homePage": {
		"Price": "價格",
		"monthly": "每月",
		"learnmore": "了解更多"
	},
	"rules": {
		"required": "必填",
		"not valid": "無效的",
		"Name is required": "名稱為必填",
		"Name is too long": "名稱太長",
		"Job Title is required": "職位名稱為必填",
		"text is required": "文字為必填",
		"company is required": "公司為必填",
		"Category Name is required": "類別名稱為必填",
		"name is required": "必填名稱",
		"E-mail is not valid": "電子郵件無效",
		"Introduction is required": "介紹為必填",
		"Loyalty Club is required": "會員俱樂部為必填",
		"Product is required": "產品為必填",
		"Start Date is required": "開始日期為必填",
		"End Date is required": "結束日期為必填",
		"Discount is required": "折扣為必填",
		"Invalid": "無效",
		"Required": "必填",
		"Min 8 characters": "最少8個字符",
		"The password you entered not match": "您輸入的密碼不匹配",
		"E-mail or mobile phone is required": "電子郵件或手機為必填",
		"First Name is too long": "名字太長",
		"Last Name is too long": "姓氏太長"
	},
	"loadMore": "加載更多",
	"Business Owner": "商家",
	"Team Member": "團隊人員",
	"Profile": "簡介",
	"Member Site": "會員網站",
	"Ad player": "廣告播放器",
	"Help": "小幫手",
	"UserManual": "用戶指南",
	"Login": "會員登入",
	"Register": "註冊",
	"AD": "廣告",
	"Join Now": "立即加入",
	"Register Now": "立即免費註冊",
	"Threesteps": "免費開啟商機",
	"Threesteps2": "步驟",
	"Last updated": "最近更新時間",
	"UseBasicRole": "使用基本角色",
	"I have read and agree to the": "我已經閱讀並且同意",
	"Make deposit": "存款",
	"Checkout with my merchant account.": "使用我的商家帳戶結帳",
	"Checkout as a guest.": "以訪客身份結帳",
	"Sign out": "登出",
	"Choose Payment": "選擇付款方式",
	"mediaLibrary": {
		"ExpertMode": "進階模式",
		"ActivateLinePay": "開啟Line Pay收款",
		"LineChannelID": "Line Pay (Channel ID)",
		"LineSecretKey": "Line Pay (Channel Secret Key)",
		"company": "公司",
		"product": "產品",
		"activities": "廣告",
		"SystemAdsAssets": "系統廣告資源",
		"CompanyAdsAssets": "公司廣告資源",
		"selectAltInfo": "請選擇您已有的廣告資源或直接上傳",
		"backToParent": "返回上級文件夾",
		"goIntoChild": "進入子文件夾",
		"addFolder": "新增文件夾",
		"editItem": "編輯項目",
		"noFilesInfo": "您尚未在此處上傳資源，請點下面的彩色區域上傳或直接拖曳文件，支援上傳圖片，影片和壓縮文件",
		"okSelect": "確認選擇",
		"filterBy": "篩選依據",
		"theme": "主題",
		"label": "標籤",
		"AdsMediaAssets": "廣告資源",
		"selectFromAssets": "從已存的廣告資源中選擇",
		"Advanced": "進階選項",
		"addtionalOptions": "附加選項",
		"chooseOrDragFile": "選擇一個文件或將其拖到此處。",
		"imageFileLimit": "圖片可用副檔名為 : jpeg, jpg, png, bmp",
		"videoFileLimit": "影片可用副檔名為 : mp4",
		"MaxFileSize": "可用的最大文件大小： image 5MB & video 30MB",
		"parentFolder": "上級文件夾",
		"displayName": "文件夾名稱",
		"zipFolderInfo": "服務器正在處理你的壓縮文件，請耐心等待處理結果頁。如果長時間未出現結果頁，請嘗試重新上傳或聯繫管理員。",
		"cancelUploadingAlert": "上傳作業尚未完成。您要取消上傳這個文件嗎？",
		"uploading": "正在上傳 {uploading} 個文件",
		"uploaded": "成功上傳 {uploaded} 個文件",
		"Add": "新增"
	},
	"submit": "保存",
	"cancel": "取消",
	"Clear": " 刪除",
	"Add": " 新增",
	"quickStart": {
		"No recent notifications": "沒有最新通知。",
		"title": "簡易上架",
		"PurchaseDevice": "購買裝置",
		"CreateAd": "建立廣告",
		"CreateAdCollection": "建立廣告集",
		"CreateCampaign": "建立推廣活動",
		"prev": "上一步",
		"next": "下一步",
		"skip": "跳過",
		"save": "保存",
		"notPurchasedDevice": "您尚未購買任何裝置，可以到“裝置”購買或選擇其他公司的裝置",
		"notFoundDevices": "找不到裝置",
		"myCompanyDevices": "我的裝置",
		"otherCompanyDevicesNearBy": "附近的裝置",
		"otherCompanyDevices": "其他公司裝置",
		"Next": "下一步",
		"Work Done": "完成 !",
		"Shortcut": "捷徑",
		"Manage Advertising": "查看與管理廣告",
		"Manage Device Enable Campaigns": "查看裝置並啟用推廣活動",
		"CRM": "客戶關係管理",
		"Manage Company": "管理公司",
		"Skip This Step": "跳過此步驟"
	},
	"dashboard": {
		"ads": "廣告",
		"devices": "裝置",
		"campaigns": "推廣活動",
		"loyaltyClubMembers": "俱樂部會員",
		"adsLabel": "發布的廣告總數",
		"devicesLabel": "您購買的裝置總數",
		"campaignsLabel": "您建立的推廣活動總數",
		"loyaltyClubMembersLabel": "加入俱樂部會員數",
		"downloadApp": "下載 Adsmodo",
		"promoteApp": "透過免費廣告宣傳您的產品",
		"merchantApp": "商家控制器",
		"playerApp": "廣告播放器"
	},
	"User create error": "使用者建立失敗",
	"The email already exists. Please try another one.": "此電子郵件地址已有人使用",
	"The company type already exists. Please try another one.": "公司類型已存在。 請嘗試另一個。",
	"There are users in the company and cannot be deleted.": "此公司已存在用戶，無法刪除",
	"There are loyalty clubs in the company and cannot be deleted.": "此公司已存在會員俱樂部，無法刪除",
	"There are devices in the company and cannot be deleted.": "此公司已存在裝置，無法刪除",
	"There are campaigns in the company and cannot be deleted.": "此公司已有推廣活動進行中，無法刪除",
	"There are users in the team and cannot be deleted.": "此團隊已存在用戶，無法刪除",
	"There are users who are the role and cannot be deleted.": "用戶已使用角色，無法刪除",
	"Please register first!": "請先註冊！",
	"pre": "數據庫錯誤",
	"server error": "伺服器錯誤",
	"User has been exist, Please sign in!": "使用者已存在, 請登入!",
	"Please select default club": "請選擇預設的俱樂部",
	"Account and password or passcode was required!": "帳號、密碼及驗證碼為必填!",
	"Password is required.": "密碼為必填",
	"Please enter your name": "請輸入姓名",
	"You have joined the club": "您已經加入此會員俱樂部！",
	"Please enter the name": "請輸入姓名",
	"Apply": "應用",
	"OK": "確定",
	"Cancel": "取消",
	"Crop": "截圖",
	"Delete": "刪除",
	"DeleteAll": "刪除所有",
	"Undo": "復原",
	"Redo": "重做",
	"Reset": "重置",
	"Rotate": "旋轉",
	"Draw": "繪製",
	"Shape": "形狀",
	"Icon": "圖形",
	"Text": "文字",
	"Mask": "遮罩",
	"Filter": "篩選器",
	"Business Company": "所屬公司",
	"Update company error": "公司更新錯誤",
	"All rights reserved": "版權所有。",
	"The ad has been used, please delete ad collection first.": "此廣告已在廣告分類中使用，無法刪除",
	"The ad collection has been used, please delete campaign first.": "此廣告集已在推廣活動中使用，無法刪除",
	"Variants quantity exceeds total quantity": "產品種類數超過總數",
	"Variant SKU should be unique for each": "每個產品種類的存貨單位SKU均應為獨一無二",
	"There are products in the category and cannot be deleted.": "有產品在此類別中，無法刪除",
	"Thank you for your message": "感謝您的來信！",
	"We will contact you as soon as possible": "我們會盡快回覆您",
	"passcode not matched": "passcode not matched",
	"Post Ads in Just Three Steps": "只需三步驟即可發布",
	"schedule": {
		"doctor": {
			"required": "請選擇醫生",
			"scheduled": "此時已安排好醫生"
		},
		"Contact Name": "聯繫人姓名",
		"Contact Email": "聯繫電子郵件",
		"Contact Mobile": "聯繫手機",
		"Schedule Time": "預約時間",
		"Doctor": "醫生",
		"Club Name": "俱樂部",
		"Make an appointment": "預約登記",
		"description": "選擇醫生進行預約",
		"Select the appointment time": "選擇預約時間",
		"onDuty": "值班",
		"appointment": "預約"
	},
	"Could not update the ad. The ad has been used in the active campaign.": "無法更新廣告，該廣告已在推廣廣告使用中",
	"passcode hasn't created yet.": "密碼尚未建立.",
	"The device has been bound, Please untie it first.": "裝備已綁定,請先解除綁定.",
	"Cannot read property 'replace' of undefined": "參數提取錯誤Cannot read property 'replace' of undefined",
	"User info verify error": "用戶資訊驗證錯誤",
	"Please input your Name.": "請輸入您的名字",
	"Please input your Email or Cell Phone.": "請輸入您的電子信箱或手機號碼",
	"Please input your Cell Phone.": "請輸入您的手機號碼",
	"Resource is not found": "無法找到資源",
	"You already have an account with this email. Please sign in instead.": "您已經擁有使用此電子郵件的帳戶。 請改為登錄。",
	"Phone is required.": "需要電話。",
	"Device": "裝置",
	"The device does not exist or has been deleted": "該設備不存在或已被刪除",
	"New Order has been submitted using your device.": "新訂單已使用您的設備提交。",
	"You do not have the permission!": "You do not have the permission!",
	"Line pay error": "Line pay 錯誤",
	"Please input Line Pay (Channel ID or Channel Secret Key)": "請輸入 Line Pay (Channel ID 或 Channel Secret Key)",
	"Successfully bind with your goole account. You can now use google sign in!": "與您的帳戶成功綁定。 您現在可以登錄了！",
	"Successfully bind with your goole account": {
		" You can now use google sign in!": "與您的帳戶成功綁定。 您現在可以登錄了！"
	},
	"OrderUpdated": "OrderUpdated",
	"You have exceeded maximum campaigns for your plan!": "您的計劃已超過最多個廣告系列。",
	"Current plan had expired!": "當前計劃已過期！",
	"Name create error": "名称错误",
	"Duration error": "时长错误"
};