module.exports = {
	"Account or password was incorrect!": "帐户或密码不正确!",
	"Can't get your device info": "无法获取您的设备信息",
	"Device does not exist or has been deleted, please reselect": "设备不存在或已被删除，请重新选择",
	"Duplicate current plan": "复制当前计划",
	"Email and passcode is required!": "电子邮件和密码为必填项！",
	"Email or password is incorrect": "电子邮件或密码不正确",
	"Find device error": "查找设备错误",
	"Find user error": "查找用户错误",
	"Get current customer plan is required": "需要获取当前客户计划",
	"Hello": "你好",
	"Id is required!": "必须输入ID！",
	"Inconsistent password": "密码不一致",
	"Member does not exist, Please try again later!": "成员不存在，请稍后再试！",
	"No id": "没有编号",
	"Please enter the device name": "请输入设备名",
	"Please enter your email": "请输入您的电子邮件",
	"Please enter your mobile": "请输入您的手机",
	"Please select an item first": "请先选择项目",
	"Signin error": "登录错误",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "请在审核过程中耐心等待您的帐户",
		"Your account does not have a subscription. Please subscribe to post an ad": "您的帐户没有订阅。请订阅发布广告"
	},
	"Swal": {
		"blockerText": "通过向访问者显示在线广告，使我们的网站成为可能。",
		"blockerText2": "请考虑通过禁用您的广告拦截器来支持我们。",
		"member_sign_up": {
			"Please register to be part of this club": "請註冊成為該俱樂部的成員"
		},
		"More than available stock": "超过可用库存",
		"Are you sure?": "你确定吗？",
		"You will not be able to recover this!": "你将无法恢复这一切！",
		"Yes, delete it!": "是的，删除它！",
		"No, cancel plx!": "不，取消 plx！",
		"You must register your company first!": "你必须先注册你的公司！",
		"Exceed the maximum number of Ad": "超过广告数量上限",
		"Upgrade your account,and create more Advertising": "升级您的帐户，并创建更多广告",
		"Out of stock": "缺货",
		"Please select the variant!": "请选择变体！",
		"Invalid coupon code": "无效优惠券代码",
		"Invalid coupon code for this product!": "此产品的优惠券代码无效！",
		"Valid coupon code": "有效优惠券代码",
		"Add success": "增加成功",
		"Add success We will": "增加成功,我们会将密码发送到您的电子邮件中。",
		"Already in the cart": "已经在购物车中",
		"Amount should be more than zero": "金额应大于零！",
		"Bind Success": "绑定成功",
		"Buy device success": "购买设备成功",
		"Device type is required": "设备类型为必填项",
		"Position is required": "位置必须填",
		"Please enter the device number": "请输入设备编号",
		"Edit success": "编辑成功",
		"End date must be greater than start date!": "结束日期必须大于开始日期！",
		"Please select a valid image": "请选择一个有效的图像",
		"Please select a valid video": "请选择一个有效的视频",
		"Session time out.Please log in again": "会话超时。请重新登录",
		"Something went wrong": "有些不对劲",
		"Subscription success": "订阅成功",
		"Successfully approved": "成功批准！",
		"Successfully generated": "成功生成！",
		"Your profile has been successfully updated": "您的个人资料已成功更新",
		"account": {
			"Cancel Subscription success": "取消订阅成功",
			"Cancel subscription error": "取消订阅错误",
			"The current user does not support this feature at this time": "当前用户当前不支持此功能。",
			"This operation is not supported at this time": "目前不支持此操作"
		},
		"ad": {
			"Ad created successfully": "广告制作成功",
			"Ad edited successfully": "广告编辑成功",
			"Cancel": "取消",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "恭喜！您已经制作了第一个广告。您想继续吗？"
			},
			"Continue": "继续",
			"Product category is needed!": "需要产品类别！",
			"Product name is needed!": "需要产品名称！",
			"success info": "成功信息"
		},
		"sign": {
			"Agreement Required": "需要協議",
			"Please agree the Terms & Condtions": "請同意條款和條件",
			"Please improve the information": "请完善信息。",
			"Business address invalid, please enter again": "商家地址无效，请重新输入。",
			"Company type require!": "公司类型要求！",
			"Invalid recaptcha token": "无效的验证码令牌",
			"Password doesnot match!": "密码不匹配！",
			"Please post your ad now": "请立即发布您的广告。",
			"Purchase fail": "购买失败",
			"Purchase success": "购买成功",
			"The Passcode have been send success": "密码已发送成功",
			"The passcode have been send successfully": "密码已成功发送",
			"The password and confirm password not the same": "密码和确认密码不一样"
		},
		"You need to set up your payment method to purchase devices": "您需要设置付款方式才能购买设备",
		"Payment method is required": "需要付款方式"
	},
	"The ad does not exist or has been deleted": "广告不存在或已被删除",
	"The device has been bound, Please untie it first": {
		"": "The device has been bound, Please untie it first."
	},
	"The device is not exist": {
		"": "The device is not exist."
	},
	"The email is already registered!": "该电子邮件已被注册！",
	"The item does not exist or has been deleted": "项目不存在或已被删除",
	"The mobile number is already registered!": "手机号码已经注册！",
	"The user does not exist or has been deleted": "用户不存在或已被删除",
	"Update plan error": "更新计划错误",
	"User does not exist, Please try again later!": "用户不存在，请稍后再试！",
	"You do not have permission": "你没有权限",
	"ad-template": {
		"edit": {
			"Add Builder Template": "新增广告样本",
			"Back": "返回",
			"Builder Template": "广告样本",
			"Edit Builder Template": "编辑广告样本",
			"Name": "名字",
			"Save": "保存",
			"Status": "状态",
			"Type": "种类"
		},
		"list": {
			"Builder Template": "广告样本",
			"Builder Template List": "广告样本清单",
			"Name": "名字",
			"Status": "状态",
			"Type": "种类",
			"data-table-title": "广告样本清单"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "广告交易",
			"AD Transaction List": "广告交易清单",
			"Ads": "广告",
			"Collection": "分类",
			"Device": "装置",
			"Type": "类型",
			"Campaign": "运动",
			"Number": "数量"
		}
	},
	"admin-nav": {
		"Tutorial": "讲解",
		"Logout": "登出",
		"Manager": "经理",
		"New Enquiry": "新查询",
		"Online": "在线",
		"Pending Ad": "待处理广告",
		"Pending User": "待处理的用户",
		"Profile": "轮廓",
		"User": "用户",
		"User Manual": "用户手册",
		"Select": "选择"
	},
	"ad": {
		"list": {
			"data-table-title": "新增广告",
			"Approve": "同意"
		}
	},
	"ads": {
		"edit": {
			"3D Effect Link": "3D特效超连结",
			"Ad Template": "广告范例",
			"Add Advertising": "新增广告",
			"Additional Discount": "额外折扣",
			"Advanced": "高级",
			"Advertising": "广告",
			"Advertising End Date": "到期日",
			"Approval": "同意",
			"Approved": "已同意",
			"Back": "返回",
			"Duration (s)": "广告长度(秒)",
			"Edit Advertising": "编辑广告",
			"Extra Link": "额外连结",
			"Name": "名字",
			"Pending": "审查中",
			"Preview": "预览",
			"Product Purchase link": "产品购买链接",
			"Product Purchasing": "产品采购",
			"Promotional Text": "促销文字",
			"Reject": "拒绝",
			"Rejected": "拒绝",
			"Save": "保存",
			"Second(s)": "秒",
			"Select products for this Ad": "选择此广告的产品",
			"Status": "状态",
			"Upgrade": "更新",
			"Video Url": "影片 Url",
			"Warning!": "警告",
			"Recent uploaded files": "最近上传的文件",
			"Currently, you have no videos": "目前，您的云存储上没有视频。立即创建广告。",
			"Currently, you have no images": "目前，您的云存储中没有图像。立即创建广告。",
			"Close": "关闭",
			"Cancel": "取消",
			"Confirm": "确认",
			"Enter Name": "请输入名字",
			"Enter Duration (s)": "请输入持续时间",
			"abbr": {
				"create": "建立自己的广告",
				"preview": "预览广告",
				"warning content": "广告余额不足，请升级。"
			}
		},
		"list": {
			"Advertising": "广告",
			"Advertising List": "广告清单",
			"Approval Status": "审查状态",
			"Approved": "已同意",
			"Due Date": "到期日",
			"Duration (s)": "广告长度(秒)",
			"Name": "名字",
			"Pending": "审查中",
			"Products": "制品",
			"Created Date": "创建日期",
			"Status": "状态",
			"Type": "类别"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "AR连结 / 3D连结",
			"Ad Name": "广告名称",
			"Ad Template": "广告范例",
			"All": "全部",
			"Campaign Name": "活动名称",
			"Cancel": "取消",
			"Collection Name": "类别",
			"Company Type": "公司类别",
			"Contact Me 3D Link": "联络我3D连结",
			"Contact Me Video Link": "联络我影片连结",
			"Create your own ads": "设计自己的广告",
			"Device List": "装置清单",
			"Duration (s)": "广告长度(秒)",
			"Edit": "编辑",
			"End Date": "结束日期",
			"Finish": "完成",
			"Let me think": "再考虑一下",
			"Maps": "地图",
			"Name": "名字",
			"Next": "下一步",
			"On The Map": "地图",
			"Previous": "上一步",
			"Publish to Device": "发布到装置",
			"Publishing Device": "发布到装置",
			"Reject": "拒绝",
			"Search": "搜寻",
			"Second(s)": "秒",
			"Shop Link": "商店连结",
			"Start Date": "开始日期",
			"Step": "步骤%{num}",
			"Submit": "送出",
			"Success": "成功",
			"Text": "文本",
			"abbr": {
				"another": "再创造一个",
				"created": "广告已建立",
				"device select": "选择你要发布到的装置",
				"introduction": "你可以透过这个网站发布广告，宣传自己的产品、餐厅，并选择想要拨放广告的装置",
				"privilege intro": "在您升级以后，可以享受以下特权",
				"start": "开始创造广告!",
				"step1": "第一步:基本资料",
				"step2": "步骤2:设计广告",
				"upgrate check": "确定要更新帐号吗?",
				"wait": "请等待审核",
				"welcome": "欢迎来到Adsmodo",
				"yes": "我确定"
			},
			"another": "再创造一个",
			"created": "广告已建立",
			"introduction": "你可以透过这个网站发布广告，宣传自己的产品、餐厅，并选择想要拨放广告的装置",
			"welcome": "欢迎来到Adsmodo"
		}
	},
	"website": {
		"list": {
			"data-table-title": "主页列表"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "主页",
				"Home Page List": "主页列表",
				"Title": "标题",
				"Created At": "创建于",
				"Updated At": "更新于"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "添加主页",
				"Website Link": "网站链接",
				"Company Type List": "公司类型列表",
				"ADVERTISEMENT Content": "广告内容",
				"About Us": "关于我们",
				"Banner": "旗帜",
				"Contact Us": "联络我们",
				"Content": "内容",
				"Edit Home Page": "编辑首页",
				"How to use": "如何使用",
				"Image": "图片",
				"Introduction": "介绍",
				"Menu Name": "选单名称",
				"Multiple select": "多重选择",
				"Project": "专案",
				"SHOPKEEPER Content": "店主内容",
				"Scenario": "脚本",
				"Sub Title": "副标题",
				"Team Member": "团队人员",
				"Title": "标题",
				"Overall": "总体",
				"Font Color": "字体颜色",
				"White": "白",
				"Black": "黑",
				"left": "左",
				"center": "中",
				"right": "右",
				"Edit": "编辑",
				"Add": "填加",
				"Title Position": "头衔位置",
				"Background Transparent": "背景透明",
				"Left/Right Image": "",
				"Left Title": "左标题",
				"Left Content": "剩余内容",
				"Right Title": "正确的标题",
				"Right Content": "正确的内容",
				"Scenario List": "方案清单",
				"Team List": "队名单",
				"Website": "网站",
				"EditHomePage": "编辑首页"
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "编辑方案",
				"Add Scenario": "添加方案",
				"Scenario Image": "方案图像",
				"Name": "姓名",
				"Description": "描述"
			},
			"list": {
				"Scenario": "方案",
				"Scenario List": "方案列表",
				"Image": "图片",
				"Name": "姓名",
				"Description": "描述"
			}
		},
		"team": {
			"edit": {
				"Add Team Member": "新增团队成员",
				"Edit Team Member": "编辑团队成员",
				"Image": "图片",
				"Job Title": "工作标题",
				"Name": "名字",
				"Text": "文字内容",
				"companyList": "公司列表",
				"Invite Users": "邀请用户",
				"You will invite users": "您将邀请指定电子邮件的用户以管理员身份加入您的团队",
				"Send Mail": "发送邮件",
				"Cancel": "取消",
				"Invite": "邀请",
				"Email：": "电子邮件：",
				"Phone: ": "电话："
			},
			"list": {
				"Team": "团队",
				"Team List": "团队列表",
				"Image": "图片",
				"Job Title": "职位名称",
				"Created At": "创建于",
				"Ad Type": "广告类别",
				"Name": "名字",
				"Team Member List": "团队成员清单"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "编辑菜单",
				"Add Menu": "添加菜单",
				"Name": "姓名",
				"Menu Type": "菜单类型",
				"Parent Menu": "父菜单",
				"Link": "链接",
				"Show On Footer": "在页脚上显示",
				"Please enter the name": "请输入名称",
				"Please enter the link": "请输入链接"
			},
			"list": {
				"Menu": "菜单",
				"Menu List": "菜单列表",
				"Name": "姓名",
				"Type": "类型",
				"Parent": "父",
				"Link": "链接"
			}
		}
	},
	"already registered!": "注册过了！",
	"bcrypt error": "bcrypt 错误",
	"campaigns": {
		"edit": {
			"Add Campaign": "新增活动",
			"All": "全部",
			"Back": "返回",
			"Cancel": "取消",
			"Company Type": "公司类别",
			"Edit Campaign": "编辑活动",
			"End Date": "结束日期",
			"Finish": "完成",
			"Maps": "地图",
			"Name": "名字",
			"On The Map": "位置",
			"Publishing Device": "发布至装置",
			"Save": "保存",
			"Search": "搜寻",
			"Select Ad Collection": "选择广告分类",
			"Search devices": "搜索设备",
			"Open Map": "打开地图",
			"Target Devices": "目标设备",
			"Start Date": "开始日期",
			"abbr": {
				"Select device": "选择设备",
				"select": "请选择你要发布至的装置"
			},
			"Search Devices": "搜索设备",
			"Company": "公司",
			"City": "城市",
			"State": "州",
			"Distance": "距离",
			"Map": "地图",
			"placeholderName": "请输入姓名",
			"PleaseSelectAdCollection": "请选择广告集",
			"YourTargetDevices": "您的目标设备",
			"Successfully modified status": "修改状态成功"
		},
		"list": {
			"Ad Collections": "广告分类",
			"Campaigns": "活动",
			"Campaigns List": "活动清单",
			"Devices": "装置",
			"End Date": "结束日期",
			"Name": "名字",
			"Start Date": "开始日期",
			"Status": "状态"
		}
	},
	"cart": {
		"Adsmodo": "Adsmodo",
		"Apply": "应用",
		"Continue Shopping": "继续购物",
		"My Cart": "我的车",
		"Order Summary": "订购摘要",
		"Price": "价钱",
		"Proceed to Checkout": "进行结算",
		"Product": "产品",
		"QTY": "数量",
		"Seller": "卖家",
		"Shipping": "运输",
		"Shipping Fee": "运输费用",
		"Sub Total": "小计",
		"Total": "总",
		"You save": "您 保存",
		"Coupon code": "优惠代码",
		"promotion": "促銷碼",
		"Please select your gift Card code": "請選擇你的禮品碼"
	},
	"ad-collection": {
		"list": {
			"data-table-title": "分类清单",
			"Ads": "广告",
			"Company": "公司",
			"Name": "名字"
		}
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "新增分类",
			"Back": "返回",
			"Edit Ad Collection": "编辑分类",
			"Name": "名字",
			"Publishing Device": "发布到装置",
			"Save": "保存",
			"Select Ads": "选择广告",
			"placeholderName": "请输入姓名",
			"placeholderSelectAds": "请选择广告"
		},
		"list": {
			"Ads": "广告",
			"Ads Collection": "广告分类",
			"Ads Collection List": "分类清单",
			"Name": "名字"
		}
	},
	"common": {
		"404": {
			"abbr": {
				"back": "返回主页面",
				"sorry": "抱歉，当前的网页无法找到"
			}
		},
		"Search": "搜索",
		"ad-image": {
			"Click to select picture": "点击来选择图片"
		},
		"ad-video": {
			"Click to select video": "点击来选择影片"
		},
		"bind-credit-card": {
			"Bind": "绑定",
			"CVC": "CVC",
			"Card Number:": "卡片号码:",
			"MM": "MM",
			"YYYY": "YYYY",
			"Please enter the card number": "请输入卡号",
			"Please enter the expiry month": "请输入有效期限",
			"Please enter a valid month": "请输入有效月份",
			"Please enter the expiry year": "请输入有效期限",
			"Please enter a valid year": "请输入有效年份",
			"Please enter the card cvc": "请输入卡的cvc",
			"Please enter a valid cvc": "请输入有效的cvc",
			"Expiry Month": "到期月",
			"Expiry Year": "到期年",
			"Card Cvc": "信用卡Cvc"
		},
		"select-ad-device": {
			"Device List": "装置清单",
			"Let me think": "再考虑一下",
			"Yes, I'm Sure!": "好，我确定!",
			"abbr": {
				"plan": "以100美金/月的方案癌订阅我们",
				"upgrade check": "确定要更新帐号吗?"
			}
		},
		"tui-image-editor": {
			"Cancel": "取消",
			"Edit image": "编辑图片",
			"Image Resolution :": "图片解析度",
			"Load Assets": "载入资产",
			"Load Promotion Asset": "载入促销资产",
			"Load assets": "载入资产",
			"Promotion assets": "促销资产",
			"Save": "保存"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "新增公司类别",
			"Back": "返回",
			"Edit Company Type": "编辑公司类别",
			"Name": "名字",
			"Save": "保存",
			"description": "描述",
			"Description": "描述",
			"Image": "图片",
			"Icon": "图标",
			"Company Icon": "公司图标"
		},
		"list": {
			"Company Type": "公司类别",
			"Company Type List": "公司类别清单",
			"Created Date": "创造日期",
			"Name": "名字",
			"Icon": "图标",
			"Image": "图片",
			"data-table-title": "公司类别清单"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "广告",
			"Ad Collection": "广告分类",
			"Back": "返回",
			"Cell Phone": "手机号码",
			"Contact Me": "联络我们",
			"Device": "装置",
			"Email": "信箱"
		},
		"list": {
			"Ad": "广告",
			"Campaign": "活动",
			"Collection": "分类",
			"Contact Me": "联络我们",
			"Contact Me List": "联络我们清单",
			"Device": "装置",
			"Email": "信箱",
			"Phone Number": "电话号码",
			"action": "动作",
			"Mobile": "电话",
			"data-table-title": "联络我们清单"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "商业地址",
			"Contacts": "联络",
			"Contacts List": "联络清单",
			"Email": "信箱",
			"Is Admin": "管理员权限",
			"Mobile": "电话号码",
			"Name": "名字"
		}
	},
	"customer failed: create customer failed": "客户失败：创建客户失败",
	"customer failed: create token failed": "客户失败：创建令牌失败",
	"dataTable": {
		"Add": "添加",
		"Are you sure to deactivate it?": "您确定要停用它吗？",
		"Are you sure to delete it?": "您确定要删除它吗？",
		"Cancel": "取消",
		"Choose your options below and drop one into the inbox of anyone on your list!": "在下面选择您的选项，然后将其放入列表中任何人的收件箱！",
		"Confirm": "确认",
		"Deactivate": "停用",
		"Delete": "删除",
		"Operate": "操作",
		"Please select the item to delete": "请选择要删除的项目。",
		"Please select the loyalty member to give reward": "请选择会员给予奖励！",
		"Reward Gift Card": "奖励礼品卡",
		"This operation cannot be undone": "此操作无法撤消。",
		"We offer electronic gift cards in any denomination": "我们提供任何面额的电子礼品卡。",
		"Info": "您的订阅更改仅从下个月开始生效。",
		"Name": "名称",
		"Address": "地址",
		"The Location is required": "位置为必填项"
	},
	"database error": "数据库错误",
	"buy-device-log": {
		"list": {
			"data-table-title": "装置购买纪录清单"
		}
	},
	"devices": {
		"addCampaign": {
			"Ad Collections": "广告集",
			"Ad Images": "广告图片",
			"Ad List": "广告清单",
			"Add Campaign": "添加广告活动",
			"Availavle Campaigns": "可用活动",
			"Back": "返回",
			"Campaign Detail": "广告活动详细资料",
			"Campaign List": "活动清单",
			"Campaigns": "活动",
			"Device": "装置",
			"Device ID": "装置ID",
			"Device Name": "装置名称",
			"Drag available campaigns here to run on device": "将可用的广告系列拖到此处以在设备上投放",
			"Duration(s)": "持续时间",
			"End Date": "结束日期",
			"Name": "名称",
			"Save": "保存",
			"Start Date": "开始日期",
			"Status": "状态",
			"This ad have no images": "这则广告没有图片"
		},
		"buy-log": {
			"Buy Device Log": "装置购买纪录",
			"Buy Device Log List": "装置购买纪录清单",
			"Name": "名字",
			"Quantity": "数量",
			"Status": "状态",
			"Unit Price": "每单位价格",
			"Permission": "允许",
			"Order": "订单"
		},
		"buy-product": {
			"Back": "返回",
			"Buy Device": "购买装置",
			"Device Quantity": "装置数量",
			"Location": "位置",
			"Device Type": "装置类别",
			"Purchase": "购买",
			"Add Location": "添加位置",
			"selectPlacementLocation": "选择放置位置",
			"selectTheDeviceType": "选择设备类型",
			"Quantity": "数量",
			"Duration Max": "最大值不应超过4500000000"
		},
		"edit": {
			"Add Device": "新增装置",
			"Are you sure to activate this device ?": "您确定要激活此设备吗？",
			"Are you sure to deactivate this device ?": "您确定要停用此设备吗？",
			"Back": "返回",
			"Business Name": "商家名称",
			"Cancel": "取消",
			"Confirm": "确认",
			"Device ID": "装置ID",
			"Device Info": "设备信息",
			"Device Name": "设备名称",
			"Device Status": "设备状态",
			"Device Type": "设备类型",
			"Edit Device": "编辑装置",
			"Manufacturer": "生产厂家",
			"Model": "模型",
			"Name": "名字",
			"Offline": "离线",
			"Online": "在线",
			"Placement Location": "展示位置",
			"Platform": "平台",
			"Save": "保存",
			"Select Ad Collections": "广告分类",
			"Serial": "串行",
			"Toggle": "切换",
			"UUID": "UUID",
			"Version": "版",
			"Your device is now Offline!": "您的设备现在离线！",
			"Your device is now Online!": "您的设备现已在线！",
			"Your device need to register via App first !": "您的设备需要先通过App注册！"
		},
		"list": {
			"Ad Collections": "广告分类",
			"Businesss Name": "商业名称",
			"Campaigns": "活动",
			"Device ID": "装置ID",
			"Device Info": " 资讯",
			"Devices": "装置",
			"Devices List": "装置清单",
			"Name": "名字",
			"Position": "位置",
			"Status": "状态",
			"User": "使用者",
			"Public Name": "公开名称",
			"Location": "位置",
			"ID": "ID",
			"title": "装置",
			"data-table-title": "装置清单"
		},
		"purchase-log": {
			"Device": "装置",
			"Order No": "订购编号",
			"Purchase Log": "购买纪录",
			"Purchase Log List": "购买纪录清单",
			"Quantity": "数量",
			"Subscription": "订阅方案",
			"Total Price": "总价格",
			"Unit Price": "美单位价格",
			"User Name": "使用者名称",
			"Get Receipt": "获取收据"
		}
	},
	"email": {
		"create-email": {
			"Back": "返回",
			"Email From": "Email寄件者",
			"Message": "讯息",
			"Send": "寄送",
			"Send To": "收件者",
			"Subject": "主旨"
		}
	},
	"email-log": {
		"edit": {
			"Back": "返回",
			"Content": "内容",
			"Created At": "创立于",
			"Email Log": "信件纪录",
			"Name": "名字",
			"Send Status": "寄送状态",
			"Subject": "主旨",
			"Template": "模板",
			"To": "收件者",
			"from": "寄件者"
		},
		"list": {
			"Email Log": "信件纪录",
			"Email Log List": "信件记录清单",
			"Email template": "信件模版",
			"Name": "名字",
			"from": "寄件者",
			"to": "收件者",
			"Send Email": "发送邮件",
			"data-table-title": "信件记录清单"
		}
	},
	"send-email": {
		"Message Type": "讯息类型",
		"message detail": "讯息",
		"Send Message": "发送信息"
	},
	"email-template": {
		"edit": {
			"Add Email Template": "新增信件模板",
			"Available keyword": "可用关键字",
			"Back": "返回",
			"Edit Email Template": "编辑信件模板",
			"Email Body": "信件内容",
			"Email From": "寄件者",
			"Name": "名字",
			"Save": "保存",
			"Send To": "收件者",
			"Status": "状态",
			"Subject": "主旨",
			"abbr": {
				"note": "若您是后台管理员，请用逗号来分隔多张的明细"
			},
			"template Type": "模板类别",
			"message": "使用{{message}}标记将发送给用户的可编辑部分",
			"Convenient code": "便捷代码 :",
			"BillingAddress message": "公司地址 : {{billingAddress}}",
			"CompanyLogo message": "公司徽标 : {{companyLogo}}",
			"CompanyName message": "公司名称 : {{companyName}}",
			"CurrentDateTime message": "当前时间 : {{currentDateTime}}",
			"Website message": "公司网站 : {{webSite}}",
			"Phone message": "公司电话 : {{companyPhone}}"
		},
		"list": {
			"Draft": "编辑中",
			"Email Template": "信件模板",
			"Email Template List": "信件模板清单",
			"Name": "名字",
			"Published": "已发布",
			"Status": "状态",
			"Type": "类别",
			"badge-danger": "危险徽章",
			"badge-success": "成功徽章",
			"data-table-title": "信件模板列表"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "新增查询",
			"Back": "返回",
			"Edit Enquiry": "编辑查询",
			"Email": "信箱",
			"Enquiry Type": "查询类别",
			"Message": "讯息",
			"Name": "名字",
			"Phone": "电话",
			"Reply": "回覆",
			"Save": "保存",
			"Show Enquiry": "显示查询"
		},
		"list": {
			"Created Date": "创建日期",
			"Email": "邮箱",
			"Enquiry": "查询",
			"Enquiry List": "查询清单",
			"Message": "信息",
			"Name": "名字",
			"Reply": "回复",
			"Type": "类别",
			"data-table-title": "查询清单"
		}
	},
	"get ad query error": "获取查询广告信息错误",
	"get ads database error": "获取数据库的广告错误",
	"get item database error ": "获取项目数据库错误",
	"get item query error": "获取查询商品错误",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "卡背面的3位安全码",
		"Amount of Gift Card": "礼品卡金额",
		"CVC": "CVC",
		"Card Number": "卡号",
		"Email From": "电邮来自",
		"Expiration Date": "截止日期",
		"For payment confirmation": "确认付款",
		"Generate Gift Card": "产生礼品卡",
		"Generate Gift Card for the selected loyalty member": "为选定的会员生成礼品卡",
		"Gift Cards": "礼品卡",
		"Gift Cards Log": "礼品卡记录",
		"Recipient Email": "收件人电子邮件",
		"Recipient Name": "收件人姓名",
		"First Name*": "名字*",
		"Last Name*": "姓*",
		"Recipient Email *": "收件人电子邮件 *",
		"Amount *": "总量 *",
		"list": {
			"data-table-title": "礼品卡列表"
		}
	},
	"home": {
		"Accountant": "会计师",
		"Add Product to cart": "新增产品到购物车",
		"Age": "年龄",
		"CRM": "客户关系管理",
		"Complete Purchases": "完成购买",
		"Daily Visits": "每日访问",
		"Dashboard": "控制台",
		"Device Orders": "装置订购数",
		"Edinburgh": "爱丁堡",
		"From Last 1 Month": "来自最近1个月",
		"From Last 24 Hours": "来自最近24小时",
		"Goal Completion": "目标完成进度",
		"Integration Specialist": "整合专家",
		"Junior Technical Author": "初级技术作者",
		"Last 3 Month Ad-Collection Record": "进3个月广告分类纪录",
		"Last 3 Month Subscription Record": "进3个月订阅纪录",
		"Last Month": "上个月",
		"Last week": "上周",
		"Mail Inbox": "信件",
		"Marketplace": "市井",
		"Monthly Earnings": "月收入",
		"Monthly Purchases": "每月购买",
		"Name": "名字",
		"New York": "纽约",
		"Office": "办公室",
		"Orders in Last 10 months": "近10月订购数",
		"Pending Ad": "待审查中的广告",
		"Pending User": "待审查中的用户",
		"Position": "位置",
		"Recent Contacts": "最近联络人",
		"Revenue": "营收",
		"Salary": "薪水",
		"Sales Assistant": "销售助理",
		"San Francisco": "旧金山",
		"Send Inquiries": "发送查询",
		"Senior Javascript Developer": "初级Javascript开发人员",
		"Start date": "开始日期",
		"System Architect": "系统结构师",
		"Tokyo": "东京",
		"Total Subscriptions": "总订阅数",
		"Unique Ad-Viewers": "未重复广告观看人数",
		"Visit Premium plan": "查看进阶方案",
		"Weekly Top 5 Advertisement": "每周前5名广告",
		"select device error": "选择设备错误"
	},
	"id required": "需提供ID",
	"image is required to show ad": "展示广告需要图片",
	"main-nav": {
		"Sign In": "登录",
		"Sign Up": "注册",
		"Terms & Conditions": "條款及細則"
	},
	"member": {
		"About": "关于",
		"Back": "返回",
		"Cancel": "取消",
		"Clear": "清除",
		"Confirm": "确认",
		"Contact Info": "联系信息",
		"Description": "描述",
		"Earned Points": "赚取积分",
		"Edit": "编辑",
		"Email Verification Success": "电子邮件验证成功",
		"Explore": "探索",
		"Got it!": "得到它！",
		"If you experience any issues with this Reward,please feel free to contact": "如果您在此奖励中遇到任何问题，请随时与我们联系",
		"LearnMore": "了解更多",
		"Please enter activation code sent to this number": "请输入发送到该号码的激活码",
		"Points history": "积分记录",
		"Redeem": "可兑换的",
		"Redeemable points can be exchanged for rewards at the Rewards page": "可兑换积分可在“奖励”页面兑换为奖励",
		"Save": "保存",
		"Share": "分享",
		"Special Deals": "特别优惠",
		"Spent Points": "消费积分",
		"Terms and Conditions": "条款和条件",
		"What are Redeemable Points?": "什么是可兑换积分？",
		"Your email has been verified.We will redirect you to home page": "您的电子邮件已通过验证。我们会将您重定向到主页",
		"nav": {
			"Adsmodo Loyalty Members Club": "Adsmodo会员俱乐部",
			"Home": "家",
			"Setting": "设置",
			"Notification": "通知",
			"Log Out": "退出登录",
			"Search": "搜索",
			"My rewards": "我的奖赏",
			"Products": "产品展示",
			"Profile": "个人信息",
			"Purchase History": "购买记录"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "蛋糕已过期",
			"Cake Used": "蛋糕已使用",
			"Roylaty Member Detail": "Roylaty会员详情"
		},
		"form": {
			"Birthday": "生日",
			"Join The Adsmodo Loyalty Club": "加入Adsmodo会员俱乐部",
			"Select Business Type*": "选择业务类型*",
			"Submit": "提交",
			"Team": "团队"
		},
		"list": {
			"Birthday": "生日",
			"Email": "电子邮件",
			"Loyalty Member": "会员",
			"Loyalty Member List": "会员列表",
			"Mobile": "移动",
			"Name": "名称",
			"Points": "点数"
		}
	},
	"no permission": "没有权限",
	"post:Device update error": "下一条：设备更新错误",
	"post:sendPasscode error": "下一条：发送密码错误",
	"post:signin account error": "下一条：登录帐户错误",
	"pre:add ad name validate failed": "上一条：添加广告名称验证失败",
	"pre:audit user query error": "上一条：审核用户查询错误",
	"pre:bind have been bound": "上一条：已绑定",
	"pre:bind verify name failed": "上一条：绑定验证名称失败",
	"pre:bind verify serial failed": "上一条：绑定验证序列失败",
	"pre:delete database error": "上一条：删除数据库错误",
	"pre:find user": "上一条：查找用户",
	"pre:get purchase log database error": "上一条：获取购买记录数据库错误",
	"pre:member get error": "上一条：成员出错",
	"pre:missing parameters": "上一条：缺少参数",
	"pre:sendPasscode find user error": "上一条：发送密码查找用户错误",
	"pre:signin device has been bound": "上一条：登录设备已绑定",
	"pre:signin find user error": "上一条：登录发现用户错误",
	"pre:unbind the device is not exist": "上一条：取消绑定该设备不存在",
	"pre:update get campaign database error": "上一条：更新获取活动数据库错误",
	"pre:update get member database error": "上一篇：更新获取会员数据库错误",
	"pre:update item query error": "上一条：更新项目查询错误",
	"pre:update missing parameters": "上一条：更新缺少参数",
	"pre:verification error": "上一条：验证错误",
	"product": {
		"ads": {
			"Recommended Products": "推荐产品",
			"more products": "更多产品",
			"Learn More": "了解更多"
		},
		"edit": {
			"Select Category is required": "需要选择类别",
			"Name is required": "必填名称",
			"Currency is required": "需要货币",
			"giftfile is required": "giftfile是必需的",
			"You will join": "您将加入",
			"club": "俱乐部",
			"You have chosen": "您已经选择了",
			"clubs": "个俱乐部",
			"please selcet loyalty club": "请选择俱乐部",
			"Loyalty club": "俱乐部",
			"Choose your club": "选择你的俱乐部",
			"Add Options": "新增选项",
			"Add Pricing": "添加定价",
			"Add Product": "添加产品",
			"Add more variant option": "添加更多不同的选项",
			"Add option value": "添加选项值",
			"Back": "返回",
			"Create Gift Card": "创建礼品卡",
			"Currency": "货币*",
			"Description": "描述",
			"Edit Product": "编辑产品",
			"Gift Card": "礼物卡",
			"Images": "图片",
			"Master price": "主价",
			"Name": "名称",
			"Option": "选项",
			"Option Types": "期权类型",
			"Price": "价钱",
			"Price Range": "价格范围*",
			"Pricing": "价钱",
			"Qty": "数量",
			"Quantity": "数量",
			"Quantity in Hand": "现有数量",
			"Is Returnable": "是否可退还",
			"Size Description": "尺寸说明",
			"Save": "保存",
			"Select Category": "选择类别*",
			"Shipping Price(Optional)": "运费（可选）",
			"Variant Option": "不同的选项"
		},
		"list": {
			"Category": "类别",
			"Image": "图片",
			"Name": "名称",
			"Price Range": "价格范围",
			"Products": "产品",
			"Products List": "产品清单",
			"data-table-title": "产品清单"
		},
		"public-detail": {
			"Add to cart": "添加到购物车",
			"Available Stock": "可用库存",
			"Options": "选项",
			"Original": "原版",
			"Please choose the product to buy": "请选择要购买的产品",
			"Price": "价钱",
			"Total Price": "总价格",
			"Product Detail": "产品信息",
			"Qty": "数量",
			"Shipping Price": "运费价格"
		},
		"public-list": {
			"Detail": "详情",
			"Product Catalog": "产品目录",
			"Shipping Price": "运费价格",
			"Ad Collection": "广告集合"
		}
	},
	"ad-product-category": {
		"edit": {
			"Category Name is required": "类别名称是必需的",
			"Add Product Category": "添加产品类别",
			"Back": "返回",
			"Category Name": "分类名称",
			"Edit Product Category": "编辑产品类别",
			"Save": "保存"
		},
		"list": {
			"Name": "名称",
			"Product Category": "产品分类",
			"data-table-title": "产品类别清单",
			"Created By": "创建者",
			"Created At": "创建于"
		}
	},
	"product-device": {
		"edit": {
			"Add Product Device": "新增产品装置",
			"Back": "返回",
			"Device Name": "装置名称",
			"Device Price": "装置价格",
			"Edit Product Device": "编辑产品装置",
			"Product Device": "产品装置",
			"Save": "保存"
		},
		"list": {
			"data-table-title": "产品设备清单",
			"Device Name": "装置名称",
			"Device Price": "装置价格",
			"Product Device": "产品装置",
			"Product Device List": "产品装置清单"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "新增促销",
			"Back": "返回",
			"Coupon count(s)": "折扣价格",
			"Description": "介绍",
			"Edit Promotion": "编辑促销",
			"End Date": "结束日期",
			"Promotion Image": "促销图片",
			"Promotion Type": "促销类别",
			"Promotional Text": "促销文字",
			"Save": "保存",
			"Start Date": "开始日期",
			"Select Products": "选择产品",
			"Max Purchase Amount": "最大购买金额",
			"Coupon Code": "优惠券代码",
			"Amount": "金额",
			"Percentage": "百分比"
		},
		"list": {
			"Coupon Count": "折扣价格",
			"End Date": "结束日期",
			"Name": "名字",
			"Promotion": "促销",
			"Promotion List": "促销清单",
			"Start Date": "开始日期",
			"Type": "类别",
			"data-table-title": "促销清单"
		},
		"log": {
			"Promotion Log": "促销记录",
			"Date": "日期",
			"Customer Name": "顾客姓名",
			"Promotion": "促销",
			"Received Amount": "已收金额"
		}
	},
	"reward": {
		"list": {
			"Reward List": "奖励清单",
			"Name": "名称",
			"Description": "描述",
			"Points": "点数",
			"Contact": "联系",
			"Contact Email": "联系邮件",
			"Contact Phone": "联系电话",
			"data-table-title": "奖励清单"
		},
		"edit": {
			"Reward Edit": "编辑奖励",
			"Reward Add": "添加奖励",
			"Image": "图片",
			"Name": "姓名",
			"Reward By": "奖励依据",
			"Location": "位置",
			"Points": "点数",
			"Mobile Number": "手机号码",
			"Email": "电子邮件",
			"Terms & Conditions": "条款和条件",
			"ADD": "添加",
			"About us": "关于我们",
			"Add your Terms & Conditions": "添加您的条款和条件"
		}
	},
	"recaptcha error": "验证码错误",
	"recaptchaToken is required": "必须提供验证码",
	"role": {
		"edit": {
			"Add Role": "新增用户群",
			"Back": "返回",
			"Edit Role": "编辑用户群",
			"Name": "名字",
			"Role Permission": "用户群权限",
			"Save": "保存"
		},
		"list": {
			"Name": "名字",
			"Permission": "权限",
			"Role": "用户群",
			"Role List": "用户群清单",
			"data-table-title": "用户群清单"
		}
	},
	"role-permission": {
		"edit": {
			"Ad Collection Module": "广告分类模组",
			"Add Role Permission": "新增用户群权限",
			"Advertising Module": "商业模组",
			"Back": "返回",
			"Campaign Module": "活动模组",
			"Create": "新增",
			"Day(s)": "天",
			"Delete": "删除",
			"Device Module": "装置模组",
			"Edit Role Permission": "编辑用户群权限",
			"Expiry Date": "到期时间",
			"List": "清单",
			"Name": "用户群名称",
			"Retrieve": "收回",
			"Save": "保存",
			"Update": "更新",
			"E-Commerce Module": "电子商务模块",
			"CRM Module": "CRM模块",
			"Level-1": "一级",
			"Level-2": "二级",
			"Level-3": "三级",
			"view": "查看",
			"edit": "修改",
			"delete": "删除"
		},
		"E-Commerce Module": {
			"Products": "产品",
			"Product Category": "产品类别",
			"Promotion": "促销",
			"Promotion Log": "推广记录",
			"Generate Gift Cards": "生成礼品卡",
			"Gift Card Log": "礼品卡记录",
			"Rewards": "奖励"
		},
		"CRM Module": {
			"Email Log": "电子邮件记录",
			"Email Template": "电子邮件模板",
			"Loyalty Club": "会员俱乐部",
			"Enquiry": "查询"
		},
		"list": {
			"Name": "名字",
			"Role Permission": "用户群权限",
			"Role Permission List": "用户群权限清单",
			"data-table-title": "用户群权限清单"
		}
	},
	"searching": {
		"Ad Collection": "广告集",
		"Ads": "广告",
		"Apply": "应用",
		"Campaign": "运动",
		"Clear filter": "清除筛选",
		"Collections": "集合",
		"Detail": "详情",
		"Device": "设备",
		"Duration": "持续时间",
		"Duration(s)": "持续时间",
		"End Date": "结束日期",
		"Filter by": "过滤",
		"First name": "名字",
		"ID": "ID",
		"Items": "项目",
		"Last name": "姓",
		"Name": "名称",
		"No results found for": "未找到结果",
		"Position": "位置",
		"Results for": "结果",
		"Start Date": "开始日期",
		"Status": "状态",
		"Type": "类型",
		"User": "用户"
	},
	"company": {
		"list": {
			"Company": "公司",
			"data-table-title": "公司列表",
			"Company Logo": "公司标志",
			"Name": "名称",
			"Phone": "电话",
			"Website": "网站"
		},
		"create": {
			"Save": "保存",
			"Back": "返回",
			"Edit Company": "编辑公司信息",
			"Create Company": "创建新的公司",
			"Business Name is required": "必填企业名称",
			"Company Type is required": "需要公司类型",
			"Business phone is required": "需要商务电话",
			"Web Site is required": "需要网站",
			"Zipcode is required": "需要邮政编码",
			"Business Address is required": "需要公司地址",
			"Business Logo": "企业徽标",
			"Business Name": "商家名称",
			"Company Type": "公司类型",
			"Business phone": "商务电话",
			"Web Site": "网站",
			"Zipcode": "邮政编码",
			"Business Address": "公司地址"
		},
		"orderlist": {
			"Date": "日期",
			"Customer Name": "顾客姓名",
			"Product": "产品",
			"Qty": "数量",
			"Total Price": "总价",
			"Pay Status": "付款状态",
			"Finish State": "完成状态"
		},
		"createChildCompanyAndStripe": {
			"Create Company Stripe Account": "创建公司Stripe帐户",
			"Edit Company Stripe Account": "编辑公司Stripe帐户",
			"Name": "名称",
			"Birthday": "生日",
			"Email": "电子邮件",
			"SSN": "SSN",
			"Account Number": "帐号",
			"Routing Number": "路由号码",
			"Company Name": "公司名",
			"MCC": "MCC",
			"City": "城市",
			"Address Line 1": "地址第一行",
			"Postal code": "邮编",
			"State": "省",
			"Phone": "电话"
		}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "广告范例类别",
			"Advertising Setting": "广告设定",
			"Day(s) ": "天",
			"Duration (s)": "长度(秒)",
			"Expiry Date": "到期日",
			"Expires in": "过期在",
			"Name": "名字",
			"Save": "保存",
			"Second(s)": "秒",
			"Subscription Plan": "订阅方案",
			"day": "天"
		},
		"general": {
			"Default Language": "默认语言",
			"Generel Settings": "通用设定",
			"Name": "名字",
			"New User Default Role": "用户预设角色群",
			"Save": "保存"
		},
		"product": {
			"Name": "名字",
			"Number": "数量",
			"Price": "价格",
			"Product Name": "名称",
			"Product Number": "数量",
			"Product Price": "价格",
			"Product Service": "服务",
			"Product Text": "叙述",
			"Product Type": "类型",
			"Save": "保存",
			"Service": "服务",
			"Subscription Lists": "订阅清单",
			"Subscription Name": "订阅名称",
			"Subscription Type": "订阅类型",
			"Usage": "用途"
		}
	},
	"sign": {
		"forget": {
			"Authentication required": "需要验证",
			"We sent": "我们向电子邮件发送了一次性密码",
			"Please enter it below": "请在下面输入。",
			"OTP will expired in": "一次性密码过期时间",
			"OTP has expired": "一次性密码已过期。",
			"Resend OTP": "重发一次性密码",
			"Enter OTP": "输入一次性密码",
			"Required": "必选",
			"Passcode is required": "需要密码",
			"Email is required": "电子邮件是必需的",
			"Please select your account type": "请选择您的账户类型",
			"Please type your email or mobile": "请键入您的电子邮件或手机",
			"mdi-reply": "mdi回复",
			"Reset your Password": "重置密码",
			"Reset my password": "重置我的密码",
			"Password Changed": "密码已更改",
			"You can now login with your new password": "现在可以使用新密码登录",
			"Login": "登录",
			"Old Password": "旧密码",
			"New Password": "新密码",
			"Confirm Password": "确认密码",
			"Forgot Password": "忘记密码",
			"Email or mobile": "邮箱或者电话号码",
			"Enter the email address or mobile phone number": "输入与您的Adsmodo帐户关联的电子邮件地址或电话号码。",
			"Continue": "继续",
			"Reset Password": "重置密码",
			"Sign In": "登入",
			"abbr": {
				"send": "发送验证码"
			}
		},
		"sign-in": {
			"Create an account": "创建帐号",
			"Forgot password": "忘记密码？",
			"Loyalty Member Sign In": "会员登录",
			"Sign In": "登入",
			"abbr": {
				"send": "发送验证码"
			},
			"Already have an account?": "是否已有账号？",
			"Forgot-Password": "忘记密码？",
			"Quick access with": "快速登录",
			"Log in with one-time passcode": "使用验证码登录"
		},
		"errorMessage": {
			"firstName": "必须输入名字",
			"lastName": "姓氏为必填项",
			"phone": "需要电话",
			"businessName": "必须输入公司名称",
			"businessPhone": "需要商务电话",
			"webSite": "需要网站",
			"zipcode": "需要邮编",
			"businessAddress": "必须提供公司地址",
			"yourGoal": "请选择您的目标",
			"productNumber": "请输入产品编号",
			"card": "请输入卡",
			"expiryMonth": "请输入到期月份",
			"expiryYear": "请输入有效期限",
			"cardCvc": "请输入卡的cvc",
			"mail": "请输入邮件",
			"password": "请输入密码",
			"confirmPassword": "确认密码"
		},
		"sign-up": {
			"Quick Sign Up": "快速註冊",
			"Complete Sign Up": "完成註冊",
			"Address:": "住址:",
			"Ads My biz": "我公司的广告?",
			"All of Above": "全选",
			"Business Address": "公司地址",
			"Business Name": "公司名称",
			"Business phone": "公司电话",
			"Card Number:": "信用卡号码:",
			"Company Type": "公司类别",
			"Confirm": "确认",
			"Confirm Information": "确认资料",
			"Confirm password": "确认密码",
			"Create user account": "新建用户帐号",
			"Email": "邮箱",
			"Email:": "邮箱:",
			"Extra $": "附加产值",
			"First Name": "名字",
			"First Name:": "名字：",
			"Help others": "帮助其他人",
			"Last Name:": "姓氏：",
			"Next": "下一步",
			"Password": "密码",
			"Payment Options": "付费选项",
			"Phone": "电话",
			"Phone:": "电话",
			"Prev": "上一步",
			"Price:": "价格:",
			"Price(month):": "价格(每月):",
			"Product:": "产品:",
			"Quantity": "数量",
			"Select Adsmodo Business Assistant": "选择adsmodod商业助手",
			"Subscription Plan": "订阅方案",
			"Tell us about your goal": "您的目标",
			"Web Site": "网页",
			"Zipcode": "邮递区号",
			"abbr": {
				"agree": "我已阅读并同意",
				"business info": "个人资料",
				"web tutorial": "您可以在w3schools学习如何制作个人网页，我们也提供免费的网页开发教学"
			},
			"last Name": "姓氏"
		}
	},
	"signin verify error": "登录验证错误",
	"signup password error": "注册密码错误",
	"single": {
		"component": {
			"Contact info": "联络方式",
			"Email address": "邮箱",
			"Input your contact info to get coupons and subscribe our service": "输入您的联络方式以获得折扣",
			"Input your contact info to get coupons!": "留下联络方式来获得折扣",
			"Phone number": "电话号码",
			"Submit": "提交",
			"abbr": {
				"get contact info": "获取联系信息"
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "您确定要激活此计划吗？",
			"Are you sure to deactivate this plan?": "您确定要停用此计划吗？",
			"Your plan is now Active!": "您的计划现已生效！",
			"Your plan is now Inactive!": "您的计划现在无效！",
			"Active": "有效",
			"Inactive": "无效",
			"Add Subscription Plan": "添加订阅计划",
			"Back": "返回",
			"Edit Subscription Plan": "编辑订阅计划",
			"Number": "数量",
			"Price": "价格",
			"Save": "保存",
			"Service": "服务",
			"Subscription Name": "订阅名称",
			"Subscription Plan": "订阅计划",
			"Subscription Type": "订阅类型",
			"Usage": "用法",
			"Description": "描述",
			"Maximum area range": "最大面积范围",
			"Maximum Area Range": "最大面积范围"
		},
		"list": {
			"Price": "价格",
			"Subscription Plan": "订阅计划",
			"Subscription Plan List": "订阅计划清单",
			"Subscription Plan Type": "订阅计划类型"
		}
	},
	"user": {
		"account": {
			"Account Type": "帐号类型",
			"Subscription Plan Type": "订阅计划类型",
			"Ad Address": "广告地址",
			"Basic Information": "基本资料",
			"Save Credit Card": "保存信用卡",
			"Change Credit Card": "更改信用卡",
			"Current Credit Card": "当前信用卡",
			"Saved Payment Methods": "已保存的付款方式",
			"Bind Status": "绑定状态",
			"Bind credit card": "绑定信用卡",
			"Business Address": "公司地址",
			"Business Information": "公司资讯",
			"Business Name": "公司名称",
			"Business Phone": "公司电话",
			"Cancel": "取消",
			"Cancel Subscription": "取消订阅",
			"Cell Phone": "手机",
			"Company Type": "公司类别",
			"Email": "邮箱",
			"Free": "免费",
			"Hi": "你好 ",
			"Info": "资料",
			"Let me think": "再考虑一下",
			"Name": "名字",
			"Password": "密码",
			"Product Type": "产品类别",
			"Profile Image": "简介图",
			"Redeem": "退货",
			"Rewards": "获利",
			"Save": "保存",
			"Skip Tour": "跳过教学",
			"Start Create Ads": "开始建立广告",
			"Subscription Plan": "订阅方案",
			"Web Site": "网站",
			"Zipcode/Postalcode": "邮编",
			"First Name": "名字",
			"Last Name": "姓",
			"enterPassword": "请输入密码",
			"Enter Business Name": "请输入商家名称",
			"Enter Business Phone": "请输入商家电话",
			"Enter webSite": "请输入网站",
			"Enter Business Information": "请输入商家信息",
			"abbr": {
				"ad collection intro": "广告集合定义了你的广告的种类，且可以包含多个广告",
				"ad intro": "广告可以包含不同的图片、文字、联络我按钮、连结、影片等，同时图片的类型可以包含3D、AR、VR等",
				"campaign intro": "一个活动包含多个相同类型的广告集合",
				"cancel subscribe check": "确定要取消订阅吗?",
				"cancel subscribe info": "取消订阅后，您将失去所有在此网站上的特权",
				"privilege info": "在您升级以后，可以享受以下特权",
				"upgrade check": "确定要更新帐号吗?",
				"Are you sure you want to change your current plan ?": "您确定要更改当前计划吗 ?",
				"welcome": "欢迎来到Adsmodo",
				"yes": "我确定",
				"Plan adsmodo a monthly $": "每月计划adsmodo $",
				"advertising services": "广告服务"
			},
			"Monthly": "每月一次",
			"Activated": "激活",
			"Current Plan": "当前计划",
			"Change Plan": "变更计划",
			"Upgrade Now": "立刻升级",
			"Last 4 digit": "后四位号码",
			"Expiring": "即将到期",
			"My Credit Cards": "我的信用卡",
			"Update Card": "更新信用卡",
			"Company Information": "公司信息",
			"Subscribe": "订阅",
			"Team Information": "团队信息"
		},
		"edit": {
			"Add User": "新增用户",
			"Back": "返回",
			"Can adding ads": "新增广告权限",
			"Edit User": "编辑用户",
			"Email": "邮箱",
			"Is Admin": "管理员权限",
			"Mobile": "电话",
			"Name": "名字",
			"Password": "密码",
			"Save": "保存",
			"User Role": "所属用户群",
			"team": "团队"
		},
		"list": {
			"Email": "邮箱",
			"Is Admin": "管理员权限",
			"Mobile": "电话",
			"Name": "名字",
			"Role": "用户群",
			"Users": "用户",
			"Users List": "用户清单",
			"data-table-title": "用户清单",
			"Team": "团队"
		}
	},
	"user permission get error": "用户权限获取错误",
	"user-goal": {
		"edit": {
			"Add User Goal": "新增用户目标",
			"Back": "返回",
			"Edit User Goal": "修改用户目标",
			"Name": "名字",
			"Save": "保存",
			"User Goal": "用户目标"
		},
		"list": {
			"data-table-title": "用户目标列表",
			"Name": "名字",
			"User Goal": "用户目标",
			"User Goal List": "用户目标清单"
		}
	},
	"validation errors": "验证错误",
	"location": {
		"edit": {
			"Name": "名称",
			"Location": "位置",
			"Add Location": "添加位置",
			"Close": "关",
			"Add": "添加",
			"title": "编辑设备位置"
		},
		"list": {
			"Device Location": "设备位置",
			"Device Location List": "设备位置列表"
		},
		"add": {
			"title": "添加设备位置"
		}
	},
	"description": "描述",
	"View More Cases": "查看更多",
	"Contact Us": "联系我们",
	"Login is abnormal, please try again later.": "登录失败，请稍后重试！",
	"header": {
		"Ecommerce": "产品",
		"Pricing": "价格",
		"Get Started": "开始",
		"Sign Out": "退出"
	},
	"footer": {
		"copy_right": "adsmodo版权所有，备案号Policy000000"
	},
	"customer failed": "创建客户失败",
	"pre": "审核时出现错误",
	"You do not have the permission!": "你没有这个权限！",
	"my-reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "有效期：2019年10月15日至2020年1月15日",
		"points": "点数",
		"mdi-check-bold": "mdi复选框粗体",
		"mdi-phone": "mdi电话",
		"mdi-email": "mdi邮箱",
		"Report Reward issue to": "向报告问题",
		"Use Now": "立即使用"
	},
	"my-reward-list": {
		"Your Rewards": "你的奖励",
		"Valid till": "有效期至",
		"mdi-content-cut": "mdi内容剪切",
		"Use Now": "立即使用",
		"MERCHANT-TO-FILL-CODE": "商品代码",
		"Please enter 6-digit code provided by the merchant": "请输入商户提供的6位数字",
		"Confirm": "确认"
	},
	"post": "signin account error",
	"member-change-password": {
		"Check": "检查",
		"Validate": "验证",
		"Verify & Change": "验证和更改",
		"Finished": "完成",
		"Hi": "你好",
		"Verification is in progress for": "正在验证",
		"mdi-information": "mdi信息",
		"Please verify your identity first": "请先核实你的身份",
		"Your email": "你的邮箱",
		"has been verified": "已经过验证",
		"mdi-checkbox-marked-circle": "标记圆的mdi复选框",
		"sentEmailPrompt": "我们将在一分钟内将密码发送到您的电子邮件以设置您的密码",
		"Next": "下一步",
		"Cancel": "取消",
		"sentPasscodePrompt": "我们已将密码发送到您的电子邮件",
		"Please check your inbox": "请检查您的收件箱",
		"Please enter current password": "请输入当前密码",
		"mdi-lock": "mdi锁",
		"Pick a new password": "选择新密码",
		"Password must contain": "密码必须包含",
		"At least 1 upper case letter": "至少一个大写字母",
		"At least 1 number (0-9)": "至少1个数字(0-9)",
		"At least 8 character": "密碼必須至少包含8個字符，包括大寫，小寫字母和數字",
		"Great": "大量",
		"Your password has been updated": "您的密码已更新"
	},
	"security-tab": {
		"mdi-tune": "mdi-调整",
		"Choose": "选择",
		"Set up": "设置",
		"Verify": "校验",
		"Finished": "完成",
		"Two-factor Authentication": "双重身份验证",
		"Enabled tasks": "已启用的任务",
		"You can choose a task": "登录后，可以从下面的列表中选择任务。",
		"Text message": "文本消息",
		"mdi-message-text": "mdi消息文本",
		"Verification codes are sent by text message": "验证码通过短信发送",
		"mdi-shield-lock": "mdi盾牌锁",
		"Authenticator App": "身份验证器应用",
		"Use the Authenticator app": "即使您的电话处于离线状态，也可以使用身份验证器应用程序获取验证码。",
		"How would you": "您想如何接收验证码？",
		"After entering your password": "输入密码后，将要求您进行第二步验证。",
		"Verification codes": "通过短信发送验证码。（需要经过验证的手机号码）",
		"SET UP": "设定",
		"Cancel": "取消",
		"Let's set up": "设置手机进行验证",
		"What phone number": "您要使用什么电话号码？",
		"mdi-checkbox-marked-circle": "mdi复选框标记为圆形",
		"mdi-information": "mdi信息",
		"Change": "更改",
		"Two Factor Setup": "双重设置",
		"Take these few steps to enable": "执行以下几步以启用双重身份验证，并使您的帐户更安全。",
		"Install an authenticator app": "在您的移动设备上安装身份验证器应用程序。",
		"Recommended options: Google Authenticator": "推荐选项：Google Authenticator，Authy，Microsoft Authenticator",
		"Scan QR code with the authenticator": "使用身份验证器扫描QR码。",
		"Enter the 2-step authentication": "输入您的应用提供的双重身份验证代码。",
		"Continue": "继续",
		"I'm ready": "我准备好了",
		"Back": "返回",
		"Verify your number": "验证您的电话号码",
		"We just sent a text message": "我们刚刚发送了一条包含验证码的短信给",
		"Didn't get it? Resend code": "没有得到？重新发送代码。",
		"Scan this QR code": "使用您的移动应用程序扫描此QR码。",
		"Then enter the six digit code": "然后输入应用程序提供的六位数代码：",
		"Can't scan the code": "无法扫描代码？",
		"Great": "好的!",
		"Two-factor Authentication has been": "已为您的帐户启用了双重身份验证",
		"All future logins will now require": "现在所有将来的登录都将要求您输入由移动消息或Authenticator App提供的代码。",
		"Finish": "完成",
		"Change Password": "修改密码",
		"Two-step verification": "两步验证",
		"Set up a unique password to protect your personal account": "设置唯一密码以保护您的个人帐户",
		"Require a security key or code in addition to your password": "除密码外还需要安全密钥或代码"
	},
	"member-home": {
		"Special offers for you": "为您提供特别优惠",
		"A confirmation email has been sent to": "已将确认电子邮件发送到",
		"Click on the confirmation link in the email to verify your account.": "请单击电子邮件中的确认链接以验证您的帐户。",
		"Points": "点数",
		"My loyalty club": "我加入的会员俱乐部",
		"Recommended loyalty club": "推荐的俱乐部",
		"More loyalty club": "更多的俱乐部",
		"More than": "超过",
		"clicks on ads": "点击广告",
		"Since this is your first login, please click": "由于您第一次登陆，请先点击这条信息进行修改密码",
		"There are currently": "当前有",
		"promotional products": "个产品在促销中",
		"Promotion code list": "促销产品列表",
		"Loyalty club name": "俱乐部名字",
		"Promotion code": "优惠码",
		"Close": "关闭",
		"View promo code": "查看促销产品"
	},
	"member-nav": {
		"Languages": "语言",
		"Shopping Cart": "购物车"
	},
	"member-orders": {
		"Order": "订单",
		"Placed on": "放置在",
		"Return Items": "归还物品"
	},
	"member-profile": {
		"points": "积分",
		"Ok": "同意",
		"Currently you don't have any points history": "当前没有历史积分",
		"Make a new purchase to earn reward points": "新购买可获得积分奖励",
		"Find More": "了解更多"
	},
	"member-setting": {
		"Profile": "简况",
		"Security": "安全"
	},
	"order-detail": {
		"Order Detail": "订单详情",
		"mdi-printer": "mdi打印机",
		"mdi-airplane-takeoff": "mdi飞机起飞",
		"Trace Your Order": "跟踪您的订单",
		"Order Number": "订单号",
		"Order Placed": "下单",
		"Status": "状态",
		"Sales Person": "销售人员",
		"Sub Total": "小计",
		"Shipping Fee": "运费",
		"Total": "总计",
		"Order Summary": "订单描述",
		"Payment Method": "付款方式",
		"Ship To": "运输至",
		"No.18,Corner of Ahlone and Baho Road,Ahlone Township,Yangon": "仰光市阿龙镇阿龙和巴霍路转角18号",
		"Shipping Method": "送货方式",
		"Standard": "标准",
		"Contact Information": "联系人信息",
		"Products Summary": "产品描述"
	},
	"products-detail": {
		"Size Guide": "尺寸指南",
		"Size Conversions": "尺寸转换",
		"Shipping price": "运费",
		"Out of stock": "(缺货!)",
		"by": "通过"
	},
	"reward-list": {
		"Used": "已使用",
		"Adsmodo": "Adsmodo",
		"$": "$",
		"OFF YOUR PURCHASE": "关闭购买",
		"Gift Code:": "礼品代码：",
		"Valid till": "有效期至"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "有效期为2019年10月15日至2020年1月15日",
		"points": "要点",
		"mdi-check-bold": "mdi 校验粗体",
		"mdi-phone": "mdi手机",
		"mdi-email": "mdi电子邮件",
		"Report Reward issue to Adsmodo": "向Adsmodo报告奖励问题"
	},
	"return-orders": {
		"Choose items to return": "选择退货",
		"Ordered on": "订购日期",
		"Order Number": "订单号",
		"Continue": "继续",
		"Not returnable": "不可退货",
		"Returnable untill": "可重复使用，直到",
		"Choose refund method": "选择退款方式",
		"Returning": "返回",
		"Reason": "原因",
		"We'll process your refund": "我们将处理以1234结尾的Visa的退款。",
		"Estimated refund timing": "预计退款时间：我们收到商品后的2-3个工作日。",
		"How do you want to send": "您想如何将其寄回？",
		"$0": "$0",
		"Our deliveries will pickup": "我们的送货将在下一个工作日取回您的包裹。需要打印机,您可以随时从您的帐户或我们发送给您的电子邮件中打印标签。",
		"Refund summary": "退款摘要",
		"Refund Subtotal": "退款小计：",
		"Shipping fee": "运输费用：",
		"Total refund": "总退款：",
		"mdi-checkbox-marked-circle": "mdi复选框标记为圆形",
		"Your label is ready to print": "您的标签已准备好打印",
		"Securely pack the items": "“安全地包装要退回的物品。将所有物品寄回给Adsmodo by",
		"mdi-cloud-upload": "mdi云上传",
		"Print label": "打印标签"
	},
	"products-list": {
		"Adsmodo": "Adsmodo",
		"Out of stock": "缺货！",
		"Available stock": "可用库存"
	},
	"common-list": {
		"no-data": "没有找到记录",
		"reset": "重置",
		"warning": "警告",
		"delete-item-warning": "你确定要删除这条数据吗？ \"删除\"操作无法回滚",
		"delete-items-warning": "你确定要删除这些数据吗？ \"删除\"操作无法回滚",
		"delete-yes": "是的",
		"delete-no": "取消",
		"operations": "操作"
	},
	"you can't delete your self": "you can't delete your self",
	"Please select the loyalty club": "Please select the loyalty club",
	"Sign in error": "Sign in error",
	"Please select default club": "Please select default club",
	"Please register first!": "Please register first!",
	"team": {
		"list": {
			"data-table-title": "团队列表"
		}
	},
	"menu": {
		"list": {
			"data-table-title": "菜单列表"
		}
	},
	"ad-product": {
		"list": {
			"data-table-title": "产品清单"
		}
	},
	"category": {
		"list": {
			"data-table-title": "产品类别清单"
		}
	},
	"promotion-log": {
		"list": {
			"data-table-title": "促销记录列表"
		}
	},
	"Update user error": "Update user error",
	"campaign": {
		"list": {
			"data-table-title": "活动清单",
			"Deactivate": "关闭",
			"Reactivate": "启动"
		}
	},
	"purchase-log": {
		"list": {
			"data-table-title": "购买日志列表"
		}
	},
	"loyalty-club": {
		"list": {
			"data-table-title": "会员俱乐部列表",
			"Loyalty Club": "会员俱乐部",
			"Name": "名字",
			"Summary": "摘要",
			"Company": "公司",
			"Users": "用户",
			"AccountType": "帐户类型",
			"State": "状态",
			"Contacts": "联系人",
			"View All": "查看全部"
		},
		"edit": {
			"Add Loyalty Club": "添加会员俱乐部",
			"Edit Loyalty Club": "编辑会员俱乐部",
			"Invite Users": "邀请用户",
			"You will invite users": "您将邀请指定电子邮件的用户加入您的会员俱乐部",
			"Send mail": "发送邮件",
			"Cancel": "取消",
			"Invite": "邀请",
			"Email": "电子邮件",
			"Company Logo": "公司标志",
			"Loyalty Club Name": "会员俱乐部名称",
			"Loyalty Club Summary": "会员俱乐部摘要",
			"Account Type": "帐户类型",
			"Loyalty Club Description": "会员俱乐部说明",
			"Users": "用户",
			"Send Message": "发送消息",
			"Send": "发送",
			"Close": "关闭",
			"Name": "名称",
			"Phone": "电话",
			"message is required": "需要输入消息",
			"user is required": "需要选择用户",
			"send email success": "发送电子邮件成功",
			"send message success": "发送消息成功",
			"Email Template": "电子邮件模板",
			"email template is require": "需要选择电子邮件模板",
			"others": "其他用户"
		}
	},
	"order": {
		"list": {
			"data-table-title": "订单列表"
		}
	},
	"get menu database error ": "get menu database error ",
	"homePage": {
		"Price": "价格",
		"monthly": "每月",
		"learnmore": "了解更多"
	},
	"rules": {
		"Name is required": "名称为必填",
		"Name is too long": "名称太长",
		"Job Title is required": "职位名称为必填",
		"text is required": "文本为必填",
		"company is required": "公司为必填",
		"Category Name is required": "类别名称为必填",
		"name is required": "必填名称",
		"E-mail is not valid": "电子邮件无效",
		"summary is required": "摘要为必填",
		"Loyalty Club is required": "会员俱乐部为必填",
		"Product is required": "产品为必填",
		"Start Date is required": "开始日期为必填",
		"End Date is required": "结束日期为必填",
		"Discount is required": "折扣为必填",
		"Invalid": "无效",
		"Required": "必填",
		"Min 8 characters": "最少8个字符",
		"The password you entered not match": "您输入的密码不匹配",
		"E-mail or mobile phone is required": "电子邮件或手机为必填",
		"First Name is too long": "名字太长",
		"Last Name is too long": "姓氏太长"
	},
	"airbnb": {
		"hotel": {
			"Edit Hotel": "编辑酒店"
		}
	},
	"Resource is not found": "Resource is not found",
	"Name create error": "名称错误",
	"Duration error": "时长错误"
};