import { filter, assign, find, map, concat, isString, union, without, differenceWith, includes } from "lodash";
import api from "../../services/api";
import { getTreeViewByParentKey } from "../../utils/general";
import { i18n } from "../../utils/language";
export default {
  namespaced: true,
  state: {
    currentNode: undefined,
    orgList: [],
    themeItems: [],
    systemList: [],
    thumbnailQueue: [],
    zipQueue: []
  },
  getters: {
    companyRoot(state) {
      const t = filter(state.orgList, x => x.type === "folder");
      return {
        id: "root",
        parent: null,
        title: i18n.t("mediaLibrary.CompanyAdsAssets"),
        mediaType: "home",
        type: "folder",
        children: getTreeViewByParentKey(t, undefined)
      };
    },
    systemRoot(state) {
      const systemFolder = filter(state.systemList, x => x.type === "folder");
      return {
        id: "system",
        parent: null,
        title: i18n.t("mediaLibrary.SystemAdsAssets"),
        mediaType: "home",
        type: "folder",
        stock: true,
        children: getTreeViewByParentKey(systemFolder, undefined)
      };
    },
    parentNode: state => {
      if (state.currentNode && !state.currentNode.stock)
        return find(state.orgList, x => x.id === state.currentNode.parent);
      else if (state.currentNode && !state.currentNode.stock)
        return find(state.systemList, x => x.id === state.currentNode.parent);
      return undefined;
    },
    childNodes: state => {
      if (!state.currentNode) {
        return [];
      }
      let toFilterList = state.currentNode.stock ? state.systemList : state.orgList;
      toFilterList = differenceWith(toFilterList, state.thumbnailQueue, (x, y) => x.id === y.id);

      return filter(
        toFilterList,
        x =>
          x.parent === state.currentNode.id ||
          ((state.currentNode.id === "root" || state.currentNode.id == "system") && x.parent === undefined)
      );
    },
    folderList: (state, getters) => {
      let t1 = [getters.companyRoot];
      if (state.systemList.length) {
        t1.push(getters.systemRoot);
      }
      return t1;
    }
  },
  mutations: {
    save(state, payload) {
      state = assign(state, payload);
    }
  },
  actions: {
    async fetch({ commit }) {
      const resp = await api.getList("media-library", undefined, undefined, 0, 0, "-type", false);
      if (resp.data) {
        commit("save", {
          systemList: resp.data.systemList,
          orgList: resp.data.results
        });
      }
    },
    async createOrUpdate({ state: { currentNode, thumbnailQueue, zipQueue }, commit }, payload) {
      if (!payload.parent)
        payload.parent = currentNode ? (currentNode.id === "root" ? undefined : currentNode.id) : undefined;
      if (payload.parent === "root") payload.parent = undefined;
      const task = payload.id
        ? api.updateItem("media-library", payload.id, payload)
        : api.createItem("media-library", payload);
      const doc = await task.then(res => res.data.results);
      if (doc && doc.type === "file") {
        const newQueue = union(thumbnailQueue, [doc]);
        commit("save", {
          thumbnailQueue: newQueue
        });
      } else if (doc && includes(doc.mediaType, "zip")) {
        const newQueue = union(zipQueue, [doc]);
        commit("save", {
          zipQueue: newQueue
        });
      }
    },
    async deleteItem(x, payload) {
      await api.deleteItems("media-library", payload);
    },
    async fetchThemes({ commit }) {
      const resp = await api.getList("media-theme", "", "", 0);
      if (resp.data) {
        const items = concat([i18n.t("mediaLibrary.company"), i18n.t("mediaLibrary.product"), i18n.t("mediaLibrary.activities")], resp.data.results);
        const themeItems = map(items, x => (isString(x) ? { id: null, displayName: x } : x));
        commit("save", { themeItems });
      }
    },
    setCurrentNode({ commit, state, getters }, id) {
      let current;
      if (id === "system") {
        current = getters.systemRoot;
      } else if (id === "root") {
        current = getters.companyRoot;
      } else {
        const mergedList = concat(state.orgList, state.systemList);
        current = find(mergedList, x => x.id === id);
      }
      commit("save", {
        currentNode: current
      });
    },
    removeFromThumbnailQueue({ commit, state: { thumbnailQueue } }, id) {
      const nd = find(thumbnailQueue, x => x.id === id);
      if (nd) {
        commit("save", {
          thumbnailQueue: without(thumbnailQueue, nd)
        });
      }
    },
    removeFromZipQueue({ commit, state: { zipQueue } }, id) {
      const nd = find(zipQueue, x => x.id === id);
      if (nd) {
        commit("save", {
          thumbnailQueue: without(zipQueue, nd)
        });
      }
    }
  }
};
