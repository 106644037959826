import api from "../../services/api";
import { setRole } from "../../utils/authority";
import { map } from "lodash";
export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    current: {
      name: "",
      permissions: {}
    }
  },
  getters: {
    ddlOptions(state) {
      return map(state.list, x => ({
        text: x.name,
        value: x.id
      }));
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async fetch({ commit }, payload) {
      const res = await api.getItem("role", payload.id);
      commit("updateValue", {
        item: res.data
      });
    },
    async fetchMyRole({ commit }) {
      const res = await api.getCurrentRole();
      commit("updateValue", {
        current: res.data.results
      });
      setRole(res.data.results);
    },
    async update(x, payload) {
      if (payload.id) {
        await api.updateItem("role", payload.id, payload);
      } else {
        await api.createItem("role", payload);
      }
    },
    fetchRoles({ commit }) {
      api.getList("role", undefined, undefined, 0).then(res => {
        commit("updateValue", {
          list: res.data.results
        });
      });
    }
  }
};
