import { each, assign, filter, without } from "lodash";
import { lookup } from "mime-types";
import axios from "axios";

export function getTreeViewByParentKey(flat, nodeId) {
  const children = filter(flat, x => x.parent === nodeId);
  const restItems = without(flat, children);
  each(children, x => {
    x.children = getTreeViewByParentKey(restItems, x.id);
  });

  return children;
}

export function getThumbnail(val) {
  let tmnUrl = val;
  if (!/\.thumbnail\./g.test(val)) {
    var match = val.match(/firebasestorage/g);
    if(match){
      const path = val.split(/[#?]/)[0];
      const paras = val.split(/[#?]/).length > 0 ? val.split(/[#?]/)[1] : "";
      const fileType = lookup(path);
      if (!fileType) return tmnUrl;
      const isVideo = fileType.startsWith("video/");
      const ext = isVideo
        ? "jpg"
        : path
            .split(".")
            .pop()
            .trim();
      tmnUrl = `${path}.thumbnail.${ext}?${paras}`;
    }
  }
  return tmnUrl;
}

export function sleep(milliseconds) {
  return new Promise(resolve =>
    setTimeout(() => {
      resolve();
    }, milliseconds)
  );
}

export function checkImgExists(imgurl) {
  const instance = axios.create();
  return instance
    .get("/api/media-library/check-assets-status?url=" + encodeURIComponent(imgurl))
    .then(r => !!r.data && r.data.success)
    .catch(err => {
      console.log(err.message);
      return false;
    });
}

export function getPlayerOptions(url, options = {}) {
  return assign(
    {
      muted: false,
      autoplay: false,
      height: "500",
      language: "en",
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [
        {
          type: "video/mp4",
          src: url
        }
      ],
      poster: getThumbnail(url)
    },
    options
  );
}

export function getMimeType(url) {
  const uo = new URL(url);
  const mediaType = uo.searchParams.get("media-type");
  if (mediaType) {
    return mediaType;
  }
  const t = lookup(uo.pathname);
  return t;
}
