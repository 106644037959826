import memberApi from "res/services/memberApi";

export default {
  namespaced: true,
  state: {
    myClubs: {
      results: [],
      total: 0
    },
    recommondedClubs: {
      results: [],
      total: 0
    },
    moreClubs: {
      results: [],
      total: 0
    },
    events: []
  },
  getters: {},
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    async fetchMyClubs({ commit }, { payload }) {
      const { sort, limit } = payload || {};
      const res = await memberApi.getMyClubs(undefined, sort, 0, limit);
      if (res.data) {
        commit("updateValue", { myClubs: res.data.data });
      }
    },
    async fetchRecommendedClubs({ commit }, { payload }) {
      const { sort, limit } = payload || {};
      const res = await memberApi.getNotMyClubs(undefined, sort, 0, limit);
      if (res.data) {
        commit("updateValue", { recommondedClubs: res.data.data });
      }
    },
    async fetchMoreClubs({ commit }, { payload }) {
      const { sort, limit } = payload || {};
      const res = await memberApi.getNotMyClubs({ priority: 1 }, sort, 0, limit);
      if (res.data) {
        commit("updateValue", { moreClubs: res.data.data });
      }
    },
    fetchEvents({ commit }, payload) {
      memberApi.getDoctorEvents(payload).then(res => {
        if (res.data.results) {
          commit("updateValue", {
            events: res.data.results
          });
        }
      });
    }
  }
};
