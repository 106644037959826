import { map, filter, get } from "lodash";

export default {
  namespaced: true,
  state: {},
  getters: {
    patientEvents(state, getters, rootState) {
      return map(
        filter(rootState.memberLoyaltyClub.events, event => event.category === "appointment"),
        x => ({
          id: x.id,
          name: `${get(x, "patient.name.first") || "**"} **`,
          color: "#82B1FF",
          start: x.start,
          end: x.end,
          timed: true
        })
      );
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    }
  }
};
