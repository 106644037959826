import api from "../../services/api";
import { map } from "lodash";
import { i18n } from "../../utils/language";
export default {
  namespaced: true,
  state: {
    events: []
  },
  getters: {
    doctorEvents(state) {
      return map(state.events, event => ({
        id: event.id,
        name: i18n.t("schedule.onDuty"),
        start: event.start,
        end: event.end,
        color: "#4CAF50",
        timed: true
      }));
    }
  },
  mutations: {
    updateValue(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateValue({ commit }, payload) {
      commit("updateValue", payload);
    },
    fetchEvents({ commit }, payload) {
      api.getList("schedule", undefined, { ...payload }, 0, 0, undefined).then(res => {
        if (res.data.results) {
          commit("updateValue", {
            events: res.data.results
          });
        }
      });
    }
  }
};
