import { remove, find, filter } from "lodash";

export default {
  namespaced: true,
  state: {
    progressList: []
  },
  getters: {
    uploading(state) {
      return filter(state.progressList, x => x.progress < 100).length;
    },
    uploaded(state) {
      return filter(state.progressList, x => x.progress === 100).length;
    }
  },
  mutations: {
    save(state, payload) {
      state = Object.assign(state, payload);
    }
  },
  actions: {
    updateProgressList({ commit, state }, { progressObj }) {
      let progressList = state.progressList;
      const existItem = find(progressList, x => x.id === progressObj.id);
      if (existItem) {
        existItem.progress = progressObj.progress;
      } else {
        progressList.push(progressObj);
      }
      if (progressObj.progress == -1) {
        remove(progressList, x => x.id === progressObj.id);
      }
      commit("save", { progressList });
    },
    clear({ commit }) {
      commit("save", { progressList: [] });
    }
  }
};
