module.exports = {
	"Account or password was incorrect!": "アカウントまたはパスワードが間違っていました！",
	"Can't get your device info": "端末情報を取得できません",
	"Device does not exist or has been deleted, please reselect": "デバイスが存在しないか削除されました。再選択してください",
	"Duplicate current plan": "最新計画の複製",
	"Email and passcode is required!": "電子メールとパスコードが必要です！",
	"Email or password is incorrect": "メールアドレスまたはパスワードが間違っています",
	"Find device error": "デバイスエラーを見つける",
	"Find user error": "ユーザー検索エラー",
	"Get current customer plan is required": "現在の顧客プランを取得する必要があります",
	"Hello": "Hello",
	"Id is required!": "IDが必要です！",
	"Inconsistent password": "一貫性のないパスワード",
	"Member does not exist, Please try again later!": "メンバーが存在しません。しばらくしてからもう一度お試しください！",
	"No id": "IDなし",
	"Please enter the device name": "デバイス名を入力してください",
	"Please enter your email": "あなたのメールアドレスを入力してください",
	"Please enter your mobile": "携帯電話を入力してください",
	"Please select an item first": "最初にアイテムを選択してください",
	"Signin error": "サインインエラー",
	"Alert": {
		"Please wait patiently for your account in the middle of the review": "",
		"Your account does not have a subscription. Please subscribe to post an ad": ""
	},
	"Swal": {
		"member_sign_up": {
			"Please register to be part of this club": "このクラブに参加するには、登録してください。"
		},
		"More than available stock": "",
		"Are you sure?": "",
		"You will not be able to recover this!": "",
		"Yes, delete it!": "",
		"No, cancel plx!": "",
		"You must register your company first!": "",
		"Exceed the maximum number of Ad": "",
		"Upgrade your account,and create more Advertising": "",
		"Out of stock": "",
		"Please select the variant!": "",
		"Invalid coupon code": "",
		"Invalid coupon code for this product!": "",
		"Valid coupon code": "",
		"Add success": "成功を追加",
		"Already in the cart": "すでにカートに入っています",
		"Amount should be more than zero": "金額はゼロ以上でなければなりません！",
		"Bind Success": "バインド成功",
		"Buy device success": "デバイスの成功を購入する",
		"Device type is required": "デバイスタイプが必要です",
		"Edit success": "成功を編集",
		"End date must be greater than start date!": "終了日は開始日よりも後でなければなりません！",
		"Please select a valid image": "有効な画像を選択してください",
		"Please select a valid video": "有効な動画を選択してください",
		"Session time out.Please log in again": "セッションがタイムアウトしました。もう一度ログインしてください",
		"Something went wrong": "何かがうまくいかなかった",
		"Subscription success": "サブスクリプションの成功",
		"Successfully approved": "承認されました！",
		"Successfully generated": "生成されました！",
		"Your profile has been successfully updated": "プロフィールが正常に更新されました",
		"account": {
			"Cancel Subscription success": "サブスクリプションの成功をキャンセル",
			"Cancel subscription error": "購読エラーをキャンセル",
			"The current user does not support this feature at this time": "現在、現在のユーザーはこの機能をサポートしていません。",
			"This operation is not supported at this time": "現在、この操作はサポートされていません"
		},
		"ad": {
			"Ad created successfully": "広告が正常に作成されました",
			"Ad edited successfully": "広告が正常に編集されました",
			"Cancel": "キャンセル",
			"Congratulations!You have created your first ad": {
				"Do you like to continue?": "おめでとうございます！最初の広告を作成しました。続行しますか？"
			},
			"Continue": "持続する",
			"Product category is needed!": "製品カテゴリが必要です！",
			"Product name is needed!": "製品名が必要です！",
			"success info": "成功情報"
		},
		"sign": {
			"Agreement Required": "同意が必要",
			"Please agree the Terms & Condtions": "利用規約に同意してください",
			"Please improve the information": "情報を改善してください",
			"Business address invalid, please enter again": "会社の住所が無効です。もう一度入力してください。",
			"Company type require!": "会社の種類が必要です！",
			"Invalid recaptcha token": "無効なrecaptchaトークン",
			"Password doesnot match!": "パスワードが一致しません！",
			"Please post your ad now": "今すぐ広告を投稿してください。",
			"Purchase fail": "購入に失敗する",
			"Purchase success": "購入成功",
			"The Passcode have been send success": "パスコードが送信されました",
			"The passcode have been send successfully": "パスコードが正常に送信されました",
			"The password and confirm password not the same": "パスワードと確認パスワードが同じではありません"
		},
		"You need to set up your payment method to purchase devices": "デバイスを購入するには支払い方法を設定する必要があります",
		"Payment method is required": "お支払い方法が必要です"
	},
	"The ad does not exist or has been deleted": "広告が存在しないか、削除されました",
	"The device has been bound, Please untie it first": {
		"": "端末が固定されています。最初に解除してください。"
	},
	"The device is not exist": {
		"": "デバイスが存在しません。"
	},
	"The email is already registered!": "メールは既に登録されています！",
	"The item does not exist or has been deleted": "アイテムが存在しないか、削除されました",
	"The mobile number is already registered!": "携帯電話番号はすでに登録されています！",
	"The user does not exist or has been deleted": "ユーザーが存在しないか、削除されました",
	"Update plan error": "更新計画エラー",
	"User does not exist, Please try again later!": "ユーザーが存在しません。しばらくしてからもう一度お試しください！",
	"You do not have permission": "あなたには許可がありません",
	"ad-template": {
		"edit": {
			"Add Builder Template": "ビルダーテンプレートを追加",
			"Back": "バック",
			"Builder Template": "ビルダーテンプレート",
			"Edit Builder Template": "ビルダーテンプレートの編集",
			"Name": "名",
			"Save": "保存する",
			"Status": "状態",
			"Type": "タイプ"
		},
		"list": {
			"Builder Template": "ビルダーテンプレート",
			"Builder Template List": "ビルダーテンプレートリスト",
			"Name": "名",
			"Status": "状態",
			"Type": "タイプ",
			"data-table-title": "ビルダーテンプレートリスト"
		}
	},
	"ad-transaction": {
		"list": {
			"AD Transaction": "ADトランザクション",
			"AD Transaction List": "ADトランザクションリスト",
			"Ads": "広告",
			"Collection": "コレクション",
			"Device": "デバイス",
			"Type": "",
			"Campaign": "",
			"Number": "数"
		}
	},
	"admin-nav": {
		"Tutorial": "",
		"Logout": "ログアウト",
		"Manager": "マネージャー",
		"New Enquiry": "新規お問い合わせ",
		"Online": "オンライン",
		"Pending Ad": "保留中の広告",
		"Pending User": "保留中のユーザー",
		"Profile": "プロフィール",
		"User": "ユーザー",
		"User Manual": "ユーザーマニュアル"
	},
	"ads": {
		"edit": {
			"3D Effect Link": "3D効果リンク",
			"Ad Template": "広告テンプレート",
			"Add Advertising": "広告を追加",
			"Additional Discount": "追加割引",
			"Advanced": "高度な",
			"Advertising": "広告",
			"Advertising End Date": "広告終了日",
			"Approval": "承認",
			"Approved": "承認済み",
			"Back": "バック",
			"Duration (s)": "期間（s）",
			"Edit Advertising": "広告を編集",
			"Extra Link": "追加リンク",
			"Name": "名",
			"Pending": "保留中",
			"Preview": "プレビュー",
			"Product Purchase link": "製品購入リンク",
			"Product Purchasing": "製品購入",
			"Promotional Text": "宣伝文",
			"Reject": "拒否する",
			"Rejected": "拒否されました",
			"Save": "保存する",
			"Second(s)": "秒",
			"Select products for this Ad": "この広告の製品を選択してください",
			"Status": "状態",
			"Upgrade": "アップグレード",
			"Video Url": "動画のURL",
			"Warning!": "警告！",
			"abbr": {
				"create": "独自の広告を作成する",
				"preview": "広告をプレビューする",
				"warning content": "パブリックに十分なADバランスがありません。アップグレードしてください。"
			}
		},
		"list": {
			"Advertising": "広告",
			"Advertising List": "広告リスト",
			"Approval Status": "承認状況",
			"Approved": "承認済み",
			"Due Date": "期日",
			"Duration (s)": "期間（s）",
			"Name": "名",
			"Pending": "保留中",
			"Products": "製品",
			"Created Date": "作成日",
			"Status": "状態",
			"Type": "タイプ"
		},
		"quickly-post-add": {
			"AR Link / 3D Link": "ARリンク/ 3Dリンク",
			"Ad Name": "広告名",
			"Ad Template": "広告テンプレート",
			"All": "すべて",
			"Campaign Name": "キャンペーン名",
			"Cancel": "キャンセル",
			"Collection Name": "コレクション名",
			"Company Type": "会社の種類",
			"Contact Me 3D Link": "連絡先3Dリンク",
			"Contact Me Video Link": "連絡先ビデオリンク",
			"Create your own ads": "独自の広告を作成する",
			"Device List": "デバイスリスト",
			"Duration (s)": "期間（s）",
			"Edit": "編集",
			"End Date": "終了日",
			"Finish": "フィニッシュ",
			"Let me think": "考えさせて",
			"Maps": "地図",
			"Name": "名",
			"Next": "次",
			"On The Map": "地図上で",
			"Previous": "前",
			"Publish to Device": "デバイスに公開",
			"Publishing Device": "公開デバイス",
			"Reject": "拒否する",
			"Search": "サーチ",
			"Second(s)": "秒",
			"Shop Link": "ショップリンク",
			"Start Date": "開始日",
			"Step": "ステップ%{num}",
			"Submit": "提出する",
			"Success": "成功",
			"Text": "テキスト",
			"abbr": {
				"another": "別のものを作成する",
				"created": "広告が作成されます。",
				"device select": "公開するデバイスを選択してください",
				"introduction": "ここでは、広告を投稿したり、独自の製品やレストランを宣伝したりできます。そして、公開したいデバイスを選択して、始めましょう。",
				"privilege intro": "購読すると、次の特権が付与されます。",
				"start": "今すぐADの作成を開始してください！",
				"step1": "ステップ1：基本情報",
				"step2": "Step2：広告をデザインする",
				"upgrate check": "アカウントをアップグレードしてもよろしいですか？",
				"wait": "プラットフォームの承認をお待ちください。",
				"welcome": "Adsmodoへようこそ",
				"yes": "はい、確かです！"
			},
			"another": "別のものを作成する",
			"created": "広告が作成されます。",
			"introduction": "ここでは、広告を投稿したり、独自の製品やレストランを宣伝したりできます。そして、公開したいデバイスを選択して、始めましょう。",
			"welcome": "Adsmodoへようこそ"
		}
	},
	"ads_admin": {
		"website": {
			"setting": {
				"Home Page": "ホームページ",
				"Home Page List": "ホームページリスト",
				"Title": "題名",
				"Created At": "で作成",
				"Updated At": "更新日"
			}
		},
		"pages": {
			"home": {
				"Add Home Page": "ホームページを追加",
				"Website Link": "ウェブサイトリンク",
				"Company Type List": "会社タイプリスト",
				"ADVERTISEMENT Content": "広告コンテンツ",
				"About Us": "私たちに関しては",
				"Banner": "バナー",
				"Contact Us": "お問い合わせ",
				"Content": "コンテンツ",
				"Edit Home Page": "ホームページを編集",
				"How to use": "使い方",
				"Image": "画像",
				"Introduction": "前書き",
				"Menu Name": "メニュー名",
				"Multiple select": "複数選択",
				"Project": "プロジェクト",
				"SHOPKEEPER Content": "SHOPKEEPERコンテンツ",
				"Scenario": "シナリオ",
				"Sub Title": "サブタイトル",
				"Team Member": "チームメンバー",
				"Title": "タイトル",
				"Overall": "全体",
				"Font Color": "フォントの色",
				"White": "白い",
				"Black": "黒",
				"left": "左",
				"center": "セントラル",
				"right": "右",
				"Edit": "編集",
				"Add": "追加",
				"Title Position": "タイトル位置",
				"Background Transparent": "背景透明",
				"Left/Right Image": "",
				"Left Title": "左のタイトル",
				"Left Content": "左コンテンツ",
				"Right Title": "正しいタイトル",
				"Right Content": "適切なコンテンツ",
				"Scenario List": "シナリオ一覧",
				"Team List": "チームリスト",
				"Website": "ウェブサイト",
				"EditHomePage": "ホームページを編集"
			}
		},
		"scenario": {
			"edit": {
				"Edit Scenario": "",
				"Add Scenario": "",
				"Scenario Image": "",
				"Name": "",
				"Description": ""
			},
			"list": {
				"Scenario": "",
				"Scenario List": "",
				"Image": "",
				"Name": "",
				"Description": ""
			}
		},
		"team": {
			"edit": {
				"Add Team Member": "チームメンバーを追加",
				"Edit Team Member": "チームメンバーの編集",
				"Image": "画像",
				"Job Title": "職名",
				"Name": "名",
				"Text": "テキスト"
			},
			"list": {
				"Team": "チーム",
				"Team List": "チームリスト",
				"Image": "画像",
				"Job Title": "役職",
				"Created At": "作成者",
				"Ad Type": "広告タイプ",
				"Name": "名",
				"Team Member List": "チームメンバーリスト"
			}
		},
		"menu": {
			"edit": {
				"Edit Menu": "編集メニュー",
				"Add Menu": "メニューを追加",
				"Name": "名前",
				"Menu Type": "メニュータイプ",
				"Parent Menu": "親メニュー",
				"Link": "リンク",
				"Show On Footer": "フッターに表示",
				"Please enter the name": "名前を入力してください",
				"Please enter the link": "リンクを入力してください"
			},
			"list": {
				"Menu": "メニュー",
				"Menu List": "メニューリスト",
				"Name": "名前",
				"Type": "タイプ",
				"Parent": "親",
				"Link": "リンク"
			}
		}
	},
	"already registered!": "すでに登録！",
	"bcrypt error": "bcryptエラー",
	"campaigns": {
		"edit": {
			"Add Campaign": "キャンペーンを追加",
			"All": "すべて",
			"Back": "バック",
			"Cancel": "キャンセル",
			"Company Type": "会社の種類",
			"Edit Campaign": "キャンペーンを編集",
			"End Date": "終了日",
			"Finish": "フィニッシュ",
			"Maps": "地図",
			"Name": "名",
			"On The Map": "地図上で",
			"Publishing Device": "公開デバイス",
			"Save": "保存する",
			"Search": "サーチ",
			"Select Ad Collection": "広告コレクションを選択",
			"Search devices": "",
			"Open Map": "地図を開く",
			"Target Devices": "対象デバイス",
			"Start Date": "開始日",
			"abbr": {
				"Select device": "デバイスを選択",
				"select": "公開するデバイスを選択してください"
			},
			"Search Devices": "デバイスを検索",
			"Company": "会社",
			"City": "市",
			"State": "州",
			"Distance": "距離",
			"Map": "地図"
		},
		"list": {
			"Ad Collections": "広告コレクション",
			"Campaigns": "キャンペーン",
			"Campaigns List": "キャンペーンリスト",
			"Devices": "デバイス",
			"End Date": "終了日",
			"Name": "名",
			"Start Date": "開始日",
			"Status": "状態"
		}
	},
	"cart": {
		"Adsmodo": "アズモド",
		"Apply": "適用する",
		"Continue Shopping": "ショッピングを続ける",
		"My Cart": "私のカート",
		"Order Summary": "注文の概要",
		"Price": "価格",
		"Proceed to Checkout": "チェックアウトに進む",
		"Product": "製品",
		"QTY": "数量",
		"Seller": "売り手",
		"Shipping": "運送",
		"Shipping Fee": "送料",
		"Sub Total": "小計",
		"Total": "合計",
		"You save": "保存します",
		"Coupon code": "Coupon code",
		"promotion": "Promo Code",
		"Please select your gift Card code": "Please select your gift Card code"
	},
	"collections": {
		"edit": {
			"Add Ad Collection": "広告コレクションを追加",
			"Back": "バック",
			"Edit Ad Collection": "広告コレクションを編集",
			"Name": "名",
			"Publishing Device": "公開デバイス",
			"Save": "保存する",
			"Select Ads": "広告を選択"
		},
		"list": {
			"Ads": "広告",
			"Ads Collection": "広告コレクション",
			"Ads Collection List": "広告コレクションリスト",
			"Name": "名"
		}
	},
	"common": {
		"Search": "Search",
		"404": {
			"abbr": {
				"back": "ダッシュボードに戻る",
				"sorry": "申し訳ありませんが、ページが見つかりません"
			}
		},
		"ad-image": {
			"Click to select picture": "クリックして画像を選択します"
		},
		"ad-video": {
			"Click to select video": "クリックしてビデオを選択"
		},
		"bind-credit-card": {
			"Bind": "バインド",
			"CVC": "CVC",
			"Card Number:": "カード番号：",
			"MM": "MM",
			"YYYY": "YYYY"
		},
		"select-ad-device": {
			"Device List": "デバイスリスト",
			"Let me think": "考えさせて",
			"Yes, I'm Sure!": "はい、確かです！",
			"abbr": {
				"plan": "毎月100ドルの広告サービスを計画する",
				"upgrade check": "アカウントをアップグレードしてもよろしいですか？"
			}
		},
		"tui-image-editor": {
			"Cancel": "キャンセル",
			"Edit image": "画像を編集",
			"Image Resolution :": "画像解像度：",
			"Load Assets": "アセットをロードする",
			"Load Promotion Asset": "ロードプロモーションアセット",
			"Load assets": "アセットをロードする",
			"Promotion assets": "プロモーション資産",
			"Save": "保存する"
		}
	},
	"company-type": {
		"edit": {
			"Add Company Type": "会社タイプを追加",
			"Back": "バック",
			"Edit Company Type": "会社タイプを編集",
			"Name": "名",
			"Save": "保存する",
			"description": "説明",
			"Description": "説明"
		},
		"list": {
			"Company Type": "会社の種類",
			"Company Type List": "会社タイプ一覧",
			"Created Date": "作成日",
			"Name": "名"
		}
	},
	"contact-me": {
		"edit": {
			"Ad": "広告",
			"Ad Collection": "広告コレクション",
			"Back": "バック",
			"Cell Phone": "携帯電話",
			"Contact Me": "私に連絡して",
			"Device": "デバイス",
			"Email": "Eメール"
		},
		"list": {
			"Ad": "広告",
			"Campaign": "キャンペーン",
			"Collection": "コレクション",
			"Contact Me": "私に連絡して",
			"Contact Me List": "連絡先リスト",
			"Device": "デバイス",
			"Email": "Eメール",
			"Phone Number": "電話番号",
			"action": "アクション",
			"data-table-title": "Contact Me List"
		}
	},
	"crm": {
		"contacts-list": {
			"Business address": "職場の住所",
			"Contacts": "連絡先",
			"Contacts List": "連絡先リスト",
			"Email": "Eメール",
			"Is Admin": "管理者です",
			"Mobile": "モバイル",
			"Name": "名"
		}
	},
	"customer failed: create customer failed": "顧客の失敗：顧客の作成に失敗しました",
	"customer failed: create token failed": "顧客の失敗：トークンの作成に失敗しました",
	"dataTable": {
		"Add": "追加",
		"Are you sure to deactivate it?": "無効にしますか？",
		"Are you sure to delete it?": "削除してもよろしいですか？",
		"Cancel": "キャンセル",
		"Choose your options below and drop one into the inbox of anyone on your list!": "以下のオプションを選択し、リスト上の誰の受信トレイにもドロップします！",
		"Confirm": "確認",
		"Deactivate": "無効化",
		"Delete": "削除する",
		"Operate": "運営する",
		"Please select the item to delete": "削除するアイテムを選択してください.",
		"Please select the loyalty member to give reward": "報酬を与えるために忠誠会員を選択してください！",
		"Reward Gift Card": "特典ギフトカード",
		"This operation cannot be undone": "この操作は元に戻せません.",
		"We offer electronic gift cards in any denomination": "あらゆる種類の電子ギフトカードを提供しています。"
	},
	"database error": "データベースエラー",
	"devices": {
		"addCampaign": {
			"Ad Collections": "広告コレクション",
			"Ad Images": "広告画像",
			"Ad List": "広告リスト",
			"Add Campaign": "キャンペーンを追加",
			"Availavle Campaigns": "利用可能なキャンペーン",
			"Back": "バック",
			"Campaign Detail": "キャンペーンの詳細",
			"Campaign List": "キャンペーンリスト",
			"Campaigns": "キャンペーン",
			"Device": "デバイス",
			"Device ID": "デバイスID",
			"Device Name": "装置名",
			"Drag available campaigns here to run on device": "利用可能なキャンペーンをここにドラッグして、デバイスで実行します",
			"Duration(s)": "期間",
			"End Date": "終了日",
			"Name": "名",
			"Save": "保存する",
			"Start Date": "開始日",
			"Status": "状態",
			"This ad have no images": "この広告には画像がありません"
		},
		"buy-log": {
			"Buy Device Log": "デバイスログを購入",
			"Buy Device Log List": "デバイスログリストを購入",
			"Name": "名",
			"Quantity": "量",
			"Status": "状態",
			"Unit Price": "単価",
			"Permission": "Permission"
		},
		"buy-product": {
			"Back": "バック",
			"Buy Device": "デバイスを購入",
			"Device Quantity": "デバイス数量",
			"Location": "ロケーション",
			"Device Type": "デバイスタイプ",
			"Save": "保存する",
			"Add Location": "場所を追加"
		},
		"edit": {
			"Add Device": "デバイスを追加",
			"Are you sure to activate this device ?": "このデバイスをアクティブにしますか？",
			"Are you sure to deactivate this device ?": "このデバイスを無効にしますか？",
			"Back": "バック",
			"Business Name": "商号",
			"Cancel": "キャンセル",
			"Confirm": "確認",
			"Device ID": "デバイスID",
			"Device Info": "機器情報",
			"Device Name": "装置名",
			"Device Status": "デバイスの状態",
			"Device Type": "デバイスタイプ",
			"Edit Device": "デバイスを編集",
			"Manufacturer": "メーカー",
			"Model": "モデル",
			"Name": "名",
			"Offline": "オフライン",
			"Online": "オンライン",
			"Placement Location": "配置場所",
			"Platform": "プラットフォーム",
			"Save": "保存する",
			"Select Ad Collections": "広告コレクションを選択",
			"Serial": "シリアル",
			"Toggle": "トグル",
			"UUID": "UUID",
			"Version": "バージョン",
			"Your device is now Offline!": "デバイスがオフラインになりました！",
			"Your device is now Online!": "デバイスがオンラインになりました！",
			"Your device need to register via App first !": "お使いのデバイスは、最初にアプリ経由で登録する必要があります！"
		},
		"list": {
			"Ad Collections": "広告コレクション",
			"Businesss Name": "商号",
			"Campaigns": "キャンペーン",
			"Device ID": "デバイスID",
			"Device Info": "機器情報",
			"Devices": "デバイス",
			"Devices List": "デバイスリスト",
			"Name": "名",
			"Position": "ポジション",
			"Status": "状態",
			"User": "ユーザー",
			"Public Name": "公開名",
			"Location": "ロケーション",
			"ID": "ID",
			"title": "デバイス",
			"data-table-title": "デバイスリスト"
		},
		"purchase-log": {
			"Device": "デバイス",
			"Order No": "注文番号",
			"Purchase Log": "購入ログ",
			"Purchase Log List": "購入ログリスト",
			"Quantity": "量",
			"Subscription": "購読",
			"Total Price": "合計金額",
			"Unit Price": "単価",
			"User Name": "ユーザー名"
		}
	},
	"email": {
		"create-email": {
			"Back": "バック",
			"Email From": "からのメール",
			"Message": "メッセージ",
			"Send": "送る",
			"Send To": "に送る",
			"Subject": "件名"
		}
	},
	"email-log": {
		"edit": {
			"Back": "バック",
			"Content": "コンテンツ",
			"Created At": "作成者：",
			"Email Log": "メールログ",
			"Name": "名",
			"Send Status": "ステータスを送信",
			"Subject": "件名",
			"Template": "テンプレート",
			"To": "に",
			"from": "から"
		},
		"list": {
			"Email Log": "メールログ",
			"Email Log List": "メールログリスト",
			"Email template": "メールテンプレート",
			"Name": "名",
			"from": "から",
			"to": "に"
		}
	},
	"email-template": {
		"edit": {
			"Add Email Template": "メールテンプレートを追加",
			"Available keyword": "利用可能なキーワード",
			"Back": "バック",
			"Edit Email Template": "メールテンプレートを編集",
			"Email Body": "メール本文",
			"Email From": "からのメール",
			"Name": "名",
			"Save": "保存する",
			"Send To": "に送る",
			"Status": "状態",
			"Subject": "件名",
			"abbr": {
				"note": "バックオフィス管理者のみが使用します。受信者が複数の場合は、カンマを使用して分割してください。"
			},
			"template Type": "テンプレートタイプ"
		},
		"list": {
			"Draft": "ドラフト",
			"Email Template": "メールテンプレート",
			"Email Template List": "メールテンプレートリスト",
			"Name": "名",
			"Published": "公開済み",
			"Status": "状態",
			"Type": "タイプ",
			"badge-danger": "バッジ危険",
			"badge-success": "バッジ成功"
		}
	},
	"enquiry": {
		"edit": {
			"Add Enquiry": "お問い合わせを追加",
			"Back": "バック",
			"Edit Enquiry": "お問い合わせを編集",
			"Email": "Eメール",
			"Enquiry Type": "お問い合わせタイプ",
			"Message": "メッセージ",
			"Name": "名",
			"Phone": "電話",
			"Reply": "応答",
			"Save": "保存する",
			"Show Enquiry": "お問い合わせを表示"
		},
		"list": {
			"Created Date": "作成日",
			"Email": "Eメール",
			"Enquiry": "問い合わせ",
			"Enquiry List": "お問い合わせリスト",
			"Message": "メッセージ",
			"Name": "名",
			"Reply": "応答",
			"Type": "タイプ",
			"data-table-title": "Enquiry List"
		}
	},
	"get ad query error": "広告クエリエラーを取得",
	"get ads database error": "広告データベースのエラーを取得",
	"get item database error ": "アイテムデータベースエラーの取得",
	"get item query error": "アイテムクエリエラーの取得",
	"giftcard": {
		"3 Digit Security Code on Back of Card": "カードの背面にある3桁のセキュリティコード",
		"Amount of Gift Card": "ギフトカードの量",
		"CVC": "CVC",
		"Card Number": "カード番号",
		"Email From": "からのメール",
		"Expiration Date": "有効期限",
		"For payment confirmation": "入金確認のため",
		"Generate Gift Card": "ギフトカードを生成",
		"Generate Gift Card for the selected loyalty member": "選択したロイヤルティメンバーのギフトカードを生成する",
		"Gift Cards": "ギフトカード",
		"Gift Cards Log": "ギフトカードログ",
		"Recipient Email": "受信者のEメール",
		"Recipient Name": "受信者の名前"
	},
	"home": {
		"Accountant": "会計士",
		"Add Product to cart": "製品をカートに追加",
		"Age": "年齢",
		"CRM": "顧客関係管理",
		"Complete Purchases": "購入を完了",
		"Daily Visits": "毎日の訪問",
		"Dashboard": "ダッシュボード",
		"Device Orders": "デバイスの注文",
		"Edinburgh": "エディンバラ",
		"From Last 1 Month": "過去1か月から",
		"From Last 24 Hours": "過去24時間から",
		"Goal Completion": "目標の完了",
		"Integration Specialist": "統合スペシャリスト",
		"Junior Technical Author": "ジュニアテクニカル著者",
		"Last 3 Month Ad-Collection Record": "過去3か月の広告コレクションレコード",
		"Last 3 Month Subscription Record": "過去3か月のサブスクリプションレコード",
		"Last Month": "先月",
		"Last week": "先週",
		"Mail Inbox": "メール受信ボックス",
		"Marketplace": "市場",
		"Monthly Earnings": "毎月の収益",
		"Monthly Purchases": "毎月の購入",
		"Name": "名",
		"New York": "ニューヨーク",
		"Office": "事務所",
		"Orders in Last 10 months": "過去10か月の注文",
		"Pending Ad": "保留中の広告",
		"Pending User": "保留中のユーザー",
		"Position": "ポジション",
		"Recent Contacts": "最近の連絡先",
		"Revenue": "収入",
		"Salary": "給料",
		"Sales Assistant": "営業アシスタント",
		"San Francisco": "サンフランシスコ",
		"Send Inquiries": "お問い合わせを送信",
		"Senior Javascript Developer": "シニアJavascript開発者",
		"Start date": "開始日",
		"System Architect": "システムアーキテクト",
		"Tokyo": "東京",
		"Total Subscriptions": "合計サブスクリプション",
		"Unique Ad-Viewers": "ユニークな広告視聴者",
		"Visit Premium plan": "プレミアムプランにアクセス",
		"Weekly Top 5 Advertisement": "ウィークリートップ5広告",
		"select device error": "デバイスの選択エラー"
	},
	"id required": "IDが必要です",
	"image is required to show ad": "広告を表示するには画像が必要です",
	"main-nav": {
		"Sign In": "サインイン",
		"Sign Up": "サインアップ",
		"Terms & Conditions": "Terms & Conditions"
	},
	"member": {
		"About": "約",
		"Back": "バック",
		"Cancel": "キャンセル",
		"Clear": "クリア",
		"Confirm": "確認",
		"Contact Info": "連絡先情報",
		"Description": "説明",
		"Earned Points": "獲得ポイント",
		"Edit": "編集",
		"Email Verification Success": "メール検証の成功",
		"Explore": "探検する",
		"Got it!": "とった！",
		"If you experience any issues with this Reward,please feel free to contact": "この特典で問題が発生した場合は、お気軽にお問い合わせください",
		"LearnMore": "もっと詳しく知る",
		"Please enter activation code sent to this number": "この番号に送信されたアクティベーションコードを入力してください",
		"Points history": "ポイント履歴",
		"Redeem": "引き換え",
		"Redeemable points can be exchanged for rewards at the Rewards page": "交換可能なポイントは、特典ページで特典と交換できます",
		"Save": "セーブ",
		"Share": "シェア",
		"Special Deals": "特別価格",
		"Spent Points": "使用済みポイント",
		"Terms and Conditions": "規約と条件",
		"What are Redeemable Points?": "交換可能ポイントとは何ですか？",
		"Your email has been verified.We will redirect you to home page": "メールが確認されました。ホームページにリダイレクトします",
		"nav": {
			"Adsmodo Loyalty Members Club": "Adsmodo Loyalty Members Club",
			"Home": "ホーム",
			"Log Out": "ログアウト",
			"My rewards": "私の報酬",
			"Products": "製品",
			"Profile": "プロフィール",
			"Purchase History": "購入履歴"
		}
	},
	"membership": {
		"detail": {
			"Cake Expired": "期限切れのケーキ",
			"Cake Used": "使用ケーキ",
			"Roylaty Member Detail": "Roylatyメンバーの詳細"
		},
		"form": {
			"Birthday": "お誕生日",
			"Join The Adsmodo Loyalty Club": "Adsmodoロイヤリティクラブに参加する",
			"Select Business Type*": "ビジネスタイプを選択*",
			"Submit": "提出する",
			"Team": "Team"
		},
		"list": {
			"Birthday": "お誕生日",
			"Email": "Eメール",
			"Loyalty Member": "ロイヤリティ会員",
			"Loyalty Member List": "ロイヤリティ会員リスト",
			"Mobile": "モバイル",
			"Name": "名",
			"Points": "ポイント"
		}
	},
	"no permission": "全く許可しません",
	"post:Device update error": "post：デバイス更新エラー",
	"post:sendPasscode error": "post：sendPasscodeエラー",
	"post:signin account error": "post：サインインアカウントエラー",
	"pre:add ad name validate failed": "pre：広告名の追加の検証に失敗しました",
	"pre:audit user query error": "pre：auditユーザークエリエラー",
	"pre:bind have been bound": "pre：bindがバインドされました",
	"pre:bind verify name failed": "pre：バインド検証名が失敗しました",
	"pre:bind verify serial failed": "pre：bind verify serial failed",
	"pre:delete database error": "pre：deleteデータベースエラー",
	"pre:find user": "pre：findユーザー",
	"pre:get purchase log database error": "pre：購入ログデータベースエラーの取得",
	"pre:member get error": "pre：memberエラーを取得",
	"pre:missing parameters": "pre：missing parameters",
	"pre:sendPasscode find user error": "pre：sendPasscodeユーザー検索エラー",
	"pre:signin device has been bound": "pre：signinデバイスがバインドされました",
	"pre:signin find user error": "pre：signinユーザー検索エラー",
	"pre:unbind the device is not exist": "pre：unbindデバイスは存在しません",
	"pre:update get campaign database error": "pre：updateキャンペーンデータベース取得エラー",
	"pre:update get member database error": "pre：updateメンバーデータベース取得エラー",
	"pre:update item query error": "pre：update item queryエラー",
	"pre:update missing parameters": "pre：欠落しているパラメーターを更新します",
	"pre:verification error": "pre：検証エラー",
	"product": {
		"ads": {
			"Recommended Products": "Recommended Products",
			"more products": "more products",
			"LearnMore": "Learn More"
		},
		"edit": {
			"Select Category is required": "Select Category is required",
			"Name is required": "Name is required",
			"Currency is required": "Currency is required",
			"giftfile is required": "giftfile is required",
			"You will join": "You will join",
			"club": "club",
			"You have chosen": "You have chosen",
			"clubs": "clubs",
			"Add Options": "オプションを追加",
			"Add Pricing": "価格を追加",
			"Add Product": "商品を追加する",
			"Add more variant option": "バリエーションオプションを追加する",
			"Add option value": "オプション値を追加",
			"Back": "バック",
			"Create Gift Card": "ギフトカードを作成",
			"Currency": "通貨*",
			"Description": "説明",
			"Edit Product": "製品を編集",
			"Gift Card": "ギフトカード",
			"Images": "画像",
			"Master price": "マスター価格",
			"Name": "名",
			"Option": "オプション",
			"Option Types": "オプションの種類",
			"Price": "価格",
			"Price Range": "価格帯*",
			"Pricing": "価格設定",
			"Qty": "数量",
			"Quantity": "量",
			"Quantity in Hand": "",
			"Is Returnable": "返品可能",
			"Size Description": "サイズ説明",
			"Save": "保存する",
			"Select Category": "カテゴリを選んでください*",
			"Shipping Price(Optional)": "送料（オプション）",
			"Variant Option": "バリアントオプション"
		},
		"list": {
			"Category": "カテゴリー",
			"Image": "画像",
			"Name": "名",
			"Price Range": "価格帯",
			"Products": "製品",
			"Products List": "製品リスト"
		},
		"public-detail": {
			"Add to cart": "カートに追加",
			"Available Stock": "利用可能在庫",
			"Options": "オプション",
			"Original": "元の",
			"Please choose the product to buy": "購入する製品を選択してください",
			"Price": "価格",
			"Total Price": "合計金額",
			"Product Detail": "製品の詳細",
			"Qty": "数量",
			"Shipping Price": "輸送価格"
		},
		"public-list": {
			"Detail": "詳細",
			"Product Catalog": "製品カタログ",
			"Shipping Price": "輸送価格"
		}
	},
	"ad-product-category": {
		"edit": {
			"Category Name is required": "Category Name is required",
			"Add Product Category": "製品カテゴリを追加",
			"Back": "バック",
			"Category Name": "種別名",
			"Edit Product Category": "製品カテゴリを編集",
			"Save": "保存する"
		},
		"list": {
			"Name": "名",
			"Product Category": "製品カテゴリ",
			"data-table-title": "製品カテゴリ一覧"
		}
	},
	"product-device": {
		"edit": {
			"Add Product Device": "製品デバイスの追加",
			"Back": "バック",
			"Device Name": "装置名",
			"Device Price": "デバイス価格",
			"Edit Product Device": "製品デバイスの編集",
			"Product Device": "製品デバイス",
			"Save": "保存する"
		},
		"list": {
			"Device Name": "装置名",
			"Device Price": "デバイス価格",
			"Product Device": "製品デバイス",
			"Product Device List": "製品デバイスリスト"
		}
	},
	"promotion": {
		"edit": {
			"Add Promotion": "プロモーションを追加",
			"Back": "バック",
			"Coupon count(s)": "クーポン数",
			"Description": "説明",
			"Edit Promotion": "プロモーションを編集",
			"End Date": "終了日",
			"Promotion Image": "プロモーション画像",
			"Promotion Type": "プロモーションの種類",
			"Promotional Text": "宣伝文",
			"Save": "保存する",
			"Start Date": "開始日",
			"Select Products": "製品を選択",
			"Max Purchase Amount": "最大購入金額",
			"Coupon Code": "クーポンコード",
			"Amount": "量",
			"Percentage": "パーセンテージ"
		},
		"list": {
			"Coupon Count": "クーポン数",
			"End Date": "終了日",
			"Name": "名",
			"Promotion": "昇進",
			"Promotion List": "プロモーションリスト",
			"Start Date": "開始日",
			"Type": "タイプ"
		},
		"log": {
			"Promotion Log": "プロモーションログ",
			"Date": "日付",
			"Customer Name": "顧客名",
			"Promotion": "昇進",
			"Received Amount": "受け取り金額"
		}
	},
	"reward": {
		"list": {
			"Reward List": "",
			"Name": "",
			"Description": "",
			"Points": "",
			"Contact": ""
		},
		"edit": {
			"Reward Edit": "",
			"Reward Add": "",
			"Image": "",
			"Name": "",
			"Reward By": "",
			"Location": "",
			"Points": "",
			"Mobile Number": "",
			"Email": "",
			"Terms & Conditions": "",
			"ADD": "",
			"About us": "",
			"Add your Terms & Conditions": ""
		}
	},
	"recaptcha error": "再捕捉エラー",
	"recaptchaToken is required": "recaptchaトークンが必要です",
	"role": {
		"edit": {
			"Add Role": "役割を追加",
			"Back": "バック",
			"Edit Role": "役割を編集",
			"Name": "名",
			"Role Permission": "役割の許可",
			"Save": "保存する"
		},
		"list": {
			"Name": "名",
			"Permission": "許可",
			"Role": "役割",
			"Role List": "役割リスト",
			"data-table-title": "Role List"
		}
	},
	"role-permission": {
		"edit": {
			"Ad Collection Module": "広告収集モジュール",
			"Add Role Permission": "役割の許可を追加",
			"Advertising Module": "広告モジュール",
			"Back": "バック",
			"Campaign Module": "キャンペーンモジュール",
			"Create": "作成する",
			"Day(s)": "日々）",
			"Delete": "削除",
			"Device Module": "デバイスモジュール",
			"Edit Role Permission": "役割の権限を編集",
			"Expiry Date": "有効期限",
			"List": "リスト",
			"Name": "名",
			"Retrieve": "回収する",
			"Save": "保存する",
			"Update": "更新",
			"E-Commerce Module": "電子商取引モジュール",
			"CRM Module": "CRMモジュール",
			"Level-1": "レベル1",
			"Level-2": "レベル2",
			"Level-3": "レベル3"
		},
		"E-Commerce Module": {
			"Products": "製品",
			"Product Category": "製品カテゴリー",
			"Promotion": "昇進",
			"Promotion Log": "プロモーションログ",
			"Generate Gift Cards": "ギフトカードを生成する",
			"Gift Card Log": "贈り物カードログ",
			"Rewards": "報酬"
		},
		"CRM Module": {
			"Email Log": "電子メールログ",
			"Email Template": "電子メールテンプレート",
			"Loyalty Club": "忠誠クラブ",
			"Enquiry": "問い合わせ"
		},
		"list": {
			"Name": "名",
			"Role Permission": "役割の許可",
			"Role Permission List": "ロール許可リスト",
			"data-table-title": "Role Permission List"
		}
	},
	"searching": {
		"Ad Collection": "広告コレクション",
		"Ads": "広告",
		"Apply": "適用する",
		"Campaign": "キャンペーン",
		"Clear filter": "フィルターをクリア",
		"Collections": "コレクション",
		"Detail": "詳細",
		"Device": "デバイス",
		"Duration": "期間",
		"Duration(s)": "期間",
		"End Date": "終了日",
		"Filter by": "で絞り込む",
		"First name": "ファーストネーム",
		"ID": "イド",
		"Items": "アイテム",
		"Last name": "苗字",
		"Name": "名",
		"No results found for": "の結果が見つかりません",
		"Position": "ポジション",
		"Results for": "の結果",
		"Start Date": "開始日",
		"Status": "状態",
		"Type": "タイプ",
		"User": "ユーザー"
	},
	"company": {
		"list": {
			"Company": "Company",
			"data-table-title": "company list",
			"Company Logo": "Company Logo",
			"Name": "name",
			"Phone": "Phone",
			"Website": "Website"
		},
		"create": {
			"Save": "Save",
			"Back": "Back",
			"Edit Company": "Edit Company",
			"Create Company": "Create Company",
			"Business Name is required": "Business Name is required",
			"Company Type is required": "Company Type is required",
			"Business phone is required": "Business phone is required",
			"Web Site is required": "Web Site is required",
			"Zipcode is required": "Zipcode is required",
			"Business Address is required": "Business Address is required",
			"Business Logo": "Business Logo",
			"Business Name": "Business Name",
			"Company Type": "Company Type",
			"Business phone": "Business phone",
			"Web Site": "Web Site",
			"Zipcode": "Zipcode",
			"Business Address": "Business Address"
		},
		"orderlist": {
			"Date": "",
			"Customer Name": "",
			"Product": "",
			"Qty": "",
			"Total Price": "",
			"Pay Status": "",
			"Finish State": ""
		},
		"createChildCompanyAndStripe": {
			"Create Company Stripe Account": "",
			"Edit Company Stripe Account": "",
			"Name": "",
			"Birthday": "",
			"Email": "",
			"SSN": "",
			"Account Number": "",
			"Routing Number": "",
			"Company Name": "",
			"MCC": "",
			"City": "",
			"Address Line 1": "",
			"Postal code": "",
			"State": "",
			"Phone": ""
		}
	},
	"setting": {
		"advertising": {
			"Ad Template Type": "広告テンプレートタイプ",
			"Advertising Setting": "広告設定",
			"Day(s) ": "日々）",
			"Duration (s)": "期間（s）",
			"Expiry Date": "有効期限",
			"Expires in": "",
			"Name": "名",
			"Save": "保存する",
			"Second(s)": "秒",
			"Subscription Plan": "サブスクリプションプラン",
			"day": "日々）"
		},
		"general": {
			"Default Language": "既定の言語",
			"Generel Settings": "ジェネレル設定",
			"Name": "名",
			"New User Default Role": "新しいユーザーのデフォルトの役割",
			"Save": "保存する"
		},
		"product": {
			"Name": "名",
			"Number": "数",
			"Price": "価格",
			"Product Name": "商品名",
			"Product Number": "製品番号",
			"Product Price": "製品価格",
			"Product Service": "製品サービス",
			"Product Text": "製品テキスト",
			"Product Type": "製品型",
			"Save": "保存する",
			"Service": "サービス",
			"Subscription Lists": "購読リスト",
			"Subscription Name": "サブスクリプション名",
			"Subscription Type": "サブスクリプションの種類",
			"Usage": "使用法"
		}
	},
	"sign": {
		"forget": {
			"Authentication required": "認証が必要",
			"We sent": "メールにワンタイムパスワードを送りました",
			"Please enter it below": "下に入れてください",
			"OTP will expired in": "1回のパスワードは期限切れになります",
			"OTP has expired": "1回のパスワードが期限切れになっている。",
			"Resend OTP": "1回のパスワードの再送",
			"Enter OTP": "ワンタイムパスワードを入力する",
			"Required": "必須",
			"Passcode is required": "パスコードが必要です",
			"Email is required": "電子メールが必要です",
			"Please select your account type": "アカウントタイプを選択してください",
			"Please type your email or mobile": "モバイルをタイプしてください",
			"mdi-reply": "MDI回答",
			"Reset your Password": "パスワードをリセット",
			"Reset my password": "パスワードをリセットします",
			"Password Changed": "パスワード変更",
			"You can now login with your new password": "今すぐあなたの新しいパスワードでログインできます",
			"Login": "ログイン",
			"Old Password": "古いパスワード",
			"New Password": "新しいパスワード",
			"Confirm Password": "パスワードを確認します",
			"Forgot Password": "忘れられたパスワード",
			"Email or mobile": "電子メールまたは携帯電話番号",
			"Enter the email address or mobile phone number": "あなたのADSMODOアカウントに関連した電子メールアドレスまたは携帯電話番号を入力してください。",
			"Continue": "続行",
			"Reset Password": "パスワードを再設定する",
			"Sign In": "サインイン",
			"abbr": {
				"send": "一度だけパスコードを送ってください"
			}
		},
		"sign-in": {
			"Create an account": "アカウントを作成する",
			"Forgot password": "パスワードをお忘れですか？",
			"Loyalty Member Sign In": "ロイヤリティ会員サインイン",
			"Sign In": "サインイン",
			"abbr": {
				"send": "一度だけパスコードを送ってください"
			},
			"Already have an account?": "",
			"Forgot-Password": "パスワードをお忘れですか？"
		},
		"sign-up": {
			"Quick Sign Up": "クイックサインアップ",
			"Complete Sign Up": "サインアップを完了する",
			"Address:": "住所：",
			"Ads My biz": "Ads My biz",
			"All of Above": "上記のすべて",
			"Business Address": "職場の住所",
			"Business Name": "商号",
			"Business phone": "ビジネス用電話機",
			"Card Number:": "カード番号：",
			"Company Type": "会社の種類",
			"Confirm": "確認",
			"Confirm Information": "情報を確認する",
			"Confirm password": "パスワードを認証する",
			"Create user account": "ユーザーアカウントを作成する",
			"Email": "Eメール",
			"Email:": "Eメール：",
			"Extra $": "余分な$",
			"First Name": "ファーストネーム",
			"First Name:": "ファーストネーム：",
			"Help others": "他の人を助ける",
			"Last Name:": "苗字：",
			"Next": "次",
			"Password": "パスワード",
			"Payment Options": "支払いオプション",
			"Phone": "電話",
			"Phone:": "電話：",
			"Prev": "前の",
			"Price:": "価格：",
			"Price(month):": "価格（月）：",
			"Product:": "製品：",
			"Quantity": "量",
			"Select Adsmodo Business Assistant": "Adsmodo Business Assistantを選択してください",
			"Subscription Plan": "サブスクリプションプラン",
			"Tell us about your goal": "目標について教えてください",
			"Web Site": "ウェブサイト",
			"Zipcode": "郵便番号",
			"abbr": {
				"agree": "利用規約を読み、同意します。",
				"business info": "あなたのビジネスについて教えてください",
				"web tutorial": "規約と条件"
			},
			"last Name": "苗字"
		}
	},
	"signin verify error": "サインイン検証エラー",
	"signup password error": "サインアップパスワードエラー",
	"single": {
		"component": {
			"Contact info": "連絡先情報",
			"Email address": "電子メールアドレス",
			"Input your contact info to get coupons and subscribe our service": "連絡先情報を入力してクーポンを取得し、サービスを購読します。",
			"Input your contact info to get coupons!": "クーポンを取得するには連絡先情報を入力してください！",
			"Phone number": "電話番号",
			"Submit": "提出する",
			"abbr": {
				"get contact info": ""
			}
		}
	},
	"subscription": {
		"edit": {
			"Are you sure to activate this plan?": "このプランをアクティブにしますか？",
			"Are you sure to deactivate this plan?": "このプランを無効にしますか？",
			"Your plan is now Active!": "あなたの計画は現在有効です！",
			"Your plan is now Inactive!": "あなたの計画は現在非アクティブです！",
			"Active": "アクティブ",
			"Inactive": "非活性",
			"Add Subscription Plan": "サブスクリプションプランを追加",
			"Back": "バック",
			"Edit Subscription Plan": "サブスクリプションプランの編集",
			"Number": "数",
			"Price": "価格",
			"Save": "保存する",
			"Service": "サービス",
			"Subscription Name": "サブスクリプション名",
			"Subscription Plan": "サブスクリプションプラン",
			"Subscription Type": "サブスクリプションの種類",
			"Usage": "使用法",
			"Description": "",
			"Maximum Area Range": "最大エリア範囲"
		},
		"list": {
			"Price": "価格",
			"Subscription Plan": "サブスクリプションプラン",
			"Subscription Plan List": "サブスクリプションプランリスト",
			"Subscription Plan Type": "サブスクリプションプランタイプ"
		}
	},
	"user": {
		"account": {
			"Account Type": "口座の種類",
			"Subscription Plan Type": "",
			"Ad Address": "広告アドレス",
			"Basic Information": "基本情報",
			"Save Credit Card": "クレジットカードを保存",
			"Change Credit Card": "",
			"Current Credit Card": "",
			"Saved Payment Methods": "",
			"Bind Status": "バインド状態",
			"Bind credit card": "クレジットカードをバインド",
			"Business Address": "職場の住所",
			"Business Information": "ビジネス情報",
			"Business Name": "商号",
			"Business Phone": "ビジネス用電話機",
			"Cancel": "キャンセル",
			"Cancel Subscription": "購読をキャンセル",
			"Cell Phone": "携帯電話",
			"Company Type": "会社の種類",
			"Email": "Eメール",
			"Free": "無料",
			"Hi": "こんにちは",
			"Info": "情報",
			"Let me think": "考えさせて",
			"Name": "名",
			"Password": "パスワード",
			"Product Type": "製品型",
			"Profile Image": "プロフィール画像",
			"Redeem": "引き換える",
			"Rewards": "報酬",
			"Save": "保存する",
			"Skip Tour": "ツアーをスキップ",
			"Start Create Ads": "広告の作成を開始",
			"Subscription Plan": "サブスクリプションプラン",
			"Web Site": "ウェブサイト",
			"Zipcode/Postalcode": "郵便番号/郵便番号",
			"abbr": {
				"ad collection intro": "広告コレクションは、どの広告にどの広告を含めるかを定義し、\n                      各広告コレクションには複数の広告を含めることができます。",
				"ad intro": "広告に別の画像、テキスト、連絡先を含めることができます\n                      ボタン、リンクまたはビデオ、および広告画像には\n                      3D、AR、VRなどのさまざまなタイプ。",
				"campaign intro": "",
				"cancel subscribe check": "サブスクリプションサービスをキャンセルしてもよろしいですか？",
				"cancel subscribe info": "購読を解除すると、このWebサイトのすべての特権が失われます。",
				"privilege info": "購読すると、次の特権が付与されます。",
				"upgrade check": " アカウントをアップグレードしてもよろしいですか？",
				"welcome": "Adsmodoへようこそ",
				"yes": "はい、確かです！"
			},
			"Monthly": "",
			"Activated": "",
			"Current Plan": "",
			"Change Plan": "",
			"Upgrade Now": "",
			"Last 4 digit": "",
			"Expiring": ""
		},
		"edit": {
			"Add User": "ユーザーを追加する",
			"Back": "バック",
			"Can adding ads": "広告を追加できます",
			"Edit User": "ユーザーを編集",
			"Email": "Eメール",
			"Is Admin": "管理者です",
			"Mobile": "モバイル",
			"Name": "名",
			"Password": "パスワード",
			"Save": "保存する",
			"User Role": "ユーザー役割",
			"team": "Team"
		},
		"list": {
			"Email": "Eメール",
			"Is Admin": "管理者です",
			"Mobile": "モバイル",
			"Name": "名",
			"Role": "役割",
			"Users": "ユーザー",
			"Users List": "ユーザーリスト",
			"data-table-title": "User List"
		}
	},
	"user permission get error": "ユーザー許可取得エラー",
	"user-goal": {
		"edit": {
			"Add User Goal": "ユーザー目標を追加",
			"Back": "バック",
			"Edit User Goal": "ユーザーの目標を編集",
			"Name": "名",
			"Save": "保存する",
			"User Goal": "ユーザーの目標"
		},
		"list": {
			"Name": "名",
			"User Goal": "ユーザーの目標",
			"User Goal List": "ユーザー目標リスト"
		}
	},
	"validation errors": "検証エラー",
	"location": {
		"edit": {
			"Name": "名前",
			"Location": "ロケーション",
			"Add Location": "場所を追加",
			"Close": "閉じる",
			"Add": "追加",
			"title": "デバイスの場所編集"
		},
		"list": {
			"Device Location": "デバイスの場所",
			"Device Location List": "デバイス位置リスト"
		},
		"add": {
			"title": "デバイスの場所の追加"
		}
	},
	"description": "説明",
	"View-More-Cases": "他のケースを見る",
	"Contact Us": "お問い合わせ",
	"Login is abnormal, please try again later.": "Login is abnormal, please try again later.",
	"header": {
		"Ecommerce": "eコマース",
		"Pricing": "価格",
		"Get Started": "始めましょう",
		"Sign Out": "サインアウト"
	},
	"footer": {
		"copy_right": "著作権2018-2019全著作権所有。"
	},
	"security-tab": {
		"mdi-tune": "mdi-tune",
		"Choose": "Choose",
		"Set up": "Set up",
		"Verify": "Verify",
		"Finished": "Finished",
		"Two-factor Authentication": "Two-factor Authentication",
		"Enabled tasks": "Enabled tasks",
		"You can choose a task": "You can choose a task form the list below after you've login.",
		"Text message": "Text message",
		"mdi-message-text": "mdi-message-text",
		"Verification codes are sent by text message": "Verification codes are sent by text message",
		"mdi-shield-lock": "mdi-shield-lock",
		"Authenticator App": "Authenticator App",
		"Use the Authenticator app": "Use the Authenticator app to get verification codes, even when your phone is offline.",
		"How would you": "How would you like to receive your authentication codes?",
		"After entering your password": "After entering your password, you’ll be asked for a second verification step.",
		"Verification codes": "Verification codes are sent by text message.(Required a verified mobile phone number)",
		"SET UP": "SET UP",
		"Cancel": "Cancel",
		"Let's set up": "Let's set up your phone for verification",
		"What phone number": "What phone number do you want to use?",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"mdi-information": "mdi-information",
		"Change": "Change",
		"Two Factor Setup": "Two Factor Setup",
		"Take these few steps to enable": "Take these few steps to enable two-factor authentication and make your account more secure.",
		"Install an authenticator app": "Install an authenticator app on your mobile device.",
		"Recommended options: Google Authenticator": "Recommended options: Google Authenticator,Authy,Microsoft Authenticator",
		"Scan QR code with the authenticator": "Scan QR code with the authenticator.",
		"Enter the 2-step authentication": "Enter the 2-step authentication code provided by your app.",
		"Continue": "Continue",
		"I'm ready": "I'm ready",
		"Back": "Back",
		"Verify your number": "Verify your number",
		"We just sent a text message": "We just sent a text message with a verification code to",
		"Didn't get it? Resend code": "Didn't get it? Resend code.",
		"Scan this QR code": "Scan this QR code with your mobile app.",
		"Then enter the six digit code": "Then enter the six digit code provided by the app:",
		"Can't scan the code": "Can't scan the code?",
		"Great": "Great!",
		"Two-factor Authentication has been": "Two-factor Authentication has been enabled for your account",
		"All future logins will now require": "All future logins will now require you to enter a code supplied by your mobile message or Authenticator App.",
		"Finish": "Finish"
	},
	"reward-list": {
		"Used": "Used",
		"Adsmodo": "Adsmodo",
		"$": "$",
		"OFF YOUR PURCHASE": "OFF YOUR PURCHASE",
		"Gift Code:": "Gift Code:",
		"Valid till": "Valid till"
	},
	"reward-detail": {
		"Valid from 15 Oct 2019 to 15 Jan 2020": "Valid from 15 Oct 2019 to 15 Jan 2020",
		"points": "points",
		"mdi-check-bold": "mdi-check-bold",
		"mdi-phone": "mdi-phone",
		"mdi-email": "mdi-email",
		"Report Reward issue to Adsmodo": "Report Reward issue to Adsmodo"
	},
	"return-orders": {
		"Choose items to return": "Choose items to return",
		"Ordered on": "Ordered on",
		"Order Number": "Order Number",
		"Continue": "Continue",
		"Not returnable": "Not returnable",
		"Returnable untill": "Returnable untill",
		"Choose refund method": "Choose refund method",
		"Returning": "Returning",
		"Reason": "Reason",
		"We'll process your refund": "We'll process your refund to Visa ending 1234.",
		"Estimated refund timing": "Estimated refund timing: 2-3 business days after we receive the item.",
		"How do you want to send": "How do you want to send it back?",
		"$0": "$0",
		"Our deliveries will pickup": "Our deliveries will pickup your return package on the next business day.Printer required - you can print the label any time from Your Account or from the email we'll send you.",
		"Refund summary": "Refund summary",
		"Refund Subtotal": "Refund Subtotal:",
		"Shipping fee": "Shipping fee:",
		"Total refund": "Total refund:",
		"mdi-checkbox-marked-circle": "mdi-checkbox-marked-circle",
		"Your label is ready to print": "Your label is ready to print",
		"Securely pack the items": "Securely pack the items to be returned. Send all the items back to Adsmodo by",
		"mdi-cloud-upload": "mdi-cloud-upload",
		"Print label": "Print label"
	},
	"products-list": {
		"Adsmodo": "Adsmodo",
		"Out of stock": "Out of stock!",
		"Available stock": "Available stock"
	},
	"common-list": {
		"no-data": "レコードが見つかりません",
		"reset": "リセット",
		"warning": "警告",
		"delete-item-warning": "このアイテムを削除してもよろしいですか？削除操作はロールバックできません",
		"delete-items-warning": "これらのアイテムを削除してもよろしいですか？削除操作はロールバックできません",
		"delete-yes": "はい",
		"delete-no": "キャンセル",
		"operations": "運営"
	},
	"member-home": {
		"Special offers for you": "Special offers for you",
		"A confirmation email has been sent to": "A confirmation email has been sent to",
		"Click on the confirmation link in the email to verify your account.": "Click on the confirmation link in the email to verify your account",
		"Points": "Points",
		"My loyalty club": "My loyalty club",
		"Recommended loyalty club": "Recommended loyalty club",
		"More loyalty club": "More loyalty club",
		"More than": "More than",
		"clicks on ads": "clicks on ads",
		"Since this is your first login, please click": "Since this is your first login, please click this message to change your password",
		"There are currently": "There are currently",
		"promotional products": "promotional products",
		"Promotion code list": "Promotion code list",
		"Loyalty club name": "Loyalty club name",
		"Promotion code": "Promotion code",
		"Close": "Close",
		"View promo code": "View promo code"
	}
}
;