import eventBus from "../plugin/eventBus";

const hasLoading = ({ type }, includes, excludes) => {
  if (includes.length === 0 && excludes.length === 0) {
    return true;
  }

  if (includes.length > 0) {
    return includes.indexOf(type) > -1;
  }

  return excludes.length > 0 && excludes.indexOf(type) === -1;
};

const _loadingPlugin = store => {
  const includes = [];
  const excludes = [];
  store.subscribeAction({
    before: action => {
      if (hasLoading(action, includes, excludes)) {
        store.commit({ type: "loading/SHOW", payload: action.type });
      }
    },
    after: action => {
      if (hasLoading(action, includes, excludes)) {
        store.commit({ type: "loading/HIDE", payload: action.type });
      }
    }
  });
  eventBus.on("request_start", url => {
    store.commit({ type: "loading/START_Request", payload: url });
  });
  eventBus.on("request_end", url => {
    store.commit({ type: "loading/END_Request", payload: url });
  });
};

export { _loadingPlugin as loadingPlugin };
