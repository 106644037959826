import { filter, reduce } from "lodash";
import Vue from "vue";
import VueI18n from "vue-i18n";

export function getLanguages() {
  const localesConfig = process.env.LOCALES ? process.env.LOCALES.split(",") : ["en", "es", "zh", "zhtw"];
  const languages = [
    {
      text: "English",
      display: "English",
      value: "en"
    },
    {
      text: "Español (Spanish)",
      display: "Español",
      value: "es"
    },
    {
      text: "繁體中文",
      display: "繁體中文",
      value: "zhtw"
    },
    {
      text: "简体中文",
      display: "简体中文",
      value: "zh"
    },
    {
      text: "日本語 (Japanese)",
      display: "日本語",
      value: "ja"
    },
    {
      text: "한국어 (Korean)",
      display: "한국어",
      value: "ko"
    },
    {
      text: "မြန်မာ (Burmese)",
      display: "မြန်မာ",
      value: "mm"
    }
  ];
  return filter(languages, x => localesConfig.includes(x.value));
}

let language;
if (localStorage.getItem("locale") != null) {
  language = localStorage.getItem("locale");
} else {
  language = "en";
}

Vue.use(VueI18n);

import en from "../../locales/en.json";
import es from "../../locales/es.json";
import ja from "../../locales/ja.json";
import ko from "../../locales/ko.json";
import mm from "../../locales/mm.json";
import zh from "../../locales/zh.json";
import zhtw from "../../locales/zhtw.json";

const allLocales = {
  en,
  es,
  ja,
  ko,
  mm,
  zh,
  zhtw
};

const languages = getLanguages();

const messages = {};

reduce(
  languages,
  (r, x) => {
    r[x.value] = allLocales[x.value];
    return r;
  },
  messages
);

const locales = {
  locale: language, // change the default language here
  messages
};

export const i18n = new VueI18n(locales);
